<!-- Layout: Modern -->
<div class="modern-layout" [ngClass]="field.className" *ngIf="to.layout === 'modern'">
    <div class="user-input">
        <div class="top-label" *ngIf="to.symbol">
            <div class="symbol-input-left">
                <span>{{ to.symbol }}</span>
            </div>
            <div class="right-item" *ngIf="to?.required">
                <mat-label class="asterix--before"></mat-label>
            </div>
        </div>
        <label class="label-input-left" *ngIf="to?.label" [innerHtml]="to?.label">
        </label>
        <div [class]="to.readonly ? 'mat-form-field-read-only' : ''">
            <mat-form-field appearance="outline" floatLabel="never">
                <mat-select
                    [id]="id"
                    [formControl]="formControl"
                    [formlyAttributes]="field"
                    [placeholder]="to.placeholder"
                    [tabIndex]="to.tabindex"
                    [required]="to.required"
                    [multiple]="to.multiple"
                    [compareWith]="to.compareWith"
                    (selectionChange)="change($event)"
                >
                    <ng-container *ngIf="to.options | formlySelectOptions: field | async as selectOptions">
                        <ng-container *ngFor="let item of selectOptions">
                            <mat-optgroup *ngIf="item.group" [label]="item.label">
                                <mat-option
                                    *ngFor="let child of item.group"
                                    [value]="child.value"
                                    [disabled]="child.disabled"
                                    [innerHtml]="(child.label | countryFlagFormField) + ' ' + child.label"
                                >
                                    <!-- {{ child.label }} -->
                                </mat-option>
                            </mat-optgroup>
                            <mat-option *ngIf="!item.group" [value]="item.value" [disabled]="item.disabled" [innerHtml]="(item.label | countryFlagFormField) + ' ' + item.label">
                                <!-- {{ item.label }} -->
                            </mat-option>
                        </ng-container>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="formControl.hasError('uniqueCountry') && (showErr$ | async)">
                    <span>{{formControl.errors['uniqueCountry'].message}}</span>
                    <formly-validation-message [field]="field" *ngIf="!formControl.hasError('uniqueCountry')"></formly-validation-message>
                </mat-error>
                <mat-error *ngIf="!formControl.hasError('uniqueCountry')">
                    <formly-validation-message [field]="field"></formly-validation-message>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
