import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthModule } from '../auth';
import { ApiConstant } from '../types';
import { AdminLazyModule } from './admin-lazy.module';
import { AdminService } from './services';
import { ADMIN_API_TOKEN } from './types';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { QuestionFormModule } from '../question-form';
@NgModule({
    imports: [AdminLazyModule, AuthModule.forRoot(environment.api), QuestionFormModule.forRoot(environment.api)],
    exports: [AdminLazyModule],
    declarations: [DashboardComponent],
})
export class AdminModule {
    static forRoot(apiConstant: ApiConstant): ModuleWithProviders<AdminModule> {
        return {
            ngModule: AdminModule,
            providers: [
                {
                    provide: ADMIN_API_TOKEN,
                    useValue: apiConstant,
                },
                AdminService,
            ],
        };
    }
}
