import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { DatePickerHeader, HotelGroupProfileComponent, IomDashBoardComponent, IomFooterComponent, ListHotelComponent, ListHotelGroupComponent, ListUsersFormComponent, MetricsComponent, NewUserFormComponent } from './components';
import { IomAdminContainer, IomUserOnboardingContainer } from './containers';
import { HotelGroupDetailContainer } from './containers/hotel-group-detail.container';

@NgModule({
    declarations: [
        IomAdminContainer,
        ListUsersFormComponent,
        NewUserFormComponent,
        IomUserOnboardingContainer,
        IomDashBoardComponent,
        MetricsComponent,
        HotelGroupProfileComponent,
        HotelGroupDetailContainer,
        ListHotelComponent,
        ListHotelGroupComponent,
        IomFooterComponent,
        DatePickerHeader,
    ],
    imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule],
    exports: [
        IomAdminContainer,
        ListUsersFormComponent,
        NewUserFormComponent,
        IomUserOnboardingContainer,
        IomDashBoardComponent,
        MetricsComponent,
        HotelGroupProfileComponent,
        HotelGroupDetailContainer,
        ListHotelComponent,
        ListHotelGroupComponent,
        IomFooterComponent,
        DatePickerHeader
    ],
})
export class IomLazyModule {}
