import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserStatus } from 'src/app/modules';

@Component({
  selector: 'app-iom-footer',
  templateUrl: './iom-footer.component.html',
  styleUrls: ['./iom-footer.component.scss']
})
export class IomFooterComponent implements OnInit {

    constructor() {}
    @Input()
    employerStatus: UserStatus;

    @Input()
    hotelGroupId: string;

    @Input()
    errorMessages: string[];

    @Input()
    brandId: string;

    @Output()
    activeOrDeActiveHotel = new EventEmitter<{
        userId: string;
        status: UserStatus;
    }>();

    @Output()
    isDeleteHotelGroup = new EventEmitter<{
        isDelete: boolean,
        hotelGroupId: string,
    }>();

    ngOnInit(): void {}

    delete(){
        this.isDeleteHotelGroup.emit({isDelete: true,hotelGroupId: this.brandId});
    }

    activeOrDeactive() {
        this.activeOrDeActiveHotel.emit({
            userId: this.hotelGroupId,
            status: this.employerStatus
        });
    }

}
