import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-wrap-question',
    templateUrl: './wrap-question.component.html',
    styleUrls: ['./wrap-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapQuestionComponent extends FieldType implements OnInit {
    ngOnInit(): void {}
}
