import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { AttachmentDownloadResponse } from '../types';
import { CustomHttpErrorResponse } from 'src/app/modules/types';
import { FileInfo } from '../../shared';
const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class FileUploadService {
    fileUploadUrl = `${environment.api.endpoint}/file-upload`;
    private errorSub$ = new Subject<string>();
    error$ = this.errorSub$.asObservable();
    private qErrorSub$ = new Subject<string>();
    qError$ = this.qErrorSub$.asObservable();

    private listAttachmentSub$ = new Subject<FileInfo>();
    listAttachment$ = this.listAttachmentSub$.asObservable();
    lstFileInfor: FileInfo;

    constructor(private http: HttpClient) {}
    uploadFile(fileForm: FormData, questionId?: string): Observable<FileInfo> {
        this.errorSub$.next('');
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Upload file' });
        return this.http
            .post<FileInfo>(`${this.fileUploadUrl}/upload-file`, fileForm, {
                headers,
            })
            .pipe(
                tap((response: FileInfo) => {
                    if (response) {
                        this.listAttachmentSub$.next(response);
                    }
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (!questionId) {
                        this.errorSub$.next(err.error.error);
                    } else {
                        this.qErrorSub$.next(';' + questionId + ';' + err.error.error);
                    }
                    return EMPTY;
                }),
            );
    }
    downloadFileService(file: FileInfo) {
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Download file attachment',
        });

        return this.http
            .post(`${this.fileUploadUrl}/download-file`, file, {
                responseType: 'blob',
                observe: 'response',
                headers,
            })
            .pipe(
                map(response => ({
                    body: response.body,
                    contentDisposition: response.headers.get('content-disposition') || '',
                    contentType: response.headers.get('content-type') || 'application/octet-stream',
                })),
            );
    }
    openFile(response: AttachmentDownloadResponse<Blob>) {
        if (response && response.body) {
            const { contentType } = response;
            const filename = this.getFileNameFromContentDisposition(response);
            saveAs(new Blob([response.body], { type: contentType }), filename);
        }
    }
    private getFileNameFromContentDisposition(res: AttachmentDownloadResponse<Blob>) {
        const { contentDisposition } = res;
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const tempFilename = ((matches && matches[1]) || 'untitled').trim();
        const filename = tempFilename.replace(/["]/g, '');
        return filename;
    }
}
