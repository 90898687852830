import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BreadcrumbService } from '../../core';
import { PraDetails, Question } from '../../question-form';
import { BadgeWidgetOptions } from '../../shared/widgets/badge-widget/badge-widget.types';
import { GridWidgetOptions } from '../../shared/widgets/grid-widget/grid-widget.types';
import {
    ListWidgetItem,
    ListWidgetItemValueType,
    ListWidgetOptions,
} from '../../shared/widgets/list-widget/list-widget.types';
import { ScopingReviewService } from '../services/scoping-review.service';
import * as myGlobals from '../../../globals';
import { DOC_TYPES } from '../../print/types';
import { CountryFlagPipe } from '../../shared/pipes/country-flag.pipe';
import { AuthService } from '../../auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployerService } from '..';
import { ConfirmationDialog, ConfirmCancelDialog } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { RecruitmentAgenciesService } from '../../recruitment-agencies/services/recruitment-agencies.service';
import { PraUserInfo } from '../..';
// import format from 'date-fns/format';

@Component({
    selector: 'scoping-questions-detail',
    template: `
        <div class="grid-container1 layout-padding">
            <app-list-widget [options]="companyProfileOptions$ | async"></app-list-widget>
            <worker-statistics [listQuestion]="lstWorkerStatisticsQuestion$ | async"></worker-statistics>
            <app-grid-widget [options]="workderStatisticsOptions$ | async"></app-grid-widget>
            <div *ngFor="let praOption of lstPraOptions$ | async" class="agency-layout">
                <app-list-widget [options]="praOption" [isPraDetail]="true"></app-list-widget>
            </div>
        </div>
    `,
    styles: [
        `
            .layout-padding {
                margin: 48px 48px 48px;
            }
            .agency-layout {
                margin-top: 40px;
            }
        `,
    ],
    providers: [ScopingReviewService],
})
export class ViewScopingQuestionsContainer implements OnInit, OnDestroy {
    scopingQuestionId = '';

    private unsubscribe$ = new Subject();
    updatedBreadCumb = false;
    role$: Observable<string>;
    role: string = '';
    error$ = this.scopingReviewService.error$;

    lstWorkerStatisticsQuestion$ = this.scopingReviewService.lstWorkerStatisticsQuestion$.pipe(
        switchMap((workerStatistics: Question[]) => {
            const widgetItems = [];
            const pipe = new CountryFlagPipe();
            const lstCountryCode = workerStatistics.filter(x => x.controlType === 'drop-down-country' && !x.parentId);
            let lstCountryItem:any = [];
            let totalAgency = 0;
            lstCountryCode.forEach(ctr => {
                let i = 0;
                let object:any = {};
                let value_1 = 0;
                workerStatistics.forEach(item => {
                    if (item.parentId === ctr.id && item.value) {
                        if (i === 0) {
                           value_1 = item.value;
                        } else if (i === 1) {
                            totalAgency += item.value;
                        }
                        ++i;
                    }
                    if (i === 2) {
                        object['value'] = value_1;
                    }
                })
                if (ctr.value) {
                    const countryCode = pipe.getCountryCode(ctr.value)
                    const flag = pipe.transform(ctr.value);
                    object['htmlTemplate'] = `from&nbsp; ${flag}&nbsp;${ctr.value}`;
                    object['countryCode'] = countryCode;
                    object['countryName'] = ctr.value;
                    lstCountryItem.push(object);
                }
            })
            const widgetItemFirst: BadgeWidgetOptions = {
                title: 'Country of origin and number of migrant workers',
                lstHtml: lstCountryItem
            };

            const widgetlast: BadgeWidgetOptions = {
                title: 'Number of Recruitment Agencies involved',
                value: +totalAgency,
                description: 'Recruitment Agencies',
            };
            widgetItems.push(widgetlast);
            widgetItems.push(widgetItemFirst);
            return of(widgetItems);
        }),
    );

    companyProfileOptions$ = this.scopingReviewService.companyProfile$.pipe(
        tap((_: Question[]) => {

        }),
        switchMap((questions: Question[]) => {
            const companyName = 'Company Profile';
            const options: ListWidgetOptions = {
                title: companyName || '',
                data: questions.map(item => {
                    const widgetItem: ListWidgetItem = {
                        label: item.label,
                        value: item.value,
                        valueType: this.guessValueType(item.label),
                    };
                    return widgetItem;
                }),
            };
            return of(options);
        }),
    );
    workderStatisticsOptions$ = this.scopingReviewService.workerStatistics$.pipe(
        switchMap((workerStatistics: Question[]) => {
            const data = workerStatistics.map(stat => {
                const item = {
                    label: stat.label,
                    value: stat.value === '' || stat.value === null ? '0' : stat.value,
                };                
                return item;
            });
            const opts: GridWidgetOptions = {
                labelHeader: 'Current number of workers at the hotel',
                columns: [
                    { name: 'Local' },
                    { name: 'Migrant' },
                    {
                        name: 'Total',
                        valueFn: (dataItem: any) => {
                            const keys = Object.keys(dataItem);
                            let total = 0;
                            keys.forEach(key => (total += parseInt(dataItem[key]) || 0));
                            return total;
                        },
                    },
                ],
                data: data,
            };            
            return of(opts);
        }),
    );
    lstPraOptions$ = this.scopingReviewService.pras$.pipe(
        switchMap((pras: PraDetails[]) => {
            const praOptions: ListWidgetOptions[] = [];
            pras.forEach(praItem => {
                const questions = praItem.questions;
                const companyName = questions.find(question => question.code === 'emp_pra_scop_A1')?.value;
                const lstOfCountry = questions.filter(question => question.controlType === 'relationship' && question.value).map(q => q.value);
                const lstOfEmployed = questions.filter(question => question.controlType === 'employed' && question.value).map(q => q.value);
                let totalOfCountry = 0;
                let totalOfEmployed = 0;
                if (lstOfCountry && lstOfCountry.length > 0){
                    lstOfCountry.forEach(item => {
                        return totalOfCountry += item;
                    });
                }

                if (lstOfEmployed && lstOfEmployed.length > 0){
                    lstOfEmployed.forEach(item => {
                        return totalOfEmployed += item;
                    });
                }
                const lstNonDisplay = ['emp_pra_scop_A1', 'emp_pra_scop_A10', 'emp_pra_scop_A9_1', 'emp_pra_scop_A9_2',
                    'emp_pra_scop_A10_1', 'emp_pra_scop_A10_2', 'emp_pra_scop_A11_1', 'emp_pra_scop_A11_2']

                const options: ListWidgetOptions = {
                    title: companyName || '',
                    praStatus: praItem?.praStatus,
                    praName: questions.find(q => q.code === 'emp_pra_scop_A1')?.value,
                    questionnaireResponseId: praItem?.questionnaireResponseId,
                    data: questions
                        .filter(question => lstNonDisplay.indexOf(question.code) < 0 )
                        .map(item => {
                            if(item.code === 'emp_pra_scop_A11') {
                                item.label = "Total number of migrant workers from the Country of Origin that are currently employed by the company"
                                item.value = totalOfEmployed
                            }
                            if(item.code === 'emp_pra_scop_A9') {
                                item.value = totalOfCountry
                            }
                            const widgetItem: ListWidgetItem = {
                                label: item.label,
                                value: this.tryGetValue(item.value, item.controlType),
                                valueType: this.guessValueType(item.label),
                                code: item.code,
                            };
                            return widgetItem;
                        }),
                };
                praOptions.push(options);
            });
            return of(praOptions);
        }),
    );

    employerId = '';
    deactivateRA$ = this.employerService.deactivateRA$;
    agencyUser: PraUserInfo;

    constructor(
        private scopingReviewService: ScopingReviewService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private breadcrumbService: BreadcrumbService,
        private authService: AuthService,
        private spinner: NgxSpinnerService,
        private employerService: EmployerService,
        private agencyService: RecruitmentAgenciesService,
    ) {
        this.breadcrumbService.setActions([
            {
                actionFn: this.downloadPdf.bind(this),
                iconCls: 'file_download',
                name: 'Download',
            }
        ]);
    }

    ngOnInit() {
        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );
        this.role$.subscribe(value => {
            this.role = value;
        });
        if (this.route.snapshot.params.id) {
            this.scopingQuestionId = this.route.snapshot.params.id;
            this.employerId = this.route.snapshot.params.employerId;
            this.spinner.show();
            this.scopingReviewService.getResponse(this.employerId, this.scopingQuestionId, this.role).subscribe(() => this.spinner.hide());
        }
        myGlobals.param.subscribe(x => {
            this.scopingQuestionId = x;
            this.spinner.show();
            this.scopingReviewService.getResponse(this.employerId, this.scopingQuestionId, this.role).subscribe(() => this.spinner.hide());
        });
        this.scopingReviewService.createdAt$.subscribe(result => {
            if (!this.updatedBreadCumb && result !== '') {
                this.breadcrumbService.updateBreadCrumbItemsWithItem({name: 'Scoping Question review', path: this.router.url});
                this.updatedBreadCumb = true;
            }
        })
        this.error$.subscribe(() => this.spinner.hide())

        this.deactivateRA$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (result && result.response) {
                if (result.response.deactivated) {
                    this.spinner.hide();
                    let dialogRef = this.dialog.open(ConfirmCancelDialog, {
                        data: {
                            title: 'This Recruitment agency was deactivated',
                            subTitle: 'Are you sure you want to invite this Recruitment Agency?',
                            message: 'Invite Recruitment agency will activate account again.',
                            cancelButtonText: 'Discard',
                            confirmButtonText: 'Invite',
                            color: 'warn'
                        },
                    });

                    dialogRef
                    .afterClosed()
                    .pipe(
                        takeUntil(this.unsubscribe$),
                        switchMap(async (res:any) => {
                            if (res) {
                                setTimeout(() => {
                                    this.inviteRA(this.agencyUser);
                                }, 1);
                            }
                            return of(undefined);
                        })
                    )
                    .subscribe();
                } else {
                    setTimeout(() => {
                        this.inviteRA(this.agencyUser);
                    }, 1);
                }
            } else {
                this.spinner.hide();
            }
        })

        myGlobals.inviteRAFromScopingReview$.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
            this.agencyUser = x
            this.inviteRAFnc();
        })
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async inviteRAFnc() {
        this.spinner.show();
        if (this.role === 'brand') {
            this.employerService.brandCheckDeactivatePra(this.employerId, this.agencyUser.email);
        } else if (this.role === 'employer') {
            this.employerService.hotelCheckDeactivatePra(this.agencyUser.email);
        }
    }


    async inviteRA(praUser: PraUserInfo) {
        this.spinner.show();
        praUser.employerId = this.employerId;
        const result = await this.agencyService.inviteRAgenciesDetail(this.agencyUser);
        if (result) {
            this.spinner.hide();
            this.dialog.open(ConfirmationDialog, {
                width: '600px',
                data: {
                    title: 'Email sent',
                    message:`Invitation email was sent to ${praUser.email} successfully.`,
                    closeButtonText: 'Close',
                },
            });
        }
    }

    guessValueType(itemLabel: string): ListWidgetItemValueType {
        const maybeEmail = (itemLabel || '').toLowerCase().indexOf('email') > -1;
        return maybeEmail ? 'email' : 'normal';
    }
    tryGetValue(value: any, controlType: string) {
        let val = 0;
        let flag = false;
        const questionValue = value && typeof value == 'object' ? (controlType == 'drop-down-multi'
                    ? this.handleDropdownMulti(value)
                    : Object.keys(value).map((item: any) => {
                        if (typeof value[item] == 'number') {
                            flag = true;
                            return val += +value[item];
                        }else {
                            return value[item];
                        }
                    }))
                : value;
        return flag ? val : questionValue;
    }

    handleDropdownMulti(value: any[]) {
        let isObject = false;
        value.forEach(item => {
            if (typeof item === "object") {
                isObject = true
            }
        })
        if (isObject) {
            return value.map((item: any) => item.key)
        } else {
            return value
        }
    }

    downloadPdf() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/print/pdf'], {
                queryParams: {
                    scopingQuestionId: this.scopingQuestionId,
                    employerId: this.employerId,
                    type: DOC_TYPES.SCOPING_REVIEW,
                },
            }),
        );
        window.open(url, '_blank');
    }
}
