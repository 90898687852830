import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuicklinkModule } from 'ngx-quicklink';
import { AppBreadcrumbModule } from './app-breadcrumb';
import { AppErrorBoxModule } from './app-error-box';
import { DataTableModule } from './datatable';
import { DataTableMobileModule } from './datatable-mobile';
import { ConfirmationDialogModule } from './dialogs';
import { UnsavedChangeDialogModule } from './dialogs/unsaved-change-dialog/unsaved-change-dialog.module';
import { FormlyFieldsModule } from './formly-fields/formly-fields.module';
import { MaterialModule } from './material.module';
import { NotificationItemModule } from './notification-item';
import { OnboardingFormModule } from './onboarding-form';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { RouterModule } from '@angular/router';
import { DocumentNamePipe } from './pipes/document-name.pipe';
import { MenuFilter, MenuHasChild } from './pipes';
import { WidgetsModule } from './widgets/widgets.module';
import { MasterLayoutComponent } from './layouts/master-layout/master-layout.component';
import { PrintLayoutComponent } from './layouts/print-layout/print-layout.component';
import { CountryFlagPipe } from './pipes/country-flag.pipe';
import { CountryCodePipe } from './pipes/country-code.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SignUpLayoutComponent } from './layouts';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        QuicklinkModule,
        MaterialModule,
        NotificationItemModule,
        FlexLayoutModule
    ],
    exports: [
        AppBreadcrumbModule,
        AppErrorBoxModule,
        MaterialModule,
        QuicklinkModule,
        DataTableModule,
        DataTableMobileModule,
        ConfirmationDialogModule,
        UnsavedChangeDialogModule,
        OnboardingFormModule,
        FormlyFieldsModule,
        WidgetsModule,
        FlexLayoutModule,
        // pipes
        DocumentNamePipe,
        CountryFlagPipe,
        CountryCodePipe,
        MenuFilter,
        MenuHasChild,
    ],
    declarations: [
        CommonLayoutComponent,
        DocumentNamePipe,
        CountryCodePipe,
        CountryFlagPipe,
        MenuFilter,
        MenuHasChild,
        MasterLayoutComponent,
        PrintLayoutComponent,
        SignUpLayoutComponent
    ],
})
export class SharedModule {}
