import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { ListUsersFormComponent, NewUserFormDialogComponent } from './components';
import { EmployeeAdminContainer } from './containers';

@NgModule({
    declarations: [EmployeeAdminContainer, ListUsersFormComponent, NewUserFormDialogComponent],
    imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule],
    exports: [EmployeeAdminContainer, ListUsersFormComponent, NewUserFormDialogComponent],
})
export class EmployeeLazyModule {}
