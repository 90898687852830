<div mat-dialog-content>
    <div mat-dialog-actions>
        <button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <h3 class="title-dialog">Terms & Conditions</h3>
    <div class="content">
        <div class="content-item">
            <h4>what is the lorem?</h4>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, dolore, cupiditate corrupti perferendis
                sit eveniet dolorem quo vitae corporis sunt tenetur dicta nisi! Alias iusto hic deserunt exercitationem
                ad voluptate!
            </p>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, dolore, cupiditate corrupti perferendis
                sit eveniet dolorem quo vitae corporis sunt tenetur dicta nisi! Alias iusto hic deserunt exercitationem
                ad voluptate!
            </p>
        </div>
        <div class="content-item">
            <h4>what is the lorem?</h4>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, dolore, cupiditate corrupti perferendis
                sit eveniet dolorem quo vitae corporis sunt tenetur dicta nisi! Alias iusto hic deserunt exercitationem
                ad voluptate!
            </p>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, dolore, cupiditate corrupti perferendis
                sit eveniet dolorem quo vitae corporis sunt tenetur dicta nisi! Alias iusto hic deserunt exercitationem
                ad voluptate!
            </p>
        </div>
        <div class="content-item">
            <h4>what is the lorem?</h4>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, dolore, cupiditate corrupti perferendis
                sit eveniet dolorem quo vitae corporis sunt tenetur dicta nisi! Alias iusto hic deserunt exercitationem
                ad voluptate!
            </p>
        </div>
    </div>
    <mat-checkbox class="example-margin" [checked]="agreeTermCheck" (change)="agreeTerm()"
        >I agree to&nbsp; <span> Terms and Conditions</span>
    </mat-checkbox>
</div>
