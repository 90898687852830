import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Injector,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { ViewScopingQuestionsPdfContainer } from '../../employer/containers/view-scoping-questions.pdf.container';
import { DOC_TYPES } from '../types';

@Component({
    selector: 'app-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.scss'],
})
export class PdfComponent implements AfterViewInit {
    @ViewChild('pdf', { read: ViewContainerRef }) entry: ViewContainerRef;

    componentRef: ComponentRef<any>;

    currentNav: Navigation | null;

    documentType: string;

    constructor(
        private route: ActivatedRoute,
        private resolver: ComponentFactoryResolver,
        private injector: Injector,
        private router: Router,
    ) {
        this.currentNav = this.router.getCurrentNavigation();
        this.documentType = this.route.snapshot.queryParams.type;
    }

    ngAfterViewInit(): void {
        if (this.documentType === DOC_TYPES.SCOPING_REVIEW) {
            this.printScopingPreview();
        }
    }

    printScopingPreview() {
        const { scopingQuestionId, employerId } = this.route.snapshot.queryParams;
        if (scopingQuestionId && employerId) {
            const factory = this.resolver.resolveComponentFactory(ViewScopingQuestionsPdfContainer);
            this.componentRef = this.entry.createComponent(factory, 0, this.injector);
            this.componentRef.instance.scopingQuestionId = scopingQuestionId;
            this.componentRef.instance.employerId = employerId;
            this.componentRef.changeDetectorRef.detectChanges();
            setTimeout(function () {
                window.print();
            }, 500);
        }
    }
}
