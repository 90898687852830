import { AbstractControl } from '@angular/forms';

export function CurrencyValidator(control: AbstractControl) {
    if (!control.value) return null;
    const reg = new RegExp(/^(?![0,.][0]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/);
    return reg.test(control.value)
        ? null
        : {
              quantity: {
                  message: 'Please input valid answer',
              },
          };
}
