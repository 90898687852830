import { InjectionToken } from '@angular/core';
import { ApiConstant, UserStatus } from '../types';

export type Role = 'employer' | 'admin' | 'brand' | 'pra';
export type ScoreOutcome = 'none' | 'accepted' | 'rejected';

export interface DirtyComponent {
    isDirty: boolean;
}

export interface CommonDirtyComponent {
    childComponent: DirtyComponent;
}

export interface AgencyInfo {
    id: string;
    name: string;
}

export interface GenericUserInfo {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    role: Role;
    lastLoginTime?: string;
    status: {
        id: number;
        value: string;
    };
    geography: GeographyInfo;
    region: {
        id: string;
        value: string;
    };
    managerId?: string;
    language?: {
        id: number;
        code: string;
        name: string;
    };
}

export interface UserInfo {
    id: string;
    fullName: string;
    email: string;
    role: Role;
    roleId?: string;
    lastLoginTime?: string;
    status: UserStatus;
    countryId?: string;
    brandId?: string;
    countryName?: string;
    companyName?: string;
    brandName?: string;
    praAgencyName?: string;
    praLicenseNumber?: string;
    updatedAt?: string;
    createdAt?: string;
    saqReceived?: number;
}

export interface WorkerAtHotel {
    name: string;
    local: number;
    migrant: number;
    total: number;
}

export interface UserInfoCollection {
    users: UserInfo[];
    userCount: number;
    startAtPage?: { pageIndex: number };
}

export interface BreadCrumbItem {
    name: string;
    path: string;
    context?: any;
}

export interface BreadcrumbAction {
    name: string;
    iconCls: string;
    actionFn: Function;
}

export interface GeographyInfo {
    id: string;
    value: string;
    region: StringValuePair;
}

export interface StringValuePair {
    id: string;
    value: string;
}

export const REGION_API_TOKEN = new InjectionToken<ApiConstant>('api.constant');

export interface RegionGeographies {
    id: string;
    value: string;
    geographies: StringValuePair[];
}

export interface Login {
    response: {
        accessToken: string;
    };
}

export interface UserReponse {
    response: {
        user: UserInfo;
    };
}
