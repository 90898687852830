import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, delay, exhaustMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { ConfirmationDialog, ConfirmCancelDialog } from '../../shared';
import { UserStatus, PaginateOptions } from '../../types';
import { ListUsersFormComponent, NewUserFormComponent } from '../components';
import { PraService } from '../services';
import { PraUserInfo } from '../types';

@Component({
    selector: 'pra-dashboard',
    template: `
        <div class="grid-container1">
            <new-user-form
                class="new-user-form"
                (registerClicked)="registerClicked$.next($event)"
                [errorMessages]="errors$ | async"
                #registerForm
            ></new-user-form>
            <list-users-form
                class="list-users-form"
                [pageSize]="pageSize"
                [userCollection]="userCollection$ | async"
                (paginate)="paginateUsers$.next($event)"
                (inactivateAdmin)="openDialog($event)"
                (inactivateBrand)="activeOrDeActive($event)"
                [errorMessages]="inactivateUserErrors$ | async"
                (filterUser)="filter$.next($event)"
                (sortChange)="sorter$.next($event)"
            >
            </list-users-form>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
            }

            .grid-container {
                align-items: stretch;

                display: grid;

                grid-template-rows: 10px minmax(min-content, auto) minmax(min-content, auto) 10px;
                grid-template-columns: minmax(30px, 1fr) minmax(280px, 8fr) minmax(30px, 1fr);
            }

            .grid-container .list-users-form {
                grid-row: 3;
                grid-column: 2;
            }

            .grid-container .new-user-form {
                grid-row: 2;
                grid-column: 2;
                margin-bottom: 2rem;
            }

            @media only screen and (max-width: 600px) {
                .grid-container {
                    grid-template-columns: 1fr;
                }

                .grid-container .list-users-form {
                    grid-column: 1;
                }

                .grid-container .new-user-form {
                    grid-column: 1;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PraAdminContainer implements OnInit, OnDestroy {
    pageSize = 10;

    @ViewChild('registerForm', { static: true })
    newUserForm: NewUserFormComponent;

    @ViewChild(ListUsersFormComponent, { static: true })
    listUserForm: ListUsersFormComponent;

    private unsubscribe$ = new Subject();
    registerClicked$ = new Subject<PraUserInfo>();
    paginateUsers$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });
    inactivateAdmin$ = new Subject<any>();
    inactivateBrand$ = new Subject<any>();
    filter$ = new BehaviorSubject<string>('');
    sorter$ = new BehaviorSubject<Sort>({ active: 'fullName', direction: 'asc' });

    userCollection$ = this.praService.userCollection$;
    errors$ = this.praService.errors$;
    inactivateUserErrors$ = this.praService.inactivateUserErrors$;
    lstCaseManager$: any;

    newUserData$: any;

    constructor(private praService: PraService, private dialog: MatDialog) {}

    ngOnInit() {
        this.registerClicked$
            .pipe(
                delay(0),
                exhaustMap(registerInfo => this.praService.registerPra(registerInfo, this.paginateUsers$.getValue())),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        this.praService.newUser$
            .pipe(
                delay(0),
                map(u => {
                    this.newUserData$ = u;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: 'Add new Recruitment agencies successfully!',
                        message:
                            `Successfully added ` +
                            this.newUserData$.fullName +
                            ` to LMPM. An invitation email was sent to ` +
                            this.newUserData$.email,
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        if (this.newUserForm) {
                            this.newUserForm.resetForm();
                        }
                    });
            });

        combineLatest([this.paginateUsers$, this.filter$, this.sorter$])
            .pipe(
                debounceTime(300),
                switchMap(([pagination, filter, sorter]) => {
                    let orderBy = '';
                    if (sorter && sorter.active) {
                        orderBy = `${sorter.active} ${sorter.direction}`;
                    }
                    return this.praService.paginteUserManagements(pagination, filter, orderBy);
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        this.inactivateAdmin$
            .pipe(
                delay(0),
                exhaustMap(res => this.praService.inactivateAdmin(res.userId, this.pageSize)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
        this.inactivateBrand$
            .pipe(
                delay(0),
                exhaustMap(res => this.praService.inactivateBrand(res.userId, res.status, this.pageSize)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
    }

    activeOrDeActive(data: { status: UserStatus; userId: string }) {
        const { userId, status } = data;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate Recruitment agencies user` : `Activate Recruitment agencies user`,
                subTitle:
                    data.status == 'Active'
                        ? `Are you sure you want to deactivate  account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When deactivated, user cannot work on LMPM platform. However, you will still be able to view their information, answers and activities.`
                        : ` When activate, Recruitment agencies user can continue working on LMPM platform.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    const activeResult = await this.praService.inactivateBrand(userId, status, this.pageSize);
                    if (activeResult) {
                        this.dialog.open(ConfirmationDialog, {
                            width: '600px',
                            data: {
                                title: (status === 'Active' ? 'Deactivated' : 'Activated') + ' Successfully!',
                                message:
                                    'User was successfully ' +
                                    (status === 'Active' ? 'deactivated' : 'activated') +
                                    ' from LMPM platform.',
                                closeButtonText: 'Close',
                            },
                        });
                    }
                }
            });
    }

    openDialog(data: { fullname: string; userId: string }) {
        const { fullname, userId } = data;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Do you confirm to delete ${fullname}?`,
                message: `
                    Deleting this user will prevent them from accessing LMPM Pilot and will delete all
                    data associated with their account.
                `,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    let info = {
                        userId: userId,
                    };
                    this.inactivateAdmin$.next(info);
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
