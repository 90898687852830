import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatCalendarCellClassFunction } from "@angular/material/datepicker";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { IomService } from "../../services";
import { DatePickerHeader } from "../date-picker-header/date-picker-header";

@Component({
    selector: 'app-iom-dashboard',
    templateUrl: './iom-dashboard.component.html',
    styleUrls: ['./iom-dashboard.component.scss'],
})

export class IomDashBoardComponent implements OnInit, OnDestroy {

    dateClassFrom: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        if (view === 'month' && this.toDate.value) {
            let toDateConvert = new Date(this.toDate.value)
            if (cellDate.getTime() === toDateConvert.getTime()) {
                return 'gray-date';
            }
            if (this.fromDate.value) {
                let fromDateConvert = new Date(this.fromDate.value)
                if (cellDate.getTime() >= fromDateConvert.getTime() && cellDate.getTime() <= toDateConvert.getTime()) {
                    return 'range-date';
                }
            }
        }    
        return '';
    }

    dateClassTo: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        if (view === 'month' && this.fromDate.value) {
            let fromDateConvert = new Date(this.fromDate.value)
            if (cellDate.getTime() === fromDateConvert.getTime()) {
                return 'gray-date';
            }
            if (this.toDate.value) {
                let toDateConvert = new Date(this.toDate.value)
                if (cellDate.getTime() >= fromDateConvert.getTime() && cellDate.getTime() <= toDateConvert.getTime()) {
                    return 'range-date';
                }
            }
        }    
        return '';
    }
    
    fromCalendarOpen = false;
    toCalendarOpen = false;

    form: FormGroup;

    fromDateRes: any;
    toDateRes: any;
    datePickerHeader = DatePickerHeader;

    matrixRes$ = this.iomService.matrixRes$;
    private unsubscribe$ = new Subject();
    today = new Date();
    constructor(
        private iomService: IomService,
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        ) {}

    fromDate = new FormControl();
    toDate = new FormControl();

    ngOnInit(): void {
        this.spinner.show();
        let today = new Date();
        this.iomService.fillteMatrix(undefined ,this.convertDateToString(today)).subscribe(_ => {
            this.spinner.hide();
        });

        this.form = this.fb.group({
            fromDate: new FormControl(new Date()),
            toDate: new FormControl(new Date()),
        });

    }

    clicked() {
        this.iomService.fillteMatrix(this.convertDateToString(this.fromDate.value),this.convertDateToString(this.toDate.value)).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    convertDateToString(input: Date) {
        if(!input) {
            return '';
        }
        const dd = String(input.getDate()).padStart(2, '0');
        const mm = String(input.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = input.getFullYear();

        const date = yyyy + '-' + mm + '-' + dd;
        return date;
    }

    onOpenFrom() {
        this.fromCalendarOpen = true
    }

    onCloseFrom() {
        this.fromCalendarOpen = false
    }

    onToFrom() {
        this.toCalendarOpen = true
    }

    onCloseTo() {
        this.toCalendarOpen = false
    }
}
