import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { AttachmentDownloadResponse, FileInfo } from '../../types';
import { AttachmentService } from './attachment.service';
import * as myGlobals from '../../../../globals';

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss'],
    providers: [AttachmentService],
})
export class AttachmentComponent extends FieldType implements OnInit{
    constructor(private attachmentService: AttachmentService) {
        super();
    }
    @ViewChild('fileInput', { static: false })
    fileInput: ElementRef<any>;
    @ViewChild('urlInput') urlInput: ElementRef<any>;
    uploadSubscription: Subscription;
    attachments$ = this.attachmentService.attachments$
    hasFile$ = this.attachmentService.hasFile$;
    error$ = this.attachmentService.error$;
    currentFile: FileInfo | undefined;
    uploadResult: any;
    fileSizeDisplay: string;

    ngOnInit(): void {
        if(this.field.defaultValue) {
            this.attachmentService.setDefaultAttachment(this.field.defaultValue);
        }
        this.attachments$.subscribe(x => {
            if (x.length > 0){
                x.forEach(file => {
                    let unit = '';
                    let size = '';
                    const fileSize = file.fileSize / 1000;
                    if (fileSize <= 99) {
                        unit = 'KB';
                        size = fileSize.toFixed(1);
                    } else {
                        unit = 'MB';
                        size = (fileSize/1000).toFixed(1);
                    }
                    file.fileSizeDisplay = size + ' ' + unit;
                });
            }
            this.uploadResult = x;
        })
    }

    // ngAfterViewInit() {}

    openFileChooser($event: any) {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.fileInput.nativeElement && this.fileInput.nativeElement.click) {
            this.fileInput.nativeElement.click();
        }
    }

    emitFiles(event: FileList) {
        const file = event && event.item(0);
        const fileForm = new FormData();
        if (file) {
            fileForm.append('file', file);
            this.uploadFile(fileForm);
        }
    }

    uploadFile(fileForm: FormData) {
        if (fileForm) {
            // this.uploadSubscription.unsubscribe();
            // Reupload
            if (this.currentFile) {
                this.uploadSubscription = this.attachmentService.uploadFile(fileForm, this.currentFile.id).subscribe();
                this.currentFile = undefined;
            }else {
                this.uploadSubscription = this.attachmentService.uploadFile(fileForm).subscribe();
                this.clearInput();
            }
        }
        this.attachments$.subscribe(x => {
            if (x.length > 0){
                this.uploadResult = x;
            }
        })
        myGlobals.saqDirtySub$.next({isDirty: true});
    }

    startReupload($event: any, currentFile: FileInfo) {
        this.currentFile = currentFile;
        this.openFileChooser($event);
    }

    // reUploadFile(event: FileList) {
    //     const file = event && event.item(0);
    //     const fileForm = new FormData();
    //     if (file) {
    //         fileForm.append('file', file);
    //         this.attachmentService.uploadFile(fileForm);
    //     }
    // }

    deleteFile(file: FileInfo) {
        this.attachmentService.removeFile(file);
        myGlobals.saqDirtySub$.next({isDirty: true});
    }

    downloadFile(file: FileInfo) {
        this.attachmentService
        .downloadFileService(file)
        .subscribe((response: AttachmentDownloadResponse<Blob>) => this.attachmentService.openFile(response));
    }

    clearInput() {
        this.fileInput.nativeElement.value = '';
    }
}
