import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AppErrorBoxModule } from '../app-error-box';
import { OnboardingFormComponent } from './onboarding-form.component';
import { TermsAndConditionsDialogModule } from '../dialogs';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [OnboardingFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AppErrorBoxModule,
        MatIconModule,
        MatInputModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
        TermsAndConditionsDialogModule,
    ],
    exports: [OnboardingFormComponent],
})
export class OnboardingFormModule {}
