import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-group-question',
    templateUrl: './group-question.component.html',
    styleUrls: ['./group-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupQuestionComponent extends FieldType implements OnInit {
    ngOnInit(): void {}
}
