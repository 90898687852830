import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    template: `
        <div class="dialog-style-form">
        <mat-icon (click)="dialogRef.close()" [attr.aria-label]="'close dialog'">close</mat-icon>

            <div class="dialog-header">
                <h2 mat-dialog-title>{{ data.title }}</h2>
            </div>
            <mat-dialog-content>
                <p class="firstMessage">{{ data.message }}</p>
                <div
                    class="list-widget"
                    *ngIf="data.companyProfile && data.roleUser === 'hotel_request_link_to_hotel_group'"
                >
                    <div class="form-row">
                        <div class="item-label">Name</div>
                        <div class="item-value">{{ data.companyProfile.companyName }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Website</div>
                        <div class="item-value">{{ data.companyProfile.website }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Hotel location</div>
                        <div class="item-value">{{ data.companyProfile.location }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Address</div>
                        <div class="item-value">{{ data.companyProfile.address }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Contact person</div>
                        <div class="item-value">{{ data.companyProfile.contactPerson }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Email</div>
                        <div class="item-value">
                            <a href="mailto:{{ data.email }}">{{ data.email }}</a>
                        </div>
                    </div>
                </div>

                <div
                    class="list-widget"
                    *ngIf="data.companyProfile && data.roleUser === 'hotel_group_request_link_to_hotel'"
                >
                    <div class="form-row">
                        <div class="item-label">Name</div>
                        <div class="item-value">{{ data.companyProfile.name }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Website</div>
                        <div class="item-value">{{ data.companyProfile.website }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Headquarters</div>
                        <div class="item-value">{{ data.companyProfile.headquarter }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Address</div>
                        <div class="item-value">{{ data.companyProfile.address }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Contact person</div>
                        <div class="item-value">{{ data.companyProfile.contactPerson }}</div>
                    </div>
                    <div class="form-row">
                        <div class="item-label">Email</div>
                        <div class="item-value">
                            <a href="mailto:{{ data.email }}">{{ data.email }}</a>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
            <div class="button-group">
                <button
                    style="float:right"
                    mat-button
                    type="button"
                    class="mat-slim"
                    [mat-dialog-close]="false"
                    cdkFocusInitial
                    [attr.aria-label]="'cancel navigation'"
                    *ngIf="!data.hideCancelButton"
                    [disabled]="data.responsed"
                >
                    Reject
                </button>
                <button
                    style="float:right"
                    mat-button
                    color="{{ data.color || 'primary' }}"
                    type="button"
                    [mat-dialog-close]="true"
                    [attr.aria-label]="'Confirm navigation'"
                    [disabled]="data.responsed"
                    class="mat-slim"
                >
                    Accept
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./accept-link-request.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptLinkRequestDialog {
    caseManagerId: string;
    isConfirmDelete: boolean;
    isDisabled = true;
    result: any;
    constructor(
        public dialogRef: MatDialogRef<AcceptLinkRequestDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            message: string;
            title?: string;
            hideCancelButton: boolean;
            color?: string;
            roleUser?: string;
            email?: string;
            companyProfile?: any;
            responsed: boolean;
            waspost?: boolean
        },
    ) {}
}
