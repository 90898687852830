<!-- Layout: Modern -->
<div class="modern-layout" *ngIf="to.layout === 'modern'">
    <div class="user-input">
        <div class="top-label" *ngIf="to.symbol">
            <div class="symbol-input-left">
                <span>{{ to.symbol }} {{ to?.label }}</span>
            </div>
            <div class="right-item" *ngIf="to?.required">
                <mat-label class="asterix--before"></mat-label>
            </div>
        </div>
    </div>
</div>
