import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { CustomHttpErrorResponse } from 'src/app/modules/types';
import { AttachmentDownloadResponse, FileInfo } from '../../types';
const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class AttachmentService {
    fileUploadUrl = `${environment.api.endpoint}/file-upload`;

    private errorSub = new Subject<string>();
    error$ = this.errorSub.asObservable();

    private attachmentsSub = new BehaviorSubject<FileInfo[]>([]);
    attachments$ = this.attachmentsSub.asObservable();
    hasFile$ = this.attachments$.pipe(
        switchMap((attachments: FileInfo[]) => {
            return of(attachments && attachments.length);
        }),
    );

    constructor(private http: HttpClient) {}
    uploadFile(fileForm: FormData, replaceFileId?: string): Observable<FileInfo> {
        this.errorSub.next('');
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Upload file' });
        return this.http.post<FileInfo>(`${this.fileUploadUrl}/upload-file`, fileForm, {
            headers,
        }).pipe(tap((fileInfo: FileInfo) => {
                    const file = [fileInfo];
                    const attachments = [...this.attachmentsSub.value];
                    if (replaceFileId) {
                        // replace
                        const newAttachments = attachments.filter(attachment => attachment.id !== replaceFileId);
                        newAttachments.push({ ...file[0], id: replaceFileId });
                        this.attachmentsSub.next(newAttachments);
                    } else {
                        // push
                        attachments.push({ ...file[0], id: uuidv4() });
                        this.attachmentsSub.next(attachments);
                    }
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err) {
                        if (err.error.error.indexOf('Payload Too Large') != -1) {
                            this.errorSub.next(`Your file reaches maximum size (2MB). Please select smaller size file`);
                        }
                        else if (err.error.error.indexOf('not have correct file type') != -1) {
                            this.errorSub.next(`Please upload valid file format (Excel, PDF, World, powerpoint, svg, png, .jpg, .jpe).`);
                        }
                        else {
                            this.errorSub.next('');
                        }
                    }
                    // this.errorSub.next(err.error.error);
                    return EMPTY;
                }),
            );
    }

    pushError(error: string) {
        this.errorSub.next(error);
    }

    downloadFileService(file: FileInfo) {
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Download file attachment',
        });

        return this.http
            .post(`${this.fileUploadUrl}/download-file`, file, {
                responseType: 'blob',
                observe: 'response',
                headers,
            })
            .pipe(
                map(response => ({
                    body: response.body,
                    contentDisposition: response.headers.get('content-disposition') || '',
                    contentType: response.headers.get('content-type') || 'application/octet-stream',
                })),
            );
    }

    removeFile(file: FileInfo) {
        const attachments = [...this.attachmentsSub.value];
        const newAttachments = attachments.filter(attachment => attachment.id !== file.id);
        this.attachmentsSub.next(newAttachments);
    }

    openFile(response: AttachmentDownloadResponse<Blob>) {
        if (response && response.body) {
            const { contentType } = response;
            const filename = this.getFileNameFromContentDisposition(response);
            saveAs(new Blob([response.body], { type: contentType }), filename);
        }
    }

    getFileNameFromContentDisposition(res: AttachmentDownloadResponse<Blob>) {
        const { contentDisposition } = res;
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const tempFilename = ((matches && matches[1]) || 'untitled').trim();
        const filename = tempFilename.replace(/["]/g, '');
        return filename;
    }

    setDefaultAttachment(files: FileInfo[]) {
    //    const attachments = this.converFileSize(files);
        this.attachmentsSub.next(files);
    }

    // converFileSize(files: FileInfo[]) {
    //     files.forEach(file => {
    //         file.fileSize = file.fileSize/1000
    //     });
    //     return files;
    // }
}
