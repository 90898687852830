import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
    selector: 'save-question-bar',
    templateUrl: './save-question-bar.component.html',
    styleUrls: ['./save-question-bar.component.scss'],
})
export class SaveQuestionBarComponent implements OnInit {
    @Output()
    saveEvent = new EventEmitter();
    @Output()
    submitEvent = new EventEmitter();
    @Input()
    isValid: boolean;

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {}

    onSave() {
        this.saveEvent.emit()
    }

    onSubmit() {
        this.submitEvent.emit();
    }
}
