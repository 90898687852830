<div class="pra-container" fxLayoutGap="30px">
    <app-list-hotels
        [lstHotelIds]="lstHotelIds$ | async"
    ></app-list-hotels>
    <app-my-questionnaires
        class="table-submitted"
        [documents]="document$ | async"
        [total]="total$ | async"
        [pageSize]="5"
        [pageIndex]="pageIndex$ | async"
        (paginate)="docListPaginate($event)"
        (sortChange)="docListSortChange($event)"
        (viewClicked)="goToDocument($event)"
        (deleteClicked)="deleteScopingPending($event)"
        (downloadRACSVEmit)="downloadAnswerRa($event)"
    ></app-my-questionnaires>
</div>
