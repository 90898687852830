import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared';
import { AuthLoginFormComponent } from './auth-login-form/auth-login-form.component';
import { AuthEmailContainer, AuthLoginContainer } from './containers';

@NgModule({
    imports: [CommonModule, SharedModule, ReactiveFormsModule, TranslateModule],
    declarations: [AuthEmailContainer, AuthLoginFormComponent, AuthLoginContainer],
    exports: [CommonModule, AuthEmailContainer, AuthLoginFormComponent, AuthLoginContainer, SharedModule],
})
export class AuthLazyModule {}
