import { InjectionToken } from '@angular/core';
import { ApiConstant } from '../types';

export class Question {
    id: string;
    sectionId: string;
    questionGroupId: string;
    parentId: string;
    label: string;
    value: any;
    required: boolean;
    order: number;
    code: string;
    symbolType: string;
    symbol: string;
    controlType: string;
    width?: string;
    options: { key: string; value: string }[];
    displayCondition?: [{ key: string; value: string }];
    displayConditionType?: string;
    requireCondition?: [{ key: string; value: string }];
    requireConditionType?: string;
    subAnswer?: {
        needToAnswer: boolean;
        answerTitle: { key: string; value: string }[];
        subTotal: boolean;
    };
    editable?: boolean = true;
    valueType?: string;
    childQuestions?: any[];
    defaultSubmitValue?: string;
    hidden?: boolean;
    placeholder?: string;
    isEmailDifferentHotelOrHotelGroup?: boolean;

    constructor(
        options: {
            id?: string;
            sectionId?: string;
            parentId?: string;
            label?: string;
            value?: any;
            required?: boolean;
            order?: number;
            symbolType?: string;
            symbol?: string;
            controlType?: string;
            type?: string;
            options?: { key: string; value: string }[];
            displayCondition?: [{ key: string; value: string }];
            subAnswer?: {
                needToAnswer: boolean;
                answerTitle: { key: string; value: string }[];
                subTotal: boolean;
            };
        } = {},
    ) {
        this.id = options.id || '';
        this.sectionId = options.sectionId || '';
        this.parentId = options.parentId || '';
        this.label = options.label || '';
        this.value = options.value || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.symbolType = options.symbolType || '';
        this.symbol = options.symbol || 'number';
        this.controlType = options.controlType || '';
        this.options = options.options || [];
        this.displayCondition = options.displayCondition;
        this.subAnswer = options.subAnswer || {
            needToAnswer: false,
            answerTitle: [],
            subTotal: false,
        };
    }
}

export class Section {
    id: string;
    label: string;
    order: number;
    symbolType: string;
    symbol?: string;
    questionGroups?: Section[];
    questions: Question[];

    constructor(
        options: {
            id?: string;
            label?: string;
            symbolType?: string;
            order?: number;
            questions?: Question[];
        } = {},
    ) {
        this.id = options.id || '';
        this.label = options.label || '';
        this.symbolType = options.symbolType || 'text';
        this.order = options.order || 0;
        this.questions = this.questions || [];
    }
}
export const QUESTIONFORM_API_TOKEN = new InjectionToken<ApiConstant>('api.constant');
export interface FileInfo {
    fileName?: string;
    fileSize?: number;
    fileUrl?: string;
    ownerId?: string;
    scoreOutCome?: string;
    isChanged?: boolean;
}
export interface AttachmentDownloadResponse<T> {
    body: T | null;
    contentDisposition: string;
    contentType: string;
}

export interface Questionnaire {
    id: string;
    type: string;
    version: number;
    sections: Section[];
}

export interface QuestionnaireResponseBrand {
    message: string;
    response: {
        questionnaire: {
            pras: PrasResponse[],
            employer: EmployerResponse,
            questionnaireResponse: {
                createdAt: string
            }
        };
    };
}

export class QuestionnaireResponse {
    message: string;
    response: {
        questionnaire: {
            employer: Questionnaire;
            pras: Questionnaire[];
        };
    };
}

export class SaqResponse {
    message: string;
    response: {
        questionnaire: Questionnaire,
        questionnaireResponse?: {
            createdAt: string
        }
        id?: string,
        praStatus?: string;
    };
}

export interface PartialResponse {
    partialId: string;
    responses: { [name: string]: any };
    formValid: boolean;
    formDirty: boolean;
    fromHades?: boolean;
}

export interface NextBackBehaviour {
    canNext: boolean;
    canBack: boolean;
}

export interface PrasResponse {
    id: string;
    type: string;
    version: number;
    sections: Section[];
    praStatus?: string;
    questionnaireResponseId?: string;
}

export interface EmployerResponse {
    id: string;
    type: string;
    version: number;
    sections: Section[];
}

export interface PraDetails {
    praStatus?: string;
    questions: Question[];
    questionnaireResponseId?: string;
}
