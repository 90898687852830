<div class="grid gap-4 grid-rows-1 grid-cols-1 container-component">
    <div class="grid gap-4 grid-rows-1 grid-cols-1 content-bottom">
        <span class="style-text-header">Hotel</span>
        <div class="grid gap-4 grid-cols-1 bg-white rounded-lg p-xl text-gray-600 form-item">
            <!-- <mat-icon>supervisor_account</mat-icon> -->
            <div class="text-layout">
                <span class="style-text-title">Scoping Questions</span>
                <span class="style-text-content"
                    >Share information about your company profile and the recruitment agencies that you work with to recruit and manage migrant workers.
                </span>
            </div>
            <div class="button-layout">
                <button
                    class="px-3 py-2 rounded" [class]="(role$ | async) !== 'brand' ? 'style-text-button-other' : 'style-text-button-brand'"
                    (click)="scopingClick()"
                >
                    <mat-icon style="margin-right: 13px;">download</mat-icon>
                    {{(role$ | async) !== 'brand' ? 'Start Scoping Question' : 'Download'}}
                </button>
            </div>
        </div>
        <div class="grid gap-4 grid-cols-1 bg-white rounded-lg p-xl text-gray-600 form-item">
            <!-- <mat-icon>library_books</mat-icon> -->
            <div class="text-layout">
                <span class="style-text-title">Self Assessment Questionnaire</span>
                <span class="style-text-content"
                    >Share information about your employment and work practices across migrant workers labour migration journey.
                </span>
            </div>
            <div class="button-layout">
                <button class="px-3 py-2 rounded" [class]="(role$ | async) !== 'brand' ? 'style-text-button-other' : 'style-text-button-brand'"
                (click)="saqForHotelClick()">
                    <mat-icon style="margin-right: 13px;">download</mat-icon>
                    {{(role$ | async) !== 'brand' ? 'Start New Assessment' : 'Download'}}
                </button>
            </div>
        </div>
        <div class="grid gap-4 grid-cols-1 bg-white rounded-lg p-xl text-gray-600 form-item">
            <!-- <mat-icon>library_books</mat-icon> -->
            <div class="text-layout">
                <span class="style-text-title">Worker Interview Questionnaire</span>
                <span class="style-text-content"
                    >Use this questionnaire and accompanying guide to support your in person interviews with workers.
                </span>
            </div>
            <div class="button-layout">
                <button class="px-3 py-2 rounded" [class]="(role$ | async) !== 'brand' ? 'style-text-button-other' : 'style-text-button-brand'"
                (click)="wiqForHotelClick()">
                    <mat-icon style="margin-right: 13px;">download</mat-icon>
                    {{(role$ | async) !== 'brand' ? 'Start New Assessment' : 'Download'}}
                </button>
            </div>
        </div>
    </div>
    <div class="grid gap-4 grid-rows-1 grid-cols-1 content-bottom">
        <span class="style-text-header">Recruitment Agency</span>
        <div class="grid gap-4 grid-cols-1 bg-white rounded-lg p-xl text-gray-600 form-item">
            <!-- <mat-icon>library_books</mat-icon> -->
            <div class="text-layout">
                <span class="style-text-title">Self Assessment Questionnaire</span>
                <span class="style-text-content"
                    >Share information about your employment and work practices across the following stages of a migrant workers labour migration experience.
                </span>
            </div>
            <div class="button-layout">
                <button class="px-3 py-2 rounded" [class]="(role$ | async) !== 'brand' ? 'style-text-button-other' : 'style-text-button-brand'"
                (click)="saqForPRAClick()">
                    <mat-icon style="margin-right: 13px;">download</mat-icon>
                    {{(role$ | async) !== 'brand' ? 'Start New Assessment' : 'Download'}}
                </button>
            </div>
        </div>
    </div>
</div>
