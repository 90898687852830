import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import format from 'date-fns/format';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomEncoder, Logger, UserInfo, UserInfoCollection } from '../../core';
import { UserStatus, ApiConstant, CustomHttpErrorResponse, PaginateOptions, LscmResponse, IHistory } from '../../types';
import { PraInforResponse, PraUserInfo, PRA_API_TOKEN } from '../types';
import { AuthService } from 'src/app/modules/auth';
import { OnboardingUserResults } from '../../shared/onboarding-form';
import { Sort } from '@angular/material/sort';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class PraService {
    urlDefault = this.apiConstant.endpoint;
    url = `${this.apiConstant.endpoint}/pra`;
    urlemail = `${this.apiConstant.endpoint}/auth`;
    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private inactivateUserErrorsSub$ = new Subject<string[]>();
    inactivateUserErrors$ = this.inactivateUserErrorsSub$.asObservable();

    private newUserSub$ = new Subject<UserInfo>();
    newUser$ = this.newUserSub$.asObservable();

    private lstCaseMangerSub$ = new BehaviorSubject<UserInfo[]>([]);
    lstCaseManger$ = this.lstCaseMangerSub$.asObservable();

    private userCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userCollection$ = this.userCollectionSub$.asObservable();

    private onboardingUserSub$ = new Subject<OnboardingUserResults>();
    onboardingUser$ = this.onboardingUserSub$.asObservable();

    private praProfileOptionSub$ = new Subject<PraInforResponse>();
    praProfileOption$ = this.praProfileOptionSub$.asObservable();

    private hotelsUserSub$ = new Subject<any>();
    hotelsUser$ = this.hotelsUserSub$.asObservable();

    sortSub = new BehaviorSubject<Sort>({ active: 'createdAt', direction: 'desc' });
    sort$ = this.sortSub.asObservable();

    DEFAULT_PAGINATION: PaginateOptions = {
        pageIndex: 0,
        pageSize: 5,
    };

    paginationSub = new BehaviorSubject<PaginateOptions>(this.DEFAULT_PAGINATION);
    pagination$ = this.paginationSub.asObservable();

    totalCountSub = new BehaviorSubject<number>(0);
    total$ = this.totalCountSub.asObservable();

    private paginateByUserRole(
        options: PaginateOptions,
        role: string,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params;
        if (role === 'managements') {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', 'pra');
        } else {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', role);
        }
        const currentUser = this.authService.getUser();
        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params
                .set('page', `${pageIndex}`)
                .set('limit', `${pageSize}`)
                .set('brandId', `${currentUser?.brandId}`)
                .set('filter', `${filter}`);
        } else {
            params = params
                .set('page', `${pageIndex}`)
                .set('limit', `${pageSize}`)
                .set('brandId', `${currentUser?.brandId}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<UserInfoCollection>(`${this.url}/users`, { params, headers }).pipe(
            tap(({ users, userCount }: UserInfoCollection) => {
                const localUsers: UserInfo[] = users.map(u => {
                    const strLastLoginTime = u.lastLoginTime
                        ? format(new Date(u.lastLoginTime), 'dd MMM, yyyy HH:mm:ss')
                        : '';
                    return {
                        ...u,
                        lastLoginTime: strLastLoginTime,
                    };
                });
                this.userCollectionSub$.next({ users: localUsers, userCount, startAtPage: { pageIndex } });
            }),
            catchError(() => {
                this.userCollectionSub$.next({ users: [], userCount: 0 });
                return EMPTY;
            }),
        );
    }

    constructor(
        @Inject(PRA_API_TOKEN) private apiConstant: ApiConstant,
        private httpClient: HttpClient,
        private authService: AuthService,
    ) {}

    registerPra(input: PraUserInfo, options: PaginateOptions) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Create new Pra user' });

        const { fullName, email, agency, licenseNumber } = input;

        return this.httpClient
            .post<UserInfo>(
                `${this.url}/register`,
                {
                    fullName,
                    email,
                    agency: agency,
                    licenseNumber,
                },
                { headers },
            )
            .pipe(
                tap((newUser: UserInfo) => {
                    this.newUserSub$.next(newUser);
                    this.errorsSub$.next([]);
                }),
                concatMap(() => this.paginateByUserRole(options, 'managements', 'User Management list')),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    paginteAdmins(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'admin', 'Paginate Iom user list', filter);
    }

    paginteUsers(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'user', 'Paginate PRA list', filter);
    }

    paginteUserManagements(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateByUserRole(options, 'managements', 'User Management list', filter, sorter);
    }

    inactivateAdmin(userId: string, pageSize: number) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete Iom user' });
        return this.httpClient.delete<UserInfo>(`${this.urlDefault}/user/user-pra/${userId}`, { headers }).pipe(
            tap(() => {
                this.inactivateUserErrorsSub$.next([]);
            }),
            switchMap(() =>
                this.paginteUserManagements({
                    pageIndex: 0,
                    pageSize,
                }),
            ),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.inactivateUserErrorsSub$.next(err.errorJson.message);
                } else {
                    this.inactivateUserErrorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        );
    }

    inactivateBrand(userId: string, status: UserStatus, pageSize: number): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated brand' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/user/updatePraStatus/${userId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    getTotalCaseManager(): Promise<number> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get total Iom user' });

        return this.httpClient
            .get<number>(`${this.url}/number-caser-manager`, { headers })
            .pipe(
                tap((result: number) => {
                    this.errorsSub$.next([]);
                    return result;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    getOnboardingUser(userId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Retrieve onboarding Iom user' });
        return this.httpClient.get<OnboardingUserResults>(`${this.url}/onboarding/${userId}`, { headers }).pipe(
            tap((result: OnboardingUserResults) => {
                this.onboardingUserSub$.next(result);
                this.errorsSub$.next([]);
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsSub$.next(err.errorJson.message);
                } else {
                    this.errorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        );
    }

    completeOnboarding(input: {
        id: string;
        newPassword: string;
        confirmPassword: string;
        language: number;
        time: string;
        sig: string;
        questionnaireResId: string;
    }) {
        const { id, newPassword, confirmPassword, time, sig, language, questionnaireResId } = input;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Complete onboarding process' });
        const body = {
            newPassword,
            confirmPassword,
            languageId: language,
        };

        return this.httpClient
            .put<void>(`${this.url}/onboarding-complete/${id}?questionnaireResId=${questionnaireResId}&time=${time}&sig=${sig}`, body, { headers })
            .pipe(
                tap(() => this.errorsSub$.next([])),
                map(() => true),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return of(false);
                }),
            );
    }

    isEmailUnique(email: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of IOM user email' });

        return this.httpClient.get(`${this.urlemail}/isEmailUnique`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'true'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    isCompanyNameUnique(name: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('companyName', name);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of PRA company name' });

        return this.httpClient.get(`${this.url}/is-unique-company`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'false'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    getPraDetail(userId: string, questResponseId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Employer detail' });
        const url = `${this.apiConstant.endpoint}/brands/employer/`;
        return this.httpClient.get<PraInforResponse>(`${url}${userId}/recruitments/${questResponseId}`, { headers }).pipe(
            tap((result: PraInforResponse) => {
                this.praProfileOptionSub$.next(result);
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsSub$.next(err.errorJson.message);
                } else {
                    this.errorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        );
    }

    async invitePra(input?: PraUserInfo):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite pra' });

        return this.httpClient.post<LscmResponse<UserInfo>>(`${this.url}/invite`, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<UserInfo>) => {
                return response.response;
            }),
        ).toPromise();
    }

    getHotelsUser() {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get list of Hotels User' });
        return this.httpClient.get<any>(`${this.url}/hotels`, { headers }).pipe(
            tap((result: any) => {
                this.hotelsUserSub$.next(result);
                this.errorsSub$.next([]);
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsSub$.next(err.errorJson.message);
                } else {
                    this.errorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        ).subscribe();
    }

    updatePagination(paginationOpts: PaginateOptions) {
        this.paginationSub.next(paginationOpts);
    }

    updateSort(sort: Sort) {
        this.sortSub.next(sort);
    }

    document$ = combineLatest([this.sort$, this.pagination$]).pipe(
        distinctUntilChanged(),
        switchMap(([sort, pagination]) => {
            let orderBy = '';
            if (sort && sort.active) {
                orderBy = `${sort.active} ${sort.direction}`;
            }
            const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite employer' });
            const url = `${this.urlDefault}/questionnaireRes?orderBy=${orderBy}&page=${pagination.pageIndex}&limit=${pagination.pageSize}`;
            return this.httpClient.get<LscmResponse<IHistory>>(url, { headers }).pipe(
                tap((res: LscmResponse<IHistory>) => {
                    this.totalCountSub.next(res.response.total);
                    this.errorsSub$.next([]);
                }),
                map((res: LscmResponse<IHistory>) =>
                    res.response.histories.map(item => {
                        const location =
                            item.location && typeof item.location == 'object'
                                ? item.location.map((x: any) => x.key)
                                : item.location;
                        const qType = item.id == "33cfb523-2c6c-4c2a-8541-0c820b08ad2b" ? 'employer_saq' : item.questionnaireType;
                        return {
                            ...item,
                            location: location,
                            questionnaireType: qType
                        };
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
        }),
    );

    deleteQuestionnaireDraft(questionnaireResponseId?: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete questionnaire response draft' });

        return this.httpClient
            .delete<LscmResponse<any>>(`${this.urlDefault}/questionnaireResDraft/${questionnaireResponseId}`, { headers })
            .pipe(
                tap(() => this.updatePagination(this.DEFAULT_PAGINATION)),
                catchError(() => {
                    return EMPTY;
                }),
            );
    }

    downloadEvaluationSAQCSV(questionnnaireResId: string): Observable<any> {
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Download evaluation recruitment agency SAQ',
        });
        const urlDownload = `${this.urlDefault}/questionnaireRes/${questionnnaireResId}/downloadEvaluation`;
        return this.httpClient.post(urlDownload, null, {responseType: 'blob', observe: 'response', headers})
        .pipe(
            map(response => ({
                body: response.body,
                contentDisposition: response.headers.get('content-disposition') || '',
                contentType: response.headers.get('content-type') || 'application/octet-stream',
            })),
        );
    }


    downloadAnswerSAQCSV(questionnnaireResId: string): Observable<any> {
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Download answer recruitment agency SAQ',
        });
        const urlDownload = `${this.urlDefault}/questionnaireRes/${questionnnaireResId}/downloadAnswer`;
        return this.httpClient.post(urlDownload, null, {responseType: 'blob', observe: 'response', headers})
        .pipe(
            map(response => ({
                body: response.body,
                contentDisposition: response.headers.get('content-disposition') || '',
                contentType: response.headers.get('content-type') || 'application/octet-stream',
            })),
        );
    }

    openFile(response: any) {
        if (response && response.body) {
            const { contentType } = response;
            const filename = this.getFileNameFromContentDisposition(response);
            saveAs(new Blob([response.body], { type: contentType }), filename);
        }
    }
    private getFileNameFromContentDisposition(res: any) {
        const { contentDisposition } = res;
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const tempFilename = ((matches && matches[1]) || 'untitled').trim();
        const filename = tempFilename.replace(/["]/g, '');
        return filename;
    }
}
