<div class="submitted-container">
    Latest submitted
    <div class="submitted-table" *ngIf="documents">
        <app-datatable
            *ngIf="config"
            [config]="config"
            (sortChange)="sortChangeValue($event)"
            (filterChange)="filterChangeValue($event)"
            (selectedPageAt)="selectedPageAtValue($event)"
        ></app-datatable>
    </div>
</div>
