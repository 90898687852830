import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules';
import { ClassificationDialog } from 'src/app/modules/shared/dialogs/classification-of-indicators/classification-of-indicators';
import { GridWidgetOptions } from 'src/app/modules/shared/widgets/grid-widget/grid-widget.types';
import { EmployerEvaluationService } from '../../services/evaluation.service';
import { EvaluationSection, EvaluationTotal } from '../../types';

@Component({
    selector: 'app-evaluation',
    templateUrl: './evaluation.component.html',
    styleUrls: ['./evaluation.component.scss'],
})
export class EvaluationComponent implements OnInit {
    @Input()
    isRecruitmentAgencyDetail: boolean = false;

    ra = false;
    type = '';

    constructor(
        private employerEvaluationService: EmployerEvaluationService,
        private route: ActivatedRoute,
        private router: Router,
        private matDialog: MatDialog,
        private breadcrumbService: BreadcrumbService,
        ) {}

    public dialogRef: any;
    reponseId: string;
    firstTimeLoaded = true;

    ngOnInit(): void {
        this.breadcrumbService.setActions([])
        this.route.params.subscribe(_ => {
            this.reponseId = this.route.snapshot.params.id;
            if (this.reponseId) {
                this.employerEvaluationService.getEvaluation(this.reponseId).subscribe();
            }
            this.ra = this.route.snapshot.params.ra;
            this.type = this.route.snapshot.params.type;
        })

        this.employerEvaluationService.resDatetime$.subscribe(res => {
            if (res.createdAt !== '') {
                if (!this.firstTimeLoaded) {
                    if (this.breadcrumbService.getBreadCrumbItems().length > 1) {
                        this.breadcrumbService.removeLastItem();
                    }
                }
                const datepipe: DatePipe = new DatePipe('en-US')
                let formattedDate = datepipe.transform(res.createdAt, 'dd MMMM, YYYY - hh:mm a')
                this.breadcrumbService.updateBreadCrumbItemsWithItem({
                    name: formattedDate,
                    path: this.router.url
                });
                if (this.firstTimeLoaded) {
                    this.firstTimeLoaded = false;
                }
            }
        })
    }

    resEvaluations$ = this.employerEvaluationService.resEvaluation$.pipe(
        switchMap((returnEvaluationSection: EvaluationSection[]) => {
            const sectionOpts:any[] = [];
            returnEvaluationSection.forEach(section => {
                const titlesection = `${section.name}`;
                const data = section.ilos.map(ilo => {
                    const value = {
                        difinite: ilo.difinite,
                        strong: ilo.strong,
                        possible: ilo.possible
                    }
                    const item = {
                        label: ilo.name,
                        value: value
                    };
                    return item;
                });
                const opts: GridWidgetOptions = {
                    title: titlesection || '',
                    labelHeader: '',
                    columns: [
                        { name: 'Definite' },
                        { name: 'Strong' },
                        { name: 'Possible' },
                    ],
                    data: data,
                };
                sectionOpts.push(opts);
            });

            return of(sectionOpts);
        }),
    );

    totalEvaluationOptions$ = this.employerEvaluationService.resEvaluationTotal$.pipe(
        switchMap((returnEvaluationTotal: EvaluationTotal[]) => {
                const titlesection = '';
                const data  = [{
                        label: "",
                        value: returnEvaluationTotal[0],
                    }];

                const opts: GridWidgetOptions = {
                    title: titlesection || '',
                    labelHeader: '',
                    columns: [
                        { name: 'Definite' },
                        { name: 'Strong' },
                        { name: 'Possible'},
                    ],
                    data: data,
                };
            return of(opts);
        })
    );

    onSubmit() {
        this.router.navigate(['/self-assessment-questionnaire']);
    }

    onClassification(){
        this.dialogRef = this.matDialog.open(ClassificationDialog,
            {
                width: '890px',
                height: '650px',
                data: {
                    title: `Classification of indicators`,
                    subTitle: `11 ILO Indicators of Forced Labor`,
                    hideCancelButton: false,
                },
            }
        )
    }

    redirectSelfAssessmentQuestionnaire() {
        let hotelFlow = false
        this.breadcrumbService.getBreadCrumbItems().forEach(element => {
            if (element.name === 'Hotels') {
                hotelFlow = true;
            }
        });
        if (hotelFlow) {
            if (this.ra) {
                this.router.navigate([`employers/agency/self-assessment-questionnaire/${this.reponseId}`]);
            } else {
                this.router.navigate([`employers/self-assessment-questionnaire/${this.reponseId}`]);
            }
        } else if (this.isRecruitmentAgencyDetail) {
            this.router.navigate([`agency/self-assessment-questionnaire/${this.reponseId}`]);
        } else {
            this.router.navigate([`dashboard/self-assessment-questionnaire/${this.reponseId}`]);
        }
    }

}
