import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-term-dialog-content',
    templateUrl: './term-dialog-content.component.html',
    styleUrls: ['./term-dialog-content.component.scss'],
})
export class TermDialogContentComponent implements OnInit {
    @Input()
    lmpmLink = '';

    @Input()
    lmpmTermLink = '';

    currentLink = window.location.origin;
    currentTermLink = window.location.origin + "/terms-and-conditions/term";
    constructor() {}

    ngOnInit(): void {
    }
}
