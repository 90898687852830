<div class="notification-component">
    <a class="notification" [attr.aria-label]="'menu'" [matMenuTriggerFor]="menu" #t="matMenuTrigger" (menuOpened)="menuOpened()" (menuClosed)="menuClosed()">
        <mat-icon class="{{t.menuOpen ? 'active-icon' : ''}}" *ngIf="(numberOfNewNoti$ | async) === 0">notifications</mat-icon>
        <mat-icon class="{{t.menuOpen ? 'active-icon' : ''}}" *ngIf="(numberOfNewNoti$ | async) !== 0" matBadge="{{numberOfNewNoti$ | async}}" matBadgeColor="warn"
            >notifications</mat-icon
        >
        <span class="break-text {{t.menuOpen ? 'active-menu' : ''}}">{{ menuTitle }}</span>
    </a>
    <mat-menu #menu="matMenu" xPosition="before" class="mat-menu-notification" >

        <div class="menu-container">
            <div class="header-title-noti" (click)="prevent($event)">
                <span>Notifications</span>
                <mat-icon class="icon-close" (click)="t.closeMenu()">close</mat-icon>
            </div>
            <div>
                <ul>
                    <li
                        *ngFor="let notification of (notificationResult$ | async)?.notifications; let i = index"
                        class="language-name noti-item"
                        [class]="notification.isRead ? '' : ''"
                        (click)="prevent($event)"
                    >
                        <div class="noti-item-wrap">
                            <img class="icon-type" [src]="(notification?.sender?.role?.role === 'brand' || notification?.sender?.role?.role === 'employer' ) ? '../assets/images/hotel.svg' : '../assets/images/pra.svg'" alt="icon"/>
                            <p>
                                <ng-container [ngSwitch]="notification.type">
                                    <span class="title-noti" *ngSwitchCase="'onboarding'">
                                        {{ notification?.sender?.role?.role !== 'pra' ? notification?.data?.hotel?.companyName : notification?.sender?.fullName }}
                                        has been onboarded {{notification?.sender?.role?.role === 'pra' && notification.data?.hotel?.companyName ? ('for ' + notification.data?.hotel?.companyName) : ' '}}
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'employer_scoping_submitted'">
                                        {{ notification.data?.hotel?.companyName }}
                                        submitted Scoping Questions.
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'hotel_request_link_to_hotel_group'">
                                        {{ notification.data?.hotel?.companyName }}
                                        requested to link under your hotel group
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'hotel_group_request_link_to_hotel'">
                                        {{ notification.data.brand.name }}
                                        requested to add you
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'hotel_reject_request_link_to_hotel_group'">
                                        {{ notification.data.hotel.companyName }}
                                        has rejected your link request.
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'hotel_group_reject_request_link_to_hotel'">
                                        {{ notification.data.brand.name }}
                                        has rejected your link request.
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'hotel_accept_request_link_to_hotel_group'">
                                        {{ notification?.data?.hotel?.companyName }}
                                        has accepted your link request. Click here to complete On-boarding.
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'myself_accept_request_link_to_hotel_group'">
                                        You are now under
                                        {{notification.data?.brand?.name}}
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'myself_accept_request_link_to_hotel'">
                                        {{notification.data?.hotel?.companyName}}
                                        is now under your group
                                    </span>
                                    <span class="title-noti" *ngSwitchCase="'hotel_group_accept_request_link_to_hotel'">
                                        {{ notification.data?.brand?.name }}
                                        accepted your link request.
                                    </span>
                                    <span class="title-noti" *ngSwitchDefault>
                                        {{ notification?.sender?.role?.role === 'employer' ? notification?.data?.hotel?.companyName : notification?.sender?.fullName }}
                                        submitted self-assessment questionnaire.
                                    </span>
                                </ng-container>
                                <span class="submitted-time">{{notification.createdAt | date: 'dd MMM, yyyy - h:mm a'}}</span>
                                <ng-container [ngSwitch]="notification.type">
                                    <span *ngSwitchCase="'onboarding'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId)">

                                    </span>
                                    <span *ngSwitchCase="'hotel_reject_request_link_to_hotel_group'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId)">

                                    </span>
                                    <span *ngSwitchCase="'hotel_group_reject_request_link_to_hotel'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId)">

                                    </span>
                                    <span *ngSwitchCase="'myself_accept_request_link_to_hotel_group'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId)">

                                    </span>
                                    <span *ngSwitchCase="'hotel_group_accept_request_link_to_hotel'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId)">

                                    </span>
                                    <span *ngSwitchCase="'employer_scoping_submitted'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId)">
                                        View Details
                                    </span>
                                    <span *ngSwitchCase="'hotel_request_link_to_hotel_group'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId, notification?.data, notification?.sender)">
                                        View Request
                                    </span>
                                    <span *ngSwitchCase="'hotel_group_request_link_to_hotel'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId, notification?.data, notification?.sender)">
                                        View Request
                                    </span>
                                    <span *ngSwitchCase="'hotel_accept_request_link_to_hotel_group'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId, notification?.data, notification?.sender)">
                                        Complete Onboarding
                                    </span>
                                    <span *ngSwitchCase="'myself_accept_request_link_to_hotel'" class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId, notification?.data, notification?.sender)">
                                        Complete Onboarding
                                    </span>
                                    <span *ngSwitchDefault class="link-btn" (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId, notification?.data)">
                                        Review SAQ
                                    </span>
                                </ng-container>
                            </p>
                            <img src="../assets/images/noti.png" *ngIf="!notification.isRead" alt="icon" class="icon-noti" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <button *ngIf="(notificationResult$ | async)?.notifications.length > 0" class="load-more" (click)="loadmore($event);$event.stopPropagation();$event.preventDefault();">Load more</button> -->
    </mat-menu>
</div>
