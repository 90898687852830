<div style="display: flex; flex-direction: column">
    <div>
        <form (ngSubmit)="onSubmit()" *ngIf="form" [formGroup]="form">
            <div *ngFor="let section of sections" class="tvf-row">
                <label class="sub-title" [attr.for]="section.id">{{ section.label }}</label>
                <div *ngFor="let question of section.questions" class="form-row">
                    <div *ngIf="question.parentId == ''">
                        <app-question
                            [question]="question"
                            [questions]="section.questions"
                            [form]="form"
                            [enableReadOnlyMode]="disabled"
                        >
                        </app-question>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
