<div class="layout-user-list">
    <mat-card class="mat-elevation-z-p-r6" [ngClass]="'mat-elevation-z0'">
        <mat-card-content class="content-container">
            <app-datatable-mobile [config]="config" *ngIf="smallScreen$ | async; else web"></app-datatable-mobile>
            <app-error-box [errorMessages]="errorMessages"></app-error-box>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #web>
    <app-datatable
        *ngIf="config"
        [config]="config"
        (sortChange)="sortChangeValue($event)"
        (filterChange)="filterChangeValue($event)"
        (selectedPageAt)="selectedPageAtValue($event)"
    ></app-datatable>
</ng-template>
