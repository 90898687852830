import { AbstractControl } from '@angular/forms';

export function UrlValidator(control: AbstractControl) {
    if (!control.value) return null;
    const reg = new RegExp(/^(?:https?:\/\/(?:www\.)?|www\.)[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/\S*)?$/);
    return reg.test(control.value)
        ? null
        : {
              url: {
                  message: 'Please input a valid website',
              },
          };
}
