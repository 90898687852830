import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { debounceTime, delay, exhaustMap, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfirmationDialog, ConfirmCancelDialog } from '../../shared';
import { UserStatus, PaginateOptions } from '../../types';
import { ListUsersFormComponent, NewUserFormComponent } from '../components';
import { IomService } from '../services';
import { IomUserInfo } from '../types';

@Component({
    selector: 'iom-dashboard',
    template: `
        <div class="grid-container1 layout-padding">
            <list-users-form
                class="list-users-form"
                [pageSize]="pageSize"
                [userCollection]="userCollection$ | async"
                (paginate)="paginateUsers$.next($event)"
                (inactivateAdmin)="openDialog($event)"
                (inactivateIom)="activeOrDeActiveIom($event)"
                [errorMessages]="inactivateUserErrors$ | async"
                (filterUser)="filter$.next($event)"
                (sortChange)="sorter$.next($event)"
            >
            </list-users-form>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
            }

            .grid-container {
                align-items: stretch;

                display: grid;

                grid-template-rows: 10px minmax(min-content, auto) minmax(min-content, auto) 10px;
                grid-template-columns: minmax(30px, 1fr) minmax(280px, 8fr) minmax(30px, 1fr);
            }

            .grid-container .list-users-form {
                grid-row: 3;
                grid-column: 2;
            }

            .grid-container .new-user-form {
                grid-row: 2;
                grid-column: 2;
                margin-bottom: 2rem;
            }

            @media only screen and (max-width: 600px) {
                .grid-container {
                    grid-template-columns: 1fr;
                }

                .grid-container .list-users-form {
                    grid-column: 1;
                }

                .grid-container .new-user-form {
                    grid-column: 1;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IomAdminContainer implements OnInit, OnDestroy {
    pageSize = 10;

    @ViewChild('registerForm', { static: true })
    newUserForm: NewUserFormComponent;

    @ViewChild(ListUsersFormComponent, { static: true })
    listUserForm: ListUsersFormComponent;

    private unsubscribe$ = new Subject();
    registerClicked$ = new Subject<IomUserInfo>();
    paginateUsers$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });
    inactivateAdmin$ = new Subject<any>();
    inactivateIom$ = new Subject<any>();
    filter$ = new BehaviorSubject<string>('');
    sorter$ = new BehaviorSubject<Sort>({ active: 'fullName', direction: 'asc' });

    userCollection$ = this.iomService.userCollection$;
    errors$ = this.iomService.errors$;
    inactivateUserErrors$ = this.iomService.inactivateUserErrors$;
    lstCaseManager$: any;

    newUserData$: any;
    userDataActive$: any;

    constructor(private iomService: IomService, private dialog: MatDialog, private spinner: NgxSpinnerService) {}

    ngOnInit() {
        this.registerClicked$
            .pipe(
                delay(0),
                tap(() => this.spinner.show()),
                exhaustMap(registerInfo =>
                    this.iomService.registerIomSHA(registerInfo, this.paginateUsers$.getValue()),
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => this.spinner.hide());

        this.iomService.newUser$
            .pipe(
                delay(0),
                map(u => {
                    this.newUserData$ = u;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                const result = this.newUserData$?.response;
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: 'Add new user successfully!',
                        message:
                            `Successfully added ` +
                            result?.fullName +
                            ` to LMPM. Invitation email was sent to ` +
                            result?.email,
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        if (this.newUserForm) {
                            this.newUserForm.resetForm();
                        }
                    });
            });

        combineLatest([this.paginateUsers$, this.filter$, this.sorter$])
            .pipe(
                debounceTime(300),
                switchMap(([pagination, filter, sorter]) => {
                    let orderBy = '';
                    if (sorter && sorter.active) {
                        orderBy = `${sorter.active} ${sorter.direction}`;
                    }
                    return this.iomService.paginteUserManagements(pagination, filter, orderBy);
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        this.inactivateAdmin$
            .pipe(
                delay(0),
                exhaustMap(res => this.iomService.inactivateAdmin(res.userId, this.pageSize)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
        this.inactivateIom$
            .pipe(
                delay(0),
                exhaustMap(res => this.iomService.inactivateIomSha(res.userId, res.status, this.pageSize)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
        this.iomService.activateUser$
            .pipe(
                delay(0),
                map(u => {
                    this.userDataActive$ = u;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title:
                            (this.userDataActive$.status === 'Active' ? `Activate ` : `Deactivate `) + `successfully`,
                        message:
                            this.userDataActive$.email +
                            ` was ` +
                            (this.userDataActive$.status === 'Active' ? `activated ` : `deactivated `) +
                            `successfully.`,
                        closeButtonText: 'OK',
                    },
                });

                confirmDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe();
            });
    }

    activeOrDeActiveIom(data: { status: UserStatus; userId: string }) {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate  IOM/ SHA user` : `Activate IOM/ SHA user`,
                subTitle:
                    data.status == 'Active'
                        ? `Are you sure you want to deactivate  account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When deactivated, user cannot work on LMPM platform. However, you will still be able to view their information.`
                        : ` When activate, IOM/ SHA user can continue working on LMPM platform.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    this.inactivateIom$.next(data);
                }
            });
    }
    openDialog(data: { fullname: string; userId: string, email: string }) {
        const { userId, email } = data;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Delete account?`,
                subTitle: `Are you sure you want to delete the following account: ${email}`,
                message: `
                When delete, this user cannot work on LMPM platform. Their information will also be deleted.
                `,
                cancelButtonText: 'Discard',
                confirmButtonText: 'Delete account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.iomService.inactivateAdmin(userId, this.pageSize);
                    }
                    return of(undefined);
                })
            )
            .subscribe(async result => {
                if (result) {
                    this.spinner.hide();
                    this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: `Delete Successfully!`,
                            message: `
                            User was successfully deleted from LMPM platform.
                            `,
                            closeButtonText: 'Close',
                        },
                    });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
