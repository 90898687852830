import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {  Subject } from 'rxjs';
import { delay, exhaustMap, map, takeUntil, tap } from 'rxjs/operators';
import { BreadcrumbService, DirtyComponent } from '../../core';
import { ConfirmationDialog } from '../../shared';
import { NewUserFormComponent } from '../components';
import { EmployerService } from '../services';
import { EmployerUserInfo } from '../types';
import * as myGlobals from '../../../globals';

@Component({
    selector: 'invite-employer',
    template: `
        <div class="grid-container1 layout-padding">
            <new-user-form
                class="new-user-form"
                (registerClicked)="registerClicked$.next($event)"
                [errorMessages]="errors$ | async"
                [questionnaire]="questionnaire$ | async"
                [lstCountrySub]="countrySub$ | async"
                [partialId]="partialId"
                #registerForm
            ></new-user-form>
            <app-registration-save-button
                [isValid]="isValidForm"
                (discardEvent)="discardClicked()"
                (submitEvent)="submitClicked()"
            ></app-registration-save-button>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
            }

            .grid-container {
                align-items: stretch;

                display: grid;

                grid-template-rows: 10px minmax(min-content, auto) minmax(min-content, auto) 10px;
                grid-template-columns: minmax(30px, 1fr) minmax(280px, 8fr) minmax(30px, 1fr);
            }

            .grid-container .list-users-form {
                grid-row: 3;
                grid-column: 2;
            }

            .grid-container .new-user-form {
                grid-row: 2;
                grid-column: 2;
                margin-bottom: 2rem;
            }

            @media only screen and (max-width: 600px) {
                .grid-container {
                    grid-template-columns: 1fr;
                }

                .grid-container .list-users-form {
                    grid-column: 1;
                }

                .grid-container .new-user-form {
                    grid-column: 1;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteEmployerContainer implements OnInit, OnDestroy, DirtyComponent {
    @ViewChild('registerForm', { static: true }) newUserForm: NewUserFormComponent;

    private unsubscribe$ = new Subject();
    registerClicked$ = new Subject<EmployerUserInfo>();
    userCollection$ = this.employerService.userCollection$;
    errors$ = this.employerService.errors$;
    inactivateUserErrors$ = this.employerService.inactivateUserErrors$;
    isValid = false;
    newUserData$: any;

    questionnaire$ = this.employerService.questionnaire$;
    countrySub$ = myGlobals.numberCountry$;
    partialId = 'invite-employer';

    constructor(private employerService: EmployerService,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private breadcrumbService: BreadcrumbService,
        private router: Router
        ) {}

    public get isDirty() {
        if (!this.newUserForm) {
            return false;
        }
        return this.newUserForm.isDirty;
    }

    public get isValidForm() {
        if (!this.newUserForm) {
            return false;
        }
        return this.newUserForm.isValid;
    }

    ngOnInit() {
        this.breadcrumbService.updateBreadCrumbItems([
            {
                name: 'Hotels',
                path: '/employers',
            },
            {
                name: 'Hotel Registration',
                path: `/employers/registration`,
            },
        ]);
        this.spinner.show();
        this.registerClicked$
            .pipe(
                delay(0),
                tap(() => this.spinner.show()),
                exhaustMap(registerInfo =>
                    this.employerService.inviteEmployer(registerInfo),
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => this.spinner.hide());

        this.employerService.errors$.pipe(takeUntil(this.unsubscribe$)).subscribe(msg => {
            this.dialog.open(ConfirmationDialog, {
                data: {
                    title: 'Failed to add new hotel!',
                    message: `Reason: ${msg}`,
                },
            });
        });

        this.employerService.newUser$
            .pipe(
                delay(0),
                map(u => {
                    this.newUserData$ = u;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.spinner.hide()
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: 'Add new user successfully!',
                        message:
                            `Successfully added ` +
                            this.newUserData$.fullName +
                            ` to LMPM. Invitation email was sent to ` +
                            this.newUserData$.email,
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.newUserForm.reset()
                        this.router.navigate(['employers']);
                    });
            });

        this.questionnaire$.subscribe(() => {
            this.spinner.hide()
        })
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    discardClicked() {
        this.newUserForm.discard();
    }

    submitClicked() {
        this.newUserForm.onSubmit();
    }
}
