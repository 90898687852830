import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedModule } from '../shared';
import { ListHotelsComponent, ListUsersFormComponent, NewUserFormComponent, PraDashboardComponent } from './components';
import { MyQuestionnairesComponent } from './components/my-questionnaires/my-questionnaires.component';
import { PraAdminContainer, PraDetailContainer, PraUserOnboardingContainer } from './containers';
@NgModule({
    declarations: [
        PraAdminContainer,
        ListUsersFormComponent,
        PraDashboardComponent,
        ListHotelsComponent,
        NewUserFormComponent,
        PraUserOnboardingContainer,
        PraDetailContainer,
        MyQuestionnairesComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        SlickCarouselModule,
    ],
    exports: [
        PraAdminContainer,
        ListUsersFormComponent,
        PraDashboardComponent,
        ListHotelsComponent,
        NewUserFormComponent,
        PraUserOnboardingContainer,
        SlickCarouselModule,
    ]
})
export class PraLazyModule {}
