<div class="employer-pra">
    <div class="employer-pra__title" *ngIf="!isDisplayHeader">Recruitment Agency</div>
    <div [class]="!isDisplayHeader ? 'employer-pra__table' : ''">
        <app-datatable
            *ngIf="config"
            [config]="config"
            (sortChange)="sortChangeValue($event)"
            (filterChange)="filterChangeValue($event)"
            (selectedPageAt)="selectedPageAtValue($event)"
        ></app-datatable>
    </div>
</div>
