<div class="date-picker-header">
    <div>
      <button mat-icon-button class="date-picker-double-arrow" (click)="previousClicked('year')">
          <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="date-picker-header-label">{{yearLabel}}</span>
      <button mat-icon-button class="date-picker-double-arrow" (click)="nextClicked('year')">
          <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>

    <div>
      <button mat-icon-button (click)="previousClicked('month')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      <span class="date-picker-header-label">{{monthLabel}}</span>
      <button mat-icon-button (click)="nextClicked('month')">
          <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
