import { CustomValidators } from './../../../shared/formly-fields/custom-validators';
import { TouchedErrorStateMatcher } from './../../../shared/validators/touched-error-state.matcher';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BrandRegisterService } from '../../services/brand-register.service';
import { ConfirmationDialog } from 'src/app/modules/shared';
import { BrandTermDialogComponent } from '..';
import { validateBrandEmail, validateCompanyName } from '../../validators';

@Component({
    selector: 'app-brand-register',
    templateUrl: './brand-register.component.html',
    styleUrls: ['./brand-register.component.scss'],
})
export class BrandRegisterComponent implements OnInit {
    matcher = new TouchedErrorStateMatcher()
    private unsubscribe$ = new Subject();
    private newUser$ = this.service.newUser$;

    constructor(
        private fb: FormBuilder,
        public dialog: MatDialog,
        private service: BrandRegisterService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}
    hide = true;
    hideConfirmPassword = true;
    formregister: FormGroup;
    tooltipList = `Valid Password format:
    - Have at least 8 characters
    - Have at least 1 uppercase
    - Have at least 1 lowercase
    - Have at least 1 numeric character
    - Have at least 1 special characters: ! @ # $ % ^ & *
    - Must not contain space
    `;
    ngOnInit(): void {
        this.formregister = this.fb.group({
            username: new FormControl('', {
                validators: [Validators.required],
            }),
            brandname: new FormControl('', {
                validators: [Validators.required],
                asyncValidators: [validateCompanyName(this.service, this.cdr)],
            }),
            email: new FormControl('', {
                validators: [Validators.required, CustomValidators.emailType],
                asyncValidators: [validateBrandEmail(this.service, this.cdr)],
            }),
            password: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
                ],
            }),
            confirmPassword: new FormControl('', {
                validators: [Validators.required, this.passwordCf.bind(this)],
            }),
            confirmIomTermsAndConditions: new FormControl('', {
                validators: [Validators.required],
            }),
        });

        this.newUser$.subscribe(user => {
            if (user) {
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: 'Registered successfully!',
                        message:
                            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti nihil dignissimos necessitatibus a, hic nostrum perspiciatis recusandae! Ducimus expedita culpa voluptas ratione natus sequi reprehenderit, corporismolestias dolorem, odit perspiciatis.',
                        closeButtonText: 'Go to Login',
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => this.gotoLogin());
            }
        });
    }

    get username() {
        return this.formregister.controls.username as FormControl;
    }

    get email() {
        return this.formregister.controls.email as FormControl;
    }

    get brandname() {
        return this.formregister.controls.brandname as FormControl;
    }

    get password() {
        return this.formregister.controls.password as FormControl;
    }

    get confirmPassword() {
        return this.formregister.controls.confirmPassword as FormControl;
    }

    get confirmIomTermsAndConditions() {
        return this.formregister.controls.confirmIomTermsAndConditions as FormControl;
    }

    clicked() {
        if (this.formregister.valid) {
            this.service.registerBrand({
                email: this.formregister.value.email,
                fullName: this.formregister.value.username,
                name: this.formregister.value.brandname,
                password: this.formregister.value.password,
                confirmPassword: this.formregister.value.confirmPassword,
            });
        }
    }

    gotoLogin() {
        this.router.navigate(['/login']);
    }

    openDialog() {
        const termDialog = this.dialog.open(BrandTermDialogComponent, {
            width: '800px',
            data: {
                isCheck: this.formregister.value.confirmIomTermsAndConditions ? true : false,
            },
        });

        termDialog.afterClosed().subscribe(res => {
            this.formregister.controls.confirmIomTermsAndConditions.setValue(res);
        });
    }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }

    passwordCf() {
        if (this.formregister !== null && this.formregister !== undefined) {
            const newPass = this.formregister.controls.password;
            const confirmPass = this.formregister.controls.confirmPassword;
            return newPass.value === confirmPass.value ? null : { passwordNotMatch: true };
        }
        return null;
    }

    passwordChanged(value: string) {
        if (this.formregister !== null && this.formregister !== undefined) {
            const repassword = this.formregister.controls.confirmPassword;
            if (repassword.value !== '' && value !== repassword.value) {
                this.formregister.controls.confirmPassword.setErrors({ passwordNotMatch: true });
            }
            if (repassword.value !== '' && value === repassword.value) {
                this.formregister.controls.confirmPassword.setErrors(null);
            }
        }
    }
}
