import { FormlyFieldConfig } from '@ngx-formly/core';
import { PaginateOptions } from '../types';

export const MIN_PASSWORD_LEN = 8;
export type QuestionnaireType = 'employer_registration_form' | 'employer_scoping' | 'employer_pra_scoping' | 'employer_saq' | 'recruitment_saq';
export type NotificationType = 'onboarding' | 'hotel_request_link_to_hotel_group' | 'hotel_group_request_link_to_hotel'
| 'employer_scoping_submitted' | 'hotel_accept_request_link_to_hotel_group' | 'myself_accept_request_link_to_hotel' | 'hotel_reject_request_link_to_hotel_group'
| 'hotel_group_reject_request_link_to_hotel' | 'myself_accept_request_link_to_hotel_group' | 'hotel_group_accept_request_link_to_hotel';

export interface Card {
    title: string;
    data: CardData;
    cardType: CARDTYPE;
}

export interface CardData {
    buttons: CardButton[];
}

export interface CardButton {
    label: string;
    navigateUrl: string;
    icon: string;
}

export enum CARDTYPE {
    DEFAULT,
}

export interface DataTableConfig<T> {
    data: Array<T>;
    columns: Array<DataTableConfigColumn<T>>;
    actions?: Array<DataTableConfigACtion<T>>;
    mergeActionColumns?: boolean;
    actionStyle?: { [key: string]: any };
    meta: DataTableConfigMeta;
    pageChange: (info: PaginateOptions) => void;
    tableName?: string;
    filterKeys?: string[];
    hideFilter?: boolean;
    hidePaginator?: boolean;
}

export interface DataTableConfigColumn<T> {
    text: string;
    label: string;
    fieldName: string;
    condition?: (element: T) => boolean;
    valueFunction?: (element: T) => string;
    templateFunction?: (element: T) => string;
    style?: { [key: string]: any };
    actionFunction?: ($event: Event, element: T) => void;
    styleClassFunction?: (element: T) => string;
    sortable?: boolean;
}

export interface DataTableConfigACtion<T> {
    text: string;
    label: string;
    fieldName: string;
    condition?: (element: T) => boolean;
    actionFunction?: ($event: Event, element: T) => void;
    style?: { [key: string]: any };
    valueFunction?: (element: T) => string;
    displayIcon?: boolean;
    iconValue?: string;
}

export interface DataTableConfigMeta {
    pageSize: number;
    rowsNumber: number;
    startAtPage?: { pageIndex: number };
}

export interface CustomFormlyFieldConfig extends FormlyFieldConfig {
    questionId?: string;
    fieldGroup?: CustomFormlyFieldConfig[];
    partialId?: string;
}

export type FormlyFieldLayout = 'default' | 'modern' | undefined;

export interface GridReportOptions {
    labelHeader: string;
    columns: GridReportColumnOption[];
}


export interface GridReportColumnOption {
    name: string;
    valueFn?: Function
}

export interface FileInfo {
    id?: string;
    fileName: string;
    fileSize: number;
    fileUrl: string;
    fileSizeDisplay?: string;
}

export interface AttachmentDownloadResponse<T> {
    body: T | null;
    contentDisposition: string;
    contentType: string;
}
