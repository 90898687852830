import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-registration-save-button',
    templateUrl: './registration-save-button.component.html',
    styleUrls: ['./registration-save-button.component.scss'],
})
export class RegistrationSaveButtonComponent implements OnInit {
    @Output()
    discardEvent = new EventEmitter();
    @Output()
    submitEvent = new EventEmitter();
    @Input()
    isValid: boolean;
    @Input()
    responseNoti: boolean;
    @Input() readOnlyMode: boolean;

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {}

    onDiscard() {
        this.discardEvent.emit()
    }

    onSubmit() {
        this.submitEvent.emit();
    }
}
