import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiConstant } from '../types';
import { PraLazyModule } from './pra-lazy.module';
import { PRA_API_TOKEN } from './types';
import { PraService } from './services/pra.service';

@NgModule({
    imports: [PraLazyModule],
    exports: [PraLazyModule],
})
export class PraModule {
    static forRoot(apiConstant: ApiConstant): ModuleWithProviders<PraModule> {
        return {
            ngModule: PraModule,
            providers: [
                {
                    provide: PRA_API_TOKEN,
                    useValue: apiConstant,
                },
                PraService,
            ],
        };
    }
}
