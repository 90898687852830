import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingEmployerContainer } from '../employer';
import { CommonLayoutComponent } from '../shared/layouts';
import { UserSettingsContainerComponent } from './components/user-settings-container/user-settings-container.component';

const routes: Routes = [
    {
        path: '',
        component: CommonLayoutComponent,
        data: {
            pageTitle: 'Account Settings',
            pageDes: '',
            // enableRequestLink: true,
        },
        children: [
            {
                path: '',
                component: UserSettingsContainerComponent,
            },
        ],
    },
    {
        path: '',
        component: CommonLayoutComponent,
        data: {
            pageTitle: '',
            pageDes: '',
        },
        children: [
            {
                path: 'link-to-hotel/:id/response/:response',
                component: OnboardingEmployerContainer,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserSettingsRoutingModule {}
