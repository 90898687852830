
<!-- Layout: Modern -->
<div class="modern-layout wrap-question-cmp" *ngIf="to.layout === 'modern'">
    <div class="top-label" *ngIf="to.symbol">
        <div class="symbol-input-left">
            <span>{{ to.symbol }}</span>
            <!-- <img src="assets/images/icon-question.svg" alt="icon" class="icon-question" /> -->
        </div>
        <div class="right-item" *ngIf="to?.required">
            <mat-label class="asterix--before"></mat-label>
        </div>
    </div>
    <div *ngIf="field.fieldGroup.length === 3">
        <div class="layout-parent">
            <formly-field [field]="field.fieldGroup[0]"></formly-field>
        </div>
        <div [class]="field.fieldGroup[1].type !== 'file-upload' && field.fieldGroup[2].type !== 'file-upload' ? 'layout-child' : ''">
            <div [class]="field.fieldGroup[1].type !== 'file-upload' && field.fieldGroup[2].type !== 'file-upload' ? 'sub-layout-child' : ''">
                <formly-field [field]="field.fieldGroup[1]"></formly-field>
            </div>
            <div [class]="field.fieldGroup[1].type !== 'file-upload' && field.fieldGroup[2].type !== 'file-upload' ? 'sub-layout-child' : ''">
                <formly-field [field]="field.fieldGroup[2]"></formly-field>
            </div>
        </div>
    </div>
    <div *ngIf="field.fieldGroup.length !== 3">
        <div *ngFor="let question of field.fieldGroup; let i = index; let last = last">
            <formly-field [field]="question"></formly-field>
        </div>
    </div>
</div>
