import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PraInforResponse, PraUserInfo } from '..';
import { BreadcrumbService } from '../../core';
import { ConfirmationDialog } from '../../shared';
import {
    ListWidgetItem,
    ListWidgetItemValueType,
    ListWidgetOptions,
} from '../../shared/widgets/list-widget/list-widget.types';
import { PraService } from '../services/pra.service';

@Component({
    selector: 'pra-detail',
    template: `
        <div class="grid-container1">
            <div class="table-enterprises">
                <app-list-widget [options]="praProfileOptionSub$ | async"></app-list-widget>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
            }

            .grid-container {
                align-items: stretch;

                display: grid;

                grid-template-rows: 10px minmax(min-content, auto) minmax(min-content, auto) 10px;
                grid-template-columns: minmax(30px, 1fr) minmax(280px, 8fr) minmax(30px, 1fr);
            }

            @media only screen and (max-width: 600px) {
                .grid-container {
                    grid-template-columns: 1fr;
                }

                .grid-container .list-users-form {
                    grid-column: 1;
                }

                .grid-container .new-user-form {
                    grid-column: 1;
                }
            }
            .table-enterprises {
                margin: 40px;
            }
        `,
    ],
})
export class PraDetailContainer implements OnInit, OnDestroy {
    employerId = '';
    reponseId = '';
    hotelName = '';
    praUser:PraUserInfo;
    constructor(
        private praService: PraService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private breadcrumbService: BreadcrumbService,
        private spinner: NgxSpinnerService
    ) {
        this.praProfileOptionSub$.subscribe((x:any) => {
            if(x.status === 'Invited' || x.status === 'Not Invited' ) {
                this.breadcrumbService.setActions([
                    {
                        actionFn: this.invitePra.bind(this),
                        iconCls: 'add',
                        name: 'Invite Recruitment Agency',
                    },
                ])
            }
        })

    }
    praProfileOptionSub$ = this.praService.praProfileOption$.pipe(
        switchMap((result: PraInforResponse) => {
            const resultData: ListWidgetItem[] = [];
            const totalOfCountry = result?.response.recruitments.info.find(question => question.code === 'emp_pra_scop_A10')?.value
            result?.response.recruitments.info.forEach(item => {
                if(item.code !== 'emp_pra_scop_A10') {
                    if(item.code === 'emp_pra_scop_A11') {
                        item.label = "Total number of migrant workers from the Country of Origin that are currently employed by the company"
                    }
                    if(item.code === 'emp_pra_scop_A9') {
                        item.value = totalOfCountry
                    }
                    const dataProfile = {
                        label: item.label,
                        value: this.tryGetValue(item.value, item.controlType),
                        valueType: this.guessValueType(item.valueType),
                    };
                    resultData.push(dataProfile);
                }
            });
            const praDetailProfile: ListWidgetOptions = {
                title: result?.response?.recruitments.name,
                status: result?.response?.recruitments.status,
                data: resultData,
            };
            this.praUser = result?.response?.recruitments?.praInfo || {agency:'', email: '', fullName: '', licenseNumber: ''};
            return of(praDetailProfile);
        }),
        tap((options: ListWidgetOptions) => {
            this.breadcrumbService.updateBreadCrumbItems([
                {
                    name: `Hotel`,
                    path: `/employers`,
                },
                {
                    name: `${this.hotelName}`,
                    path: `/employers/detail/${this.employerId}`,
                },
                {
                    name: options.title,
                    path: this.router.url,
                },
            ]);
        }),
    );

    ngOnInit() {
        this.reponseId = this.route.snapshot.params.id;
        this.employerId = this.route.snapshot.params.employerId;
        this.hotelName = this.route.snapshot.params.hotelName;
        this.praService.getPraDetail(this.employerId, this.reponseId).subscribe();
    }

    ngOnDestroy() {
        this.breadcrumbService.setActions([]);
    }

    async invitePra() {
        this.spinner.show();
        this.praUser.employerId = this.employerId;
        const result = await this.praService.invitePra(this.praUser);
        if (result) {
            this.spinner.hide();
            this.dialog.open(ConfirmationDialog, {
                width: '600px',
                data: {
                    title: 'Email sent',
                    message:`Invitation email was sent to ${this.praUser?.email} successfully.`,
                    closeButtonText: 'Close',
                },
            });
        }
    }

    guessValueType(itemLabel: string): ListWidgetItemValueType {
        const maybeEmail = (itemLabel || '').toLowerCase().indexOf('email') > -1;
        return maybeEmail ? 'email' : 'normal';
    }

    tryGetValue(value: any, controlType?: string) {
        let val = 0;
        let flag = false;
        const questionValue = value && typeof value == 'object' ? (controlType == 'drop-down-multi'
                    ? this.handleDropdownMulti(value)
                    : Object.keys(value).map((item: any) => {
                        if (typeof value[item] == 'number') {
                            flag = true;
                            return val += +value[item];
                        }else {
                            return value[item];
                        }
                    }))
                : value;
        return flag ? val : questionValue;
    }
    handleDropdownMulti(value: any[]) {
        let isObject = false;
        value.forEach(item => {
            if (typeof item === "object") {
                isObject = true
            }
        })
        if (isObject) {
            return value.map((item: any) => item.key)
        } else {
            return value
        }
    }
}
