import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, EMPTY } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
    ApiConstant,
    CustomHttpErrorResponse,
    IHistory,
    // IQuestionnaireEmployerResponse,
    // IQuestionnaireRes,
    LscmResponse,
    PaginateOptions,
} from '../../types';
import { EMPLOYER_API_TOKEN } from '../types';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class DocumentListService {
    urlDefault = this.apiConstant.endpoint;
    constructor(@Inject(EMPLOYER_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) {}

    errorsSub = new BehaviorSubject<any[]>([]);
    errors$ = this.errorsSub.asObservable();

    DEFAULT_PAGINATION: PaginateOptions = {
        pageIndex: 0,
        pageSize: 5,
    };
    sortSub = new BehaviorSubject<Sort>({ active: 'createdAt', direction: 'desc' });
    sort$ = this.sortSub.asObservable();
    updateSort(sort: Sort) {
        this.sortSub.next(sort);
    }

    paginationSub = new BehaviorSubject<PaginateOptions>(this.DEFAULT_PAGINATION);
    pagination$ = this.paginationSub.asObservable();

    updatePagination(paginationOpts: PaginateOptions) {
        this.paginationSub.next(paginationOpts);
    }

    totalCountSub = new BehaviorSubject<number>(0);
    total$ = this.totalCountSub.asObservable();

    document$ = combineLatest([this.sort$, this.pagination$]).pipe(
        distinctUntilChanged(),
        switchMap(([sort, pagination]) => {
            let orderBy = '';
            if (sort && sort.active) {
                orderBy = `${sort.active} ${sort.direction}`;
            }
            const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite employer' });
            const url = `${this.urlDefault}/questionnaireRes?orderBy=${orderBy}&page=${pagination.pageIndex}&limit=${pagination.pageSize}`;
            return this.httpClient.get<LscmResponse<IHistory>>(url, { headers }).pipe(
                tap((res: LscmResponse<IHistory>) => {
                    this.totalCountSub.next(res.response.total);
                    this.errorsSub.next([]);
                }),
                map((res: LscmResponse<IHistory>) =>
                    res.response.histories.map(item => {
                        const location =
                            item.location && typeof item.location == 'object'
                                ? item.location.map((x: any) => x.key)
                                : item.location;
                        const qType = item.id == "33cfb523-2c6c-4c2a-8541-0c820b08ad2b" ? 'employer_saq' : item.questionnaireType;
                        return {
                            ...item,
                            location: location,
                            questionnaireType: qType
                        };
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub.next(err.errorJson.message);
                    } else {
                        this.errorsSub.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
        }),
    );

    deleteQuestionnaireDraft(questionnaireResponseId?: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete questionnaire response draft' });

        return this.httpClient
            .delete<LscmResponse<any>>(`${this.urlDefault}/questionnaireResDraft/${questionnaireResponseId}`, { headers })
            .pipe(
                tap(() => this.updatePagination(this.DEFAULT_PAGINATION)),
                catchError(() => {
                    return EMPTY;
                }),
            );
    }
}
