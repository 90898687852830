import { InjectionToken } from '@angular/core';
import { ApiConstant } from '../types';

export const PRA_API_TOKEN = new InjectionToken<ApiConstant>('api.constant');

export interface PraUserInfo {
    fullName: string;
    email: string;
    agency: string;
    region?: string;
    geography?: string;
    licenseNumber: string;
    password?: string;
    questionnaireResponseId?: string;
    employerId?: string;
    status?: string;
}

export interface PraUserActionData {
    email: string;
    status?: string;
    employerId: string;
    questionnaireResponseId?: string;
    hotelName?: string;
}
export interface ForcePasswordChangePra {
    newPassword: string;
    confirmPassword: string;
    language: number;
    time: string;
    sig: string;
}

export interface PraInforResponse {
    message: string;
    response: {
        recruitments: Recruitment;
    };
}

export interface Recruitment {
    name: string,
    info: Info[],
    status?: string;
    praInfo?: PraUserInfo;
}

export interface Info {
    label: string;
    code: string;
    value: string | any;
    valueType: string;
    oder: number;
    controlType: string;
}
