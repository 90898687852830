import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiConstant } from '../types';
import { IomLazyModule } from './iom-lazy.module';
import { IOM_API_TOKEN } from './types';
import { IomService } from './services/iom.service';

@NgModule({
    imports: [IomLazyModule],
    exports: [IomLazyModule],
})
export class IomModule {
    static forRoot(apiConstant: ApiConstant): ModuleWithProviders<IomModule> {
        return {
            ngModule: IomModule,
            providers: [
                {
                    provide: IOM_API_TOKEN,
                    useValue: apiConstant,
                },
                IomService,
            ],
        };
    }
}
