import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    AuthModule,
    CoreModule,
    IomModule,
    AdminModule,
    ResetPasswordModule,
    BrandModule,
    PraModule,
    EmployerModule,
    EmployeeModule,
    BrandRegisterModule,
} from './modules';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserSettingsModule } from './modules/user-settings/user-settings.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrintModule } from './modules/print/print.module';
import { NgxSpinnerModule } from 'ngx-spinner';
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        NgxSpinnerModule,
        AuthModule.forRoot(environment.api),
        NgxsModule.forRoot([], { developmentMode: !environment.production }),
        IomModule.forRoot(environment.api),
        BrandModule.forRoot(environment.api),
        BrandRegisterModule.forRoot(environment.api),
        PraModule.forRoot(environment.api),
        EmployerModule.forRoot(environment.api),
        EmployeeModule.forRoot(environment.api),
        AdminModule.forRoot(environment.api),
        ResetPasswordModule.forRoot(environment.api),
        UserSettingsModule,
        HttpClientModule,
        TranslateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        PrintModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
