<div class="grid-widget" *ngIf="widget">
    <div class="list-widget__title">{{ options.title }}</div>
    <!-- HEADERS -->
    <div class="grid-widget__list wrap-list-top" *ngIf="widget.headers">
        <div class="grid-widget__item title-grid">
            <div [class]="i === 0 ? 'item-header-title' : 'item-header'" *ngFor="let header of widget.headers; let i = index">{{ header }}</div>
        </div>
    </div>
    <!-- DATA ROWS -->
    <div class="grid-widget__list wrap-list" *ngIf="widget.rows">
        <div class="grid-widget__item" *ngFor="let item of widget.rows">
            <div class="item-label">{{ item.label }}</div>
            <div class="item-value" *ngFor="let val of item.values">{{ val ? +val : 0  }}</div>
        </div>
    </div>
</div>
