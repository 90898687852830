import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserStatus } from 'src/app/modules';

@Component({
    selector: 'employer-button',
    templateUrl: './employer-button.component.html',
    styleUrls: ['./employer-button.component.scss'],
})
export class EmployerButtonComponent implements OnInit {
    constructor() {}
    @Input()
    employerStatus: UserStatus;

    @Output()
    activeOrDeActiveHotel = new EventEmitter<{
        status: UserStatus;
    }>();

    @Output()
    isDeleteHotel = new EventEmitter<boolean>(false);

    ngOnInit(): void {}

    DeleteHotel() {
        this.isDeleteHotel.emit(true);
    }

    activeOrDeactive() {
        this.activeOrDeActiveHotel.emit({
            status: this.employerStatus
        });
    }
}
