import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { exhaustMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../services';
import { AuthenticateInfo } from '../types';

@Component({
    selector: 'auth-login-container',
    template: `
        <div class="language-switcher-included">
            <div class="login-container">
                <div class="child-container">
                    <auth-login-form
                        class="component-form"
                        (loginClicked)="login$.next($event)"
                        (forgetPasswordClicked)="forgetPassword$.next()"
                        [errorMessages]="loginError$ | async"
                    ></auth-login-form>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .main-container {
                display: flex;
                justify-content: center;
                margin: 30px 0;
            }

            .switcher-language {
                width: 100%;
                padding: 30px 0;
            }

            .child-container {
                width: 53.125rem;
                /* box-shadow: 0px 0px 5px 3px #969696;
                -webkit-box-shadow: 0px 0px 5px 3px #969696; */
            }

            .login-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
            }

            app-language-switcher {
                float: right;
                padding-right: 40px;
            }

            auth-login-form {
                max-width: 53.125rem;
                margin: 0 auto;
            }

            .language-switcher-included {
                display: flex;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLoginContainer implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    login$ = new Subject<AuthenticateInfo>();
    loginError$ = this.authService.loginError$;
    forgetPassword$ = new Subject();
    currentLanguageCode = '';

    constructor(private authService: AuthService, private router: Router, private spinner: NgxSpinnerService) {}

    ngOnInit() {
        this.login$
            .pipe(
                tap(() => this.spinner.show()),
                exhaustMap(loginValues => this.authService.login(loginValues)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => this.spinner.hide());

        this.forgetPassword$.subscribe(() => {
            this.router.navigate(['/reset-password']);
        });
        this.authService.loginError$.subscribe(() => this.spinner.hide());
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
