<div class="employer-pra">
    <div class="employer-pra__title">Latest submitted & saved</div>
    <div class="employer-pra__table">
        <app-datatable
            *ngIf="config"
            [config]="config"
            (sortChange)="sortChangeValue($event)"
            (filterChange)="filterChangeValue($event)"
            (selectedPageAt)="selectedPageAtValue($event)"
    ></app-datatable>
    </div>
</div>
