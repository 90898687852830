import { InjectionToken } from '@angular/core';
import { ApiConstant, UserStatus } from '../types';

export const IOM_API_TOKEN = new InjectionToken<ApiConstant>('api.constant');

export interface IomUserInfo {
    fullname: string;
    email: string;
}
export interface ForcePasswordChange {
    newPassword: string;
    confirmPassword: string;
    language: number;
    time: string;
    sig: string;
}

export interface metrics {
    title: string,
    total: number;
    active: number;
    deactive: number;
    invited: number;
    questionnaireNumber?: number;
    scopingNumber?: number;
}

export interface HotelGroupInfo {
    name: string;
    companyName?: string;
    info: Array<{label: string; value: string; }>;
}

export interface HotelGroupInfoResponse {
    message: string;
    response: {
        companyName: string,
        email: string,
        fullName: string,
        identificationCode: string,
        address: string,
        headquarter: string,
        totalSaq: string,
        status: UserStatus
    };
}

export interface MatrixRes {
    brand: MatrixBrand;
    hotel: MatrixSAQ;
    pra: MatrixSAQ;
}

export interface MatrixBrand {
    total: number;
    active: number;
    // invited: number;
    deactive: number;
    deleted: number;
}

export interface MatrixSAQ {
    total: number
    active: number
    invited: number;
    deactive: number;
    deleted: number;
    questionnaire: number;
    scoping: number;
}

export interface submitDate {
    fromDate: string;
    toDate: string;
}

