import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, combineLatest, of, Subject } from "rxjs";
import { debounceTime, switchMap, takeUntil } from "rxjs/operators";
import { BreadcrumbService, PaginateOptions, UserStatus } from "../..";
import { ConfirmationDialog, ConfirmCancelDialog } from "../../shared";
import { IomService } from "../services/iom.service";


@Component({
    selector: 'hotel-group-detail',
    template: `<div class="grid-container1 layout-padding">
                    <app-hotel-group-profile></app-hotel-group-profile>
                    <div class="title"><label>Hotel Group Users</label></div>
                    <app-list-hotel-group
                        [hotelGroupId]="hotelGroupId"
                        [userCollection]="userCollection$ | async"
                        [pageSize]="pageSize"
                        (paginate)="paginateUsers$.next($event)"
                        (deleteEmployeeUser)="deleteEmployeeUser($event)"
                        (inactivateEmployeeUser)="activeOrDeactiveEmployee($event)"
                        (filterUser)="filter$.next($event)"
                        (sortChange)="sorter$.next($event)"
                        [errorMessages]="inactivateUserErrors$ | async"
                    ></app-list-hotel-group>
                    <div class="title"><label>Hotels</label></div>
                    <app-list-hotel
                        [userCollection]="userHotelsCollection$ | async"
                        [pageSize]="pageSize"
                        (paginate)="paginateHotels$.next($event)"
                        (deleteHotelUser)="deleteHotelUser($event)"
                        (inactivateHotelUser)="activeOrDeactiveHotel($event)"
                        (filterUser)="filterHotels$.next($event)"
                        (sortChange)="sorterHotels$.next($event)"
                    ></app-list-hotel>
                    <app-iom-footer
                        (isDeleteHotelGroup)="deleteHotelGroup($event)"
                        (activeOrDeActiveHotel)="activeOrDeActive($event)"
                        [hotelGroupId]="hotelGroupId"
                        [brandId]="brandId"
                        [employerStatus]="status$ | async"
                        [errorMessages]="inactivateUserErrors$ | async"
                    ></app-iom-footer>
            </div>`,
    styles: [
        `
            .layout-padding {
                padding-bottom: 5%;
                margin: 48px;
            }

            .title {
                margin-top: 48px;
                background: none;
                margin-top: 48px;
                background: none;
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                color: #8F9BB3;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelGroupDetailContainer implements OnInit, OnDestroy {
    pageSize = 5;
    paginateUsers$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });
    filter$ = new BehaviorSubject<string>('');
    sorter$ = new BehaviorSubject<Sort>({ active: 'fullName', direction: 'asc' });
    filterHotels$ = new BehaviorSubject<string>('');
    sorterHotels$ = new BehaviorSubject<Sort>({ active: 'fullName', direction: 'asc' });

    hotelGroupId: string;

    private unsubscribe$ = new Subject();
    hotelGroupProfile$ = this.iomService.hotelGroupProfile$
    userCollection$ = this.iomService.userCollection$;
    userHotelsCollection$ = this.iomService.userHotelsCollection$;
    status$ = this.iomService.hotelGroupStatus$;
    isDeleteHotelGroup: boolean;
    inactivateUserErrors$ = this.iomService.inactivateUserErrors$;
    deleteHotelGroup$ = this.iomService.deleteHotelGroup$;
    brandId: string
    currentNumberEmployeeUser = 0;

    paginateHotels$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });
    constructor(
        private iomService: IomService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private routerApp: Router,
        private breadCrumbService: BreadcrumbService,
        private router: Router
        ) {}

    ngOnInit(): void {
        this.hotelGroupId = this.route.snapshot.params.id;
        this.brandId = this.route.snapshot.params.brandId;
        combineLatest([this.paginateUsers$, this.filter$, this.sorter$])
        .pipe(
            debounceTime(300),
            switchMap(([pagination, filter, sorter]) => {
                let orderBy = '';
                if (sorter && sorter.active) {
                    orderBy = `${sorter.active} ${sorter.direction}`;
                }
                return this.iomService.paginteUserEmployees(pagination, filter, orderBy,this.hotelGroupId);
            }),
            takeUntil(this.unsubscribe$),
        )
        .subscribe();
        combineLatest([this.paginateHotels$, this.filterHotels$, this.sorterHotels$])
        .pipe(
            debounceTime(300),
            switchMap(([pagination, filter, sorter]) => {
                let orderBy = '';
                if (sorter && sorter.active) {
                    orderBy = `${sorter.active} ${sorter.direction}`;
                }
                return this.iomService.paginteUserHotels(pagination, filter, orderBy, this.hotelGroupId);
            }),
            takeUntil(this.unsubscribe$),
        )
        .subscribe();
        combineLatest([this.paginateHotels$, this.filterHotels$, this.sorterHotels$])
        this.iomService.getHotelGroupProfile(this.hotelGroupId).subscribe();

        this.hotelGroupProfile$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.breadCrumbService.updateBreadCrumbItemsWithItem({name: result.name, path: '/brands/detail/' + this.hotelGroupId + '/' + this.brandId})
        })
        this.userCollection$.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
            this.currentNumberEmployeeUser = x.userCount;
        })
        this.iomService.deleteLastEmployee$.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
            if (x) {
                this.spinner.hide();
                this.router.navigate(['brands']);
            }
        })
    }

    deleteEmployeeUser(data: { fullname: string; userId: string }) {
        if (this.currentNumberEmployeeUser === 1) {
            this.deleteEmployeeUserAndGroup(data)
            return;
        }
        const { userId } = data;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Delete account`,
                subTitle: `Are you sure you want to delete account?`,
                message: `
                        When delete, this user cannot work on LMPM platform.
                        Their information, activities will be deleted.
                    `,
                cancelButtonText: 'Discard',
                confirmButtonText: 'Delete account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.iomService.deleteHotelGroupUser(userId, this.hotelGroupId);
                    }
                    return of(undefined)
                })
            )
            .subscribe(async result => {
                if (!result) {
                    return;
                }
                if (result) {
                    this.spinner.hide();
                    if (result.userCount > 0) {
                        this.dialog.open(ConfirmationDialog, {
                            width: '600px',
                            data: {
                                title: `Delete Successfully!`,
                                message: `
                                User was successfully deleted from LMPM platform.
                                `,
                                closeButtonText: 'Close',
                            },
                        });
                    } else {
                        this.router.navigate(['brands']);
                    }
                } else if (this.currentNumberEmployeeUser === 1) {
                    this.router.navigate(['brands']);
                }
            });
    }

    deleteEmployeeUserAndGroup(data: { fullname: string; userId: string }) {
        const { userId } = data;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Delete account`,
                subTitle: `Are you sure you want to delete account?`,
                message: `
                        When delete, this user cannot work on LMPM platform.
                        Their information, activities will be deleted.
                    `,
                cancelButtonText: 'Discard',
                confirmButtonText: 'Delete account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    this.spinner.show();
                    this.iomService.deleteHotelGroupUserAndGroup(userId).subscribe();                    
                }
            });
    }

    activeOrDeActive(data: { status: UserStatus; userId: string }) {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate Hotel group user` : `Activate Hotel group user`,
                subTitle:
                    data.status === 'Active'
                        ? `Are you sure you want to deactivate account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When deactivated, user cannot work on LMPM platform. However, you will still be able to view their information, answers and activities.`
                        : ` When activate, Hotel group user can continue working on LMPM platform.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.iomService.inactivateBrand(data.userId, data.status);
                    }
                    return of(undefined);
                })
            )
            .subscribe(async result => {
                if (result) {
                    this.spinner.hide();
                    const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                        data: {
                            title: data.status == 'Active' ? 'Deactivated Successfully!' : 'Activated Successfully',
                            message:
                                data.status == 'Active'
                                    ? 'User was successfully deactivated from LMPM platform.'
                                    : 'User was successfully activated from LMPM platform.',
                            closeButtonText: 'Close',
                        },
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe();
                }
            });
    }

    deleteHotelGroup(data: { isDelete: UserStatus; hotelGroupId: string }) {
        if (data.isDelete) {
            let dialogRef = this.dialog.open(ConfirmCancelDialog, {
                width: '600px',
                data: {
                    title: `Warning`,
                    subTitle: ``,
                    message: `
                        Deleting a hotel group will also delete all hotels and data that is associated with this group. Are you sure you want to continue?
                    `,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Delete',
                    hideCancelButton: false,
                    color: 'warn',
                },
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(async result => {
                    if (result) {
                        this.spinner.show();
                        const resultDel = await this.iomService.deleteBrand(data.hotelGroupId);
                        if (resultDel) {
                            this.spinner.hide();
                            const resultDialog = this.dialog.open(ConfirmationDialog, {
                                width: '600px',
                                data: {
                                    title: `Delete Successfully!`,
                                    message: `
                                    User was successfully deleted from LMPM platform.
                                    `,
                                    closeButtonText: 'Close',
                                },
                            });
                            resultDialog.afterClosed().subscribe(() =>
                                this.routerApp.navigate(['/brands'])
                            );
                        }
                    }
                });
        }
    }

    activeOrDeactiveEmployee(data: { status: UserStatus; userId: string }) {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate user` : `Activate user`,
                subTitle:
                    data.status === 'Active'
                        ? `Are you sure you want to deactivate account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When deactivated, user cannot work on LMPM platform.`
                        : `When activate, user can continue working on LMPM platform.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate' : 'Activate',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.iomService.inactivateHotelGroupUser(data.userId, data.status, this.hotelGroupId);
                    }
                    return of(undefined);
                })
            )
            .subscribe(async result => {
                if (result) {
                    this.spinner.hide();
                    const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                        data: {
                            title: data.status == 'Active' ? 'Deactivated Successfully!' : 'Activated Successfully',
                            message:
                                data.status == 'Active'
                                    ? 'User was successfully deactivated from LMPM platform.'
                                    : 'User was successfully activated from LMPM platform.',
                            closeButtonText: 'Close',
                        },
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe();
                }
            });
    }

    deleteHotelUser(data: { fullname: string; userId: string }) {
        const { userId } = data;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Delete Hotel`,
                subTitle: `Are you sure you want to delete account?`,
                message: `
                    When delete, this user cannot work on LMPM platform. Their information, answers and
                    activities will be deleted.Self-assessment of other users, who relate to this user will also be deleted.
                `,
                cancelButtonText: 'Discard',
                confirmButtonText: 'Delete account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.iomService.deleteHotelUser(userId,this.hotelGroupId);
                    }
                    return of(undefined)
                })
            )
            .subscribe(async result => {
                if (result) {
                    this.spinner.hide();
                    this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: `Delete Successfully!`,
                            message: `
                            User was successfully deleted from LMPM platform.
                            `,
                            closeButtonText: 'Close',
                        },
                    });
                }
            });
    }

    activeOrDeactiveHotel(data: { status: UserStatus; userId: string }) {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate Hotel user` : `Activate Hotel user`,
                subTitle:
                    data.status === 'Active'
                        ? `Are you sure you want to deactivate account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When deactivated, user cannot work on LMPM platform. However, you will still be able to view their information, answers and activities.`
                        : ` When activate, Hotel user can continue working on LMPM platform.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.iomService.inactivateHotelUser(data.userId, data.status, this.hotelGroupId);
                    }
                    return of(undefined);
                })
            )
            .subscribe(async result => {
                if (result) {
                    this.spinner.hide();
                    const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                        data: {
                            title: data.status == 'Active' ? 'Deactivated Successfully!' : 'Activated Successfully',
                            message:
                                data.status == 'Active'
                                    ? 'User was successfully deactivated from LMPM platform.'
                                    : 'User was successfully activated from LMPM platform.',
                            closeButtonText: 'Close',
                        },
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
