<div class="brand-register-success">
    <div class="brand-register-success__wrap">
        <h2>Registered successfully</h2>
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti nihil dignissimos necessitatibus a, hic
            nostrum perspiciatis recusandae! Ducimus expedita culpa voluptas ratione natus sequi reprehenderit, corporis
            molestias dolorem, odit perspiciatis.
        </p>
        <button type="submit" mat-button color="primary" aria-label="success" class="success">Submit</button>
    </div>
</div>
