import { CustomValidators } from './../formly-fields/custom-validators';
import { TouchedErrorStateMatcher } from './../validators/touched-error-state.matcher';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ChangePasswordInfo } from 'src/app/modules/admin';
import { UserInfo } from 'src/app/modules/core';
import { environment } from 'src/environments/environment';
import { CrossFieldErrorMatcher } from '../confirm-password-error-state';
import { TermDialogComponent } from '../dialogs';
import { MIN_PASSWORD_LEN } from '../types';
import { OnboardingUserResults } from './types';

@Component({
    selector: 'admin-onboarding-form',
    templateUrl: './onboarding-form.component.html',
    styleUrls: ['./onboarding-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingFormComponent implements OnInit, OnDestroy {
    matcher = new TouchedErrorStateMatcher()
    @Input()
    userResults: OnboardingUserResults;

    @Input()
    errorMessages: string[];

    @Input()
    userType = '';

    @Input()
    language: any;

    @Output()
    completeOnboardingClicked = new EventEmitter<ChangePasswordInfo>();

    @Output()
    cancelClicked = new EventEmitter();

    @Output()
    gotoLogin = new EventEmitter<void>();

    errorMatcher = new CrossFieldErrorMatcher();
    unsubscribe$ = new Subject();

    form: FormGroup;
    hideNewPassword = true;
    hideConfirmPassword = true;
    newUser: UserInfo | undefined;

    smallScreen$ = this.breakpointObserver
        .observe(['(max-width: 600px)'])
        .pipe(map(observer => (observer.matches ? 'yes' : 'no')));

    minimumPasswordLenght = 'At least minimum 8 character';
    atLeast1Number = 'At least 1 number';
    atLeast1LowerCase = 'At least 1 lowercase';
    atLeast1UpperCase = 'At least 1 uppercase';
    noSpaceChar = 'No space character';
    criteriaSub$ = new BehaviorSubject<{ failed: boolean; description: any }[]>([]);
    criteria$ = this.criteriaSub$.asObservable();

    tooltipList = `Valid Password format:
    - Have at least 8 characters
    - Have at least 1 uppercase
    - Have at least 1 lowercase
    - Have at least 1 numeric character
    - Have at least 1 special characters: ! @ # $ % ^ & *
    - Must not contain space
    `;

    isIomUser$: Observable<boolean>;
    minPasswordLen = MIN_PASSWORD_LEN;

    enableIomTermsConditions = environment.enableIomTermsAndConditions;

    constructor(
        private fb: FormBuilder,
        private breakpointObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        public translate: TranslateService,
    ) {}

    inputEmailFormControl = new FormControl({ value: null, disabled: true });

    ngOnInit() {
        this.newUser = this.userResults && this.userResults.newUser;
        this.form = this.fb.group({
            newPassword: new FormControl('', {
                validators: [
                    Validators.required,
                    Validators.minLength(this.minPasswordLen),
                    Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
                ],
            }),
            confirmPassword: new FormControl('', {
                validators: [Validators.required, this.passwordCf.bind(this)],
            }),
            confirmTermsAndConditions: new FormControl(false, {
                validators: [Validators.required],
            }),
            confirmIomTermsAndConditions: new FormControl('', {
                validators: [Validators.required],
            }),
            email: new FormControl(this.newUser?.email, {
                validators: [Validators.required, CustomValidators.emailType],
            }),
        });

        this.newPassword.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
            this.cdr.markForCheck();
        });
    }

    get newPassword() {
        return this.form.controls.newPassword as FormControl;
    }

    get confirmPassword() {
        return this.form.controls.confirmPassword as FormControl;
    }

    get confirmTermsAndConditions() {
        return this.form.controls.confirmTermsAndConditions as FormControl;
    }

    get confirmIomTermsAndConditions() {
        return this.form.controls.confirmIomTermsAndConditions as FormControl;
    }
    get email() {
        return this.form.controls.email as FormControl;
    }

    completeOnboarding() {
        if (this.form.valid) {
            this.completeOnboardingClicked.emit(this.form.value);
        }
    }

    openTermsAndConditions($event: Event, type: string) {
        $event.preventDefault();
        $event.stopPropagation();
        const termDialog = this.dialog.open(TermDialogComponent, {
            disableClose: true,
            width: '800px',
            maxHeight: '90vh',
            data: {
                type,
                isCheck: this.form.value.confirmIomTermsAndConditions ? true : false,
            },
        });

        termDialog.afterClosed().subscribe(res => {
            this.form.controls.confirmIomTermsAndConditions.setValue(res);
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }

    passwordCf() {
        if (this.form !== null && this.form !== undefined) {
            const newPass = this.form.controls.newPassword;
            const confirmPass = this.form.controls.confirmPassword;
            return newPass.value === confirmPass.value ? null : { passwordNotMatch: true };
        }
        return null;
    }

    passwordChanged(value: string) {
        if (this.form !== null && this.form !== undefined) {
            const repassword = this.form.controls.confirmPassword;
            if (repassword.value !== '' && value !== repassword.value) {
                this.form.controls.confirmPassword.setErrors({ passwordNotMatch: true });
            }
            if (repassword.value !== '' && value === repassword.value) {
                this.form.controls.confirmPassword.setErrors(null);
            }
        }
    }
}
