import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-multi-select-input',
    templateUrl: './multi-select-input.component.html',
    styleUrls: ['./multi-select-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleSelectInputComponent extends FieldType implements AfterViewInit {
    dropdownSettings: IDropdownSettings = {};

    constructor(private elementRef: ElementRef) {
        super();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'key',
            textField: 'key',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 100,
            allowSearchFilter: true,
            enableCheckAll: false,
        };
    }
    ngAfterViewInit() {
        var elRef = this.elementRef;
        var scrollPos = 0;
        this.elementRef.nativeElement.querySelector('.dropdown-list').addEventListener('keyup', function(e: any){
            if (elRef){
                if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
                    var parent = elRef?.nativeElement.querySelector(`.dropdown-list .item2`);
                    if (parent) {
                        var item = elRef?.nativeElement.querySelectorAll(
                            `.dropdown-list .item2 .multiselect-item-checkbox`,
                        );
                        var index = -1;
                        if (item.length > 0) {
                            for (var i = 0; i < item.length; i++) {
                                if (item[i].classList.contains('navigated')) {
                                    index = i;
                                    break;
                                }
                            }
                            if (index == -1 && e.key == 'ArrowDown') {
                                item[0].classList.add('navigated');
                            } else if (item[i] ){
                                var from = scrollPos / 32;
                                var to = from + 5;
                                if (e.key == 'ArrowDown' && i < item.length - 1) {
                                    item[i].classList.remove('navigated');
                                    item[i + 1].classList.add('navigated');
                                    if (i == to) {
                                        scrollPos += 32;
                                        parent.scrollTop = scrollPos;
                                    }
                                } else if (e.key == 'ArrowUp' && i > 0) {
                                    item[i].classList.remove('navigated');
                                    item[i - 1].classList.add('navigated');
                                    if (i == from) {
                                        scrollPos -= 32;
                                        parent.scrollTop = scrollPos;
                                    }
                                }
                            }
                        }
                    }
                } else if (e.key == 'Enter' || e.key == 'Space') {
                    var item = elRef?.nativeElement.querySelector(
                        `.dropdown-list .item2 .multiselect-item-checkbox.navigated`,
                    );
                    item.click();
                }
            }
        });
    }
}
