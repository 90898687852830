import { Pipe, PipeTransform } from '@angular/core';

const countries = [
    {
        code: 'af',
        name: 'Afghanistan',
        path: 'afghanistan',
    },
    {
        code: 'al',
        name: 'Albania',
        path: 'albania',
    },
    {
        code: 'dz',
        name: 'Algeria',
        path: 'algeria',
    },
    {
        code: 'as',
        name: 'American Samoa',
        path: 'americansamoa',
    },
    {
        code: 'ad',
        name: 'Andorra',
        path: 'andorra',
    },
    {
        code: 'ao',
        name: 'Angola',
        path: 'angola',
    },
    {
        code: 'ai',
        name: 'Anguilla',
        path: 'anguilla',
    },
    {
        code: 'aq',
        name: 'Antarctica',
        path: 'antarctica',
    },
    {
        code: 'ag',
        name: 'Antigua and Barbuda',
        path: 'antiguaandbarbuda',
    },
    {
        code: 'ar',
        name: 'Argentina',
        path: 'argentina',
    },
    {
        code: 'am',
        name: 'Armenia',
        path: 'armenia',
    },
    {
        code: 'aw',
        name: 'Aruba',
        path: 'aruba',
    },
    {
        code: 'au',
        name: 'Australia',
        path: 'australia',
    },
    {
        code: 'at',
        name: 'Austria',
        path: 'austria',
    },
    {
        code: 'az',
        name: 'Azerbaijan',
        path: 'azerbaijan',
    },
    {
        code: 'bs',
        name: 'Bahamas',
        path: 'bahamas',
    },
    {
        code: 'bh',
        name: 'Bahrain',
        path: 'bahrain',
    },
    {
        code: 'bd',
        name: 'Bangladesh',
        path: 'bangladesh',
    },
    {
        code: 'bb',
        name: 'Barbados',
        path: 'barbados',
    },
    {
        code: 'by',
        name: 'Belarus',
        path: 'belarus',
    },
    {
        code: 'be',
        name: 'Belgium',
        path: 'belgium',
    },
    {
        code: 'bz',
        name: 'Belize',
        path: 'belize',
    },
    {
        code: 'bj',
        name: 'Benin',
        path: 'benin',
    },
    {
        code: 'bm',
        name: 'Bermuda',
        path: 'bermuda',
    },
    {
        code: 'bt',
        name: 'Bhutan',
        path: 'bhutan',
    },
    {
        code: 'bo',
        name: 'Bolivia',
        path: 'bolivia',
    },
    {
        code: 'ba',
        name: 'Bosnia and Herzegovina',
        path: 'bosnia',
    },
    {
        code: 'bw',
        name: 'Botswana',
        path: 'botswana',
    },
    {
        code: 'br',
        name: 'Brazil',
        path: 'brazil',
    },
    {
        code: 'io',
        name: 'British Indian Ocean Territory',
        path: 'britishindianoceanterritory',
    },
    {
        code: 'vg',
        name: 'British Virgin Islands',
        path: 'britishvirginislands',
    },
    {
        code: 'bn',
        name: 'Brunei',
        path: 'brunei',
    },
    {
        code: 'bg',
        name: 'Bulgaria',
        path: 'bulgaria',
    },
    {
        code: 'bf',
        name: 'Burkina Faso',
        path: 'burkinafaso',
    },
    {
        code: 'bi',
        name: 'Burundi',
        path: 'burundi',
    },
    {
        code: 'kh',
        name: 'Cambodia',
        path: 'cambodia',
    },
    {
        code: 'cm',
        name: 'Cameroon',
        path: 'cameroon',
    },
    {
        code: 'ca',
        name: 'Canada',
        path: 'canada',
    },
    {
        code: 'cv',
        name: 'Cape Verde',
        path: 'capeverde',
    },
    {
        code: 'ky',
        name: 'Cayman Islands',
        path: 'caymanislands',
    },
    {
        code: 'cf',
        name: 'Central African Republic',
        path: 'centralafricanrepublic',
    },
    {
        code: 'td',
        name: 'Chad',
        path: 'chad',
    },
    {
        code: 'cl',
        name: 'Chile',
        path: 'chile',
    },
    {
        code: 'cn',
        name: 'China',
        path: 'china',
    },
    {
        code: 'cx',
        name: 'Christmas Island',
        path: 'christmasisland',
    },
    {
        code: 'cc',
        name: 'Cocos Islands',
        path: 'cocoskeelingislands',
    },
    {
        code: 'co',
        name: 'Colombia',
        path: 'colombia',
    },
    {
        code: 'km',
        name: 'Comoros',
        path: 'comoros',
    },
    {
        code: 'ck',
        name: 'Cook Islands',
        path: 'cookislands',
    },
    {
        code: 'cr',
        name: 'Costa Rica',
        path: 'costarica',
    },
    {
        code: 'hr',
        name: 'Croatia',
        path: 'croatia',
    },
    {
        code: 'cu',
        name: 'Cuba',
        path: 'cuba',
    },
    {
        code: 'cw',
        name: 'Curacao',
        path: 'curacao',
    },
    {
        code: 'cy',
        name: 'Cyprus',
        path: 'cyprus',
    },
    {
        code: 'cz',
        name: 'Czech Republic',
        path: 'czechrepublic',
    },
    {
        code: 'cd',
        name: 'Democratic Republic of the Congo',
        path: 'congodemocraticrepublic',
    },
    {
        code: 'dk',
        name: 'Denmark',
        path: 'denmark',
    },
    {
        code: 'dj',
        name: 'Djibouti',
        path: 'djibouti',
    },
    {
        code: 'dm',
        name: 'Dominica',
        path: 'dominica',
    },
    {
        code: 'do',
        name: 'Dominican Republic',
        path: 'dominicanrepublic',
    },
    {
        code: 'tl',
        name: 'East Timor',
        path: 'easttimor',
    },
    {
        code: 'ec',
        name: 'Ecuador',
        path: 'ecuador',
    },
    {
        code: 'eg',
        name: 'Egypt',
        path: 'egypt',
    },
    {
        code: 'sv',
        name: 'El Salvador',
        path: 'elsalvador',
    },
    {
        code: 'gq',
        name: 'Equatorial Guinea',
        path: 'equatorialguinea',
    },
    {
        code: 'er',
        name: 'Eritrea',
        path: 'eritrea',
    },
    {
        code: 'ee',
        name: 'Estonia',
        path: 'estonia',
    },
    {
        code: 'et',
        name: 'Ethiopia',
        path: 'ethiopia',
    },
    {
        code: 'fk',
        name: 'Falkland Islands',
        path: 'falklands',
    },
    {
        code: 'fo',
        name: 'Faroe Islands',
        path: 'faroeislands',
    },
    {
        code: 'fj',
        name: 'Fiji',
        path: 'fiji',
    },
    {
        code: 'fi',
        name: 'Finland',
        path: 'finland',
    },
    {
        code: 'fr',
        name: 'France',
        path: 'france',
    },
    {
        code: 'pf',
        name: 'French Polynesia',
        path: 'frenchpolynesia',
    },
    {
        code: 'ga',
        name: 'Gabon',
        path: 'gabon',
    },
    {
        code: 'gm',
        name: 'Gambia',
        path: 'gambia',
    },
    {
        code: 'ge',
        name: 'Georgia',
        path: 'georgia',
    },
    {
        code: 'de',
        name: 'Germany',
        path: 'germany',
    },
    {
        code: 'gh',
        name: 'Ghana',
        path: 'ghana',
    },
    {
        code: 'gi',
        name: 'Gibraltar',
        path: 'gibraltar',
    },
    {
        code: 'gr',
        name: 'Greece',
        path: 'greece',
    },
    {
        code: 'gl',
        name: 'Greenland',
        path: 'greenland',
    },
    {
        code: 'gd',
        name: 'Grenada',
        path: 'grenada',
    },
    {
        code: 'gu',
        name: 'Guam',
        path: 'guam',
    },
    {
        code: 'gt',
        name: 'Guatemala',
        path: 'guatemala',
    },
    {
        code: 'gg',
        name: 'Guernsey',
        path: 'guernsey',
    },
    {
        code: 'gn',
        name: 'Guinea',
        path: 'guinea',
    },
    {
        code: 'gw',
        name: 'Guinea-Bissau',
        path: 'guineabissau',
    },
    {
        code: 'gy',
        name: 'Guyana',
        path: 'guyana',
    },
    {
        code: 'ht',
        name: 'Haiti',
        path: 'haiti',
    },
    {
        code: 'hn',
        name: 'Honduras',
        path: 'honduras',
    },
    {
        code: 'hk',
        name: 'Hong Kong',
        path: 'hongkong',
    },
    {
        code: 'hu',
        name: 'Hungary',
        path: 'hungary',
    },
    {
        code: 'is',
        name: 'Iceland',
        path: 'iceland',
    },
    {
        code: 'in',
        name: 'India',
        path: 'india',
    },
    {
        code: 'id',
        name: 'Indonesia',
        path: 'indonesia',
    },
    {
        code: 'ir',
        name: 'Iran',
        path: 'iran',
    },
    {
        code: 'iq',
        name: 'Iraq',
        path: 'iraq',
    },
    {
        code: 'ie',
        name: 'Ireland',
        path: 'ireland',
    },
    {
        code: 'im',
        name: 'Isle of Man',
        path: 'isleofman',
    },
    {
        code: 'il',
        name: 'Israel',
        path: 'israel',
    },
    {
        code: 'it',
        name: 'Italy',
        path: 'italy',
    },
    {
        code: 'ci',
        name: 'Ivory Coast',
        path: 'ivorycoast',
    },
    {
        code: 'jm',
        name: 'Jamaica',
        path: 'jamaica',
    },
    {
        code: 'jp',
        name: 'Japan',
        path: 'japan',
    },
    {
        code: 'je',
        name: 'Jersey',
        path: 'jersey',
    },
    {
        code: 'jo',
        name: 'Jordan',
        path: 'jordan',
    },
    {
        code: 'kz',
        name: 'Kazakhstan',
        path: 'kazakhstan',
    },
    {
        code: 'ke',
        name: 'Kenya',
        path: 'kenya',
    },
    {
        code: 'ki',
        name: 'Kiribati',
        path: 'kiribati',
    },
    {
        code: 'xk',
        name: 'Kosovo',
        path: 'kosovo',
    },
    {
        code: 'kw',
        name: 'Kuwait',
        path: 'kuwait',
    },
    {
        code: 'kg',
        name: 'Kyrgyzstan',
        path: 'kyrgyzstan',
    },
    {
        code: 'la',
        name: 'Laos',
        path: 'laos',
    },
    {
        code: 'lv',
        name: 'Latvia',
        path: 'latvia',
    },
    {
        code: 'lb',
        name: 'Lebanon',
        path: 'lebanon',
    },
    {
        code: 'ls',
        name: 'Lesotho',
        path: 'lesotho',
    },
    {
        code: 'lr',
        name: 'Liberia',
        path: 'liberia',
    },
    {
        code: 'ly',
        name: 'Libya',
        path: 'libya',
    },
    {
        code: 'li',
        name: 'Liechtenstein',
        path: 'liechtenstein',
    },
    {
        code: 'lt',
        name: 'Lithuania',
        path: 'lithuania',
    },
    {
        code: 'lu',
        name: 'Luxembourg',
        path: 'luxembourg',
    },
    {
        code: 'mo',
        name: 'Macau',
        path: 'macau',
    },
    {
        code: 'mk',
        name: 'Macedonia',
        path: 'macedonia',
    },
    {
        code: 'mg',
        name: 'Madagascar',
        path: 'madagascar',
    },
    {
        code: 'mw',
        name: 'Malawi',
        path: 'malawi',
    },
    {
        code: 'my',
        name: 'Malaysia',
        path: 'malaysia',
    },
    {
        code: 'mv',
        name: 'Maldives',
        path: 'maldives',
    },
    {
        code: 'ml',
        name: 'Mali',
        path: 'mali',
    },
    {
        code: 'mt',
        name: 'Malta',
        path: 'malta',
    },
    {
        code: 'mh',
        name: 'Marshall Islands',
        path: 'marshallislands',
    },
    {
        code: 'mr',
        name: 'Mauritania',
        path: 'mauritania',
    },
    {
        code: 'mu',
        name: 'Mauritius',
        path: 'mauritius',
    },
    {
        code: 'yt',
        name: 'Mayotte',
        path: 'mayotte',
    },
    {
        code: 'mx',
        name: 'Mexico',
        path: 'mexico',
    },
    {
        code: 'fm',
        name: 'Micronesia',
        path: 'micronesia',
    },
    {
        code: 'fm',
        name: 'Micronesia(Federated States of)', //Federated States of Micronesia
        path: 'micronesia',
    },
    {
        code: 'fm',
        name: 'Federated States of Micronesia',
        path: 'micronesia',
    },
    {
        code: 'md',
        name: 'Moldova',
        path: 'moldova',
    },
    {
        code: 'mc',
        name: 'Monaco',
        path: 'monaco',
    },
    {
        code: 'mn',
        name: 'Mongolia',
        path: 'mongolia',
    },
    {
        code: 'me',
        name: 'Montenegro',
        path: 'montenegro',
    },
    {
        code: 'ms',
        name: 'Montserrat',
        path: 'montserrat',
    },
    {
        code: 'ma',
        name: 'Morocco',
        path: 'morocco',
    },
    {
        code: 'mz',
        name: 'Mozambique',
        path: 'mozambique',
    },
    {
        code: 'mm',
        name: 'Myanmar',
        path: 'burma',
    },
    {
        code: 'na',
        name: 'Namibia',
        path: 'namibia',
    },
    {
        code: 'nr',
        name: 'Nauru',
        path: 'nauru',
    },
    {
        code: 'np',
        name: 'Nepal',
        path: 'nepal',
    },
    {
        code: 'nl',
        name: 'Netherlands',
        path: 'netherlands',
    },
    {
        code: 'an',
        name: 'Netherlands Antilles',
        path: 'netherlandsantilles',
    },
    {
        code: 'nc',
        name: 'New Caledonia',
        path: 'newcaledonia',
    },
    {
        code: 'nz',
        name: 'New Zealand',
        path: 'newzealand',
    },
    {
        code: 'ni',
        name: 'Nicaragua',
        path: 'nicaragua',
    },
    {
        code: 'ne',
        name: 'Niger',
        path: 'niger',
    },
    {
        code: 'ng',
        name: 'Nigeria',
        path: 'nigeria',
    },
    {
        code: 'nu',
        name: 'Niue',
        path: 'niue',
    },
    {
        code: 'kp',
        name: 'North Korea',
        path: 'northkorea',
    },
    {
        code: 'mp',
        name: 'Northern Mariana Islands',
        path: 'northernmarianaislands',
    },
    {
        code: 'no',
        name: 'Norway',
        path: 'norway',
    },
    {
        code: 'om',
        name: 'Oman',
        path: 'oman',
    },
    {
        code: 'pk',
        name: 'Pakistan',
        path: 'pakistan',
    },
    {
        code: 'pw',
        name: 'Palau',
        path: 'palau',
    },
    {
        code: 'ps',
        name: 'Palestine',
        path: 'palestine',
    },
    {
        code: 'pa',
        name: 'Panama',
        path: 'panama',
    },
    {
        code: 'pg',
        name: 'Papua New Guinea',
        path: 'papuanewguinea',
    },
    {
        code: 'py',
        name: 'Paraguay',
        path: 'paraguay',
    },
    {
        code: 'pe',
        name: 'Peru',
        path: 'peru',
    },
    {
        code: 'ph',
        name: 'Philippines',
        path: 'philippines',
    },
    {
        code: 'pn',
        name: 'Pitcairn',
        path: 'pitcairnislands',
    },
    {
        code: 'pl',
        name: 'Poland',
        path: 'poland',
    },
    {
        code: 'pt',
        name: 'Portugal',
        path: 'portugal',
    },
    {
        code: 'pr',
        name: 'Puerto Rico',
        path: 'puertorico',
    },
    {
        code: 'qa',
        name: 'Qatar',
        path: 'qatar',
    },
    {
        code: 'cg',
        name: 'Republic of the Congo',
        path: 'congo',
    },
    {
        code: 're',
        name: 'Reunion',
        path: 'reunion',
    },
    {
        code: 'ro',
        name: 'Romania',
        path: 'romania',
    },
    {
        code: 'ru',
        name: 'Russia',
        path: 'russia',
    },
    {
        code: 'rw',
        name: 'Rwanda',
        path: 'rwanda',
    },
    {
        code: 'bl',
        name: 'Saint Barthelemy',
        path: 'saintbarthelemy',
    },
    {
        code: 'sh',
        name: 'Saint Helena',
        path: 'sthelena',
    },
    {
        code: 'kn',
        name: 'Saint Kitts and Nevis',
        path: 'stkitts',
    },
    {
        code: 'lc',
        name: 'Saint Lucia',
        path: 'stlucia',
    },
    {
        code: 'mf',
        name: 'Saint Martin',
        path: 'stmartin',
    },
    {
        code: 'pm',
        name: 'Saint Pierre and Miquelon',
        path: 'stpierre',
    },
    {
        code: 'vc',
        name: 'Saint Vincent and the Grenadines',
        path: 'stvincent',
    },
    {
        code: 'ws',
        name: 'Samoa',
        path: 'samoa',
    },
    {
        code: 'sm',
        name: 'San Marino',
        path: 'sanmarino',
    },
    {
        code: 'st',
        name: 'Sao Tome and Principe',
        path: 'saotomeandprincipe',
    },
    {
        code: 'sa',
        name: 'Saudi Arabia',
        path: 'saudiarabia',
    },
    {
        code: 'sn',
        name: 'Senegal',
        path: 'senegal',
    },
    {
        code: 'rs',
        name: 'Serbia',
        path: 'serbia',
    },
    {
        code: 'sc',
        name: 'Seychelles',
        path: 'seychelles',
    },
    {
        code: 'sl',
        name: 'Sierra Leone',
        path: 'sierraleone',
    },
    {
        code: 'sg',
        name: 'Singapore',
        path: 'singapore',
    },
    {
        code: 'sx',
        name: 'Sint Maarten',
        path: 'sintmaarten',
    },
    {
        code: 'sk',
        name: 'Slovakia',
        path: 'slovakia',
    },
    {
        code: 'si',
        name: 'Slovenia',
        path: 'slovenia',
    },
    {
        code: 'sb',
        name: 'Solomon Islands',
        path: 'solomonislands',
    },
    {
        code: 'so',
        name: 'Somalia',
        path: 'somalia',
    },
    {
        code: 'za',
        name: 'South Africa',
        path: 'southafrica',
    },
    {
        code: 'kr',
        name: 'South Korea',
        path: 'southkorea',
    },
    {
        code: 'ss',
        name: 'South Sudan',
        path: 'southsudan',
    },
    {
        code: 'es',
        name: 'Spain',
        path: 'spain',
    },
    {
        code: 'lk',
        name: 'Sri Lanka',
        path: 'srilanka',
    },
    {
        code: 'sd',
        name: 'Sudan',
        path: 'sudan',
    },
    {
        code: 'sr',
        name: 'Suriname',
        path: 'suriname',
    },
    {
        code: 'sj',
        name: 'Svalbard and Jan Mayen',
        path: 'svalbard',
    },
    {
        code: 'sz',
        name: 'Swaziland',
        path: 'swaziland',
    },
    {
        code: 'se',
        name: 'Sweden',
        path: 'sweden',
    },
    {
        code: 'ch',
        name: 'Switzerland',
        path: 'switzerland',
    },
    {
        code: 'sy',
        name: 'Syria',
        path: 'syria',
    },
    {
        code: 'tw',
        name: 'Taiwan',
        path: 'taiwan',
    },
    {
        code: 'tj',
        name: 'Tajikistan',
        path: 'tajikistan',
    },
    {
        code: 'tz',
        name: 'Tanzania',
        path: 'tanzania',
    },
    {
        code: 'th',
        name: 'Thailand',
        path: 'thailand',
    },
    {
        code: 'tg',
        name: 'Togo',
        path: 'togo',
    },
    {
        code: 'tk',
        name: 'Tokelau',
        path: 'tokelau',
    },
    {
        code: 'to',
        name: 'Tonga',
        path: 'tonga',
    },
    {
        code: 'tt',
        name: 'Trinidad and Tobago',
        path: 'trinidadandtobago',
    },
    {
        code: 'tn',
        name: 'Tunisia',
        path: 'tunisia',
    },
    {
        code: 'tr',
        name: 'Turkey',
        path: 'turkey',
    },
    {
        code: 'tm',
        name: 'Turkmenistan',
        path: 'turkmenistan',
    },
    {
        code: 'tc',
        name: 'Turks and Caicos Islands',
        path: 'turksandcaicos',
    },
    {
        code: 'tv',
        name: 'Tuvalu',
        path: 'tuvalu',
    },
    {
        code: 'vi',
        name: 'U.S. Virgin Islands',
        path: 'virginislands',
    },
    {
        code: 'ug',
        name: 'Uganda',
        path: 'uganda',
    },    
    {
        code: 'ae',
        name: 'United Arab Emirates',
        path: 'uae',
    },
    {
        code: 'gb',
        name: 'United Kingdom',
        path: 'uk',
    },
    {
        code: 'ua',
        name: 'Ukraine',
        path: 'ukraine',
    },
    {
        code: 'us',
        name: 'United States',
        path: 'usa',
    },
    {
        code: 'uy',
        name: 'Uruguay',
        path: 'uruguay',
    },
    {
        code: 'uz',
        name: 'Uzbekistan',
        path: 'uzbekistan',
    },
    {
        code: 'vu',
        name: 'Vanuatu',
        path: 'vanuatu',
    },
    {
        code: 'va',
        name: 'Vatican',
        path: 'vatican',
    },
    {
        code: 've',
        name: 'Venezuela',
        path: 'venezuela',
    },
    {
        code: 'vn',
        name: 'Vietnam',
        path: 'vietnam',
    },
    {
        code: 'wf',
        name: 'Wallis and Futuna',
        path: 'wallisandfutuna',
    },
    {
        code: 'eh',
        name: 'Western Sahara',
        path: 'westernsahara',
    },
    {
        code: 'ye',
        name: 'Yemen',
        path: 'yemen',
    },
    {
        code: 'zm',
        name: 'Zambia',
        path: 'zambia',
    },
    {
        code: 'zw',
        name: 'Zimbabwe',
        path: 'zimbabwe',
    },
];

@Pipe({
    name: 'countryCode',
})
export class CountryCodePipe implements PipeTransform {
    transform(value: string): string {       
        if (!value) return '';
        const matched = countries.filter(c => value.toLowerCase().indexOf(c.name.toLowerCase()) > -1).map(c => c.code);
        const result = matched.pop();
        return result || '';
    }
}
