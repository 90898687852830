import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import format from 'date-fns/format';
import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomEncoder, Logger, UserInfo, UserInfoCollection, UserReponse } from '../../core';
import { OnboardingUserResults } from '../../shared/onboarding-form';
import { UserStatus, ApiConstant, CustomHttpErrorResponse, PaginateOptions } from '../../types';
import { HotelGroupInfo, HotelGroupInfoResponse, IomUserInfo, IOM_API_TOKEN, MatrixBrand, MatrixRes, MatrixSAQ } from '../types';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class IomService {
    urlDefault = this.apiConstant.endpoint;
    url = `${this.apiConstant.endpoint}/auth`;
    userUrl = `${this.apiConstant.endpoint}/user`;
    iomUrl = `${this.apiConstant.endpoint}/iom`;

    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private inactivateUserErrorsSub$ = new Subject<string[]>();
    inactivateUserErrors$ = this.inactivateUserErrorsSub$.asObservable();

    private deleteHotelGroupSub$ = new Subject<string[]>();
    deleteHotelGroup$ = this.deleteHotelGroupSub$.asObservable();

    private newUserSub$ = new Subject<UserInfo>();
    newUser$ = this.newUserSub$.asObservable();

    private activateUserSub$ = new Subject<UserInfo>();
    activateUser$ = this.activateUserSub$.asObservable();

    private lstCaseMangerSub$ = new BehaviorSubject<UserInfo[]>([]);
    lstCaseManger$ = this.lstCaseMangerSub$.asObservable();

    private userCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userCollection$ = this.userCollectionSub$.asObservable();

    private userHotelsCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userHotelsCollection$ = this.userHotelsCollectionSub$.asObservable();

    private onboardingUserSub$ = new Subject<OnboardingUserResults>();
    onboardingUser$ = this.onboardingUserSub$.asObservable();

    private hotelGroupProfileSub$ = new Subject<HotelGroupInfo>();
    hotelGroupProfile$ = this.hotelGroupProfileSub$.asObservable();

    private hotelsUserSub$ = new Subject<any>();
    hotelsUser$ = this.hotelsUserSub$.asObservable();

    private hotelGroupStatusSub$ = new Subject<UserStatus>();
    hotelGroupStatus$ = this.hotelGroupStatusSub$.asObservable();

    private matrixResSub$ = new Subject<MatrixRes>();
    matrixRes$ = this.matrixResSub$.asObservable();

    private deleteLastEmployeeSub$ = new Subject<boolean>();
    deleteLastEmployee$ = this.deleteLastEmployeeSub$.asObservable();

    private paginateByUserRole(
        options: PaginateOptions,
        role: string,
        transactionName: string,
        filter?: string,
        orderBy?: string,
        hotelGroupId?: string
    ) {
        let params;
        if (role === 'managements') {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', 'admin');
        } else {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', role);
        }

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        let subUrl = '';
        switch(role) {
            case 'employee': subUrl = this.urlDefault + `/brands/${hotelGroupId}/employees`;
                break;
            case 'employer': subUrl = this.urlDefault + `/brands/${hotelGroupId}/employers`;
                break;
            default: subUrl = this.userUrl + '/ioms'
        }

        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<UserInfoCollection>(`${subUrl}`, { params, headers }).pipe(
            tap(({ users, userCount }: UserInfoCollection) => {
                const localUsers: UserInfo[] = users.map(u => {
                    const strLastLoginTime = u.lastLoginTime
                        ? format(new Date(u.lastLoginTime), 'dd MMM, yyyy HH:mm:ss')
                        : '';
                    return {
                        ...u,
                        lastLoginTime: strLastLoginTime,
                    };
                });
                if(role === 'employer') {
                    this.userHotelsCollectionSub$.next({ users: localUsers, userCount, startAtPage: { pageIndex } });
                }else {
                    this.userCollectionSub$.next({ users: localUsers, userCount, startAtPage: { pageIndex } });
                }
            }),
            catchError(() => {
                this.userCollectionSub$.next({ users: [], userCount: 0 });
                return EMPTY;
            }),
        );
    }

    constructor(@Inject(IOM_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) {}

    registerIomSHA(input: IomUserInfo, options: PaginateOptions) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Create new Iom/Sha user' });

        const { fullname, email } = input;

        return this.httpClient
            .post<UserInfo>(
                `${this.userUrl}/register-iom-sha`,
                {
                    fullname,
                    email,
                },
                { headers },
            )
            .pipe(
                tap((newUser: UserInfo) => {
                    this.newUserSub$.next(newUser);
                    this.errorsSub$.next([]);
                }),
                concatMap(() => this.paginateByUserRole(options, 'managements', 'User Management list')),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    paginteAdmins(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'admin', 'Paginate Iom user list', filter);
    }

    paginteUsers(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'user', 'Paginate PRA list', filter); //roles=[user
    }

    paginteUserManagements(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateByUserRole(options, 'managements', 'User Management list', filter, sorter);
    }

    paginteUserEmployees(options: PaginateOptions, filter?: string, sorter?: string, hotelGroupId?: string) {
        return this.paginateByUserRole(options, 'employee', 'User employee list', filter, sorter, hotelGroupId);
    }

    paginteUserHotels(options: PaginateOptions, filter?: string, sorter?: string, hotelGroupId?: string) {
        return this.paginateByUserRole(options, 'employer', 'Hotel list', filter, sorter, hotelGroupId);
    }

    inactivateAdmin(userId: string, pageSize: number): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete Iom user' });
        return this.httpClient
            .delete<UserInfo>(`${this.urlDefault}/user/${userId}`, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    inactivateIomSha(userId: string, status: UserStatus, pageSize: number) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated Iom or Sha' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserReponse>(`${this.urlDefault}/user/updateIomStatus/${userId}`, body, { headers })
            .pipe(
                tap((response: UserReponse) => {
                    this.inactivateUserErrorsSub$.next([]);
                    this.activateUserSub$.next(response.response.user);
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    getTotalCaseManager(): Promise<number> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get total Iom user' });

        return this.httpClient
            .get<number>(`${this.url}/number-caser-manager`, { headers })
            .pipe(
                tap((result: number) => {
                    this.errorsSub$.next([]);
                    return result;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    getOnboardingUser(userId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Retrieve onboarding Iom user' });
        return this.httpClient.get<OnboardingUserResults>(`${this.url}/onboarding/${userId}`, { headers }).pipe(
            tap((result: OnboardingUserResults) => {
                this.onboardingUserSub$.next(result);
                this.errorsSub$.next([]);
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsSub$.next(err.errorJson.message);
                } else {
                    this.errorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        );
    }

    completeOnboarding(input: {
        id: string;
        newPassword: string;
        confirmPassword: string;
        language: number;
        time: string;
        sig: string;
    }) {
        const { id, newPassword, confirmPassword, time, sig, language } = input;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Complete onboarding process' });
        const body = {
            newPassword,
            confirmPassword,
            languageId: language,
        };

        return this.httpClient
            .put<void>(`${this.url}/onboarding-complete/${id}?time=${time}&sig=${sig}`, body, { headers })
            .pipe(
                tap(() => this.errorsSub$.next([])),
                map(() => true),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return of(false);
                }),
            );
    }

    getHotelGroupProfile(hotelGroupId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get hotel group profile' });
        return this.httpClient
            .get<HotelGroupInfoResponse>(`${this.urlDefault}/brands/${hotelGroupId}`, { headers })
            .pipe(
                tap((hotelGroupInfoResponse: HotelGroupInfoResponse) => {
                    const info = [
                        {
                            label: 'Email',
                            value: hotelGroupInfoResponse.response.email
                        },
                        {
                            label: 'Headquarter',
                            value: hotelGroupInfoResponse.response.headquarter
                        },
                        {
                            label: 'SAQ Received',
                            value: hotelGroupInfoResponse.response.totalSaq
                        }
                    ]
                    this.hotelGroupProfileSub$.next({ name: hotelGroupInfoResponse.response.companyName, info: info });
                    this.hotelGroupStatusSub$.next(hotelGroupInfoResponse.response.status);

                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    isEmailUnique(email: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of IOM user email' });

        return this.httpClient.get(`${this.url}/isEmailUnique`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'true'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    inactivateBrand(userId: string, status: UserStatus) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated brand' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/user/updateBrandStatus/${userId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.getHotelGroupProfile(userId),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    deleteBrand(brandId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete brand' });

        return this.httpClient
            .delete<UserInfo>(`${this.urlDefault}/brands/${brandId}`, { headers })
            .pipe(
                tap(() => {
                    this.deleteHotelGroupSub$.next([]);
                    return true;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.deleteHotelGroupSub$.next(err.errorJson.message);
                    } else {
                        this.deleteHotelGroupSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    deleteEmployee(employeeId: string, hotelGroupId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete employee' });
        return this.httpClient
            .delete<UserReponse>(`${this.urlDefault}/user/${employeeId}`, { headers })
            .pipe(
                tap(() => {
                    this.errorsSub$.next([]);
                }),
                switchMap(() =>{
                    return this.paginateByUserRole({pageSize: 5, pageIndex: 0},  'employee', 'User employee list', '', '', hotelGroupId);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    fillteMatrix(fromDate?: string, toDate?: string) {
        let matrixResObj: MatrixRes;
        let brandMatrixResObj: MatrixBrand;
        let praMatrixResObj: MatrixSAQ;
        let hotelMatrixResObj: MatrixSAQ;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Fillter Matrix' });
        let subUrl = ''
        if(fromDate && toDate) {
            subUrl= `fromDate=${fromDate}&toDate=${toDate}`;
        }else {
            subUrl = fromDate ? `fromDate=${fromDate}` : `toDate=${toDate}`;
        }

        return this.httpClient
            .get<any>(`${this.urlDefault}/user/filter-matrix?`+subUrl, {headers })
            .pipe(
                tap((matrixRes: any) => {
                    //brand
                    brandMatrixResObj = {
                        total: matrixRes.brand?.active + matrixRes.brand?.deactive + matrixRes.brand?.deleted,
                        active: matrixRes.brand?.active,
                        deactive: matrixRes.brand?.deactive,
                        // invited: matrixRes.brand?.invited,
                        deleted: matrixRes.brand?.deleted,
                    }
                    //hotel
                    hotelMatrixResObj = {
                        total: matrixRes.hotel?.active + matrixRes.hotel?.deactive + matrixRes.hotel?.invited + matrixRes.hotel?.deleted,
                        active: matrixRes.hotel?.active,
                        deactive: matrixRes.hotel?.deactive,
                        invited: matrixRes.hotel?.invited,
                        deleted: matrixRes.hotel?.deleted,
                        questionnaire: matrixRes.hotel?.questionnaire,
                        scoping: matrixRes.hotel?.scoping
                    }
                    //pra
                    praMatrixResObj = {
                        total: matrixRes.pra?.active + matrixRes.pra?.deactive + matrixRes.pra?.invited + matrixRes.pra?.deleted,
                        active: matrixRes.pra?.active,
                        deactive: matrixRes.pra?.deactive,
                        invited: matrixRes.pra?.invited,
                        deleted: matrixRes.pra?.deleted,
                        questionnaire: matrixRes.pra?.questionnaire,
                        scoping: 0
                    }
                    //Matrix
                    matrixResObj = {
                        brand: brandMatrixResObj,
                        hotel: hotelMatrixResObj,
                        pra: praMatrixResObj,
                    };
                    this.matrixResSub$.next(matrixResObj);
                    this.errorsSub$.next([]);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    inactivateHotelGroupUser(userId: string, status: UserStatus, hotelGroupId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated hotel group user' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/brands/${userId}/status`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>{
                    return this.paginateByUserRole({pageSize: 5, pageIndex: 0},  'employee', 'User employee list', '', '', hotelGroupId);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    deleteHotelGroupUser(employeeId: string, hotelGroupId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete hotel group user' });
        return this.httpClient
            .delete<UserReponse>(`${this.urlDefault}/brands/employee/${employeeId}`, { headers })
            .pipe(
                tap(() => {
                    this.errorsSub$.next([]);
                }),
                switchMap(() => {
                    return this.paginateByUserRole({pageSize: 5, pageIndex: 0},  'employee', 'User employee list', '', '', hotelGroupId);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    deleteHotelGroupUserAndGroup(employeeId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete hotel group user' });
        return this.httpClient
            .delete<any>(`${this.urlDefault}/brands/employee/${employeeId}`, { headers })
            .pipe(
                tap(() => {
                    this.deleteLastEmployeeSub$.next(true);
                    this.errorsSub$.next([]);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    inactivateHotelUser(userId: string, status: UserStatus, hotelGroupId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated hotel user' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/user/updateEmployerStatus/${userId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>{
                    return this.paginateByUserRole({pageSize: 5, pageIndex: 0}, 'employer', 'Hotel list', '', '', hotelGroupId);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    deleteHotelUser(userId: string, hotelGroupId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete hotel user' });
        return this.httpClient
            .delete<UserReponse>(`${this.urlDefault}/user/user-employer/${userId}`, { headers })
            .pipe(
                tap(() => {
                    this.errorsSub$.next([]);
                }),
                switchMap(() =>{
                    return this.paginateByUserRole({pageSize: 5, pageIndex: 0}, 'employer', 'Hotel list', '', '', hotelGroupId);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }
}
