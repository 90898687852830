import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as myGlobals from '../../../../globals';
import { CustomFormlyFieldConfig } from '../../types';

@Component({
    selector: 'app-question-button',
    templateUrl: './question-button.component.html',
    styleUrls: ['./question-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionButtonComponent extends FieldType {
    count = 0;
    value = '';
    numberCountry$ = myGlobals.numberCountry$;
    ngOnInint() {
        if (this.count === 0) {
            myGlobals.valueChangeSub$.next({questionId: this.field.key, value: ''});
        }
    }
    addCountry(value: number, lstNumberCountry: any) {
        const partialId = (this.field as CustomFormlyFieldConfig).partialId;
        const lstSub = JSON.parse(lstNumberCountry);
        this.count = ++value;
        lstSub.forEach((item: any) => {
            if (item.key === partialId) {
                item.value = this.count === 1 ? 1 : (this.count === 2 ? 2 : item.value);
            }
            return item;
        })
        switch(this.count) {
            case 1:
                this.value = 'Yes,1';
                myGlobals.numberCountrySub$.next(lstSub);
                break;
            case 2:
                this.value = 'Yes,1,2';
                myGlobals.numberCountrySub$.next(lstSub);
                break;
            default:
                break;
        }
        if (this.count === 1 || this.count == 2) {
            myGlobals.valueChangeSub$.next({questionId: this.field.key, value: this.value, partialId: partialId});
        }
    }
}
