import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiConstant } from '../types';
import { EmployerLazyModule } from './employer-lazy.module';
import { EMPLOYER_API_TOKEN } from './types';
import { EmployerService } from './services/employer.service';

@NgModule({
    imports: [EmployerLazyModule],
    exports: [EmployerLazyModule],
})
export class EmployerModule {
    static forRoot(apiConstant: ApiConstant): ModuleWithProviders<EmployerModule> {
        return {
            ngModule: EmployerModule,
            providers: [
                {
                    provide: EMPLOYER_API_TOKEN,
                    useValue: apiConstant,
                },
                EmployerService,
            ],
        };
    }
}
