import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AuthService } from '../services';

@Component({
    selector: 'auth-email-container',
    template: `
        <div class="save-question">
            <div class="container-wrap" (click)="saveChange()">
                <button [attr.aria-label]="'logout'">Save change</button>
            </div>
        </div>
    `,
    styles: [
        `
            .save-question {
                position: fixed;
                bottom: 0;
                width: calc(100% - 339px);
                left: 339px;
                z-index: 9;
                background-color: #fff;
                padding: 26px 0;
                border-top: 1px solid #c9cfd6;
                height: 95px;

                button {
                    background-color: #1DBAA9;
                    border: 1px solid #1DBAA9;
                    color: #fff;
                    margin-right: 30px;
                    line-height: 24px;
                    padding: 8px 20px;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            .container-wrap {
                    width: 100%;
                    height: 100%;
                    max-width: 1500px;
                    padding: 0 16px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 1380px !important
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthEmailContainer implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    isLogin$: Observable<boolean>;
    fullname$: Observable<string>;
    initial$: Observable<string>;

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.isLogin$ = this.authService.isLogin$;

        const user$ = this.authService.user$;
        this.fullname$ = user$.pipe(
            filter(user => !!user),
            map(user => {
                return (user && user.fullName) || '';
            }),
            shareReplay(1),
            takeUntil(this.unsubscribe$),
        );

        this.initial$ = user$.pipe(
            filter(user => !!user),
            map(user => {
                const fullname = (user && user.fullName) || '';
                const fullnameFirstChar = fullname.length > 0 ? fullname.substring(0, 1) : '';
                return fullnameFirstChar;
            }),
            shareReplay(1),
            takeUntil(this.unsubscribe$),
        );
    }

    saveChange() {
        this.authService.logout();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
