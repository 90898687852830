import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Question, Questionnaire } from 'src/app/modules/question-form';
import { ConfirmCancelDialog } from 'src/app/modules/shared/dialogs';
import { Formly2Service } from 'src/app/modules/shared/services/formly2.service';
import { EMPLOYER_PROFILE_QUESTION_CODE } from '../../services';
import { EmployerUserInfo } from '../../types';
import * as myGlobals from '../../../../globals';
import { CustomFormlyFieldConfig } from 'src/app/modules/shared';
import { IUserResponse, numberCountryChange, valueChange } from 'src/app/modules/types';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

export interface MultipleSelectCountry {
    id: number;
    name: string;
}
@Component({
    selector: 'new-user-form',
    templateUrl: './new-user-form.component.html',
    styleUrls: ['./new-user-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUserFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    errorMessages: string[];
    @Input()
    questionnaire: Questionnaire;
    @Input()
    hotelId: string;

    @Input()
    responseNoti: boolean;

    @Input()
    partialId = 'regis_form'

    @Input() lstCountrySub: numberCountryChange[];

    @Output()
    registerClicked = new EventEmitter<EmployerUserInfo>();

    form: FormGroup;
    model: any;
    fields: FormlyFieldConfig[];
    options: FormlyFormOptions = {};
    isDisable: boolean = false;
    valueChange$ = myGlobals.valueChange$;
    headerGroupQuestion = '';
    questionButtonId = '';
    questionBtn: any;
    isShowGroupQuestion = false;
    conditionButton = false;
    name='';
    private unsubscribe$ = new Subject();

    public get isDirty() {
        if (!this.form) return false;
        return this.form.dirty;
    }

    public get isValid() {
        if (!this.form.valid || this.isDisable) return false;
        return this.form.valid;
    }

    constructor(private formlyService: Formly2Service, private dialog: MatDialog, private spinner: NgxSpinnerService, private router: Router) {
        myGlobals.valueChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(valueObj => {
            if (valueObj) {
                if (valueObj?.questionId === 'emp_regis_A15' && this.questionButtonId) {
                    this.questionBtn = {
                        partialId: valueObj?.partialId,
                        questionId: this.questionButtonId,
                        value: valueObj?.value
                    };                   
                } else {
                    if (valueObj?.questionId === 'emp_regis_A8') {
                        if (valueObj.value === 'No') {
                            this.formlyService.lstCountryQuestionDropDown.forEach(x => {
                                x.value = null;
                            })
                            myGlobals.numberCountrySub$.next([]);
                        }
                        this.conditionButton = valueObj?.value === 'Yes' ? true : false;
                    }
                }
                for (let i = 0; i < this.fields.length; i++){
                    const currentQuestion = myGlobals.allQuestion.find(q => {
                        return q.id == (this.fields[i] as CustomFormlyFieldConfig).questionId;
                    });
                    if (currentQuestion && currentQuestion.id) {
                        if (!currentQuestion.displayCondition) {
                            this.fields[i].hide = false;
                        } else {
                            const result = this.getHidden(valueObj, currentQuestion);
                            this.fields[i].hide = result;
                            myGlobals.allQuestion.forEach(q => {
                                if(q.id === currentQuestion.id){
                                    q.hidden = result;
                                }
                            });
                        }
                    }
                }
            }
        });
        myGlobals.removeCountry$.pipe(takeUntil(this.unsubscribe$)).subscribe(code => {
            this.formlyService.lstCountryQuestionDropDown.forEach(x => {
                if (x.code === code) {
                    x.value = null;
                }
            })
        })
    }
    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getHidden(changeValue: valueChange, currentQuestion: Question) {
        let result = true;
        currentQuestion.displayCondition?.forEach((questionCondition: any) => {
            const keyQuestion = myGlobals.allQuestion.filter(q => {
                return q.id == questionCondition.key && !q.hidden;
            })[0];
            if(keyQuestion) {
                keyQuestion.code = this.formatKey(keyQuestion.code)
                if (keyQuestion.code == changeValue.questionId){
                    keyQuestion.value = changeValue.value;
                }
                if (changeValue.questionId === 'emp_regis_A8' && changeValue.value === 'No' && keyQuestion.controlType === 'question-btn' ) {
                    keyQuestion.value = '';
                }
                if (keyQuestion && keyQuestion.id) {
                    if(keyQuestion.displayConditionType && keyQuestion.displayConditionType === "and") {
                        if(keyQuestion.value?.toLowerCase() === questionCondition.value?.toLowerCase()) {
                            result = false;
                        }
                    }else if(keyQuestion.value?.toLowerCase() === questionCondition.value?.toLowerCase()) {
                        result = false;
                    }
                }
            }

        });

        return result;
    }

    formatKey(code: string): string {
        return (code || '').replace('.', '_');
    }

    ngOnInit() {
        this.isDisable = false;
        this.form = new FormGroup({});
        this.model = {};
        if (!this.responseNoti) {
            this.name = 'Profile';
        } else {
            this.name = 'Registration';
        }
    }

    ngAfterViewInit() {
        this.form.valueChanges.subscribe(() => {
            if (this.form.valid) {
                this.isDisable = false;
            }
        });
    }

    ngOnChanges() {
        if (this.questionnaire) {
            this.spinner.show();
            let lstNumberCountryNext:any = [];
            const group = this.questionnaire.sections[0]?.questionGroups || [];
            this.headerGroupQuestion = group[0]?.symbol +  group[0]?.label || this.headerGroupQuestion;
            const questions = this.questionnaire.sections[0].questions;
            const questionBtn = questions.filter(x => x.controlType === 'question-btn').map(x => x.id);
            this.questionButtonId = questionBtn && questionBtn.length > 0 ? questionBtn[0] : this.questionButtonId;
            if (this.hotelId) {
                if (this.responseNoti) {
                    myGlobals.numberCountrySub$.next([{key: this.partialId, value: 3}]);
                }
                const qLogic =  questions.filter(x => x.code === 'emp_regis_A8').map(x => x.value);
                this.isShowGroupQuestion = qLogic && qLogic.length > 0 && qLogic[0] === 'Yes' ? true : this.isShowGroupQuestion;
            }
            myGlobals.allQuestionSub$.next(questions);
            this.fields = this.formlyService.allQuestionsToFormlyConfig(
                questions,
                'modern',
                false,
                undefined,
                undefined,
                false,
                false,
                true,
                this.partialId);
                const lengthCountry = this.fields && this.fields.length > 0 ?
                                this.fields.filter(x => !!x && x.type === 'wrap-country-group' && !x.hide).length : 0;
                switch(lengthCountry) {
                    case 2:
                        lstNumberCountryNext.push({key: this.partialId, value: 1});
                        break;
                    case 3:
                        lstNumberCountryNext.push({key: this.partialId, value: 2});
                        break;
                    default:
                        lstNumberCountryNext.push({key: this.partialId, value: 0});
                        break;
                }
                let newNext = this.lstCountrySub;
                lstNumberCountryNext.forEach((item:any) => {
                    let flag = false;
                    this.lstCountrySub.forEach(ls => {
                        if (item.key === ls.key) {
                            flag = true;
                        }
                    });
                    if (!flag){
                        newNext.push(item)
                    } else {
                        newNext.forEach(x => {
                            if (x.key === item.key){
                                x.value = item.value;
                            }
                            return x;
                        })
                    }
                })
                myGlobals.numberCountrySub$.next(newNext && newNext.length > 0 ? newNext : lstNumberCountryNext);
            this.spinner.hide();
        }
    }

    onSubmit() {
        if (this.form.invalid) {
            return;
        }
        const empResponse = this.formlyService.mapFormlyFieldToResponse(this.fields, this.model);
        const mapped = Object.keys(empResponse).map(key => ({questionId: key, value: empResponse[key]}));
        if (this.questionBtn && this.questionButtonId === this.questionBtn.questionId && this.partialId === this.questionBtn.partialId) {
            mapped.push(this.questionBtn);
        } else if (this.conditionButton) {
            mapped.push({
                questionId: this.questionButtonId,
                value: '1'
            });
        }
        const result: IUserResponse = {
            questionnaireId: this.questionnaire.id,
            questionResponses: mapped,
            hotelId: this.hotelId
        };
        const arrRes = [];
        arrRes.push(result);
        
        const employerRegisterInfo: EmployerUserInfo = {
            companyName: this.model[EMPLOYER_PROFILE_QUESTION_CODE.companyName],
            website: this.model[EMPLOYER_PROFILE_QUESTION_CODE.website],
            address: this.model[EMPLOYER_PROFILE_QUESTION_CODE.address],
            location: this.model[EMPLOYER_PROFILE_QUESTION_CODE.location],
            fullName: this.model[EMPLOYER_PROFILE_QUESTION_CODE.fullName],
            email: this.model[EMPLOYER_PROFILE_QUESTION_CODE.email],
            isBrandResponsibility:
                this.model[EMPLOYER_PROFILE_QUESTION_CODE.isBrandResponsibility] === 1 ? true : false,
            relationshipWithBrand: this.model[EMPLOYER_PROFILE_QUESTION_CODE.relationshipWithBrand],
            questionnaireResponse: arrRes,
        };

        this.registerClicked.emit(employerRegisterInfo);
    }

    discard() {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: this.hotelId ? 'Discard' : `You have unsaved changes`,
                subTitle: this.hotelId ? 'Are you sure you want to discard changes?' : '',
                message: this.hotelId ? 'Click on this button will remove all changes.' : `Are you sure you want to discard all input?`,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Discard',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    this.reset();
                    myGlobals.discardInviteHotelFormSub$.next(true);
                    this.router.navigate(['employers']);
                }
            });
    }

    reset() {
        if (this.hotelId) {
            Object.keys(this.form.value).forEach(item => {
                this.form.controls[item].patchValue(null);
                this.form.controls[item].markAsUntouched();
            });
        } else {
            this.form.reset();
        }
        myGlobals.valueChangeSub$.next({questionId: 'emp_regis_A8', value: ''});
    }

    resetForm() {
        if (this.options.resetModel) {
            this.options.resetModel();
        }
        this.isDisable = true;
    }
}
