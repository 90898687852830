import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './modules/auth';
import { MasterLayoutComponent, SignUpLayoutComponent } from './modules/shared/layouts';
import { PrintLayoutComponent } from './modules/shared/layouts/print-layout/print-layout.component';

const routes: Routes = [
    {
        path: '',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        component: SignUpLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'dashboard',
        component: MasterLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule),
    },
    {
        path: 'iom',
        // component: MasterLayoutComponent,
        loadChildren: () => import('./modules/iom/iom-routing.module').then(m => m.IomRoutingModule),
    },
    {
        path: 'admin',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/admin/admin-routing.module').then(m => m.AdminRoutingModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'reset-password',
        loadChildren: () =>
            import('./modules/reset-password/reset-password-routing.module').then(m => m.ResetPasswordRoutingModule),
    },
    {
        path: 'complete-onboarding',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/user-settings/user-settings-routing.module').then(m => m.UserSettingsRoutingModule),
    },
    {
        path: 'my-account',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/user-settings/user-settings-routing.module').then(m => m.UserSettingsRoutingModule),
    },
    {
        path: 'dashboard/scoping-questions',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/scoping-question/scoping-question-routing.module').then(
                m => m.ScopingQuestionRoutingModule,
            ),
    },
    {
        path: 'dashboard/self-assessment-questionnaire/:id',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/self-assessment-questionnaire/self-assessment-questionnaire-routing.module').then(
                m => m.SelfAssessmentQuestionnaireRoutingModule,
            ),
    },
    {
        path: 'dashboard/agency/self-assessment-questionnaire/:hotelId',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/self-assessment-questionnaire/self-assessment-questionnaire-routing.module').then(
                m => m.SelfAssessmentQuestionnaireRoutingModule,
            ),
    },
    {
        path: 'dashboard/agency/self-assessment-questionnaire-ra',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/pra/pra-routing.module').then(
                m => m.PraRoutingModule,
            ),
    },
    {
        path: 'employers/self-assessment-questionnaire',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/self-assessment-questionnaire/self-assessment-questionnaire-routing.module').then(
                m => m.SelfAssessmentQuestionnaireRoutingModule,
            ),
    },
    {
        path: 'employers/agency/self-assessment-questionnaire',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/self-assessment-questionnaire/self-assessment-questionnaire-routing.module').then(
                m => m.SelfAssessmentQuestionnaireRoutingModule,
            ),
    },
    {
        path: 'agency/self-assessment-questionnaire',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/self-assessment-questionnaire/self-assessment-questionnaire-routing.module').then(
                m => m.SelfAssessmentQuestionnaireRoutingModule,
            ),
    },
    {
        path: 'dashboard/self-assessment-questionnaire',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/self-assessment-questionnaire/self-assessment-questionnaire-routing.module').then(
                m => m.SelfAssessmentQuestionnaireRoutingModule,
            ),
    },
    {
        path: 'brand',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/brand/brand-routing.module').then(m => m.BrandRoutingModule),
    },
    {
        path: 'brand-register',
        component: MasterLayoutComponent,
        loadChildren: () =>
            import('./modules/brand-register/brand-register-routing.module').then(m => m.BrandRegisterRoutingModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'pra',
        // component: MasterLayoutComponent,
        loadChildren: () => import('./modules/pra/pra-routing.module').then(m => m.PraRoutingModule),
    },
    {
        path: 'brands',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/brand/brand-routing.module').then(m => m.BrandRoutingModule),
    },
    {
        path: 'employers',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/employer/employer-routing.module').then(m => m.EmployerRoutingModule),
    },
    {
        path: 'employee',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/employee/employee-routing.module').then(m => m.EmployeeRoutingModule),
    },
    {
        path: 'questionnaire',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/brand/brand-routing.module').then(m => m.BrandRoutingModule),
    },
    {
        path: 'print',
        component: PrintLayoutComponent,
        loadChildren: () => import('./modules/print/print.module').then(m => m.PrintModule)
    },
    {
        path: 'agency',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/recruitment-agencies/recruitment-agencies-routing.module').then(m => m.RecruitmentAgenciesRoutingModule)
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./modules/sign-up/sign-up-routing.module').then(m => m.SignUpRoutingModule),
    },
    {
        path: 'change-email',
        loadChildren: () => import('./modules/change-email/change-email-routing.module').then(m => m.ChangeEmailRoutingModule),
    },
    {
        path: 'terms-and-conditions',
        loadChildren: () => import('./modules/sign-up/sign-up-routing.module').then(m => m.SignUpRoutingModule),
    },
    {
        path: 'not-found',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    },
    {
        path: '**',
        component: MasterLayoutComponent,
        loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: QuicklinkStrategy,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
