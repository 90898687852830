<input hidden [formControl]="formControl"  [formlyAttributes]="field"  [required]="to.required" [(ngModel)]="uploadResult"/>
<div class="attachment">
    <div class="top-label" *ngIf="to.symbol">
        <div class="top-label" *ngIf="to.symbol">
            <div class="symbol-input-left">
                <span>{{ to.symbol }}</span>
            </div>
        </div>
        <div class="right-item" *ngIf="to?.required">
            <mat-label class="asterix--before"></mat-label>
        </div>
    </div>
    <label class="label-input-left" *ngIf="to?.label">
        {{ to?.label }}
    </label>
    <div *ngIf="!to?.readonly" class="attachment-browse">
        <button class="upload-file" (click)="fileInput.click($event)">
            <img src="assets/images/description.svg" alt="icon" class="icon-question" />
            <label class="text-filed">Browse File</label>
        </button>
        <input hidden #fileInput type="file" (change)="emitFiles($event.target.files)" />
    </div>
    <div *ngIf="hasFile$ | async">
        <div class="attachment-action" *ngFor="let file of attachments$ | async">
            <div class="file-group">
                <img src="assets/images/description.svg" alt="icon" class="icon-question" />
                <div class="file-name"><a href="javascript:void(0);" (click)="downloadFile(file)">{{ file.fileName}}</a></div>
                <label class="file-size">{{ file.fileSizeDisplay }}</label>
            </div>
            <div class="button-group button-group-action">
                <button *ngIf="to?.readonly" class="re-upload" (click)="downloadFile(file)">
                    <img src="assets/images/Vector.svg" alt="icon" class="icon-question" />
                    <label class="text-filed">Download</label>
                </button>
                <button *ngIf="!to?.readonly" class="delete" (click)="deleteFile(file)">
                    <img src="assets/images/delete_forever.svg" alt="icon" class="icon-question" />
                    <label class="text-filed">Delete</label>
                </button>
            </div>
        </div>
    </div>
    <div class="attachment error-message">{{ error$ | async }}</div>
</div>
