import { Component, Input } from "@angular/core";
import { MatrixRes } from "../../types";

@Component({
    selector: 'app-metrics',
    templateUrl: './metrics.component.html',
    styleUrls: ['./metrics.component.scss'],
})

export class MetricsComponent {
    @Input()
    matrixRes: MatrixRes;
}
