import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ScopingQuestionContainer } from './containers/scoping-question.containers';
import { SurveyContentComponent } from './components/survey-content/survey-content.component';
import { ScopingQuestionNavigatorComponent } from './components/scoping-question-navigator/scoping-question-navigator.component';
import { PraNavigatorComponent } from './components/pra-navigator/pra-navigator.component';
import { SaveQuestionBarComponent } from './components/save-question-bar/save-question-bar.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { QuestionFormModule } from '../question-form';
import { environment } from 'src/environments/environment';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
    declarations: [
        SurveyContentComponent,
        ScopingQuestionNavigatorComponent,
        PraNavigatorComponent,
        ScopingQuestionContainer,
        SaveQuestionBarComponent,
    ],
    imports: [
        RouterModule,
        MatExpansionModule,
        CommonModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        FormsModule,
        QuestionFormModule.forRoot(environment.api),
        FormlyModule.forRoot({
            validationMessages: [{ name: 'required', message: 'Please input your answer' }],
        }),
    ],
    exports: [
        SurveyContentComponent,
        ScopingQuestionNavigatorComponent,
        PraNavigatorComponent,
        ScopingQuestionContainer,
        SaveQuestionBarComponent,
    ],
})
export class ScopingQuestionLazyModule {}
