<div class="save-question" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="save-title">You can save the questionnaire answers and continue later</div>
    <div class="group-button" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1.5rem">
        <mat-card-actions>
            <button type="submit" name="save" mat-button aria-label="submit" (click)="onSave()">
                <mat-icon matPrefix>save</mat-icon>
                Save
            </button>
            <button type="submit" name="submit" mat-button [ngClass]="isValid ? '' : 'btn-disabled'" [disabled]="!isValid" (click)="onSubmit()" >
                <mat-icon matPrefix>send</mat-icon>
                Submit
            </button>
        </mat-card-actions>
    </div>
</div>
