<form (ngSubmit)="registerUser()" #f="ngForm" novalidate>
    <ng-container *ngIf="smallScreen$ | async as smallScreen">
        <mat-card class="mat-elevation-z-p-r6" [ngClass]="'mat-elevation-z0'">
            <mat-card-header>
                <mat-card-title class="page-section-title"> ADD NEW RECRUITMENT AGENCIES USER </mat-card-title>
            </mat-card-header>
            <mat-card-content class="content-container">
                <ng-container *ngIf="smallScreen === 'no'; else mobile" [formGroup]="form">
                    <div class="form-wrapper">
                        <div class="form-field-label-3">FULL NAME</div>
                        <div class="form-field-label-3">EMAIL</div>
                        <div class="form-field-label-3">LICENSE NUMBER</div>
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="text"
                                formControlName="fullName"
                                [attr.aria-label]="'full name'"
                                required
                                autocomplete="off"
                                maxlength="100"
                                tabindex="1"
                            />
                            <mat-error *ngIf="fullName.errors && fullName.errors['required']"
                                >Please input recruitment agencies full name.</mat-error
                            >
                            <mat-error *ngIf="fullName.errors && fullName.errors['maxlength']"
                                >Full name cannot exceed 100 characters.</mat-error
                            >
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="email"
                                formControlName="email"
                                [attr.aria-label]="'Email'"
                                required
                                autocomplete="off"
                                tabindex="3"
                                (ngModelChange)="emailChanged($event)"
                                [errorStateMatcher]="matcher"
                            />
                            <mat-error *ngIf="email.errors && email.errors['required']">Please input email.</mat-error>
                            <mat-error *ngIf="email.errors && email.errors['email']"
                                >Please input valid email.</mat-error
                            >
                            <mat-error *ngIf="email.errors && email.errors['taken']"
                                >This email already exists.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="text"
                                formControlName="licenseNumber"
                                [attr.aria-label]="'licenseNumber'"
                                required
                                autocomplete="off"
                                tabindex="3"
                            />
                            <mat-error *ngIf="licenseNumber.errors && licenseNumber.errors['required']"
                                >Please input license number.</mat-error
                            >
                        </mat-form-field>
                    </div>
                    <div class="form-wrapper">
                        <div class="form-field-label-3">AGENCY</div>
                        <div class="form-field-label-3">RE-ENTER EMAIL</div>
                        <div class="form-field-label-3"></div>
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="text"
                                formControlName="agency"
                                [attr.aria-label]="agency"
                                required
                                autocomplete="off"
                                tabindex="1"
                            />
                            <mat-error *ngIf="hasError(agency, 'required')">Please input agency name.</mat-error>
                            <mat-error *ngIf="hasError(agency, 'taken')">
                                {{ agency }} already has a user on LMPM. A second user cannot be added at this time.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="email"
                                formControlName="reemail"
                                [attr.aria-label]="'Email'"
                                required
                                autocomplete="off"
                                tabindex="4"
                                [errorStateMatcher]="matcher"
                            />
                            <mat-error *ngIf="hasError(reemail, 'required')">Please input re-enter email.</mat-error>
                            <mat-error *ngIf="hasError(reemail, 'email') || hasError(reemail, 'pattern')"
                                >Please input a valid re-enter email.</mat-error
                            >
                            <mat-error *ngIf="hasError(reemail, 'emailNotMatch')"
                                >Re-enter email doesn't match with email</mat-error
                            >
                        </mat-form-field>
                    </div>
                </ng-container>
                <app-error-box [errorMessages]="errorMessages"></app-error-box>
                <div style="text-align: left; margin-top: 10px">
                    <button class="btn-add-new" type="submit" mat-button color="primary" [disabled]="form.invalid">
                        Add new Recruitment agencies
                    </button>
                </div>
            </mat-card-content>
        </mat-card>

        <hr class="bottom-line" />
    </ng-container>
</form>

<ng-template #mobile>
    <ng-container [formGroup]="form">
        <div class="row">
            <div class="form-field-label">FULL NAME</div>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="text"
                    formControlName="username"
                    [attr.aria-label]="'User name'"
                    required
                    autocomplete="off"
                    maxlength="100"
                />
                <mat-error *ngIf="username.errors && username.errors['required']">Full name is required.</mat-error>
                <mat-error *ngIf="username.errors && username.errors['maxlength']"
                    >Full name cannot exceed 100 characters.</mat-error
                >
            </mat-form-field>
        </div>
        <div class="row">
            <div class="form-field-label">AGENCY</div>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="text"
                    formControlName="agency"
                    [attr.aria-label]="'agency'"
                    required
                    autocomplete="off"
                />
                <mat-error *ngIf="hasError(agency, 'required')">Agency is required</mat-error>
                <mat-error *ngIf="hasError(agency, 'taken')">
                    {{ agency.value }} already has a user on IRIS SAFER. A second user cannot be added at this time.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="form-field-label">EMAIL</div>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="email"
                    formControlName="email"
                    [attr.aria-label]="'Email'"
                    required
                    autocomplete="off"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="email.errors && email.errors['required']">Email is required.</mat-error>
                <mat-error *ngIf="email.errors && email.errors['email']">Please input valid email.</mat-error>
                <mat-error *ngIf="email.errors && email.errors['taken']">This email already exists.</mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="form-field-label">RE-EMAIL</div>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="email"
                    formControlName="reemail"
                    [attr.aria-label]="'Email'"
                    required
                    autocomplete="off"
                    tabindex="4"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="hasError(reemail, 'required')">Email is required</mat-error>
                <mat-error *ngIf="hasError(reemail, 'email') || hasError(reemail, 'pattern')"
                    >Email is invalid</mat-error
                >
                <mat-error *ngIf="hasError(reemail, 'emailNotMatch')"
                    >Re-enter email doesn't match with email</mat-error
                >
            </mat-form-field>
        </div>

        <div class="row">
            <div class="form-field-label">Region Office</div>
            <mat-form-field appearance="outline" class="region-select">
                <mat-select formControlName="region" required placeholder="Choose Region...">
                    <mat-option>Choose Region...</mat-option>
                    <mat-option *ngFor="let option of regions" [value]="option.id">
                        {{ option.value }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="region.errors && region.errors['required']">Region is required</mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="form-field-label">Country Office</div>
            <mat-form-field appearance="outline" class="geography-select">
                <mat-select formControlName="geography" required placeholder="Choose Territory...">
                    <mat-option>Choose Territory...</mat-option>
                    <!-- <mat-option *ngFor="let option of filteredGeographies" [value]="option.id">
                        {{ option.value }}
                    </mat-option> -->
                </mat-select>
                <mat-error *ngIf="geography.errors && geography.errors['required']">Territory is required</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</ng-template>
