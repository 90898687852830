<ng-container
    *ngIf="
        userResults &&
            userResults.found === true &&
            userResults.newUser &&
            userResults.newUser?.status === 'Invited';
        else abnormalFlows
    "
>
    <div class="onboarding-form-container">
        <div class="children-container">
            <form [formGroup]="form" (ngSubmit)="completeOnboarding()" novalidate>
                <mat-card class="mat-elevation-z0" style="text-align: center">
                    <mat-card-header>
                        <mat-card-title>Register</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="content-container">
                        <div class="row onboarding-item">
                            <div class="form-field-label">Email</div>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    type="email"
                                    formControlName="email"
                                    [attr.aria-label]="'Email'"
                                    placeholder="Enter Email"
                                    autocomplete="off"
                                    [formControl]="inputEmailFormControl"
                                    [errorStateMatcher]="matcher"
                                />
                                <mat-error *ngIf="email.errors && email.errors['required']"
                                    >Please input your email.</mat-error
                                >
                                <mat-error *ngIf="email.errors && email.errors['email']"
                                    >Please input valid email.</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <div class="row onboarding-item">
                            <div class="form-field-label">Password <img src="../assets/images/info.png" class="password-criteria" alt="password" [matTooltip]="tooltipList" [matTooltipPosition]="'right'" /></div>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    [type]="hideNewPassword ? 'password' : 'text'"
                                    formControlName="newPassword"
                                    [attr.aria-label]="'new password'"
                                    required
                                    placeholder="Enter Password"
                                    autocomplete="off"
                                    (ngModelChange)="passwordChanged($event)"
                                />
                                <button
                                    mat-icon-button
                                    type="button"
                                    matSuffix
                                    (click)="hideNewPassword = !hideNewPassword"
                                    [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide"
                                >
                                    <mat-icon>{{ hideNewPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error *ngIf="newPassword.errors && newPassword.errors['required']">
                                    Please input your password.
                                </mat-error>
                                <mat-error *ngIf="newPassword.errors && newPassword.errors['pattern']">
                                    Please input valid password.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row onboarding-item">
                            <div class="form-field-label">Re-enter Password</div>
                            <mat-form-field appearance="outline">
                                <input
                                    matInput
                                    [type]="hideConfirmPassword ? 'password' : 'text'"
                                    formControlName="confirmPassword"
                                    [attr.aria-label]="'confirmPassword'"
                                    required
                                    placeholder="Re-enter Password"
                                    autocomplete="off"
                                />
                                <button
                                    mat-icon-button
                                    type="button"
                                    matSuffix
                                    (click)="hideConfirmPassword = !hideConfirmPassword"
                                    [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide"
                                >
                                    <mat-icon>{{ hideConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error *ngIf="confirmPassword.errors && confirmPassword.errors['required']">
                                    Please input your re-enter password.
                                </mat-error>
                                <mat-error *ngIf="confirmPassword.value && hasError(confirmPassword, 'passwordNotMatch')">
                                    Re-enter password doesn't match with Password.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="terms-and-conditions">
                            <mat-checkbox formControlName="confirmIomTermsAndConditions">
                                I agreed to
                                <a href="#" (click)="openTermsAndConditions($event, 'iom')"> Terms &amp; Conditions</a>
                            </mat-checkbox>
                        </div>

                        <button
                            type="submit"
                            mat-button
                            color="primary"
                            aria-label="login"
                            [attr.aria-label]="'Login'"
                            class="mat-regis-btn"
                            [disabled]="form.invalid || confirmIomTermsAndConditions.value != true"
                            [class]="form.valid && confirmIomTermsAndConditions.value ? 'btn-active' : ''"
                        >
                            Submit
                        </button>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</ng-container>

<ng-template #web>
    <ng-container [formGroup]="form">
        <div class="row">
            <label>{{ 'ONBOARDING.NAME' }}</label>
            <span>{{ (newUser && newUser?.firstname) || 'N/A' }}</span>
        </div>
        <div class="row">
            <label>{{ 'ONBOARDING.SURNAME' }}</label>
            <span>{{ (newUser && newUser?.lastname) || 'N/A' }}</span>
        </div>
        <div class="row">
            <label>{{ 'ONBOARDING.EMAIL' }}</label>
            <span>{{ (newUser && newUser?.email) || 'N/A' }}</span>
        </div>
        <div class="row">
            <div class="form-field-label">{{ 'ONBOARDING.PASSWORD.TITLE' }}</div>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    [type]="hideNewPassword ? 'password' : 'text'"
                    formControlName="newPassword"
                    [attr.aria-label]="'new password'"
                    required
                    autocomplete="off"
                />
                <button
                    mat-icon-button
                    type="button"
                    matSuffix
                    (click)="hideNewPassword = !hideNewPassword"
                    [attr.aria-label]="'Hide new password'"
                    [attr.aria-pressed]="hideNewPassword"
                >
                    <mat-icon>{{ hideNewPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                </button>
                <mat-error *ngIf="newPassword.errors && newPassword.errors['required']">{{
                    'ONBOARDING.PASSWORD.REQUIRED'
                }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</ng-template>

<ng-template #web2>
    <ng-container [formGroup]="form">
        <div class="row">
            <div class="form-field-label">{{ 'ONBOARDING.REENTER_PASSWORD' }}</div>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    [type]="hideConfirmPassword ? 'password' : 'text'"
                    formControlName="confirmPassword"
                    [attr.aria-label]="'confirm password'"
                    required
                    autocomplete="off"
                    [errorStateMatcher]="errorMatcher"
                />
                <button
                    mat-icon-button
                    type="button"
                    matSuffix
                    (click)="hideConfirmPassword = !hideConfirmPassword"
                    [attr.aria-label]="'Hide confirm password'"
                    [attr.aria-pressed]="hideConfirmPassword"
                >
                    <mat-icon>{{ hideConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                </button>
                <mat-error *ngIf="confirmPassword.errors && confirmPassword.errors['required']"
                    >{{ 'ACCOUNT_SETTINGS.CHANGE_PASSWORD.CONFIRM_PASSWORD_REQUIRE' }}
                </mat-error>
                <mat-error *ngIf="confirmPassword.errors && confirmPassword.errors['minlength']">{{
                    'ACCOUNT_SETTINGS.CHANGE_PASSWORD.MIN_LENGTH_CONFIRM_PASSWORD'
                }}</mat-error>
                <mat-error *ngIf="form.errors && form.errors['mismatch']"
                    >{{ 'ACCOUNT_SETTINGS.CHANGE_PASSWORD.MATCH_PASS' }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="form-field-label">{{ 'ONBOARDING.LANGUAGE' }}</div>
            <mat-form-field appearance="outline">
                <mat-select formControlName="language" name="language">
                    <mat-option *ngFor="let language of languages" [value]="language.id">
                        {{ language.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row" *ngIf="enableIomTermsConditions && userType === 'pra'">
            <div class="form-field-label">&nbsp;</div>
            <mat-checkbox class="terms-and-conditions" formControlName="confirmIomTermsAndConditions">
                <a href="#" (click)="openTermsAndConditions($event, 'iom')"
                    >Please Confirm IOM Terms &amp; Conditions</a
                >
            </mat-checkbox>
        </div>
        <div class="row">
            <div class="form-field-label">&nbsp;</div>
            <mat-checkbox class="terms-and-conditions" formControlName="confirmTermsAndConditions">
                <a href="#" (click)="openTermsAndConditions($event, 'vendor')">{{ 'ONBOARDING.T&C' }}</a>
            </mat-checkbox>
        </div>
    </ng-container>
</ng-template>

<ng-template #abnormalFlows>
    <ng-container
        *ngIf="
            userResults && userResults.found === true && userResults.newUser && userResults.newUser?.status === 'Live';
            else userNotFound
        "
    >
        <ng-container *ngIf="smallScreen$ | async as smallScreen">
            <mat-card class="mat-elevation-z0">
                <mat-card-header>
                    <mat-card-title [ngClass]="smallScreen === 'yes' ? 'small' : ''"> ONBOARDING </mat-card-title>
                </mat-card-header>
                <mat-card-content class="onboarding-container">
                    <div class="onboarding-confirmation" style="text-align: center">
                        <p class="message">
                            Onboarding process of {{ userResults.newUser?.firstname }}
                            {{ userResults.newUser?.lastname }} is completed.
                        </p>
                        <button type="button" mat-button color="primary" (click)="gotoLogin.emit()">
                            LOGIN TO IRIS SAFER
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #userNotFound>
    <ng-container *ngIf="smallScreen$ | async as smallScreen">
        <mat-card class="mat-elevation-z0">
            <mat-card-header>
                <mat-card-title [ngClass]="smallScreen === 'yes' ? 'small' : ''"> ONBOARDING </mat-card-title>
            </mat-card-header>
            <mat-card-content class="onboarding-container">
                <div class="onboarding-confirmation" style="text-align: center">
                    This invitation URL was deleted.
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
</ng-template>
