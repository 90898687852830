import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BrandRegisterService } from '../services';

export function validateCompanyName(service: BrandRegisterService, cdr: ChangeDetectorRef): AsyncValidatorFn {
    return (control: AbstractControl) => {
        if (!control.dirty && !control.touched) {
            return of(null);
        }
        const value = control.value as string;
        return service.isCompanyNameUnique(value.trim()).pipe(
            map((result: boolean) => (result ? null : { taken: true })),
            finalize(() => cdr.markForCheck()),
        );
    };
}
