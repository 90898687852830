import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService, BreadcrumbService, EmployerService, PraUserActionData, PraUserInfo } from 'src/app/modules';
import { ConfirmationDialog, ConfirmCancelDialog } from 'src/app/modules/shared';
import { IQuestionnaireRes, PaginateOptions } from 'src/app/modules/types';
import { DocumentListService } from '../../services/document-list.service';

@Component({
    selector: 'app-employer-dashboard',
    templateUrl: './employer-dashboard.component.html',
    styleUrls: ['./employer-dashboard.component.scss'],
    providers: [DocumentListService],
})
export class EmployerDashboardComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private documentListService: DocumentListService,
        private employerService: EmployerService,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private authService: AuthService,
        private breadCrumbService: BreadcrumbService){}

    private unsubscribe$ = new Subject();
    pageSize = 5;
    document$ = this.documentListService.document$;
    pageIndex$ = this.documentListService.pagination$.pipe(
        switchMap((paginationOpts: PaginateOptions) => {
            return of(paginationOpts.pageIndex);
        })
    );
    total$ = this.documentListService.total$;
    currentUserId$: Observable<string>;
    currentUserId: string;

    praEmployerCollection$ = this.employerService.praEmployer$;
    paginatePras$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });
    sorterPra$ = new BehaviorSubject<Sort>({ active: 'name', direction: 'asc' });
    filter$ = new BehaviorSubject<string>('');
    role$: Observable<string>;
    currentPaginateOption: PaginateOptions;
    currentOrder: string;
    inactivateUserErrors$ = this.employerService.inactivateUserErrors$;
    deactivateRA$ = this.employerService.deactivateRA$;
    errors$ = this.employerService.errors$;
    currentRA: PraUserInfo;

    ngOnInit(): void {
        let breadcrumbItems = [{name: 'Dashboard', path: '/dashboard'}]
        this.breadCrumbService.setBreadCrumbItemsFirstPage(breadcrumbItems);

        this.currentUserId$ = this.authService.user$.pipe(
            filter(user => !!user),
            pluck('id'),
        );
        this.currentUserId$.subscribe((userId: string) => {
            this.currentUserId = userId;
        });
        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );
        combineLatest([this.paginatePras$, this.filter$, this.sorterPra$])
            .pipe(
                debounceTime(300),
                switchMap(([pagination, filter, sorter]) => {
                    let orderBy = '';
                    if (sorter && sorter.active) {
                        orderBy = `${sorter.active} ${sorter.direction}`;
                    }
                    this.currentOrder = orderBy;
                    this.currentPaginateOption = pagination;
                    this.spinner.show();
                    return this.employerService.paginteAllPraEmployer(pagination, filter, orderBy);
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => this.spinner.hide());

        this.inactivateUserErrors$.pipe(takeUntil(this.unsubscribe$)).subscribe(errors => {
            this.spinner.hide()
            if (errors && errors.length > 0) {
                this.dialog.open(ConfirmationDialog, {
                    width: '450px',
                    data: {
                        title: 'Error',
                        message:
                            errors[0],
                        closeButtonText: 'Close',
                    },
                });
            }
        })

        this.deactivateRA$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (result && result.response) {
                if (result.response.deactivated) {
                    this.spinner.hide();
                    let dialogRef = this.dialog.open(ConfirmCancelDialog, {
                        data: {
                            title: 'This Recruitment agency was deactivated',
                            subTitle: 'Are you sure you want to invite this Recruitment Agency?',
                            message: 'Invite Recruitment agency will activate account again.',
                            cancelButtonText: 'Discard',
                            confirmButtonText: 'Invite',
                            color: 'warn'
                        },
                    });

                    dialogRef
                    .afterClosed()
                    .pipe(
                        takeUntil(this.unsubscribe$),
                        switchMap(async (res:any) => {
                            if (res) {
                                setTimeout(() => {
                                    this.inviteRA(this.currentRA);
                                }, 1);
                            }
                            return of(undefined);
                        })
                    )
                    .subscribe();
                } else {
                    setTimeout(() => {
                        this.inviteRA(this.currentRA);
                    }, 1);
                }
            } else {
                this.spinner.hide();
            }
        })

        this.errors$.pipe(takeUntil(this.unsubscribe$)).subscribe(errors => {
            this.spinner.hide()
            if (errors && errors.length > 0) {
                this.dialog.open(ConfirmationDialog, {
                    width: '450px',
                    data: {
                        title: 'Error',
                        message:
                            errors[0],
                        closeButtonText: 'Close',
                    },
                });
            }
        })
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    redirectScopingQuestion() {
        this.router.navigate(['dashboard/scoping-questions']);
    }

    redirectSelfAssessmentQuestionnaire() {
        this.router.navigate(['dashboard/self-assessment-questionnaire']);
    }

    docListPaginate(paginateOpts: PaginateOptions) {
        this.documentListService.updatePagination(paginateOpts);
    }

    docListSortChange(sort: Sort) {
        this.documentListService.updateSort(sort);
    }

    goToDocument(document: IQuestionnaireRes) {
        switch (document.questionnaireType) {
            case 'employer_pra_scoping':
                this.router.navigate(['employers', 'pra', document.id]);
                break;
            case 'employer_scoping':
                this.router.navigate(['employers', document.userId , 'scoping', document.id]);
                break;
            case 'employer_saq':
                    this.router.navigate(['employers', document.userId , 'scoping', document.id]);
                    break;
            case 'employer_registration_form':
            default:
                break;
        }
    }

    deleteScopingPending(document: IQuestionnaireRes) {
        let subTitle = 'Are you sure you want to delete?'
        let messageSuccess = 'Deleted successfully.'
        let message = 'It will remove all answers that you saved previously.'
        let title = 'Delete';
        if (document.questionnaireType === 'employer_scoping') {
            subTitle = 'Are you sure you want to delete Scoping questions?'
            messageSuccess = 'Your Scoping questions has been deleted successfully.'
            message = 'Delete Scoping questions will remove all answers that you saved previously.'
            title = 'Delete Scoping questions'
        }
        if (document.questionnaireType === 'employer_saq') {
            subTitle = 'Are you sure you want to delete Self-assessment questionnaire?'
            messageSuccess = 'Your Self-assessment questionnaire has been deleted successfully.'
            message = 'Delete Self-assessment questionnaire will remove all answers that you saved previously.'
            title = 'Delete Self-assessment questionnaire'
        }
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: title,
                subTitle: subTitle,
                message: message,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.documentListService.deleteQuestionnaireDraft(document.id);
                    }
                    return of(undefined)
                }))
            .subscribe(res => {
                if (res) {
                    this.spinner.hide();
                    this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: ``,
                            message: messageSuccess,
                            closeButtonText: 'Close',
                        },
                    });
                }
            });
    }

    async invitePraFnc (praUser: PraUserInfo) {
        this.currentRA = praUser;
        this.spinner.show();
        this.employerService.hotelCheckDeactivatePra(praUser.email);
    }

    async inviteRA (praUser: PraUserInfo) {
        praUser.employerId = this.currentUserId;
        this.spinner.show();
        const result = await this.employerService.invitePraEmployerDashboard(praUser, this.currentPaginateOption, this.currentOrder);
        if (result) {
            this.spinner.hide();
            this.dialog.open(ConfirmationDialog, {
                width: '600px',
                data: {
                    title: 'Email sent',
                    message:`Invitation email was sent to ${praUser.email} successfully.`,
                    closeButtonText: 'Close',
                },
            });
        }
    }

    async inactivatePraFnc (data: PraUserActionData) {
        data.employerId = this.currentUserId;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate Recruitment Agency` : `Activate Recruitment Agency`,
                subTitle:
                    data.status === 'Active'
                        ? `Are you sure you want to deactivate account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When de-activated, Recruitment Agency cannot submit Self assessment questionnaire for your Hotel. `
                        : `When activate, user can continue submitting Self assessment questionnaire for your Hotel.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
        .afterClosed()
        .pipe(
            takeUntil(this.unsubscribe$),
            switchMap((res:any) => {
                if (res) {
                    this.spinner.show();
                    return this.employerService.inactivatePraEmployerDashboard(data);
                }
                return of(undefined);
            })
        )
        .subscribe(async result => {
            if (result) {
                this.paginatePras$.next(this.currentPaginateOption);
                this.spinner.hide();
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: data.status == 'Active' ? 'Deactivated Successfully!' : 'Activated Successfully',
                        message:
                            data.status == 'Active'
                                ? 'RA was successfully deactivated from LMPM platform.'
                                : 'RA was successfully activated from LMPM platform.',
                        closeButtonText: 'Close',
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe();
            }
        });
    }

    async deletePraFnc (praUser: PraUserActionData) {
        praUser.employerId = this.currentUserId;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Delete Recruitment Agency`,
                subTitle: `Are you sure you want to delete account?`,
                message: `
                    When delete, this user cannot work on LMPM platform. Their information, answers and activities will be deleted.
                `,
                cancelButtonText: 'Discard',
                confirmButtonText: 'Delete account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    this.spinner.show();
                    const deleteResult = await this.employerService.deletePraEmployerDashboard(praUser, this.currentPaginateOption);
                    if (deleteResult) {
                        this.spinner.hide();
                        this.dialog.open(ConfirmationDialog, {
                            width: '600px',
                            data: {
                                title: `Delete Successfully`,
                                message: `
                                User was successfully deleted from LMPM platform.
                                `,
                                closeButtonText: 'Close',
                            },
                        });
                    }
                }
            });
    }
}
