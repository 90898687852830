import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NextBackBehaviour, PartialResponse, Question, Section } from 'src/app/modules/question-form';
import { Formly2Service } from 'src/app/modules/shared/services/formly2.service';
import { isSection, ISurveyPartial } from '../../types';
import * as myGlobals from '../../../../globals';
import { numberCountryChange, valueChange } from 'src/app/modules';
import { CustomFormlyFieldConfig } from 'src/app/modules/shared';

@Component({
    selector: 'survey-content',
    templateUrl: './survey-content.component.html',
    styleUrls: ['./survey-content.component.scss'],
})
export class SurveyContentComponent implements OnChanges, OnDestroy {
    @Input() partial: ISurveyPartial;
    @Input() nextBackBehaviour: NextBackBehaviour;
    @Output() formChange = new EventEmitter<PartialResponse>();
    @Output() next = new EventEmitter();
    @Output() back = new EventEmitter();
    @Input() readOnlyMode: boolean;
    @Input() lstCountrySub: numberCountryChange[];
    @Input() numberSectionRA: number;

    form: FormGroup;
    model: any;
    fields: FormlyFieldConfig[];
    options: FormlyFormOptions = {};
    formValuesSub: Subscription;estion = '';
    questionButtonId = '';
    questionBtn: any;
    private unsubscribe$ = new Subject();

    constructor(private formlyService: Formly2Service) {
        this.form = new FormGroup({});
        this.model = {};
        myGlobals.valueChange$.pipe(takeUntil(this.unsubscribe$)).subscribe(valueObj => {
            if (valueObj) {
                if ((valueObj?.questionId === 'employer_scoping_A17' || valueObj?.questionId === 'emp_pra_scop_A15') && this.questionButtonId) {
                    this.questionBtn = {
                        partialId: valueObj.partialId,
                        questionId: this.questionButtonId,
                        value: valueObj?.value
                    };
                }
                for (let i = 0; i < this.fields.length; i++){
                    const currentQuestion = myGlobals.allQuestion.find(q => {
                        return q.id == (this.fields[i] as CustomFormlyFieldConfig).questionId;
                    });
                    if (currentQuestion && currentQuestion.id) {
                        if (!currentQuestion.displayCondition) {
                            this.fields[i].hide = false;
                        } else {
                            const result = this.getHidden(valueObj, currentQuestion);
                            this.fields[i].hide = result;
                            myGlobals.allQuestion.forEach(q => {
                                if(q.id === currentQuestion.id){
                                    q.hidden = result;
                                }
                            });
                        }
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getHidden(changeValue: valueChange, currentQuestion: Question) {
        let result = true;
        currentQuestion.displayCondition?.forEach((questionCondition: any) => {
            const keyQuestion = myGlobals.allQuestion.filter(q => {
                return q.id == questionCondition.key && !q.hidden;
            })[0];
            if(keyQuestion) {
                keyQuestion.code = this.formatKey(keyQuestion.code)
                if (keyQuestion.code == changeValue.questionId){
                    keyQuestion.value = changeValue.value;
                }
                if (keyQuestion && keyQuestion.id) {
                    if(keyQuestion.displayConditionType && keyQuestion.displayConditionType === "and") {
                        if(keyQuestion.value?.toLowerCase() === questionCondition.value?.toLowerCase()) {
                            result = false;
                        }
                    }else if(keyQuestion.value?.toLowerCase() === questionCondition.value?.toLowerCase()) {
                        result = false;
                    }
                }
            }

        });

        return result;
    }

    formatKey(code: string): string {
        return (code || '').replace('.', '_');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.partial && changes.partial) {
            let lstNumberCountryNext:any = [];
            this.model = {};
            if (isSection(this.partial.content)) {
                const questions = (this.partial.content as Section).questions;
                myGlobals.allQuestionSub$.next(questions);
                questions.forEach(q => {
                    if (q.controlType === 'question-btn') {
                        this.questionButtonId = q.id;
                        q.value = !q.value ? '1' : q.value;
                    }
                    return q;
                })
                this.fields = this.formlyService.allQuestionsToFormlyConfig(
                   questions,
                    'modern',
                    this.readOnlyMode,
                    undefined,
                    undefined,
                    false,
                    false,
                    true,
                    this.partial.id
                );
                const lengthCountry = this.fields && this.fields.length > 0  ?
                            this.fields.filter(x => !!x && x.type === 'wrap-country-group' && !x.hide).length : 0;
                switch(lengthCountry) {
                    case 2:
                        lstNumberCountryNext.push({key: this.partial.id, value: 1});
                        break;
                    case 3:
                        lstNumberCountryNext.push({key: this.partial.id, value: 2});
                        break;
                    default:
                        lstNumberCountryNext.push({key: this.partial.id, value: 0});
                        break;
                }

            } else {
                let questions: Question[] = [];
                (this.partial.content as Section[]).forEach(section => {
                    questions = questions.concat(section.questions);
                });
                myGlobals.allQuestionSub$.next(questions);
                questions.forEach(q => {
                    if (q.controlType === 'question-btn') {
                        this.questionButtonId = q.id;
                        q.value = !q.value ? '1' : q.value;
                    }
                    return q;
                })
                this.fields = this.formlyService.allQuestionsToFormlyConfig(questions, 'modern', this.readOnlyMode, undefined, undefined, false, false, true, this.partial.id);
                const lengthCountry = this.fields && this.fields.length > 0 ?
                                 this.fields.filter(x => !!x && x.type === 'wrap-country-group' && !x.hide).length : 0;
                switch(lengthCountry) {
                    case 2:
                        lstNumberCountryNext.push({key: this.partial.id, value: 1});
                        break;
                    case 3:
                        lstNumberCountryNext.push({key: this.partial.id, value: 2});
                        break;
                    default:
                        lstNumberCountryNext.push({key: this.partial.id, value: 0});
                        break;
                }
            }
            let newNext = this.lstCountrySub;
            lstNumberCountryNext.forEach((item:any) => {
                let flag = false;
                this.lstCountrySub.forEach(ls => {
                    if (item.key === ls.key) {
                        flag = true;
                    }
                });
                if (!flag){
                    newNext.push(item)
                } else {
                    newNext.forEach(x => {
                        if (x.key === item.key){
                            x.value = item.value;
                        }
                        return x;
                    })
                }
            })
            myGlobals.numberCountrySub$.next(newNext && newNext.length > 0 ? newNext : lstNumberCountryNext);
            if (this.formValuesSub) {
                this.formValuesSub.unsubscribe();
            }
            this.formValuesSub = this.form.valueChanges
                .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
                .subscribe(_formValues => {
                    let responses = this.formlyService.mapFormlyFieldToResponse(this.fields, this.model);
                    if (this.questionBtn && this.questionButtonId === this.questionBtn.questionId && this.partial.id === this.questionBtn.partialId) {
                        delete responses[this.questionButtonId];
                        responses[this.questionButtonId] = this.questionBtn.value;
                    }
                    const summary: PartialResponse = {
                        partialId: this.partial.id,
                        responses: responses,
                        formValid: !this.form.invalid,
                        formDirty: this.form.dirty
                    };
                    this.formChange.emit(summary);
                });
        }
    }

    onBack() {
        this.back.emit();
    }

    onNext() {
        this.next.emit();
    }
}
