import { CustomValidators } from './../../../shared/formly-fields/custom-validators';
import { TouchedErrorStateMatcher } from './../../../shared/validators/touched-error-state.matcher';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AuthService, PraService } from 'src/app/modules';
import { validateIomEmail } from 'src/app/modules/pra/validators';
import { AdminService } from '../../../admin';
import { ConfirmationDialog, ConfirmCancelDialog, confirmPasswordValidator, MIN_PASSWORD_LEN } from '../../../shared';
import { MyAccountService } from '../../services/my-account.service';
import { ChangePasswordInfo, PRAAccountInfo, UpdatePRAAccountInfo } from '../../types';

@Component({
    selector: 'user-settings-pra-user',
    templateUrl: './pra-user.component.html',
    styleUrls: ['./pra-user.component.scss'],
})
export class PraUserComponent implements OnInit, OnDestroy {
    matcher = new TouchedErrorStateMatcher()
    @Output()
    saveRAChangeEmit = new EventEmitter<{
        formValid: boolean;
        data: any;
    }>();

    errorMessages = new BehaviorSubject<string[]>([]);
    errorMessagesPass = new BehaviorSubject<string[]>([]);

    constructor(
        private fb: FormBuilder,
        public dialog: MatDialog,
        private adminService: AdminService,
        private myAccountService: MyAccountService,
        private authService: AuthService,
        private spinner: NgxSpinnerService,
        private service: PraService,
        private cdr: ChangeDetectorRef,
    ) {}
    forminfo: FormGroup;
    hidePassword = true;
    hideNewPassword = true;
    hideReEnterPassword = true;
    private unsubscribe$ = new Subject();
    fullname$: Observable<string>;
    errors$ = this.adminService.errors$;
    formEmail: FormGroup;
    successPRA$ = this.myAccountService.successPRA$;
    errorsPRA$ = this.myAccountService.errorsPRA$;
    successChangePass$ = this.adminService.success$;

    originAccountInfo: PRAAccountInfo;
    formChanged = false;
    agencyName:any = '';
    minPasswordLen = MIN_PASSWORD_LEN;

    tooltipList = `Valid Password format:
    - Have at least 8 characters
    - Have at least 1 uppercase
    - Have at least 1 lowercase
    - Have at least 1 numeric character
    - Have at least 1 special characters: ! @ # $ % ^ & *
    - Must not contain space
    `;

    ngOnInit(): void {
        this.forminfo = this.fb.group(
            {
                fullName: new FormControl(''),
                address: new FormControl('', { validators: [Validators.required] }),
                password: new FormControl('', {
                    validators: [
                        Validators.required,
                        Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
                    ],
                }),
                newPassword: new FormControl('', {
                    validators: [
                        Validators.required,
                        Validators.minLength(this.minPasswordLen),
                        Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
                    ],
                }),
                confirmPassword: new FormControl('', {
                    validators: [Validators.required, this.passwordCf.bind(this)],
                }),
            },
            { validators: confirmPasswordValidator() },
        );

        this.formEmail = this.fb.group({
            email: new FormControl('', {
                validators: [Validators.required, CustomValidators.emailType],
                asyncValidators: [validateIomEmail(this.service, this.cdr, () => this.originAccountInfo?.email)],
            }),
        });

        this.forminfo.controls.address.disable();
        this.forminfo.controls.fullName.disable();
        this.myAccountService
            .getAccountInfoForPRA()
            .pipe(
                catchError(() => {
                    this.dialog.open(ConfirmationDialog, {
                        data: {
                            title: 'Operation failed!',
                            message: 'Failed to get your account info!',
                            closeButtonText: 'Cancel',
                        },
                    });
                    return EMPTY;
                }),
                map(res => {
                    this.originAccountInfo = res.response as PRAAccountInfo;
                    this.listenToFormChanges();
                    this.bindFormValues(this.originAccountInfo);
                }),
                shareReplay(1),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        this.successChangePass$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.spinner.hide();
            if (result) {
                const confirmDialogRef1 = this.dialog.open(ConfirmationDialog, {
                    width: '600px',
                    data: {
                        title: 'New password has been saved',
                        message: 'Please login LMPM again with you new password',
                        closeButtonText: 'OK',
                    },
                });

                confirmDialogRef1
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.authService.logout();
                    });
            }
        });

        this.errors$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.spinner.hide();
            this.errorMessagesPass.next(result);
            if (result && result.length > 0) {
                this.dialog.open(ConfirmationDialog, {
                    width: '600px',
                    data: {
                        title: 'Unable to save change',
                        message: result[0] || 'Your change has not been saved due to an error. Please try again',
                        closeButtonText: 'Close',
                    },
                });
            }
        });

        this.successPRA$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.spinner.hide();
            if (result) {
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    width: '600px',
                    data: {
                        title: 'Your email address has been updated',
                        message: 'Please check your new mailbox and verify email',
                        closeButtonText: 'Close',
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.authService.logout();
                    });
            }
        });

        this.errorsPRA$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.spinner.hide();
            this.errorMessages.next(result);
            if (result.length > 0) {
                this.dialog.open(ConfirmationDialog, {
                    width: '600px',
                    data: {
                        title: 'Unable to save change',
                        message: result[0] || 'Your change has not been saved due to an error. Please try again',
                        closeButtonText: 'Close',
                    },
                });
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    listenToFormChanges() {
        this.forminfo.valueChanges.subscribe(val => {
            this.formChanged = val.fullName !== this.originAccountInfo.fullName;
        });

        this.formEmail.valueChanges.subscribe(val => {
            this.formChanged = val.email !== this.originAccountInfo.email;
        });
    }

    bindFormValues(info: PRAAccountInfo) {
        if (info) {
            this.forminfo.controls.fullName.setValue(info?.fullName);
            this.formEmail.controls.email.setValue(info?.email);
            this.forminfo.controls.address.setValue(info?.address);
            this.agencyName = info?.agencyName
        }
    }

    get fullName() {
        return this.forminfo.controls.fullName as FormControl;
    }

    get email() {
        return this.formEmail.controls.email as FormControl;
    }

    get address() {
        return this.forminfo.controls.address as FormControl;
    }

    get newPassword() {
        return this.forminfo.controls.newPassword as FormControl;
    }

    get password() {
        return this.forminfo.controls.password as FormControl;
    }

    get confirmPassword() {
        return this.forminfo.controls.confirmPassword as FormControl;
    }

    changeEmail() {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: 'Update email address',
                message: 'Are you sure you want to move from this email address to the new one?',
                cancelButtonText: 'Discard',
                confirmButtonText: 'Update email address',
                hideCancelButton: false,
                color: 'warn',
            },
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                const infoPRA: UpdatePRAAccountInfo = {
                    email: this.email.value,
                };
                this.spinner.show();
                this.myAccountService.updateAccountInfoForPRA(infoPRA);
            }
        });
    }

    async changePassWord() {
        const inputPRA: ChangePasswordInfo = {
            newPassword: this.newPassword.value,
            currentPassword: this.password.value,
            confirmPassword: this.confirmPassword.value,
        };
        this.spinner.show();
        this.adminService.updatePassword(inputPRA);
    }

    passwordCf() {
        if (this.forminfo !== null && this.forminfo !== undefined) {
            const newPass = this.forminfo.controls.newPassword;
            const confirmPass = this.forminfo.controls.confirmPassword;
            return newPass?.value === confirmPass?.value ? null : { passwordNotMatch: true };
        }
        return null;
    }

    passwordChanged(value: string) {
        if (this.forminfo !== null && this.forminfo !== undefined) {
            const repassword = this.forminfo.controls.confirmPassword;
            if (repassword.value !== '' && value !== repassword.value) {
                this.forminfo.controls.confirmPassword.setErrors({ passwordNotMatch: true });
            }
            if (repassword.value !== '' && value === repassword.value) {
                this.forminfo.controls.confirmPassword.setErrors(null);
            }
        }
    }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }
}
