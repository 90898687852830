<!-- <div mat-dialog-content>
    <div mat-dialog-actions>
        <button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <app-term-dialog-content [lmpmLink]="lmpmLink" [lmpmTermLink]="lmpmTermLink"></app-term-dialog-content>

    <div class="agree-btn">
        <mat-checkbox class="example-margin" [checked]="agreeTermCheck" (change)="agreeTerm()"
            >I agree to&nbsp;
        </mat-checkbox>
        <a href="#" (click)="openDialog($event)" class="term" style="cursor: pointer">Terms and Conditions</a>
    </div>
</div> -->

<div class="dialog-style-form">
    <mat-icon (click)="closeDialog()" [attr.aria-label]="'close dialog'">close</mat-icon>
    <div>
        <app-term-dialog-content [lmpmLink]="lmpmLink" [lmpmTermLink]="lmpmTermLink"></app-term-dialog-content>
    </div>
    <div class="agree-btn">
        <mat-checkbox class="example-margin" [checked]="agreeTermCheck" (change)="agreeTerm()"
            >I agree to&nbsp;Terms and Conditions
        </mat-checkbox>
    </div>
</div>
