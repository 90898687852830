<div class="list-hotels-container">
    Self Assessment Questionnaire
    <div class="saq-hotel-content">
        <ngx-slick-carousel class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
            (init)="slickInit($event)"
            (breakpoint)="breakpoint($event)"
            (afterChange)="afterChange($event)"
            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let hotel of hotels" class="slide">
                <div class="layout-img">
                    <mat-icon class="img-content">apartment</mat-icon>
                </div>
                <p class="hotel-title">{{hotel.companyname}}</p>
                <p class="date-title">Requested on {{hotel.createdat | date:'dd/MM/yyyy'}}</p>
                <button [class]="hotel.continue ? 'button-continue' : 'button-start'" (click)="startAssessment(hotel.id, hotel.prastatus)">
                    {{hotel.continue ? 'Continue Questionnaire' : 'Start Questionnaire'}}
                </button>
            </div>
        </ngx-slick-carousel>
        <div class="group-btn" *ngIf="hotels?.length > 0">
            <div class="group-prev">
                <button class="btn-next-prev" [class]="disablePrev ? 'disable-group' : ''" (click)="prev()" [disabled]="disablePrev">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </div>
            <div class="group-next">
                <button class="btn-next-prev" [class]="disableNext ? 'disable-group' : ''" (click)="next()" [disabled]="disableNext">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
