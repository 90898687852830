import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth';
import { EmployeeUserInfo } from 'src/app/modules/employee/types';
import { EmployerService } from 'src/app/modules/employer/services';
import { ConfirmationDialog } from 'src/app/modules/shared';

@Component({
    selector: 'app-popup-request',
    templateUrl: './popup-request.component.html',
    styleUrls: ['./popup-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupRequestComponent implements OnInit, OnDestroy {
    @ViewChild('f', { static: true })
    formDirective: NgForm;
    @Input()
    errorMessages: string[];

    @Output()
    registerClicked = new EventEmitter<EmployeeUserInfo>();

    form: FormGroup;

    private unsubscribed$ = new Subject();
    role$: Observable<string>;
    role = '';
    hotelName = '';
    error$ = this.myAccountservice.error$;

    smallScreen$ = this.breakpointObserver
        .observe(['(max-width: 1200px)'])
        .pipe(map(observer => (observer.matches ? 'yes' : 'no')))

    constructor(
        private fb: FormBuilder,
        private breakpointObserver: BreakpointObserver,
        private spinner: NgxSpinnerService,
        private matDialogRef: MatDialogRef<PopupRequestComponent>,
        private authenService: AuthService,
        private dialog: MatDialog,
        private myAccountservice: EmployerService,
        ) {}

    ngOnInit() {
        this.role$ = this.authenService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : ''))
        );
        this.role$.subscribe(role => this.role = role);
        if(this.role === 'brand'){
            this.hotelName = "hotel";
        }else{
            this.hotelName = "hotel group";
        }
        this.form = this.fb.group({
            hotelId: new FormControl('', {
                validators: [
                    Validators.required,
                    // Validators.maxLength(5),
                    Validators.pattern(/^(?=.*[A-Z])(?=.*\d)[A-Z\d]{5}$/),
                ],
            }),
        });
        this.myAccountservice.errors$.subscribe(_error => {
            this.spinner.hide();
            this.closePopup();
            this.matDialogRef.afterClosed().subscribe(() => {
                let resultDialog;
                if(this.role === 'brand'){
                    resultDialog = this.dialog.open(ConfirmationDialog, {
                        width: '660px',
                        data: {
                            title: 'Wrong Hotel ID!',
                            message: `This ID does not refer to an existing hotel group. Please confirm Hotel ID and submit again.`,
                            closeButtonText: 'Ok',
                        },
                    });
                }else{
                    resultDialog = this.dialog.open(ConfirmationDialog, {
                        width: '660px',
                        data: {
                            title: 'Wrong Hotel group ID!',
                            message: `This ID does not refer to an existing hotel group. Please confirm Hotel group ID and submit again.`,
                            closeButtonText: 'Ok',
                        },
                    });
                }
                resultDialog.afterClosed()
                    .subscribe(() => {
                        this.dialog.open(PopupRequestComponent, {
                            width: '660px',
                        })
                    });
                })
        })
    }

    ngOnDestroy() {
        this.unsubscribed$.next();
        this.unsubscribed$.complete();
    }

    get hotelId() {
        return this.form.controls.hotelId as FormControl;
    }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }

    registerHotelID() {
        this.errorMessages = [];
        if (this.form.valid) {
            const values = this.form.value;
            this.spinner.show();
            this.myAccountservice.requestLinkHotelOrHotelGroup(this.role, values['hotelId']).subscribe(() => {
                this.spinner.hide();
                this.closePopup();
                if(this.role === 'brand'){
                    this.dialog.open(ConfirmationDialog, {
                        width: '660px',
                        data: {
                            title: 'Thank you!',
                            message: `This ID refers to a valid ${this.hotelName}. A request has been automatically sent to them. You will receive a notification if they accept your request to link their hotel with your group.`,
                            closeButtonText: 'Ok',
                        },
                    });
                }else{
                    this.dialog.open(ConfirmationDialog, {
                        width: '660px',
                        data: {
                            title: 'Thank you!',
                            message: `This ID refers to a valid ${this.hotelName}. A request has been automatically sent to them. You will receive a notification if they accept your request to link your hotel with their group.`,
                            closeButtonText: 'Ok',
                        },
                    });
                }
            });
        }
    }

    closePopup() {
        this.matDialogRef.close();
        this.resetForm();
    }
    resetForm() {
        this.formDirective.resetForm();
        this.form.reset();

        Object.keys(this.form.controls).forEach(key => this.form.controls[key].setErrors(null));
    }
}
