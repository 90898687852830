import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { IQuestionnaireRes, PaginateOptions } from 'src/app/modules';
import { DataTableConfig } from 'src/app/modules/shared';
import { DocumentNamePipe } from 'src/app/modules/shared/pipes/document-name.pipe';
import format from 'date-fns/format';
import { CountryFlagPipe } from 'src/app/modules/shared/pipes/country-flag.pipe';
import { Router } from '@angular/router';
import { DOC_TYPES } from 'src/app/modules/print/types';
import { EmployerService } from '../../services';
@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements AfterViewInit, OnChanges {
    @Input() employerDashboard: boolean = false;

    @Input() documents: IQuestionnaireRes[] = [];

    @Input()
    role = '';

    @Input()
    pageSize = 5;

    @Input()
    pageIndex = 1;

    @Input()
    total = 0;

    @Input()
    errorMessages: string[];

    @Output()
    viewClicked = new EventEmitter<IQuestionnaireRes>();

    @Output()
    deleteClicked = new EventEmitter<IQuestionnaireRes>();

    @Output()
    paginate = new EventEmitter<PaginateOptions>();

    @Output()
    sortChange = new EventEmitter<Sort>();

    config: DataTableConfig<IQuestionnaireRes>;

    constructor(private router: Router, private employerService: EmployerService) {}

    ngAfterViewInit() {
        const startAtPage = { pageIndex: this.pageIndex };
        this.initDatatable(this.documents, this.total, startAtPage);
    }

    ngOnChanges(_changes: SimpleChanges) {
        const startAtPage = { pageIndex: this.pageIndex };
        this.initDatatable(this.documents, this.total, startAtPage);
    }

    initDatatable(data: IQuestionnaireRes[], count: number, startAtPage?: { pageIndex: number }) {
        if (!data) {
            return;
        }

        this.config = {
            data,
            tableName: 'employer-users',
            hideFilter: true,
            columns: [
                {
                    text: 'Document',
                    label: 'Document',
                    fieldName: 'questionnaireType',
                    style: { flex: 1 },
                    sortable: true,
                    valueFunction: element => {
                        const documentNamePipe = new DocumentNamePipe();
                        return element.label || documentNamePipe.transform(element.questionnaireType);
                    },
                    templateFunction: element => {
                        const documentNamePipe = new DocumentNamePipe();
                        const value = element.label || documentNamePipe.transform(element.questionnaireType);
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${value}</a>`;
                    },
                },
                {
                    text: 'Location',
                    label: 'Location',
                    fieldName: 'location',
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        const pipe = new CountryFlagPipe();
                        const flag = pipe.transform(element.location);
                        return element.location ? `<a href="${this.getUrl(element)}" class='document-list-item'>${flag}&nbsp;&nbsp;${element.location}</a>` :  '-';
                    },
                },
                {
                    text: 'Status',
                    label: 'Status',
                    fieldName: 'status',
                    style: { flex: 3 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.status === 'Pending' ? 'In progress' : element.status}</a>`;
                    },
                },
                {
                    text: 'Last Update',
                    label: 'Last Update',
                    fieldName: 'createdAt',
                    style: { flex: 3 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${this.formatDate(element.createdAt)}</a>`;
                    },
                },
            ],
            mergeActionColumns: true,
            actions: [
                {
                    text: 'Delete',
                    label: 'Delete',
                    fieldName: '',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/delete.svg',
                    condition: element => {
                        return element.status === 'Pending';
                    },
                    actionFunction: this.deleteClickedCallback.bind(this),
                },
                {
                    text: 'Download',
                    label: 'Download',
                    fieldName: '',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/download-icon.svg',
                    actionFunction: (_action, element) => {
                        return this.downloadPdf(element);
                    },
                    condition: element => element.status === 'Submitted' && (element.questionnaireType === 'employer_scoping'),
                },
                {
                    text: 'Download',
                    label: 'Download',
                    displayIcon: true,
                    iconValue: 'assets/images/download-icon.svg',
                    fieldName: '',
                    style: { flex: 1 },
                    actionFunction: (_action, element) => {
                        return this.downloadCSV(element);
                    },
                    condition: element => element.status === 'Submitted' && (element.questionnaireType === 'employer_saq' || element.questionnaireType === 'recruitment_saq'),
                },
            ],
            meta: {
                pageSize: this.pageSize,
                rowsNumber: count,
                startAtPage,
            },
            pageChange: ({ pageSize, pageIndex }: PaginateOptions) => {
                this.paginate.emit({ pageIndex, pageSize });
            },
        };
    }

    getUrl(element: IQuestionnaireRes) {
        if(element.questionnaireType === 'employer_saq') {
            const reponseId = element.id;
            const url = element.status == 'Submitted' ? this.employerDashboard ? `dashboard/evaluation/${reponseId}` : `employers/evaluation/${reponseId}` : 'dashboard/self-assessment-questionnaire';
            return url;
        }else if(element.questionnaireType === ('employer_scoping' || 'employer_pra_scoping')) {
            const reponseId = element.id;
            const url = element.status == 'Submitted' ? this.employerDashboard ? `dashboard/employers/${element.userId}/scoping/${reponseId}` : `employers/${element.userId}/scoping/${reponseId}` : 'dashboard/scoping-questions';
            return url;
        }

    }

    formatDate(date: string) {
        return format(new Date(date), 'dd/MM/yyyy hh:mm a');
    }

    sortChangeValue(sort: Sort) {
        this.sortChange.emit(sort);
    }

    selectedPageAtValue(index: number) {
        this.paginate.emit({ pageIndex: index, pageSize: this.pageSize });
    }

    alwaysDisplay() {
        return true;
    }

    viewClickedCallback(_event: Event, element: IQuestionnaireRes): void {
        this.viewClicked.emit(element);
    }

    deleteClickedCallback(_event: Event, element: IQuestionnaireRes): void {
        this.deleteClicked.emit(element);
    }

    downloadPdf(element: IQuestionnaireRes) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/print/pdf'], {
                queryParams: {
                    scopingQuestionId: element.id,
                    employerId: element.userId,
                    type: DOC_TYPES.SCOPING_REVIEW,
                },
            }),
        );
        window.open(url, '_blank');
    }

    downloadCSV(element: IQuestionnaireRes) {
        this.employerService.downloadSAQAnswerCSV(this.role, element.userId, element.id).subscribe(
            res => {
                this.employerService.openFile(res);
            }
        );
        this.employerService.downloadSAQEvaluationCSV(this.role, element.userId, element.id).subscribe(
            res => {
                this.employerService.openFile(res);
            }
        );
    }
}
