import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared';
import { ForgotPasswordFormComponent, ResetPasswordFormComponent } from './components';
import { ForgotPasswordFormContainer, ResetPasswordFormContainer } from './containers';

const components = [
    ForgotPasswordFormComponent,
    ForgotPasswordFormContainer,
    ResetPasswordFormComponent,
    ResetPasswordFormContainer,
];

@NgModule({
    declarations: components,
    imports: [CommonModule, SharedModule, ReactiveFormsModule, RouterModule, TranslateModule],
    exports: components,
})
export class ResetPasswordLazyModule {}
