<div class="iom-container">
    <form [formGroup]="form" (ngSubmit)="clicked()" novalidate>
        <div class="fillter">
            <div class="group-date">
                <div class="choose-date">
                    <mat-form-field appearance="fill" [class]="fromCalendarOpen ? 'mat-form-field-selected' : ''">
                        <span class="span-inside">Start Date</span>
                        <input
                            readonly
                            matInput
                            [matDatepicker]="picker1"
                            [formControl]="fromDate"
                            [max]="toDate.value ? toDate.value : today"
                            placeholder="Add Date">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker [dateClass]="dateClassFrom" #picker1 [calendarHeaderComponent]="datePickerHeader" (opened)="onOpenFrom()" (closed)="onCloseFrom()"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="choose-date">
                    <mat-form-field appearance="fill" [class]="toCalendarOpen ? 'mat-form-field-selected' : ''">
                        <span class="span-inside">End Date</span>
                        <input
                            readonly
                            matInput
                            [matDatepicker]="picker2"
                            [formControl]="toDate"
                            [max]="today"
                            [min]="fromDate.value"
                            placeholder="Add Date">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker [dateClass]="dateClassTo" #picker2 [calendarHeaderComponent]="datePickerHeader" (opened)="onToFrom()" (closed)="onCloseTo()"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="btn-group">
                <button mat-button type="submit">
                    <!-- <img src="../assets/images/filter_alt.svg" alt="logo" class="image"/> -->
                    <mat-icon class="image">filter_alt</mat-icon>
                     Apply Filters
                </button>
            </div>
        </div>
    </form>
    <app-metrics [matrixRes]="matrixRes$ | async"></app-metrics>
</div>
