import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/services';
import * as myGlobals from '../../../globals';
import { MatDialog } from '@angular/material/dialog';
import { AcceptLinkRequestDialog } from '../dialogs/accept-link-request';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployerService } from '../../employer/services';
import { NotificationResult } from '../../auth';
import { NotificationType } from '../types';
import { BreadcrumbService } from '../../core';
import { ConfirmationDialog } from '../dialogs';
@Component({
    selector: 'notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
    isLogin$: Observable<boolean>;
    role$: Observable<string>;
    role: string;
    @Input() menuTitle: string = '';
    pageSize = 50;
    numberOfNewNoti$ = this.authService.numberOfNewNoti$;
    notificationResult$ = this.authService.menuNotification$;
    paginateOpt = { pageIndex: 0, pageSize: this.pageSize }
    dialogRef: any;
    hotelName = '';
    private unsubscribe$ = new Subject();
    error$ = this.employerService.error$;
    notificationResult: NotificationResult;

    constructor(
        private authService: AuthService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private matDialog: MatDialog,
        private employerService: EmployerService,
        private breadCrumbService: BreadcrumbService,
        ) { }

    ngOnInit(): void {
        this.isLogin$ = this.authService.isLogin$;

        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );

        this.role$.subscribe(value => {
            this.role = value;
        });

        if(this.role === 'brand'){
            this.hotelName = "Hotel";
        }else{
            this.hotelName = "Hotel Group";
        }

        this.isLogin$.subscribe(y => {
            if (y && (this.role === 'brand' || this.role === 'employer')) {
                this.authService.getNotification(this.paginateOpt).subscribe(result => {
                    if (result) {
                        this.notificationResult = result;
                    }
                });
                this.authService.getNumberOfNewNotification().subscribe();
            }
        });

        this.error$.subscribe((_res) => {this.spinner.hide()});

    }

    redirectNotification(notiId: string, type: NotificationType, employerId: string, scopingId?: any, data?: any, sender?: any) {
        this.menuTrigger.closeMenu();
        this.authService.markItemReadNotification(notiId).subscribe(() => {
            this.authService.getNotification(this.paginateOpt).subscribe();
        });
        if (data && data?.removed) {
            const confirmDialogRef = this.matDialog.open(ConfirmationDialog, {
                width: '400px',
                data: {
                    title: 'Page not found',
                    message: 'This link is no longer available',
                    closeButtonText: 'Close',
                },
            });

            confirmDialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe();
            return;
        }
        let responseRequest = false;
        switch(type) {
            case 'onboarding':
                break;
            case 'hotel_request_link_to_hotel_group':
                // let isAccept = false;
                if (data && sender) {
                    this.dialogRef = this.matDialog.open(AcceptLinkRequestDialog,{
                        width: '58.5rem',
                        height: '44.438rem',
                        data: {
                            title:  `${this.hotelName} Link Request`,
                            message: `By accepting this link, ${data?.hotel?.companyName} will provide access to their scoping questions and questionnaire responses. You will also obtain access to the questionnaire responses provided by the recruitment agencies that you have invited to your account.`,
                            companyProfile: data?.hotel,
                            hideCancelButton: false,
                            roleUser: type,
                            email: sender?.email,
                            color: 'warn',
                            responsed: data.response,
                        },
                    });

                    this.dialogRef
                        .afterClosed()
                        .pipe(
                            takeUntil(this.unsubscribe$),
                            switchMap((res:any) => {
                                if (res !== undefined) {
                                    if (res === true) {
                                        // isAccept = true;
                                        const responseRequestLink = {
                                            notificationId: notiId,
                                            responseType: 'accept'
                                        };
                                        const id = data?.brand ? data?.brand.id : (data?.hotel ? data?.hotel.id : '');
                                        this.spinner.show();
                                        return this.employerService.acceptRequestHotelOrHotelGroup(this.role,id,responseRequestLink);
                                    }
                                    else {
                                        const responseRequestLink = {
                                            notificationId: notiId,
                                            responseType: 'reject'
                                        };
                                        const id = data?.brand ? data?.brand.id : (data?.hotel ? data?.hotel.id : '');
                                        this.spinner.show();
                                        return this.employerService.acceptRequestHotelOrHotelGroup(this.role,id,responseRequestLink);
                                    }
                                }
                                return of(undefined)
                            })
                        )
                        .subscribe(() => {
                            this.spinner.hide();
                            // if (data && data?.hotel && data?.hotel?.id && this.role === 'brand' && isAccept) {
                            //     responseRequest = data?.response ? data?.response : responseRequest;
                            //     this.router.navigate(['my-account', 'link-to-hotel',  data?.hotel?.id, 'response', responseRequest])
                            // }
                        });
                }
                break;
            case 'hotel_group_request_link_to_hotel':
                if (data && sender) {
                    this.dialogRef = this.matDialog.open(AcceptLinkRequestDialog,{
                        width: '58.5rem',
                        height: '44.438rem',
                        data: {
                            title:  `${this.hotelName} Link Request`,
                            message: `By accepting this link, you are agreeing to provide ${data?.brand?.name} with access to your scoping questions and questionnaire responses.  You are also agreeing to provide them access to the questionnaire responses provided by the recruitment agencies that you have invited to your account.`,
                            companyProfile: data?.brand,
                            hideCancelButton: false,
                            roleUser: type,
                            email: sender?.email,
                            color: 'warn',
                            responsed: data.response,
                        },
                    });

                    this.dialogRef
                        .afterClosed()
                        .pipe(
                            takeUntil(this.unsubscribe$),
                            switchMap((res:any) => {
                                if (res !== undefined) {
                                    if (res === true) {
                                        const notiObj = {
                                            notificationId: notiId,
                                            responseType: 'accept'
                                        };
                                        const id = data?.brand ? data?.brand.id : (data?.hotel ? data?.hotel.id : '');
                                        this.spinner.show();
                                        return this.employerService.acceptRequestHotelOrHotelGroup(this.role,id,notiObj);
                                    }
                                    else {
                                        const responseRequestLink = {
                                            notificationId: notiId,
                                            responseType: 'reject'
                                        };
                                        const id = data?.brand ? data?.brand.id : (data?.hotel ? data?.hotel.id : '');
                                        this.spinner.show();
                                        return this.employerService.acceptRequestHotelOrHotelGroup(this.role,id,responseRequestLink);
                                    }
                                }
                                return of(undefined)
                            })
                        )
                        .subscribe(() => {
                            this.spinner.hide();
                        });
                }
                break;
            case 'employer_scoping_submitted':
                this.setupBreadCrumb();
                if (scopingId && employerId) {
                    this.router.navigate(['dashboard', employerId, 'scoping', scopingId]);
                    myGlobals.paramSub$.next(scopingId);
                }
                break;
            case 'hotel_accept_request_link_to_hotel_group':
            case 'myself_accept_request_link_to_hotel':
                if (data && data?.hotel && data?.hotel?.id && this.role === 'brand') {
                    responseRequest = data?.response ? data?.response : responseRequest;
                    this.router.navigate(['complete-onboarding', 'link-to-hotel',  data?.hotel?.id, 'response', responseRequest])
                }
                break;
            default:
                this.setupBreadCrumb();
                this.router.navigateByUrl(`dashboard/evaluation/${scopingId}`)
                break;
        }
    }

    setupBreadCrumb() {
        this.breadCrumbService.clearBreadCrumbItems();
        let breadcrumbItems = [{name: 'Dashboard', path: '/dashboard'}]
        this.breadCrumbService.setBreadCrumbItems(breadcrumbItems);
    }

    prevent (event: any) {
        event.preventDefault();
        event.stopPropagation();
        event.preventDefault();
    }

    menuOpened() {
        myGlobals.openNotificationSub$.next(true)
    }

    menuClosed() {
        myGlobals.openNotificationSub$.next(false)
        if (this.notificationResult && this.notificationResult.notifications && this.notificationResult.notifications.length > 0) {
            this.notificationResult.notifications.map(notification => {
                if ((notification.type === 'onboarding' ||
                    notification.type === 'hotel_reject_request_link_to_hotel_group' ||
                    notification.type === 'hotel_group_reject_request_link_to_hotel' ||
                    notification.type === 'myself_accept_request_link_to_hotel_group' ||
                    notification.type === 'hotel_group_accept_request_link_to_hotel') && !notification.isRead) {
                    this.authService.markItemReadNotification(notification.id.toString()).subscribe(() => {
                        this.authService.getNotification(this.paginateOpt).subscribe();
                    });
                }
            })
        }
    }
}
