<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <ng-container>
        <mat-card class="mat-elevation-z-p-r6" [ngClass]="'mat-elevation-z0'">
            <mat-card-header>
                <mat-card-title class="page-section-title"> Hotel {{name}} </mat-card-title>
            </mat-card-header>
            <mat-card-content class="content-container">
                <div class="form-holder">
                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
                </div>
                <div style="display: flex; justify-content: center; padding: 2rem">
                </div>
            </mat-card-content>
        </mat-card>

        <hr class="bottom-line" />
    </ng-container>
</form>
