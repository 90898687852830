import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialog } from './confirmation.dialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ConfirmationDialog],
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, TranslateModule],
    exports: [ConfirmationDialog],
})
export class ConfirmationDialogModule {}
