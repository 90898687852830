import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { FieldType } from '@ngx-formly/core';
import * as myGlobals from '../../../../globals';

@Component({
    selector: 'app-single-select-input',
    templateUrl: './single-select-input.component.html',
    styleUrls: ['./single-select-input.component.scss'],
})
export class SingleSelectInputComponent extends FieldType implements OnInit {
    defaultOptions = {
        templateOptions: {
            options: [],
            compareWith(o1: any, o2: any) {
                return o1 === o2;
            },
        },
    };

    ngOnInit() {
        if (this.field.formControl?.value) {
            this.formControl.markAsTouched();
        } else {
            this.formControl.markAsUntouched();
        }
    }

    change($event: MatSelectChange) {
        this.to.change?.(this.field, $event);
        myGlobals.valueChangeSub$.next({questionId: this.field.key, value: this.field.formControl?.value});
    }
}
