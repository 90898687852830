import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-question-group-label',
    templateUrl: './question-group-label.component.html',
    styleUrls: ['./question-group-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionGroupLabelComponent extends FieldType {}
