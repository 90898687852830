<div class="brand-register">
    <div class="brand-register__wrap">
        <h2>Welcome to LMPM!</h2>
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti nihil dignissimos necessitatibus a, hic
            nostrum perspiciatis recusandae! Ducimus expedita culpa voluptas ratione natus sequi reprehenderit, corporis
            molestias dolorem, odit perspiciatis.
        </p>
    </div>
    <div class="container-register">
        <form [formGroup]="formregister" (ngSubmit)="clicked()" novalidate>
            <mat-card class="mat-elevation-z0">
                <mat-card-content class="content-container">
                    <div class="brand-register__list">
                        <div class="brand-register__item">
                            <mat-label>Full Name:</mat-label>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="username" placeholder="Full Name" />
                                <mat-error *ngIf="username.errors && username.errors['required']"
                                    >Please input your full name.</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <div class="brand-register__item">
                            <mat-label>Email:</mat-label>
                            <mat-form-field appearance="standard">
                                <input
                                    matInput
                                    type="email"
                                    formControlName="email"
                                    [attr.aria-label]="'Email'"
                                    required
                                    placeholder="Enter Email"
                                    autocomplete="off"
                                    [errorStateMatcher]="matcher"
                                />
                                <mat-error *ngIf="email.errors && email.errors['required']"
                                    >Please input your email.
                                </mat-error>
                                <mat-error *ngIf="email.errors && email.errors['email']"
                                    >Please input a valid email.
                                </mat-error>
                                <mat-error *ngIf="email.errors && email.errors['taken']"
                                    >This email already exists.</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <div class="brand-register__item">
                            <mat-label>Password:</mat-label>
                            <mat-form-field appearance="standard">
                                <input
                                    matInput
                                    [type]="hide ? 'password' : 'text'"
                                    formControlName="password"
                                    [attr.aria-label]="'Password'"
                                    required
                                    placeholder="Enter Password"
                                    autocomplete="off"
                                    (ngModelChange)="passwordChanged($event)"
                                />
                                <button
                                    mat-icon-button
                                    type="button"
                                    matSuffix
                                    (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="hide"
                                >
                                    <mat-icon class="btn-hide">{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error *ngIf="password.errors && password.errors['required']"
                                    >Please input your password.
                                </mat-error>
                                <mat-error *ngIf="password.errors && password.errors['pattern']"
                                    >Please input a valid password.
                                </mat-error>
                            </mat-form-field>
                            <mat-icon class="password-criteria" [matTooltip]="tooltipList" matSuffix>info </mat-icon>
                        </div>
                        <div class="brand-register__item">
                            <mat-label>Re-enter Password:</mat-label>
                            <mat-form-field appearance="standard">
                                <input
                                    matInput
                                    [attr.aria-label]="'Password'"
                                    placeholder="Re-enter Password"
                                    formControlName="confirmPassword"
                                    autocomplete="off"
                                    [type]="hideConfirmPassword ? 'password' : 'text'"
                                    required
                                />
                                <button
                                    mat-icon-button
                                    type="button"
                                    matSuffix
                                    (click)="hideConfirmPassword = !hideConfirmPassword"
                                    [attr.aria-label]="'Hide confirm password'"
                                    [attr.aria-pressed]="hideConfirmPassword"
                                >
                                    <mat-icon>{{ hideConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error *ngIf="confirmPassword.errors && confirmPassword.errors['required']">
                                    Please input your re-enter password.
                                </mat-error>
                                <mat-error *ngIf="confirmPassword.value && hasError(confirmPassword, 'passwordNotMatch')">
                                    Re-enter password doesn't match with New Password.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="brand-register__item">
                            <mat-label>Hotel group name:</mat-label>
                            <mat-form-field appearance="standard">
                                <input matInput formControlName="brandname" placeholder="Hotel group name" />
                                <mat-error *ngIf="brandname.errors && brandname.errors['required']"
                                    >Please input your Hotel group name.</mat-error
                                >
                                <mat-error *ngIf="brandname.errors && brandname.errors['taken']"
                                    >This company is already existed.</mat-error
                                >
                            </mat-form-field>
                        </div>
                        <div class="agree-btn">
                            <mat-checkbox formControlName="confirmIomTermsAndConditions" class="example-margin"
                                >I agree to&nbsp;
                            </mat-checkbox>
                            <span (click)="openDialog()" style="cursor: pointer">Terms and Conditions</span>
                        </div>
                        <button
                            type="submit"
                            mat-button
                            color="primary"
                            aria-label="register"
                            [disabled]="formregister.invalid || confirmIomTermsAndConditions.value != true"
                            class="btn-register"
                        >
                            Register
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </form>
    </div>
</div>
