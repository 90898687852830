import { Section } from '../question-form';

export interface ISection {
    id: string;
    text: string;
    order: number;
    isSelected?: boolean;
    isCompleted?: boolean;
    isCompletedForward?: boolean;
    numOfRequiredQuestions: number;
    numOfQuestions?: number;
    numOfAnswered?: number;
    isShowDelete?: boolean;
}

export interface ISurveyPartial {
    id: string;
    label: string;
    content: Section | Section[];
    canDelete?: boolean;
}

export function isSection(object: Section | Section[]): object is Section {
    return 'id' in object;
}
