<form [formGroup]="form" (ngSubmit)="clicked()" novalidate>
    <mat-card class="mat-elevation-z0" style="text-align: center">
        <mat-card-content class="content-container">
            <div class="login-wrapper">
                <div class="left-login login-box-item">
                    <div class="info">
                        <h2 class="title">Welcome</h2>
                        <span>Don't have an account?</span>
                        <span>Sign up below!</span>
                    </div>
                    <div class="sign-up-btn">
                        <button
                            type="button"
                            mat-button
                            color="#fff"
                            (click)="navigateSignup()"
                        >
                            Sign up
                        </button>
                    </div>
                </div>
                <div class="right-login login-box-item">
                    <div class="logo">
                        <img src="../assets/images/lmpm-logo.png" alt="logo" />
                    </div>
                    <div class="login-box">
                        <div class="input-container">
                            <div class="form-field-label">Account</div>
                            <div class="item-group">
                                <mat-form-field appearance="outline" class="item-password">
                                    <input
                                        matInput
                                        [attr.aria-label]="'email'"
                                        placeholder="Email"
                                        formControlName="email"
                                        autocomplete="off"
                                        type="text"
                                        required
                                        (click)="checkError = true"
                                        autocomplete="doNotAutoComplete"
                                        [errorStateMatcher]="matcher"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="input-container">
                            <div class="form-field-label">Password <img src="../assets/images/info.png" class="password-criteria" [matTooltip]="tooltipList" alt="password"/></div>
                            <div class="item-group">
                                <mat-form-field appearance="outline" class="item-password">
                                    <input
                                        matInput
                                        [attr.aria-label]="'Password'"
                                        placeholder="Password"
                                        formControlName="password"
                                        autocomplete="off"
                                        [type]="hide ? 'password' : 'text'"
                                        required
                                        (click)="checkError = true"
                                        autocomplete="doNotAutoComplete"
                                    />
                                    <button
                                        mat-icon-button
                                        type="button"
                                        matSuffix
                                        (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'"
                                        [attr.aria-pressed]="hide"
                                    >
                                        <mat-icon>{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>
                        <button
                            type="submit"
                            mat-button
                            aria-label="login"
                            [attr.aria-label]="'Login'"
                            class="login-btn"
                            [class]="form.touched && form.invalid ? '' : 'btn-active'"
                            [disabled]="form.invalid"
                        >
                            Log in
                        </button>
                        <div class="error-box">
                            <app-error-box *ngIf="form.valid" [errorMessages]="errorMessages" class="error-mess-box"></app-error-box>
                            <div *ngIf="checkError">
                                <mat-error *ngIf="form.invalid">
                                    Email or password is invalid!
                                </mat-error>
                            </div>
                        </div>
                        <button mat-button class="mat-underline forgot-btn" type="button" (click)="forgetPasswordClicked.emit()">
                            Forgot Password?
                        </button>
                    </div>
                    <div class="list-logo">
                        <div>
                            <p class="title-list-logo">A collaboration between</p>
                        </div>
                        <div style="width: 100%; height: calc(100% - 16px)">
                            <div class="sub-class-logos">
                                <div style="display: flex; justify-content: flex-end; align-items: center;" class="logo-1">
                                    <img style="width: 32px; height: 37px; margin-right: 25px;" src="../assets/images/global-fund-logo.png" alt="logo" />
                                </div>
                                <div style="display: flex; justify-content: center; align-items: center;" class="logo-1">
                                    <img style="width: 70px; height: 37px;" src="../assets/images/us-flag.png" alt="logo" />
                                </div>
                                <div style="display: flex; justify-content: flex-start; align-items: center;" class="logo-1">
                                    <img style="width: 93px; height: 46px;" src="../assets/images/IOM-logo.png" alt="logo" />
                                </div>
                            </div>
                            <div class="sub-class-logos">
                                <div style="display: flex; justify-content: flex-end; align-items: center;" class="logo-2">
                                    <img style="width: 93px; height: 19px; margin-right: 7px;" src="../assets/images/dgx-logo.png" alt="logo" />
                                </div>
                                <div style="display: flex; justify-content: flex-start; align-items: center;" class="logo-2">
                                    <img style="width: 93px; height: 40px; margin-left: 7px;" src="../assets/images/sha-logo.png" alt="logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</form>
