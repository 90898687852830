import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { PaginateOptions } from 'src/app/modules';
import { DataTableConfig } from 'src/app/modules/shared';
import { IHistory, IQuestionnaireRes } from 'src/app/modules/types';
import { Router } from '@angular/router';
import { DOC_TYPES } from 'src/app/modules/print/types';
import { CountryFlagPipe } from 'src/app/modules/shared/pipes/country-flag.pipe';
import { DocumentNamePipe } from 'src/app/modules/shared/pipes/document-name.pipe';
import format from 'date-fns/format';
@Component({
    selector: 'employer-history',
    templateUrl: './employer-history.component.html',
    styleUrls: ['./employer-history.component.scss'],
})
export class EmployerHistoryComponent implements OnInit, AfterViewInit, OnChanges {
    @Input()
    employerHistoryCollection: IHistory;

    @Input()
    pageSize = 5;

    @Input()
    currentEmployerId = '';

    @Input()
    errorMessages: string[];

    @Output()
    paginate = new EventEmitter<PaginateOptions>();

    @Output()
    filterUser = new EventEmitter<string>();

    @Output()
    sortChange = new EventEmitter<Sort>();

    @Output()
    downloadCSV = new EventEmitter<IQuestionnaireRes>()

    nextClicked$ = new Subject();
    unsubscribe$ = new Subject();
    config: DataTableConfig<IQuestionnaireRes>;

    constructor(private router: Router) {}

    ngOnInit() {}

    ngAfterViewInit() {
        const lstHistory = this.employerHistoryCollection?.histories || {};
        const historyCount = this.employerHistoryCollection?.total || 0;
        this.initDatatable(lstHistory, historyCount, this.employerHistoryCollection.startAtPage);
    }

    ngOnChanges(changes: SimpleChanges) {
        const { employerHistoryCollection = null } = changes || {};
        const { currentValue = null } = employerHistoryCollection || {};
        const { histories = null, total = 0 } = currentValue || {};
        this.initDatatable(histories, total || 0, this.employerHistoryCollection.startAtPage);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initDatatable(data: IQuestionnaireRes[], count: number, startAtPage?: { pageIndex: number }) {
        if (!data) {
            return;
        }

        this.config = {
            data,
            tableName: 'employer-history',
            hideFilter: true,
            filterKeys: ['hotelName', 'username', 'email', 'lastLoginTime', 'status', 'country'],
            columns: [
                {
                    text: 'Document',
                    label: 'Document',
                    fieldName: 'label',
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        const documentNamePipe = new DocumentNamePipe();
                        const value = element.label ||  documentNamePipe.transform2(element.questionnaireType);
                        return element.status !== 'Pending' ? `<a href="${this.getUrl(element)}" class='document-list-item'>${value}</a>`
                                                            : `${value}`;
                    },
                },
                {
                    text: 'Location',
                    label: 'Location',
                    fieldName: 'location',
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        const pipe = new CountryFlagPipe();
                        const flag = pipe.transform(element.location);
                        return element.status !== 'Pending' ? `<a href="${this.getUrl(element)}" class='document-list-item'>${flag}&nbsp;&nbsp;${element.location}</a>`
                                                            : (element.location ? `${flag}&nbsp;&nbsp;${element.location}` : '-');
                    },
                },
                {
                    text: 'Status',
                    label: 'scopping status',
                    fieldName: 'status',
                    valueFunction: element => {
                        return element.status;
                    },
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        return element.status !== 'Pending' ? `<a href="${this.getUrl(element)}" class='document-list-item'>${element.status === 'Pending' ? 'In progress' : element.status}</a>`
                                                            : (element.status === 'Pending' ? 'In progress' : element.status);
                    },
                },
                {
                    text: 'Last Update',
                    label: 'last update',
                    fieldName: 'lastLoginTime',
                    valueFunction: element => {
                        return element.createdAt || 'N/A';
                    },
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        return element.status !== 'Pending' ? `<a href="${this.getUrl(element)}" class='document-list-item'>${element.createdAt || 'N/A'}</a>`
                                                            : `${element.createdAt || 'N/A'}`;
                    },
                },
            ],
            mergeActionColumns: true,
            actions: [
                {
                    text: 'Download',
                    label: 'Download',
                    fieldName: '',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/download-icon.svg',
                    actionFunction: (_action, element) => {
                        return this.downloadPdf(element);
                    },
                    condition: element => element.status === 'Submitted' && element.questionnaireType === 'employer_scoping',
                },
                {
                    text: 'Download',
                    label: 'Download',
                    fieldName: '',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/download-icon.svg',
                    actionFunction: (_action, element) => {
                        return this.downloadCSVFnc(element);
                    },
                    condition: element =>
                    element.status === 'Submitted' && (element.questionnaireType === 'employer_saq' || element.questionnaireType === 'recruitment_saq'),
                },
            ],
            meta: {
                pageSize: this.pageSize,
                rowsNumber: count,
                startAtPage,
            },
            pageChange: ({ pageSize, pageIndex }: PaginateOptions) => {
                this.paginate.emit({ pageIndex, pageSize });
            },
        };
    }

    getUrl(element: IQuestionnaireRes) {
        if(element.questionnaireType === 'employer_saq' || element.questionnaireType === 'recruitment_saq') {
            const reponseId = element.id;
            const url = element.status == 'Submitted' ? `employers/evaluation/${reponseId}` : 'dashboard/self-assessment-questionnaire';
            return url;
        }else if(element.questionnaireType === ('employer_scoping' || 'employer_pra_scoping')) {
            const reponseId = element.id;
            const url = element.status == 'Submitted' ? `employers/${element.userId}/scoping/${reponseId}` : 'dashboard/scoping-questions';
            return url;
        }
    }

    filterChangeValue(filterValue: string) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.filterUser.emit(filterValue.trim());
    }

    sortChangeValue(sort: Sort) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.sortChange.emit(sort);
    }

    selectedPageAtValue(index: number) {
        this.paginate.emit({ pageIndex: index, pageSize: this.pageSize });
    }

    formatDate(date: string) {
        return format(new Date(date), 'dd/MM/yyyy hh:mm a');
    }

    // navigateToScoppingQuestion(scoppingId: string) {
    //     this.router.navigate(['employers', this.currentEmployerId, 'scoping', scoppingId]);
    // }

    navigateToSAQQuestion(saqId: string) {
        this.router.navigateByUrl(`employers/evaluation/${saqId}`);
    }

    downloadPdf(element: IQuestionnaireRes) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/print/pdf'], {
                queryParams: {
                    scopingQuestionId: element.id,
                    employerId: element.userId,
                    type: DOC_TYPES.SCOPING_REVIEW,
                },
            }),
        );
        window.open(url, '_blank');
    }

    downloadCSVFnc(element: IQuestionnaireRes) {
        this.downloadCSV.emit(element);
    }
}
