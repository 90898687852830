import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ApiConstant } from '../types';
import { MaterialModule } from '../shared/material.module';
import { QuestionFormComponent } from './question-form.component';
import { QuestionComponent } from './single-question/question.component';
import { QUESTIONFORM_API_TOKEN } from './types';
import { FileUploadService } from './services';

@NgModule({
    declarations: [QuestionFormComponent, QuestionComponent],
    imports: [MaterialModule, FormsModule, ReactiveFormsModule, NgMultiSelectDropDownModule],
    exports: [QuestionFormComponent, QuestionComponent, ReactiveFormsModule],
})
export class QuestionFormModule {
    static forRoot(apiConstant: ApiConstant): ModuleWithProviders<QuestionFormModule> {
        return {
            ngModule: QuestionFormModule,
            providers: [
                {
                    provide: QUESTIONFORM_API_TOKEN,
                    useValue: apiConstant,
                },
                FileUploadService,
            ],
        };
    }
}
