import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules/core';
import { CommonLayoutData, DEFAULT_COMMON_LAYOUT_DATA } from '../types';

@Component({
    selector: 'app-sign-up-layout',
    templateUrl: './sign-up-layout.component.html',
    styleUrls: ['./sign-up-layout.component.scss'],
})
export class SignUpLayoutComponent {
    layoutData: CommonLayoutData = DEFAULT_COMMON_LAYOUT_DATA;
    constructor(private route: ActivatedRoute, private breadcrumbService: BreadcrumbService, private routerApp: Router,) {
        this.layoutData = Object.assign({}, this.layoutData, {
            pageTitle: this.route.snapshot.data['pageTitle'],
            pageDes: this.route.snapshot.data['pageDes'],
            enableInvite: this.route.snapshot.data['enableInvite'],
            enableRequestLink: this.route.snapshot.data['enableRequestLink'],
            breadcrumb: this.route.snapshot.data['breadcrumb'],
        });
    }

    actions$ = this.breadcrumbService.actions$;
    breadcrumbItems$ = this.breadcrumbService.breadcrumbItems$;
    hasAsyncBreadcrumb$ = this.breadcrumbService.breadcrumbItems$.pipe(
        switchMap(items => {
            return of(items && items.length);
        }),
    );

    inviteHotel() {
        this.routerApp.navigate(['/employers/registration']);
    }
}
