<div [class]="pdfPreview ? 'badge-widget-pdf' : 'badge-widget'">
    <div class="badge-widget__heading">{{ options.title }}</div>
    <div class="badge-widget__content">
        <div *ngIf="options.lstHtml; else diff" class="lst-country">
            <div *ngFor="let item of options.lstHtml" class="item">
                <div class="total">{{ item.value }}</div>
                <div class="name" *ngIf="item?.description">{{ item?.description }}</div>
                <div class="name" *ngIf="item.htmlTemplate && item.countryCode === undefined" [innerHTML]="item.htmlTemplate">item.htmlTemplate</div>
                <div class="name" *ngIf="item.htmlTemplate && item.countryCode">
                    from <img src="{{item.countryCode}}.svg" class="img-country" alt="country"/> {{item.countryName}}
                </div>
            </div>
        </div>
       <ng-template #diff>
            <div class="total">{{ options.value }}</div>
            <div class="name" *ngIf="options.description">{{ options.description }}</div>
            <div class="name" *ngIf="options.htmlTemplate" [innerHTML]="options.htmlTemplate"></div>
       </ng-template>
    </div>
</div>
