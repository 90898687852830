import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/modules';
import { ConfirmationDialog, ConfirmCancelDialog } from 'src/app/modules/shared';
import { IQuestionnaireRes, PaginateOptions } from 'src/app/modules/types';
import { PraService } from '../../services';

@Component({
    selector: 'app-pra-dashboard',
    templateUrl: './pra-dashboard.component.html',
    styleUrls: ['./pra-dashboard.component.scss'],
})
export class PraDashboardComponent implements OnInit, OnDestroy {
    //praEmployerCollection$ = this.employerService.praEmployer$;
    pageSize = 5;
    employerId = '';
    lstHotelIds$ = new BehaviorSubject<string[]>([]);
    paginatePras$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });

    private unsubscribe$ = new Subject();

    document$ = this.praService.document$;
    pageIndex$ = this.praService.pagination$.pipe(
        switchMap((paginationOpts: PaginateOptions) => {
            return of(paginationOpts.pageIndex);
        })
    );
    total$ = this.praService.total$;

    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private praService: PraService,
        private dialog: MatDialog,
        private breadCrumbService: BreadcrumbService,
    ) {}

    ngOnInit(): void {
        let breadcrumbItems = [{name: 'Dashboard', path: '/dashboard'}]
        this.breadCrumbService.setBreadCrumbItemsFirstPage(breadcrumbItems);

        this.document$.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
            let lstHotelIds: string[] = [];
            if (x.length > 0) {
                x.forEach(x => {
                    if (x.status === 'Pending') {
                        lstHotelIds.push(x.hotelId || '')
                    }
                })
            }
            this.lstHotelIds$.next(lstHotelIds);
        })
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    goToDocument(document: IQuestionnaireRes) {
        switch (document.questionnaireType) {
            case 'employer_pra_scoping':
                this.router.navigate(['employers', 'pra', document.id]);
                break;
            case 'employer_scoping':
                this.router.navigate(['employers', document.userId , 'scoping', document.id]);
                break;
            case 'employer_saq':
                    this.router.navigate(['employers', document.userId , 'scoping', document.id]);
                    break;
            case 'employer_registration_form':
            default:
                break;
        }
    }

    redirectScopingQuestion() {
        this.router.navigate(['dashboard/scoping-questions']);
    }

    redirectSelfAssessmentQuestionnaire() {
        this.router.navigate(['dashboard/self-assessment-questionnaire']);
    }

    docListPaginate(paginateOpts: PaginateOptions) {
        this.praService.updatePagination(paginateOpts);
    }

    docListSortChange(sort: Sort) {
        this.praService.updateSort(sort);
    }

    deleteScopingPending(document: IQuestionnaireRes) {
        let subTitle = 'Are you sure you want to delete?'
        let messageSuccess = 'Deleted successfully.'
        let message = 'It will remove all answers that you saved previously.'
        let title = 'Delete';
        if (document.questionnaireType === 'employer_scoping') {
            subTitle = 'Are you sure you want to delete Scoping questions?'
            messageSuccess = 'Your Scoping questions has been deleted successfully.'
            message = 'Delete Scoping questions will remove all answers that you saved previously.'
            title = 'Delete Scoping questions'
        }
        if (document.questionnaireType === 'employer_saq') {
            subTitle = 'Are you sure you want to delete Self-assessment questionnaire?'
            messageSuccess = 'Your Self-assessment questionnaire has been deleted successfully.'
            message = 'Delete Self-assessment questionnaire will remove all answers that you saved previously.'
            title = 'Delete Self-assessment questionnaire'
        }
        if (document.questionnaireType === 'recruitment_saq') {
            subTitle = 'Are you sure you want to delete this Self Assessment questionnaire?'
            messageSuccess = 'Your Self-assessment questionnaire has been deleted successfully.'
            message = 'You will lost all answers saved in this Self Assessment questionnaire.'
            title = 'Delete Self Assessment questionnaire'
        }
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: title,
                subTitle: subTitle,
                message: message,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.praService.deleteQuestionnaireDraft(document.id);
                    }
                    return of(undefined)
                }))
            .subscribe(res => {
                if (res) {
                    this.spinner.hide();
                    this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: ``,
                            message: messageSuccess,
                            closeButtonText: 'Close',
                        },
                    });
                }
            });
    }

    downloadAnswerRa(element: IQuestionnaireRes) {
        this.praService.downloadAnswerSAQCSV(element.id).subscribe(
            res => {
                this.praService.openFile(res);
            }
        );
        // this.praService.downloadEvaluationSAQCSV(element.id).subscribe(
        //     res => {
        //         this.praService.openFile(res);
        //     }
        // );
    }
}
