import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ClassificationDialog } from './classification-of-indicators';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [ClassificationDialog],
    imports: [CommonModule, MatDialogModule, TranslateModule, MatIconModule],
    exports: [ClassificationDialog],
})
export class ClassificationDialogModule {}
