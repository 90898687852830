import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TermDialogComponent } from './term-dialog/term-dialog.component';
import { TermDialogContentComponent } from './term-dialog-content/term-dialog-content.component';

@NgModule({
    declarations: [TermDialogComponent, TermDialogContentComponent],
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatCardModule, MatIconModule, MatCheckboxModule],
    exports: [TermDialogComponent, TermDialogContentComponent],
})
export class TermsAndConditionsDialogModule {}
