import { InjectionToken } from '@angular/core';
import { Role } from '../core';
import { ApiConstant, UserStatus } from '../types';

export const API_TOKEN = new InjectionToken<ApiConstant>('api.constant');

export interface AuthenticateInfo {
    email: string;
    password: string;
    currentLanguageCode?: string;
    isPersisMission?: boolean;
}

export interface TokenPayload {
    id: string;
    email: string;
    role: Role;
    roleId?: string;
    iat: number;
    exp: number;
    fullName: string;
    lastLoginTime: string;
    status: UserStatus;
    countryId?: string;
    companyName?: string;
    brandId?: string;
}
export interface NotificationResult {
    notifications: [Notification];
    total: number;
}

export interface Notification {
    id: number;
    type: string;
    isRead: boolean;
    sender: {
        id: string;
        fullName: string;
        email: string;
    };
    createdAt: string;
    data?: {
        questionnaireResponseId: string;
    }
}
