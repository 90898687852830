import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListWidgetComponent } from './list-widget/list-widget.component';
import { GridWidgetComponent } from './grid-widget/grid-widget.component';
import { BadgeWidgetComponent } from './badge-widget/badge-widget.component';

@NgModule({
    declarations: [ListWidgetComponent, GridWidgetComponent, BadgeWidgetComponent],
    imports: [CommonModule],
    exports: [ListWidgetComponent, GridWidgetComponent, BadgeWidgetComponent],
})
export class WidgetsModule {}
