import { AbstractControl } from '@angular/forms';

export function QuantityValidator(control: AbstractControl) {
    if (control.value?.toLocaleString() === '-0') {
        return {
            quantity: {
                message: 'Please input valid answer',
            },
        };
    }
    if (!control.value) return null;
    const reg = new RegExp(/^[0-9]+$/);
    return reg.test(control.value) && Number(control.value) >= 0
        ? null
        : {
              quantity: {
                  message: 'Please input valid answer',
              },
          };
}
