import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject } from 'rxjs';
import { AttachmentDownloadResponse } from '../types';
import { FileUploadService } from '../services';
import { Question } from '../types';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent implements OnInit, AfterViewInit {
    @Input() questions!: Question[];
    @Input() form!: FormGroup;
    @Input() question!: Question;
    @Input() enableReadOnlyMode: boolean;
    @ViewChild('fileInput') fileInput: ElementRef<any>;
    dropdownSettings: IDropdownSettings = {};
    questionAnswer: any;
    private errorSub$ = new Subject<string>();
    error$ = this.errorSub$.asObservable();
    uploadedSub$ = new Subject<string>();
    uploaded$ = this.uploadedSub$.asObservable();
    onDisplay: boolean = false;
    valueTotal: number = 0;
    parentTotal: any = {};
    allTotal: number = 0;
    columnWidth: string = '100%';
    selected: string;
    errorMesValidate: string = '';
    isSubAnswerHasError: boolean = false;
    listDate: number[];
    arrayKeyError: string[] = [];
    constructor(private fileUploadService: FileUploadService, private cd: ChangeDetectorRef) {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'key',
            textField: 'key',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            allowSearchFilter: true,
            enableCheckAll: false,
        };
    }
    ngAfterViewInit() {
        this.form.valueChanges.subscribe(() => {
            this.onDisplay = this.getDisplay();
            this.setValueTotal();
            this.cd.detectChanges();
        });
    }
    ngOnInit() {
        this.listDate = this.getListYears();
        this.onDisplay = this.getDisplay();
        switch (this.question.controlType) {
            case 'text-box':
                if (
                    this.question.valueType === 'number' &&
                    (this.question.subAnswer?.needToAnswer || this.question.subAnswer == undefined)
                ) {
                    this.form.controls[this.question.id].setValidators([
                        Validators.required,
                        Validators.pattern('^[0-9]+$'),
                    ]);
                }
                if (this.question.valueType === 'unique-email') {
                    this.form.controls[this.question.id].setValidators([
                        Validators.required,
                        Validators.pattern(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        ),
                    ]);
                }
                break;
            case 'drop-down':
                this.questionAnswer = this.question.value || '';
                break;
            case 'drop-down-multi':
                this.questionAnswer = this.question.value || [];
                break;
            case 'question-group':
                if (typeof this.question.value === 'undefined') {
                    this.questionAnswer = '';
                } else if (typeof this.question.value === 'string') {
                    this.questionAnswer = JSON.parse(this.question.value || '{}') || {};
                } else {
                    this.questionAnswer = this.question.value || {};
                }
                break;
            case 'file-upload':
                this.questionAnswer = this.question.value;
                this.uploadedSub$.next(this.questionAnswer.fileName);
                break;
            default:
                this.questionAnswer = this.question.value || '';
                break;
        }
        this.setValueTotal();
        if (this.enableReadOnlyMode) {
            this.setReadonly()
        }
    }

    setReadonly() {
        if (!this.question.editable) {
            if (this.question.controlType !== 'drop-down' && this.question.controlType !== 'drop-down-date') {
                this.form.controls[this.question.id].disable();
            }
        }
    }

    setValueTotal() {
        if (!this.question?.subAnswer?.needToAnswer) {
            this.allTotal = 0;
            let totalAnswer = this.question?.subAnswer?.answerTitle.length
                ? this.question?.subAnswer?.answerTitle.length
                : 0;
            if (totalAnswer > 0) {
                this.question.width = 100 / (totalAnswer + 1) + '%';
            }
            this.question?.subAnswer?.answerTitle.forEach(x => {
                this.parentTotal[x.key] = 0;
                this.questions
                    .filter(z => z.parentId == this.question.id)
                    .forEach(
                        y =>
                            (this.parentTotal[x.key] += parseInt(
                                this.form.controls[y.id].value[x.key] ? this.form.controls[y.id].value[x.key] : 0,
                            )),
                    );
                this.allTotal += parseInt(this.parentTotal[x.key] ? this.parentTotal[x.key] : 0);
            });
        } else {
            this.valueTotal = 0;
            this.question?.subAnswer?.answerTitle.forEach(x => {
                this.valueTotal += parseInt(this.questionAnswer[x.key] ? this.questionAnswer[x.key] : 0);
            });
        }
    }

    get isValid() {
        if (this.isSubAnswerHasError) {
            this.errorMesValidate = 'Please input the valid data';
        } else {
            this.errorMesValidate = 'Please input your answer';
        }
        const matchPattern =
            this.form.controls[this.question.id].errors && this.form.controls[this.question.id].errors?.pattern;
        const require =
            this.form.controls[this.question.id].errors && this.form.controls[this.question.id].errors?.required;
        if (this.question.valueType === 'number' || this.question.valueType === 'unique-email') {
            if (require) {
                return !require;
            }
            if (matchPattern) {
                this.errorMesValidate = 'Please input the valid data';
                return !matchPattern;
            }
        }
        return this.form.controls[this.question.id].valid;
    }

    get isTouched() {
        return this.form.controls[this.question.id].touched && !this.form.controls[this.question.id].disabled;
    }

    get subQuestions() {
        return this.questions.filter(x => x.parentId == this.question.id);
    }
    getDisplay() {
        let result: boolean = false;
        if (!this.question.displayCondition) {
            result = true;
        } else {
            this.question.displayCondition.forEach(questionCondition => {
                if (!questionCondition.key && !questionCondition.value) {
                    result = true;
                }
                if (
                    questionCondition.key &&
                    questionCondition.value &&
                    (this.form.controls[questionCondition.key].value ===
                        questionCondition.value ||
                        questionCondition.value === '')
                ) {
                    result = true;
                }
            });
        }
        return result;
    }
    bindGroupValue($event: any, subQuestion: string) {
        this.questionAnswer[subQuestion] = $event.target.value;
        this.form.controls[this.question.id].setValue(this.questionAnswer);
        let hasError = false;
        this.arrayKeyError = [];
        this.question?.subAnswer?.answerTitle.forEach(item => {
            const value = this.questionAnswer[item.key];
            const reg = new RegExp('^[0-9]+$');
            if (value) {
                if (reg.test(value) && Number(value) >= 0) {
                    this.errorMesValidate = '';
                } else {
                    hasError = true;
                    this.arrayKeyError.push(item.key);
                    this.form.controls[this.question.id].setErrors({ invalid: true });
                    this.errorMesValidate = 'Please input the valid data';
                }
            } else if (this.question.required) {
                this.arrayKeyError.push(item.key);
                this.form.controls[this.question.id].setErrors({ invalid: true });
            }
        });
        if (hasError) {
            this.isSubAnswerHasError = true;
        } else {
            this.isSubAnswerHasError = false;
        }
    }
    emitFiles(event: FileList) {
        const file = event && event.item(0);
        const fileForm = new FormData();
        if (file) {
            this.uploadedSub$.next('');
            fileForm.append('file', file);
            this.errorSub$.next('');
            this.fileUploadService.uploadFile(fileForm, this.question.id).subscribe();
            // this.fileUploadService.listAttachment$.pipe(delay(0)).subscribe(x => {
            //     this.questionAnswer = {};
            //     if (x && x.ownerId == this.question.id) {
            //         this.uploadedSub$.next(file.name);
            //         this.questionAnswer = {
            //             fileName: x.fileName,
            //             fileSize: x.fileSize,
            //             fileUrl: x.fileUrl,
            //         };
            //     }
            // });
            this.fileUploadService.qError$.subscribe(x => {
                if (x && x != '' && x.indexOf(';' + this.question.id + ';') != -1) {
                    if (x.indexOf('Payload Too Large') != -1) {
                        this.errorSub$.next(`Your file reached maximum size. Please upload file under 2MB
                        or your file won't reach to receiver.`);
                    } else if (x.indexOf('not have correct file type') != -1) {
                        this.errorSub$.next(`Your file does not have correct file type.`);
                    } else {
                        this.errorSub$.next('');
                    }
                }
            });
            this.fileInput.nativeElement.value = '';
        }
    }
    downloadFile() {
        if (this.questionAnswer) {
            this.fileUploadService
                .downloadFileService(this.questionAnswer)
                .subscribe((response: AttachmentDownloadResponse<Blob>) => this.fileUploadService.openFile(response));
        }
    }

    choiseOption(btnValue: string) {
        this.form.controls[this.question.id].setValue(btnValue);
        if (btnValue == 'yes') {
            this.selected = 'yes';
        } else {
            this.selected = 'no';
        }
    }

    getListYears() {
        const currentYear = new Date().getFullYear();
        const years = [];
        let startYear = 1900;
        while (startYear <= currentYear) {
            years.push(startYear++);
        }

        var sortedArray: number[] = years.sort((n1, n2) => n2 - n1);
        return sortedArray;
    }
}
