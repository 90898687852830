<div class="nav-question">
    <div class="title-group">
        <h2 class="title-nav">Scoping Questions</h2>
    </div>
    <div class="container">
        <div
            class="step"
            *ngFor="let partial of partialSummary"
            [ngClass]="{
                completed: partial.isCompleted || isCompleted,
                active: partial.isSelected,
                uncompleted: partial.isCompletedForward
            }"
            (click)="selectPartial(partial)"
        >
            <div class="group-content">
                <div class="content">{{ partial.text }}</div>
                <div class="item-total">
                    <span>{{ partial.numOfAnswered }}/{{ partial.numOfQuestions }}</span>
                </div>
            </div>
            <button class="btn-delete" *ngIf="partial.isShowDelete && !readOnlyMode" (click)="deletePartial(partial)">
                <mat-icon class="icon-delete">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="border-bottom"></div>
</div>
