<div [formGroup]="form" *ngIf="getDisplay()" class="style-text-input question-item">
    <div *ngIf="question.controlType != 'question-group'; else question_group" class="tvf-row">
        <label [attr.for]="question.id" class="top-label">
            <div class="left-item">
                <span>{{ question.symbol }}.</span>
            </div>
            <div class="right-item" *ngIf="question.required">
                <mat-label class="asterix--before"></mat-label>
            </div>
        </label>
        <label [attr.for]="question.id" class="label-input">{{ question.label }}</label>
        <div *ngIf="question.controlType == 'group-btn'" [formControlName]="question.id">
            <div class="button-group" *ngIf="questionAnswer">
                <button
                    mat-button
                    isRoundButton="true"
                    type="button"
                    class="option-btn"
                    (click)="choiseOption('yes')"
                    [disable]="questionAnswer === 'no'"
                    [class]="questionAnswer === 'yes' ? 'btn-color' : ''"
                >
                    <mat-icon class="icon-mat">check</mat-icon>
                    <span>Yes</span>
                </button>
                <button
                    mat-button
                    isRoundButton="true"
                    class="option-btn"
                    (click)="choiseOption('no')"
                    [disable]="questionAnswer === 'yes'"
                    [class]="questionAnswer === 'no' ? 'btn-color' : ''"
                >
                    <mat-icon class="icon-mat">close</mat-icon>
                    <span>No</span>
                </button>
            </div>
            <div class="button-group" *ngIf="!questionAnswer">
                <button
                    mat-button
                    isRoundButton="true"
                    type="button"
                    class="option-btn"
                    (click)="choiseOption('yes')"
                    [class]="selected === 'yes' ? 'btn-color' : ''"
                    [disabled]="!enableReadOnlyMode ? false : true"
                >
                    <mat-icon class="icon-mat">check</mat-icon>
                    <span>Yes</span>
                </button>
                <button
                    mat-button
                    isRoundButton="true"
                    class="option-btn"
                    (click)="choiseOption('no')"
                    [class]="selected === 'no' ? 'btn-color' : ''"
                    [disabled]="!enableReadOnlyMode ? false : true"
                >
                    <mat-icon class="icon-mat">close</mat-icon>
                    <span>No</span>
                </button>
            </div>
        </div>
        <div [ngSwitch]="question.controlType">
            <div *ngIf="question?.subAnswer?.needToAnswer != false" [class]="question.editable ? 'text-show' : ''">
                <mat-form-field appearance="outline" *ngSwitchCase="'text-box'">
                    <input
                        matInput
                        [formControlName]="question.id"
                        [value]="questionAnswer"
                        [id]="question.id"
                        [type]="question.type"
                        [readonly]="!question.editable"
                        [class]="!question.editable ? 'text-hidden' : 'text-show'"
                    />
                    <mat-error *ngIf="!isValid">{{ errorMesValidate }}</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field
                appearance="outline"
                *ngSwitchCase="'drop-down'"
                [class]="question.editable ? 'text-show' : ''"
            >
                <mat-select
                    [formControlName]="question.id"
                    [value]="questionAnswer"
                    [class]="!question.editable ? 'text-hidden' : 'text-show'"
                >
                    <mat-option *ngFor="let opt of question.options" [value]="opt.key" [disabled]="!question.editable">
                        {{ opt.key }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!isValid && question.editable">{{ errorMesValidate }}</mat-error>
            </mat-form-field>
            <mat-form-field
                appearance="outline"
                *ngSwitchCase="'drop-down-date'"
                [class]="question.editable ? 'text-show' : ''"
            >
                <mat-select [formControlName]="question.id" [value]="questionAnswer">
                    <mat-option *ngFor="let opt of listDate" [value]="opt" [disabled]="!question.editable">
                        {{ opt }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!isValid && question.editable">{{ errorMesValidate }}</mat-error>
            </mat-form-field>
            <div *ngSwitchCase="'drop-down-multi'" class="multi-select-dropdown">
                <ng-multiselect-dropdown
                    *ngSwitchCase="'drop-down-multi'"
                    [formControlName]="question.id"
                    [placeholder]="'Please fill your answer'"
                    [settings]="dropdownSettings"
                    [data]="question.options"
                    [(ngModel)]="questionAnswer"
                    [disabled]="!question.editable"
                >
                </ng-multiselect-dropdown>
                <mat-error *ngIf="!isValid && isTouched"> Please input your answer </mat-error>
            </div>
            <div class="choose-file-btn" *ngSwitchCase="'file-upload'">
                <input
                    type="text"
                    [(ngModel)]="questionAnswer"
                    [formControlName]="question.id"
                    hidden
                    [value]="questionAnswer"
                />
                <div class="answer-file-name">
                    <button
                        class="answer-choose-file-btn"
                        type="button"
                        mat-button
                        (click)="fileInput.click()"
                        *ngIf="!document"
                    >
                        Choose File
                    </button>
                    <div [title]="uploaded$ | async" (click)="downloadFile()" class="file-upload-name">
                        <a href="javascript:void(0);">{{ uploaded$ | async }}</a>
                    </div>
                </div>
                <input hidden (change)="emitFiles($event.target.files)" #fileInput type="file" />
                <div class="question-doc error-message">{{ error$ | async }}</div>
            </div>
        </div>
    </div>
    <ng-template #question_group>
        <div class="style-text-input">
            <label class="top-label">
                <div class="tvf-row left-item">{{ question.symbol + ' ' + question.label }}</div>
                <div class="right-item" *ngIf="question?.required">
                    <mat-label class="asterix--before"></mat-label>
                </div>
            </label>
        </div>
        <div *ngIf="!question?.subAnswer?.needToAnswer; else need_to_answer">
            <!-- <div *ngFor="let title of question?.subAnswer?.answerTitle" class="col-2">
        {{title.key}}
      </div>
      <div class="col-2" *ngIf="question?.subAnswer?.subTotal">Total</div>       -->
        </div>
    </ng-template>
    <ng-template #need_to_answer>
        <input
            type="text"
            [(ngModel)]="questionAnswer"
            [formControlName]="question.id"
            [value]="questionAnswer"
            hidden
        />
        <div class="form-question">
            <div
                *ngFor="let title of question?.subAnswer?.answerTitle"
                [ngStyle]="{ width: '33%', float: 'left', padding: '0 10px' }"
            >
                <label [attr.for]="question.id" class="layout-item">{{ title.key }}</label>
                <div></div>
                <mat-form-field appearance="outline">
                    <input
                        name="title.key"
                        matInput
                        type="text"
                        (change)="bindGroupValue($event, title.key)"
                        [value]="
                            question.required
                                ? questionAnswer[title.key] || questionAnswer[title.key] == '0'
                                    ? questionAnswer[title.key]
                                    : ''
                                : questionAnswer[title.key]
                                ? questionAnswer[title.key]
                                : (role$ | async) === 'employer'
                                ? 0
                                : ''
                        "
                        [readonly]="!question.editable"
                        [class]="!question.editable ? 'text-hidden' : ''"
                        class="left-item"
                    />
                </mat-form-field>
                <div class="sub-answer-error">
                    <mat-error *ngIf="!isValid && arrayKeyError.includes(title.key)">{{ errorMesValidate }}</mat-error>
                </div>
            </div>
            <div class="col-2" *ngIf="question?.subAnswer?.subTotal" [ngStyle]="{ float: 'left', padding: '0 10px' }">
                <label [attr.for]="question.id" class="label-input">Total</label>
                <span class="total-item subTotalCurrent tvf-row">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" [value]="valueTotal" readonly [class]="'text-hidden'" />
                    </mat-form-field>
                </span>
            </div>
        </div>
    </ng-template>

    <!-- <div class="errorMessage" *ngIf="!isValid">{{ question.label }} is required</div> -->

    <div *ngFor="let subQuestion of subQuestions; let i = index" class="form-row">
        <div>
            <app-question class="sub-question" [question]="subQuestion" [questions]="questions" [form]="form">
            </app-question>
        </div>
    </div>

    <!-- <div class="tvf-row" *ngIf="question?.subAnswer?.subTotal && !question?.subAnswer?.needToAnswer">
    <div class="col-6">
      Total
    </div>
    <div *ngFor="let title of question?.subAnswer?.answerTitle" class="col-2 pl-2">
      {{parentTotal[title.key]}}
    </div>
    <div class="col-2">
      {{allTotal}}
    </div>
  </div> -->
</div>
