import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as myGlobals from '../../../../globals';

@Component({
    selector: 'app-logical-input',
    templateUrl: './logical-input.component.html',
    styleUrls: ['./logical-input.component.scss'],
})
export class LogicalInputComponent extends FieldType implements OnInit, OnDestroy {
    
    private unsubscribe$ = new Subject();
    private discardInviteHotelFormSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    ngOnInit(): void {
        myGlobals.discardInviteHotelForm$.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
            if (x) {
                this.field.defaultValue = null;
                this.formControl.setValue(null);
                this.discardInviteHotelFormSub$.next(true)
            }
        })
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    selectOption(value: string) {
        this.field.defaultValue = value;
        this.formControl.setValue(value);
        myGlobals.valueChangeSub$.next({questionId: this.field.key, value: value, isDirty: true});
    }
}
