import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, of, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { AuthService, EmployerService, IQuestionnaireRes, Notification, NotificationResult, PaginateOptions } from "src/app/modules";
import { BreadcrumbService } from "src/app/modules/core";
import { AcceptLinkRequestDialog, ConfirmationDialog, ConfirmCancelDialog } from "src/app/modules/shared";
import { BrandService } from "../../services";
import * as myGlobals from '../../../../globals';

@Component({
    selector: 'app-brand-dashboard',
    templateUrl: './brand-dashboard.component.html',
    styleUrls: ['./brand-dashboard.component.scss'],
})

export class BrandDashboardComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject();
    document$ = this.brandService.document$;
    pageIndex$ = this.brandService.pagination$.pipe(
        switchMap((paginationOpts: PaginateOptions) => {
            return of(paginationOpts.pageIndex);
        })
    );
    total$ = this.brandService.total$;
    notificationResult: NotificationResult;
    notificationResult$ = this.authService.menuNotification$;
    pageSize = 50;
    paginateOpt = { pageIndex: 0, pageSize: this.pageSize }
    dialogRef: any;
    hotelName = '';
    role: string;
    role$: Observable<string>;
    error$ = this.employerService.error$;

    constructor(
        private router: Router,
        private authService: AuthService,
        private brandService: BrandService,
        private employerService: EmployerService,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private breadCrumbService: BreadcrumbService,
        private matDialog: MatDialog,) { }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        let breadcrumbItems = [{name: 'Dashboard', path: '/dashboard'}]
        this.breadCrumbService.setBreadCrumbItemsFirstPage(breadcrumbItems);

        this.spinner.show();
        this.authService.getNotification(this.paginateOpt).pipe(takeUntil(this.unsubscribe$)).subscribe();
        this.notificationResult$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.spinner.hide()
            if (result) {
                this.notificationResult = result;
            }
        })

         this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );

        this.role$.subscribe(value => {
            this.role = value;
        });

        if(this.role === 'brand'){
            this.hotelName = "Hotel";
        } else {
            this.hotelName = "Hotel Group";
        }

        this.error$.pipe(takeUntil(this.unsubscribe$)).subscribe((_res) => {this.spinner.hide()});
    }

    docListPaginate(paginateOpts: PaginateOptions) {
        this.brandService.updatePagination(paginateOpts);
    }

    docListSortChange(sort: Sort) {
        this.brandService.updateSort(sort);
    }

    goToDocument(document: IQuestionnaireRes) {
        switch (document.questionnaireType) {
            case 'employer_pra_scoping':
                this.router.navigate(['employers', 'pra', document.id]);
                break;
            case 'employer_scoping':
                this.router.navigate(['employers', document.userId , 'scoping', document.id]);
                break;
            case 'employer_saq':
                    this.router.navigate(['employers', document.userId , 'scoping', document.id]); //Hardcode and Dummy
                    break;
            case 'employer_registration_form':
            default:
                break;
        }
    }

    deleteScopingPending(document: IQuestionnaireRes) {
        let subTitle = 'Are you sure you want to delete?'
        let messageSuccess = 'Deleted successfully.'
        let message = 'It will remove all answers that you saved previously.'
        let title = 'Delete';
        if (document.questionnaireType === 'employer_scoping') {
            subTitle = 'Are you sure you want to delete Scoping questions?'
            messageSuccess = 'Your Scoping questions has been deleted successfully.'
            message = 'Delete Scoping questions will remove all answers that you saved previously.'
            title = 'Delete Scoping questions'
        }
        if (document.questionnaireType === 'employer_saq') {
            subTitle = 'Are you sure you want to delete Self-assessment questionnaire?'
            messageSuccess = 'Your Self-assessment questionnaire has been deleted successfully.'
            message = 'Delete Self-assessment questionnaire will remove all answers that you saved previously.'
            title = 'Delete Self-assessment questionnaire'
        }
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: title,
                subTitle: subTitle,
                message: message,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {

                        this.spinner.show();
                        return this.brandService.deleteQuestionnaireDraft(document.id);
                    }
                    return of(undefined)
                }))
            .subscribe(res => {
                if (res) {
                    this.spinner.hide();
                    this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: ``,
                            message: messageSuccess,
                            closeButtonText: 'Close',
                        },
                    });
                }
            });
    }

    listResponsesFromLatestUpdates(notifications: Notification[]) {
        this.brandService.listResponsesLatestUpdatesSub.next(notifications)
    }

    redirectNotification(notification: any) {
        this.authService.markItemReadNotification(notification.notiId).subscribe(() => {
            this.authService.getNotification(this.paginateOpt).subscribe();
        });
        if (notification.type === 'employer_scoping_submitted' && notification.id && notification.employerId) {
            this.router.navigate(['dashboard', notification.employerId, 'scoping', notification.id]);
            myGlobals.paramSub$.next(notification.id);
        }
        if ((notification.type === 'employer_saq_submitted' || notification.type === 'recruitment_saq_submitted') && notification.id && notification.employerId) {
            this.router.navigateByUrl(`dashboard/evaluation/${notification.id}`)
        }
    }

    viewRequest(notificaiton: any) {
        const {notiId, type, data, sender} = notificaiton
        this.authService.markItemReadNotification(notiId).subscribe(() => {
            this.authService.getNotification(this.paginateOpt).subscribe();
        });

        if (type === 'hotel_request_link_to_hotel_group' && data && sender) {
            this.dialogRef = this.matDialog.open(AcceptLinkRequestDialog,{
                width: '58.5rem',
                height: '44.438rem',
                data: {
                    title:  `${this.hotelName} Link Request`,
                    message: `By accepting this link, ${data?.hotel?.companyName} will provide access to their scoping questions and questionnaire responses. You will also obtain access to the questionnaire responses provided by the recruitment agencies that you have invited to your account.`,
                    companyProfile: data?.hotel,
                    hideCancelButton: false,
                    roleUser: type,
                    email: sender?.email,
                    color: 'warn',
                    responsed: data.response,
                },
            });

            this.dialogRef
                .afterClosed()
                .pipe(
                    takeUntil(this.unsubscribe$),
                    switchMap((res:any) => {
                        if (res !== undefined) {
                            if (res === true) {
                                // isAccept = true;
                                const responseRequestLink = {
                                    notificationId: notiId,
                                    responseType: 'accept'
                                };
                                const id = data?.brand ? data?.brand.id : (data?.hotel ? data?.hotel.id : '');
                                this.spinner.show();
                                return this.employerService.acceptRequestHotelOrHotelGroup(this.role,id,responseRequestLink);
                            }
                            else {
                                const responseRequestLink = {
                                    notificationId: notiId,
                                    responseType: 'reject'
                                };
                                const id = data?.brand ? data?.brand.id : (data?.hotel ? data?.hotel.id : '');
                                this.spinner.show();
                                return this.employerService.acceptRequestHotelOrHotelGroup(this.role,id,responseRequestLink);
                            }
                        }
                        return of(undefined)
                    })
                )
                .subscribe(() => {
                    this.spinner.hide();
                });
        }
        else if (type === 'hotel_group_request_link_to_hotel' && data && sender) {
            this.dialogRef = this.matDialog.open(AcceptLinkRequestDialog,{
                width: '58.5rem',
                height: '44.438rem',
                data: {
                    title:  `${this.hotelName} Link Request`,
                    message: `By accepting this link, you are agreeing to provide ${data?.brand?.name} with access to your scoping questions and questionnaire responses. You are also agreeing to provide them access to the questionnaire responses provided by the recruitment agencies that you have invited to your account.`,
                    companyProfile: data?.brand,
                    hideCancelButton: false,
                    roleUser: type,
                    email: sender?.email,
                    color: 'warn',
                },
            });

            this.dialogRef
                .afterClosed()
                .pipe(
                    takeUntil(this.unsubscribe$),
                    switchMap((res:any) => {
                        if (res) {
                            const notiObj = {
                                notificationId: notiId,
                                responseType: 'accept'
                            };
                            const id = data?.brand ? data?.brand.id : (data?.hotel ? data?.hotel.id : '');
                            this.spinner.show();
                            return this.employerService.acceptRequestHotelOrHotelGroup(this.role,id,notiObj);
                        }
                        return of(undefined);
                    })
                )
                .subscribe(() => {
                    this.spinner.hide();
                });
        }
    }

    downloadCSV(element: IQuestionnaireRes) {
        this.employerService.downloadSAQAnswerCSV(this.role, element.userId, element.id).subscribe(
            res => {
                this.employerService.openFile(res);
            }
        );
        this.employerService.downloadSAQEvaluationCSV(this.role, element.userId, element.id).subscribe(
            res => {
                this.employerService.openFile(res);
            }
        );
    }
}
