import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../auth';
import { BreadcrumbService } from '../../core';
import { PraUserActionData, PraUserInfo } from '../../pra';
import { ConfirmationDialog, ConfirmCancelDialog } from '../../shared';
import { IQuestionnaireRes, PaginateOptions, UserStatus } from '../../types';
import { ListUsersFormComponent } from '../components';
import { EmployerService } from '../services';
import { EmployerUserInfo } from '../types';

@Component({
    selector: 'employer-detail',
    template: `
        <employer-button
            (activeOrDeActiveHotel)="activeOrDeActive($event)"
            (isDeleteHotel)="deleteCurrentHotel($event)"
            [employerStatus]="(companyProfile$ | async)?.status"
        ></employer-button>
        <div class="grid-container1 layout-padding">
            <employer-profile></employer-profile>
            <employer-history
                *ngIf="employerHistoryCollection$ | async"
                [pageSize]="pageSize"
                [employerHistoryCollection]="employerHistoryCollection$ | async"
                (paginate)="paginateHistory$.next($event)"
                [errorMessages]="inactivateUserErrors$ | async"
                (filterUser)="filter$.next($event)"
                (sortChange)="sorterHistory$.next($event)"
                [currentEmployerId]="employerId"
                (downloadCSV)="downloadCSV($event)"
            ></employer-history>
            <list-pra
                *ngIf="praEmployerCollection$ | async"
                [pageSize]="pageSize"
                [praCollection]="praEmployerCollection$ | async"
                (paginate)="paginatePras$.next($event)"
                [errorMessages]="inactivateUserErrors$ | async"
                (filterUser)="filter$.next($event)"
                (sortChange)="sorterPra$.next($event)"
                [currentEmployerId]="employerId"
                (invitePra)="invitePraFnc($event)"
                (inactivatePra)="inactivatePraFnc($event)"
                (deletePra)="deletePraFnc($event)"
                [hotelName]="hotelName"
            ></list-pra>
        </div>
    `,
    styles: [
        `
            .layout-padding {
                padding-bottom: 5%;
                margin: 48px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployerDetailContainer implements OnInit, OnDestroy {
    employerId = '';
    pageSize = 5;
    hotelName = '';

    @ViewChild(ListUsersFormComponent, { static: true })
    listUserForm: ListUsersFormComponent;

    private unsubscribe$ = new Subject();
    registerClicked$ = new Subject<EmployerUserInfo>();
    paginateHistory$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });
    paginatePras$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });

    filter$ = new BehaviorSubject<string>('');
    sorterHistory$ = new BehaviorSubject<Sort>({ active: 'createdAt', direction: 'desc' });
    sorterPra$ = new BehaviorSubject<Sort>({ active: 'name', direction: 'asc' });

    employerHistoryCollection$ = this.employerService.employerHistory$;
    praEmployerCollection$ = this.employerService.praEmployer$;
    updatedBreadCrums = false;

    inactivateUserErrors$ = this.employerService.inactivateUserErrors$;
    deactivateRA$ = this.employerService.deactivateRA$;
    errors$ = this.employerService.errors$;
    currentRA: PraUserInfo;

    companyProfile$ = this.employerService.conpanyProfile$.pipe(
        tap(companyProfile => {
            this.hotelName = companyProfile.name;
            if (!this.updatedBreadCrums && this.hotelName !== '') {
                this.breadcrumbService.updateBreadCrumbItemsWithItem({name: this.hotelName, path: this.router.url});
                this.updatedBreadCrums = true;
            }
        }),
    );

    questionnaire$ = this.employerService.questionnaire$;
    role$: Observable<string>;
    role: string = '';
    currentPraPagination: PaginateOptions;
    currentPraOrder: string;

    constructor(
        private employerService: EmployerService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private router: Router,
        private breadcrumbService: BreadcrumbService,
        private spinner: NgxSpinnerService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.employerId = this.route.snapshot.params.id;
        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );
        this.role$.subscribe(value => {
            this.role = value;
        });
        combineLatest([this.paginateHistory$, this.filter$, this.sorterHistory$])
            .pipe(
                debounceTime(300),
                switchMap(([pagination, filter, sorter]) => {
                    let orderBy = '';
                    if (sorter && sorter.active) {
                        orderBy = `${sorter.active} ${sorter.direction}`;
                    }
                    return this.employerService.paginteEmployerHistory(this.employerId, pagination, filter, orderBy);
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        combineLatest([this.paginatePras$, this.filter$, this.sorterPra$])
            .pipe(
                debounceTime(300),
                switchMap(([pagination, filter, sorter]) => {
                    let orderBy = '';
                    if (sorter && sorter.active) {
                        orderBy = `${sorter.active} ${sorter.direction}`;
                    }
                    this.currentPraPagination = pagination;
                    this.currentPraOrder = orderBy;
                    return this.employerService.pagintePraEmployer(this.employerId, pagination, filter, orderBy);
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        this.employerService.getCompanyProfile(this.employerId).subscribe();

        combineLatest([this.praEmployerCollection$, this.companyProfile$])
            .pipe(
                tap(() => this.spinner.show())
            )
            .subscribe(() => this.spinner.hide())

        this.inactivateUserErrors$.pipe(takeUntil(this.unsubscribe$)).subscribe(errors => {
            this.spinner.hide()
            if (errors && errors.length > 0) {
                this.dialog.open(ConfirmationDialog, {
                    width: '450px',
                    data: {
                        title: 'Error',
                        message:
                            errors[0],
                        closeButtonText: 'Close',
                    },
                });
            }
        })

        this.deactivateRA$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (result && result.response) {
                if (result.response.deactivated) {
                    this.spinner.hide();
                    let dialogRef = this.dialog.open(ConfirmCancelDialog, {
                        data: {
                            title: 'This Recruitment agency was deactivated',
                            subTitle: 'Are you sure you want to invite this Recruitment Agency?',
                            message: 'Invite Recruitment agency will activate account again.',
                            cancelButtonText: 'Discard',
                            confirmButtonText: 'Invite',
                            color: 'warn'
                        },
                    });

                    dialogRef
                    .afterClosed()
                    .pipe(
                        takeUntil(this.unsubscribe$),
                        switchMap(async (res:any) => {
                            if (res) {
                                setTimeout(() => {
                                    this.inviteRA(this.currentRA);
                                }, 1);
                            }
                            return of(undefined);
                        })
                    )
                    .subscribe();
                } else {
                    setTimeout(() => {
                        this.inviteRA(this.currentRA);
                    }, 1);
                }
            } else {
                this.spinner.hide();
            }
        })

        this.errors$.pipe(takeUntil(this.unsubscribe$)).subscribe(errors => {
            this.spinner.hide()
            if (errors && errors.length > 0) {
                this.dialog.open(ConfirmationDialog, {
                    width: '450px',
                    data: {
                        title: 'Error',
                        message:
                            errors[0],
                        closeButtonText: 'Close',
                    },
                });
            }
        })
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    activeOrDeActive(data: { status: UserStatus }) {
        const { status } = data;
        const userId = this.employerId;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate Hotel user` : `Activate Hotel user`,
                subTitle:
                    data.status == 'Active'
                        ? `Are you sure you want to deactivate  account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When deactivated, user cannot work on LMPM platform. However, you will still be able to view their information, answers and activities.`
                        : ` When activate, Hotel user can continue working on LMPM platform.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    this.spinner.show();
                    const activeResult = await this.employerService.inactivateBrand(userId, status, this.pageSize);
                    this.employerService.getCompanyProfile(this.employerId).subscribe();
                    if (activeResult) {
                        this.spinner.hide();
                        this.dialog.open(ConfirmationDialog, {
                            width: '600px',
                            data: {
                                title: (status === 'Active' ? 'Deactivated' : 'Activated') + ' Successfully!',
                                message:
                                    'User was successfully ' +
                                    (status === 'Active' ? 'deactivated' : 'activated') +
                                    ' from LMPM platform.',
                                closeButtonText: 'Close',
                            },
                        });
                    }
                }
            });
    }

    deleteCurrentHotel(isDelete: boolean) {
        if (isDelete) {
            let dialogRef = this.dialog.open(ConfirmCancelDialog, {
                width: '600px',
                data: {
                    title: `Delete Hotel`,
                    subTitle: `Are you sure you want to delete account?`,
                    message: `
                        When delete, this user cannot work on LMPM platform. Their information, answers and
                        activities will be deleted.Self-assessment of other users, who relate to this user will also be deleted.
                    `,
                    cancelButtonText: 'Discard',
                    confirmButtonText: 'Delete account',
                    hideCancelButton: false,
                    color: 'warn',
                },
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(async result => {
                    if (result) {
                        this.spinner.show();
                        const resultDel = await this.employerService.inactivateAdmin(this.employerId, this.pageSize);
                        if (resultDel) {
                            this.spinner.hide();
                            const resultDialog = this.dialog.open(ConfirmationDialog, {
                                width: '600px',
                                data: {
                                    title: `Delete Successfully!`,
                                    message: `
                                    User was successfully deleted from LMPM platform.
                                    `,
                                    closeButtonText: 'Close',
                                },
                            });
                            resultDialog.afterClosed().subscribe(() => this.router.navigate(['employers']));
                        }
                    }
                });
        }
    }

    async invitePraFnc (praUser: PraUserInfo) {
        this.currentRA = praUser;
        this.spinner.show();
        this.employerService.brandCheckDeactivatePra(this.employerId, praUser.email);
    }

    async inviteRA(praUser: PraUserInfo) {
        this.spinner.show();
        praUser.employerId = this.employerId;
        const result = await this.employerService.invitePra(praUser, this.employerId, this.currentPraPagination, this.currentPraOrder);
        if (result) {
            this.spinner.hide();
            this.dialog.open(ConfirmationDialog, {
                width: '600px',
                data: {
                    title: 'Email sent',
                    message:`Invitation email was sent to ${praUser.email} successfully.`,
                    closeButtonText: 'Close',
                },
            });
        }
    }

    async inactivatePraFnc (data: PraUserActionData) {
        const hotel = this.role == 'brand' ? data.hotelName : 'your Hotel';
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate Recruitment Agency` : `Activate Recruitment Agency`,
                subTitle:
                    data.status === 'Active'
                        ? `Are you sure you want to deactivate account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When de-activated, Recruitment Agency cannot submit Self assessment questionnaire for ${hotel}.`
                        : `When activate, user can continue submitting Self assessment questionnaire for ${hotel}`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
        .afterClosed()
        .pipe(
            takeUntil(this.unsubscribe$),
            switchMap((res:any) => {
                if (res) {
                    this.spinner.show();
                    return this.employerService.inactivatePra(data, this.currentPraPagination, this.currentPraOrder);
                }
                return of(undefined);
            })
        )
        .subscribe(async result => {
            if (result) {
                this.spinner.hide();
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: data.status == 'Active' ? 'Deactivated Successfully!' : 'Activated Successfully',
                        message:
                            data.status == 'Active'
                                ? 'RA was successfully deactivated from LMPM platform.'
                                : 'RA was successfully activated from LMPM platform.',
                        closeButtonText: 'Close',
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe();
            }
        });
    }

    async deletePraFnc (praUser: PraUserActionData) {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Delete Recruitment Agency`,
                subTitle: `Are you sure you want to delete account?`,
                message: `
                    When delete, this user cannot work on LMPM platform. Their information, answers and activities will be deleted.
                `,
                cancelButtonText: 'Discard',
                confirmButtonText: 'Delete account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    this.spinner.show();
                    const deleteResult = await this.employerService.deletePra(praUser, this.pageSize);
                    if (deleteResult) {
                        this.spinner.hide();
                        this.dialog.open(ConfirmationDialog, {
                            width: '600px',
                            data: {
                                title: `Delete Successfully`,
                                message: `
                                User was successfully deleted from LMPM platform.
                                `,
                                closeButtonText: 'Close',
                            },
                        });
                    }
                }
            });
    }

    downloadCSV(element: IQuestionnaireRes) {
        this.employerService.downloadSAQAnswerCSV(this.role, this.employerId, element.id).subscribe(
            res => {
                this.employerService.openFile(res);
            }
        );
        this.employerService.downloadSAQEvaluationCSV(this.role, this.employerId, element.id).subscribe(
            res => {
                this.employerService.openFile(res);
            }
        );
    }
}
