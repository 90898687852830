import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import format from 'date-fns/format';
import { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, of, Subject, combineLatest } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomEncoder, Logger, UserInfo, UserInfoCollection } from '../../core';
import { UserStatus, ApiConstant, CustomHttpErrorResponse, PaginateOptions, LscmResponse, IHistory } from '../../types';
import { BrandUserInfo, BRAND_API_TOKEN, ExportType } from '../types';
import { Notification } from "src/app/modules";

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class BrandService {
    urlDefault = this.apiConstant.endpoint;
    url = `${this.apiConstant.endpoint}/auth`;

    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private inactivateUserErrorsSub$ = new Subject<string[]>();
    inactivateUserErrors$ = this.inactivateUserErrorsSub$.asObservable();

    private newUserSub$ = new Subject<UserInfo>();
    newUser$ = this.newUserSub$.asObservable();

    private lstCaseMangerSub$ = new BehaviorSubject<UserInfo[]>([]);
    lstCaseManger$ = this.lstCaseMangerSub$.asObservable();

    private userCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userCollection$ = this.userCollectionSub$.asObservable();

    DEFAULT_PAGINATION: PaginateOptions = {
        pageIndex: 0,
        pageSize: 5,
    };

    private paginateByUserRole(
        options: PaginateOptions,
        role: string,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params;
        if (role === 'managements') {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', 'brand');
            //.append('roles', 'admin');
        } else {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', role);
        }

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<UserInfoCollection>(`${this.urlDefault}/brands`, { params, headers }).pipe(
            tap(({ users, userCount }: UserInfoCollection) => {
                const localUsers: UserInfo[] = users.map(u => {
                    const strLastLoginTime = u.lastLoginTime
                        ? format(new Date(u.lastLoginTime), 'dd MMM, yyyy HH:mm:ss')
                        : '';
                    return {
                        ...u,
                        lastLoginTime: strLastLoginTime,
                    };
                });
                this.userCollectionSub$.next({ users: localUsers, userCount, startAtPage: { pageIndex } });
            }),
            catchError(() => {
                this.userCollectionSub$.next({ users: [], userCount: 0 });
                return EMPTY;
            }),
        );
    }

    constructor(@Inject(BRAND_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) {}

    registerBrand(input: BrandUserInfo, options: PaginateOptions) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Create new Brand user' });

        const { fullname, email, company } = input;

        return this.httpClient
            .post<UserInfo>(
                `${this.url}/register-brand`,
                {
                    fullname,
                    email,
                    companyName: company,
                },
                { headers },
            )
            .pipe(
                tap((newUser: UserInfo) => {
                    this.newUserSub$.next(newUser);
                    this.errorsSub$.next([]);
                }),
                concatMap(() => this.paginateByUserRole(options, 'managements', 'User Management list')),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    // paginteAdmins(options: PaginateOptions, filter?: string) {
    //     return this.paginateByUserRole(options, 'admin', 'Paginate Iom user list', filter);
    // }

    // paginteUsers(options: PaginateOptions, filter?: string) {
    //     return this.paginateByUserRole(options, 'user', 'Paginate PRA list', filter); //roles=[user
    // }

    paginteUserManagements(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateByUserRole(options, 'managements', 'User Management list', filter, sorter);
    }

    inactivateAdmin(userId: string, pageSize: number) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete Iom user' });
        return this.httpClient.delete<UserInfo>(`${this.urlDefault}/user/${userId}`, { headers }).pipe(
            tap(() => {
                this.inactivateUserErrorsSub$.next([]);
            }),
            switchMap(() =>
                this.paginteUserManagements({
                    pageIndex: 0,
                    pageSize,
                }),
            ),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.inactivateUserErrorsSub$.next(err.errorJson.message);
                } else {
                    this.inactivateUserErrorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        );
    }

    inactivateBrand(userId: string, status: UserStatus, pageSize: number) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated brand' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/user/updateBrandStatus/${userId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    getTotalCaseManager(): Promise<number> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get total Iom user' });

        return this.httpClient
            .get<number>(`${this.url}/number-caser-manager`, { headers })
            .pipe(
                tap((result: number) => {
                    this.errorsSub$.next([]);
                    return result;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    completeOnboarding(input: {
        id: string;
        newPassword: string;
        confirmPassword: string;
        language: number;
        time: string;
        sig: string;
    }) {
        const { id, newPassword, confirmPassword, time, sig, language } = input;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Complete onboarding process' });
        const body = {
            newPassword,
            confirmPassword,
            languageId: language,
        };

        return this.httpClient
            .put<void>(`${this.url}/onboarding-complete/${id}?time=${time}&sig=${sig}`, body, { headers })
            .pipe(
                tap(() => this.errorsSub$.next([])),
                map(() => true),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return of(false);
                }),
            );
    }

    isEmailUnique(email: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of IOM user email' });

        return this.httpClient.get(`${this.url}/isEmailUnique`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'true'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    isCompanyNameUnique(name: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('companyName', name);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of PRA company name' });

        return this.httpClient.get(`${this.url}/is-unique-company`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'false'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    async deleteBrand(brandId: string, pageSize: number): Promise<UserInfoCollection> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete brand' });

        return this.httpClient
            .delete<UserInfo>(`${this.urlDefault}/brands/${brandId}`, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                    return true;
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    sortSub = new BehaviorSubject<Sort>({ active: 'createdAt', direction: 'desc' });
    sort$ = this.sortSub.asObservable();
    updateSort(sort: Sort) {
        this.sortSub.next(sort);
    }

    paginationSub = new BehaviorSubject<PaginateOptions>(this.DEFAULT_PAGINATION);
    pagination$ = this.paginationSub.asObservable();

    listResponsesLatestUpdatesSub = new BehaviorSubject<Notification[]>([]);
    listResponsesLatestUpdates$ = this.listResponsesLatestUpdatesSub.asObservable();

    updatePagination(paginationOpts: PaginateOptions) {
        this.paginationSub.next(paginationOpts);
    }

    totalCountSub = new BehaviorSubject<number>(0);
    total$ = this.totalCountSub.asObservable();

    document$ = combineLatest([this.sort$, this.pagination$, this.listResponsesLatestUpdates$]).pipe(
        distinctUntilChanged(),
        switchMap(([sort, pagination, listResponsesLatestUpdates]) => {
            let idNotIn = "&idNotIn="
            if (listResponsesLatestUpdates.length > 0) {
                for (let i = 0; i < listResponsesLatestUpdates.length; i++) {
                    idNotIn += listResponsesLatestUpdates[i].data?.questionnaireResponseId
                    if (i < listResponsesLatestUpdates.length - 1) {
                        idNotIn += ','
                    }
                }
            }

            let orderBy = '';
            if (sort && sort.active) {
                orderBy = `${sort.active} ${sort.direction}`;
            }
            const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get all questionnaire response of hotel group' });
            let url = ''
            if (listResponsesLatestUpdates.length > 0) {
                url = `${this.urlDefault}/brands/questionnaire-response?orderBy=${orderBy}&page=${pagination.pageIndex}&limit=${pagination.pageSize}` + idNotIn;
            } else {
                url = `${this.urlDefault}/brands/questionnaire-response?orderBy=${orderBy}&page=${pagination.pageIndex}&limit=${pagination.pageSize}`;
            }
            return this.httpClient.get<LscmResponse<IHistory>>(url, { headers }).pipe(
                tap((res: LscmResponse<IHistory>) => {
                    this.totalCountSub.next(res.response.total);
                    this.errorsSub$.next([]);
                }),
                map((res: LscmResponse<IHistory>) =>
                    res.response.histories.map(item => {
                        const location =
                            item.location && typeof item.location == 'object'
                                ? item.location.map((x: any) => {
                                    return x.key ? x.key : x
                                })
                                : item.location;
                        return {
                            ...item,
                            location: location
                        };
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
        }),
    );

    deleteQuestionnaireDraft(questionnaireResponseId?: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete questionnaire response draft' });

        return this.httpClient
            .delete<LscmResponse<any>>(`${this.urlDefault}/questionnaireResDraft/${questionnaireResponseId}`, { headers })
            .pipe(
                tap(() => this.updatePagination(this.DEFAULT_PAGINATION)),
                catchError(() => {
                    return EMPTY;
                }),
            );
    }

    downloadTemplateCSV(type: ExportType): Observable<any> {
        let url = `${this.urlDefault}/file-upload/`;
        switch(type) {
            case 'scoping-template':
                url += 'download-scoping-template';
                break;
            case 'hotel-saq':
                url += 'download-hotel-saq';
                break;
            case 'recruitment-saq':
                url += 'download-recruitment-saq';
                break;
            case 'worker-interview':
                 url += 'download-worker-interview';
                break;
            default:
                break;
        };
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Download file attachment',
        });
        return this.httpClient.get<any>(url, {responseType: 'blob' as 'json', observe: 'response', headers})
        .pipe(
            map(response => ({
                body: response.body,
                contentDisposition: response.headers.get('content-disposition') || '',
                contentType: response.headers.get('content-type') || 'application/octet-stream',
            })),
        );
    }

    openFile(response: any) {
        if (response && response.body) {
            const { contentType } = response;
            const filename = this.getFileNameFromContentDisposition(response);
            saveAs(new Blob([response.body], { type: contentType }), filename);
        }
    }
    private getFileNameFromContentDisposition(res: any) {
        const { contentDisposition } = res;
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const tempFilename = ((matches && matches[1]) || 'untitled').trim();
        const filename = tempFilename.replace(/["]/g, '');
        return filename;
    }
}
