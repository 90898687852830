<form (ngSubmit)="registerUser()" #f="ngForm" novalidate>
    <ng-container *ngIf="smallScreen$ | async as smallScreen">
        <mat-card class="mat-elevation-z-p-r6" [ngClass]="'mat-elevation-z0'">
            <mat-card-header>
                <mat-card-title class="page-section-title"> Add New User </mat-card-title>
            </mat-card-header>
            <mat-card-content class="content-container">
                <ng-container *ngIf="smallScreen === 'no'" [formGroup]="form">
                    <div class="user-form-wrapper">
                        <div class="user-item">
                            <div class="form-field-label">Name</div>
                            <div class="item-group">
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        type="text"
                                        placeholder="Name"
                                        formControlName="username"
                                        autocomplete="off"
                                        [(ngModel)]="data.fullName"
                                        (ngModelChange)="fullNameChanged($event)"
                                    />
                                    <mat-error *ngIf="username.errors && username.errors['required']">
                                        Please input full name.
                                    </mat-error>
                                    <mat-error *ngIf="username.errors && username.errors['maxlength']">
                                        User name cannot exceed 100 characters.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="user-item">
                            <div class="form-field-label">Email</div>
                            <div class="item-group">
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        required
                                        type="email"
                                        placeholder="Email"
                                        formControlName="email"
                                        autocomplete="off"
                                        [(ngModel)]="data.email"
                                        (ngModelChange)="emailChanged($event)"
                                        [errorStateMatcher]="matcher"
                                    />
                                    <mat-error *ngIf="email.errors && email.errors['required']">Please input email.</mat-error>
                                    <mat-error *ngIf="email.errors && email.errors['email']">
                                        Please input valid email.
                                    </mat-error>
                                    <mat-error *ngIf="email.errors && email.errors['taken']">
                                        This email already exists.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- <div class="user-item">
                            <div class="form-field-label">Re-enter Email</div>
                            <div class="item-group">
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        type="email"
                                        formControlName="reemail"
                                        [attr.aria-label]="'Email'"
                                        required
                                        autocomplete="off"
                                        placeholder="Re-enter Email"
                                    />
                                    <mat-error *ngIf="hasError(reemail, 'required')">Please input re-enter email.</mat-error>
                                    <mat-error *ngIf="hasError(reemail, 'email') || hasError(reemail, 'pattern')">
                                        Email is invalid
                                        </mat-error>
                                    <mat-error *ngIf="hasError(reemail, 'emailNotMatch')">
                                        Re-enter email doesn't match with email</mat-error>
                                </mat-form-field>
                            </div>
                        </div> -->

                    </div>
                </ng-container>
                <app-error-box [errorMessages]="errorMessages"></app-error-box>
                <div style="text-align: left; margin-top: 10px">
                    <button class="btn-cancel" type="button" mat-button color="primary" (click)="closePopup()">
                        Cancel
                    </button>
                    <button class="btn-add-new" type="submit" mat-button color="primary" [class]="form.valid ? 'btn-add-active' : ''" [disabled]="form.invalid">
                        Confirm
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
</form>
