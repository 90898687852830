import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as myGlobals from '../../../../globals';
import { CustomFormlyFieldConfig } from '../../types';

@Component({
    selector: 'app-wrap-country-group',
    templateUrl: './wrap-country-group.component.html',
    styleUrls: ['./wrap-country-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapCountryGroupComponent extends FieldType implements OnInit {
    numberCountry$ =  myGlobals.numberCountry$;
    questionBtnCode = '';
    ngOnInit(): void {
        const code = this.field.key ? this.field.key?.toString().slice(0,10) : '';
        this.questionBtnCode = myGlobals.allQuestion.filter(q=> q.controlType === 'question-btn' && q.code.startsWith(code)).map(q => q.code)[0];
    }

    removeCountry(value: number, lstNumberCountry: any) {
        this.field.hide = true;
        const partialId = (this.field as CustomFormlyFieldConfig).partialId;
        const lstSub = JSON.parse(lstNumberCountry);
        lstSub.forEach((item: any) => {
            if (item.key === partialId) {
                item.value = value - 1;
            }
            return item;
        })
        myGlobals.numberCountrySub$.next(lstSub);
        if (value > 1) {
            if (this.field.key === 'emp_regis_A11_3' || this.field.key === 'employer_scoping_A14_3' || this.field.key === 'emp_pra_scop_A11') {
                myGlobals.valueChangeSub$.next({questionId: this.questionBtnCode, value: 'Yes,1', partialId: partialId});
            } else {
                myGlobals.valueChangeSub$.next({questionId: this.questionBtnCode, value: 'Yes,2', partialId: partialId});
            }
        } else {
            myGlobals.valueChangeSub$.next({questionId: this.questionBtnCode, value: '', partialId: partialId});
        }
        myGlobals.removeCountrySub$.next(this.field.key + '')
    }
}
