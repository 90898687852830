'use strict';

import { BehaviorSubject, Subject } from "rxjs";
import { PraUserInfo } from "./modules";
import { Question } from "./modules/question-form";
import { numberCountryChange, validCountryChange, validEmailDiff, valueChange } from "./modules/types";

export const menuItems = [
    {
        id: 1,
        parentId: 0,
        text: 'Dashboard',
        url: '/dashboard',
        role: ['brand', 'employer', 'pra'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/dash.svg',
        activeIcon: '../assets/images/menu-icons/dash-active.svg',
    },
    {
        id: 2,
        parentId: 0,
        text: 'Dashboard',
        url: '/dashboard',
        role: ['admin'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/dash.svg',
        activeIcon: '../assets/images/menu-icons/dash-active.svg',
    },
    // {
    //     id: 2,
    //     parentId: 0,
    //     text: 'USER MANAGEMENT',
    //     url: '',
    //     role: ['admin'],
    //     hasChild: false,
    //     valid: 0,
    //     icon: '../assets/images/menu-icons/person.svg',
    //     activeIcon: '../assets/images/menu-icons/person.svg',
    // },
    {
        id: 4,
        parentId: 0,
        text: 'Hotel groups',
        url: '/brands',
        role: ['admin'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/apartment.png',
        activeIcon: '../assets/images/menu-icons/apartment-active.png',
    },
    {
        id: 3,
        parentId: 0,
        text: 'User Managerment',
        url: '/iom/users',
        role: ['admin'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/person.svg',
        activeIcon: '../assets/images/menu-icons/person-active.svg',
    },

    {
        id: 7,
        parentId: 0,
        text: 'Hotels',
        url: '/employers',
        role: ['brand'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/apartment.png',
        activeIcon: '../assets/images/menu-icons/apartment-active.png',
    },
    {
        id: 8,
        parentId: 0,
        text: 'Recruitment Agencies',
        url: '/agency',
        role: ['brand'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/agency.svg',
        activeIcon: '../assets/images/menu-icons/agency-active.svg',
    },
    {
        id: 5,
        parentId: 0,
        text: 'Questionnaire',
        url: '/questionnaire/download',
        role: ['brand'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/questionnaire.svg',
        activeIcon: '../assets/images/menu-icons/questionnaire-active.svg',
    },
    {
        id: 6,
        parentId: 0,
        text: 'User Management',
        url: '/employee',
        role: ['brand'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/person.svg',
        activeIcon: '../assets/images/menu-icons/person-active.svg',
    },
    {
        id: 10,
        parentId: 0,
        text: 'Notifications',
        url: '/notifi',
        role: ['brand', 'employer'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/notifications.svg',
        activeIcon: '../assets/images/menu-icons/notification-active.svg',
    },
    {
        id: 9,
        parentId: 0,
        text: 'Account Settings',
        url: '/my-account',
        role: ['admin', 'employer', 'brand', 'pra'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/setting.svg',
        activeIcon: '../assets/images/menu-icons/setting-active.svg',
    },
    {
        id: 11,
        parentId: 0,
        text: 'Log Out',
        url: '/logout',
        role: ['admin', 'employer', 'brand', 'pra'],
        hasChild: false,
        valid: 0,
        icon: '../assets/images/menu-icons/logout.svg',
        activeIcon: '../assets/images/menu-icons/logout.svg',
    },
];

export let paramSub$ : Subject<string> = new Subject<string>();
export let param = paramSub$.asObservable();

export let allQuestionSub$ = new BehaviorSubject<Question[]>([]);
export let allQuestion: Question[] ;
allQuestionSub$.subscribe( x => {
    return allQuestion = x;
});

export let valueChangeSub$ : Subject<valueChange> = new Subject<valueChange>();
export let valueChange$ = valueChangeSub$.asObservable();

export let removeCountrySub$ : Subject<string> = new Subject<string>();
export let removeCountry$ = removeCountrySub$.asObservable();

export let numberCountrySub$ : BehaviorSubject<numberCountryChange[]> = new BehaviorSubject<numberCountryChange[]>([]);
export let numberCountry$ = numberCountrySub$.asObservable();

export let saqDirtySub$: Subject<{isDirty: boolean}> = new Subject<{isDirty: boolean}>();
export let saqDirty$ = saqDirtySub$.asObservable();

export let openNotificationSub$: Subject<boolean> = new Subject<boolean>();
export let openNotification$ = openNotificationSub$.asObservable();

export let emailAddUserSub$: Subject<string> = new Subject<string>();
export let emailAddUser$ = emailAddUserSub$.asObservable();

export let fullNameAddUserSub$: Subject<string> = new Subject<string>();
export let fullNameAddUser$ = fullNameAddUserSub$.asObservable();

export let countryChangeSub$ : Subject<validCountryChange> = new Subject<validCountryChange>();
export let countryChange$ = countryChangeSub$.asObservable();

export let isEmailDifferentHotelOrHotelGroupSub$: Subject<validEmailDiff[]> = new Subject<validEmailDiff[]>();
export let isEmailDifferentHotelOrHotelGroup$ = isEmailDifferentHotelOrHotelGroupSub$.asObservable();

export let discardInviteHotelFormSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
export let discardInviteHotelForm$ = discardInviteHotelFormSub$.asObservable();

export let inviteRAFromScopingReviewSub$: Subject<PraUserInfo> = new Subject<PraUserInfo>();
export let inviteRAFromScopingReview$ = inviteRAFromScopingReviewSub$.asObservable();

export let validateEmployerSaqA1Sub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
export let validateEmployerSaqA1$ = validateEmployerSaqA1Sub$.asObservable();
