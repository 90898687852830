<mat-table [dataSource]="dataSource" class="mat-elevation-z0" role="grid" matSort>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <ng-container matColumnDef="rowDetails">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Details</mat-header-cell>
        <mat-cell *matCellDef="let element">
            <span class="detail-container">
                <div class="column" *ngFor="let column of config?.columns">
                    <label>{{ column.text }}:</label>
                    <span class="wrap-text" *ngIf="!column.actionFunction && !column.styleClassFunction">
                        {{ column.valueFunction ? column.valueFunction(element) : element[column.fieldName] }}</span
                    >
                    <span
                        class="wrap-text"
                        *ngIf="!column.actionFunction && column.styleClassFunction"
                        [ngClass]="column.styleClassFunction(element)"
                    >
                        {{ column.valueFunction ? column.valueFunction(element) : element[column.fieldName] }}</span
                    >
                    <span class="wrap-text" *ngIf="column.actionFunction">
                        <a [attr.arial-label]="column.label" href="#" (click)="column.actionFunction($event, element)">
                            {{ element[column.fieldName] || column.label }}
                        </a>
                    </span>
                </div>
                <div class="column" *ngFor="let action of config?.actions">
                    <label>{{ action.text }}:</label>
                    <span class="wrap-text">
                        <a
                            name="delete"
                            [attr.arial-label]="action.label"
                            href="#"
                            (click)="action.actionFunction($event, element)"
                            *ngIf="action.condition(element)"
                        >
                            {{ action.label }}
                        </a>
                    </span>
                </div>
            </span>
        </mat-cell>
    </ng-container>
</mat-table>

<mat-paginator
    [length]="config?.meta.rowsNumber || 0"
    [pageSize]="config?.meta.pageSize"
    [attr.aria-label]="'paginator'"
    [showFirstLastButtons]="true"
>
</mat-paginator>
