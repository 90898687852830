<div class="container-component" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="130px">
    <div class="grid gap-4 grid-rows-1 grid-cols-1 content-bottom">
        <span class="style-text-header">Questionnaires</span>
        <div class="grid gap-4 grid-cols-1 bg-white rounded-lg p-xl text-gray-600" style="height: 142px;" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- <mat-icon>supervisor_account</mat-icon> -->
            <div class="text-layout">
                <span class="style-text-title">Scoping Questions</span>
                <span class="style-text-content">
                    Share information about your company profile and the recruitment agencies that you work with to recruit and manage migrant workers.
                </span>
            </div>
            <div class="button-layout">
                <button
                    class="px-3 py-2 rounded text-white bg-teal-600 style-text-button"
                    (click)="redirectScopingQuestion()"
                >
                    <mat-icon style="margin-right: 13px">mode</mat-icon>
                    Start Scoping Questions
                </button>
            </div>
        </div>
        <div class="grid gap-4 grid-cols-1 bg-white rounded-lg p-xl text-gray-600" style="height: 142px;" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- <mat-icon>library_books</mat-icon> -->
            <div class="text-layout">
                <span class="style-text-title">Self Assessment Questionnaire</span>
                <span class="style-text-content">
                    Share information about your employment and work practices across migrant workers labour migration journey.
                </span>
            </div>
            <div class="button-layout">
                <button
                    class="px-3 py-2 rounded text-white bg-teal-600 style-text-button"
                    (click)="redirectSelfAssessmentQuestionnaire()"
                >
                    <mat-icon style="margin-right: 13px">mode</mat-icon>
                    Start Questionnaire
                </button>
            </div>
        </div>
    </div>

    <div class="bg-white rounded-lg text-gray-700 content-bottom">
        <span class="style-text-header" style="left: 4px; color: #8f9bb3">Latest submitted & saved</span>
        <app-document-list
            class="table-submitted"
            [documents]="document$ | async"
            [total]="total$ | async"
            [pageSize]="5"
            [pageIndex]="pageIndex$ | async"
            (paginate)="docListPaginate($event)"
            (sortChange)="docListSortChange($event)"
            (viewClicked)="goToDocument($event)"
            (deleteClicked)="deleteScopingPending($event)"
            [role]="role$ | async"
            [employerDashboard]="true"
        ></app-document-list>
    </div>

    <div class="bg-white rounded-lg text-gray-700 content-bottom margin-top">
        <span class="style-text-header" style="left: 4px; color: #8f9bb3">Recruitment Agency</span>
        <list-pra
            *ngIf="praEmployerCollection$ | async"
            [isDisplayHeader]="true"
            [pageSize]="pageSize"
            [praCollection]="praEmployerCollection$ | async"
            (paginate)="paginatePras$.next($event)"
            [errorMessages]="inactivateUserErrors$ | async"
            (filterUser)="filter$.next($event)"
            (sortChange)="sorterPra$.next($event)"
            [currentEmployerId]="currentUserId"
            (invitePra)="invitePraFnc($event)"
            (inactivatePra)="inactivatePraFnc($event)"
            (deletePra)="deletePraFnc($event)"
            [hotelName]="hotelName"
            [employerDashboard]="true"
        ></list-pra>
    </div>
</div>
