import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import format from 'date-fns/format';
import { BehaviorSubject, EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomEncoder, Logger, UserInfo, UserInfoCollection } from '../../core';
import { PraUserActionData, PraUserInfo } from '../../pra';
import { PrasResponse, Question, Questionnaire, QuestionnaireResponse, QuestionnaireResponseBrand } from '../../question-form';
import {
    ApiConstant,
    CustomHttpErrorResponse,
    IQuestionnaireEmployerResponse,
    LscmResponse,
    PaginateOptions,
    QuestionnaireType,
    UserStatus,
    IQuestionnaireRes,
    IEmployerHistoryResponse,
    IPraResponse,
    IHistory,
    IPraList,
    validEmailDiff,
} from '../../types';
import { CompanyProfile, DEFAULT_COMPANY_PROFILE, EmployerUserInfo, EMPLOYER_API_TOKEN } from '../types';
import * as myGlobals from '../../../globals';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

export const EMPLOYER_PROFILE_QUESTION_CODE = {
    companyName: 'emp_regis_A1',
    website: 'emp_regis_A2',
    location: 'emp_regis_A3',
    address: 'emp_regis_A4',
    fullName: 'emp_regis_A5',
    email: 'emp_regis_A6',
    relationshipWithBrand: 'emp_regis_A7',
    isBrandResponsibility: 'emp_regis_A8',
};

@Injectable()
export class EmployerService {
    urlDefault = this.apiConstant.endpoint;
    url = `${this.apiConstant.endpoint}/employer`;
    brandUrl = `${this.apiConstant.endpoint}/brands/employer`;

    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private inactivateUserErrorsSub$ = new Subject<string[]>();
    inactivateUserErrors$ = this.inactivateUserErrorsSub$.asObservable();

    private newUserSub$ = new Subject<UserInfo>();
    newUser$ = this.newUserSub$.asObservable();

    private userCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userCollection$ = this.userCollectionSub$.asObservable();

    private employerHistorySub$ = new BehaviorSubject<IHistory>({histories: [], total: 0});
    employerHistory$ = this.employerHistorySub$.asObservable();

    private praEmployerSub$ = new BehaviorSubject<IPraList>({recruitments: [], total: 0});
    praEmployer$ = this.praEmployerSub$.asObservable();

    private conpanyProfileSub$ = new BehaviorSubject<CompanyProfile>(DEFAULT_COMPANY_PROFILE);
    conpanyProfile$ = this.conpanyProfileSub$.asObservable();

    private formDirtySub = new BehaviorSubject<boolean>(false);
    formDirtySub$ = this.formDirtySub.asObservable();

    private questionnaireOnboardingSub$ = new Subject<any>();
    questionnaireOnboarding$ = this.questionnaireOnboardingSub$.asObservable();

    private saveQuestionnaireResResultSub$ = new Subject<UserInfo>();
    saveQuestionnaireResResult$ = this.saveQuestionnaireResResultSub$.asObservable();

    private deactivateRASub$ = new Subject<any>();
    deactivateRA$ = this.deactivateRASub$.asObservable();

    private questionnaireResponsesSub = new BehaviorSubject<Questionnaire>({
        id: '',
        type: '',
        version: 0,
        sections: [],
    });
    questionnaireResponses$ = this.questionnaireResponsesSub.asObservable();

    private companyProfileSub$ = new BehaviorSubject<any[]>([]);
    companyProfile$ = this.companyProfileSub$.asObservable();

    private lstPraSub$ = new BehaviorSubject<PrasResponse[]>([]);
    lstPra$ = this.lstPraSub$.asObservable();

    private employerQuestionSub$ = new BehaviorSubject<Question[]>([]);
    employerQuestion$ = this.employerQuestionSub$.asObservable();

    private employerSub$ = new BehaviorSubject<Question[]>([]);
    employer$ = this.employerSub$.asObservable();

    private errorSub$ = new Subject<string[]>();
    error$ = this.errorSub$.asObservable();

    // private lstCheckUniqueEmail$ = myGlobals.isEmailDifferentHotelOrHotelGroup$;
    listEmailDifferent?: validEmailDiff[] = [];

    private paginateByUserRole(
        options: PaginateOptions,
        role: string,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params;
        if (role === 'managements') {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', 'employer');
            //.append('roles', 'admin');
        } else {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', role);
        }

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<UserInfoCollection>(`${this.urlDefault}/brands/employers`, { params, headers }).pipe(
            tap(({ users, userCount }: UserInfoCollection) => {
                const localUsers: UserInfo[] = users.map(u => {
                    const strLastLoginTime = u.lastLoginTime
                        ? format(new Date(u.lastLoginTime), 'dd MMM, yyyy HH:mm:ss')
                        : '';
                    return {
                        ...u,
                        lastLoginTime: strLastLoginTime,
                    };
                });
                this.userCollectionSub$.next({ users: localUsers, userCount, startAtPage: { pageIndex } });
            }),
            catchError(() => {
                this.userCollectionSub$.next({ users: [], userCount: 0 });
                return EMPTY;
            }),
        );
    }

    private paginateEmployerHistory(
        employerId: string,
        options: PaginateOptions,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params = new HttpParams();

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const getHistoryResponseFormUrl = `${this.urlDefault}/brands/employer/${employerId}/questionnaire-histories`;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<LscmResponse<IEmployerHistoryResponse>>(getHistoryResponseFormUrl, { params, headers }).pipe(
            tap((res: LscmResponse<IEmployerHistoryResponse>) => {
                const historiesRes: IQuestionnaireRes[] = res.response?.histories?.histories.map(u => {
                    const strLastUpdate = u.createdAt
                        ? format(new Date(u.createdAt), 'dd/MM/yyyy hh:mm a')
                        : '';
                    return {
                        ...u,
                        createdAt: strLastUpdate,
                    };
                });
                this.employerHistorySub$.next({histories: historiesRes, total: res.response?.histories?.total, startAtPage: { pageIndex }});
            }),
            catchError(() => {
                this.employerHistorySub$.next({histories: [], total: 0});
                return EMPTY;
            }),
        );
    }

    private paginateListPraEmployer(
        employerId: string,
        options: PaginateOptions,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params = new HttpParams();

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const getPraEmployerUrl = `${this.urlDefault}/brands/employer/${employerId}/recruitments`;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<LscmResponse<IPraList>>(getPraEmployerUrl, { params, headers }).pipe(
            tap((res: LscmResponse<IPraList>) => {
                const praResponse: IPraResponse[] = res.response?.recruitments?.map(u => {
                    const praLocation = u.location && typeof u.location == 'object'
                        ? u.location.map((item:any) => item.key)
                        : u.location;
                    const strLastUpdate = u.updatedAt
                        ? format(new Date(u.updatedAt), 'dd/MM/yyyy hh:mm a')
                        : '';
                    return {
                        ...u,
                        location: praLocation,
                        updatedAt: strLastUpdate
                    };
                });
                this.praEmployerSub$.next({recruitments: praResponse, total: res.response?.total, startAtPage: { pageIndex }});
            }),
            catchError(() => {
                this.praEmployerSub$.next({recruitments: [], total: 0});
                return EMPTY;
            }),
        );
    }

    private paginateListAllPraEmployer(
        options: PaginateOptions,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params = new HttpParams();

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const getPraEmployerUrl = `${this.urlDefault}/employer/recruitments`;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<LscmResponse<IPraList>>(getPraEmployerUrl, { params, headers }).pipe(
            tap((res: LscmResponse<IPraList>) => {
                const praResponse: IPraResponse[] = res.response?.recruitments?.map(u => {
                    const praLocation = u.location && typeof u.location == 'object'
                        ? u.location.map((item:any) => item.key)
                        : u.location;
                    const strLastUpdate = u.updatedAt
                        ? format(new Date(u.updatedAt), 'dd/MM/yyyy hh:mm a')
                        : '';
                    return {
                        ...u,
                        location: praLocation,
                        updatedAt: strLastUpdate
                    };
                });
                this.praEmployerSub$.next({recruitments: praResponse, total: res.response?.total, startAtPage: { pageIndex }});
            }),
            catchError(() => {
                this.praEmployerSub$.next({recruitments: [], total: 0});
                return EMPTY;
            }),
        );
    }

    constructor(@Inject(EMPLOYER_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) {}

    inviteEmployer(input: EmployerUserInfo, options?: PaginateOptions) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite employer' });

        return this.httpClient.post<LscmResponse<UserInfo>>(`${this.url}/invite`, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<UserInfo>) => {
                this.formDirtySub.next(false)
                this.newUserSub$.next(response.response as UserInfo);
            }),
            concatMap(() => options ? this.paginateByUserRole(options, 'managements', 'User Management list') : ''),
        );
    }

    saveOnboardingQuestionnaireResponse(input: any) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Save onboarding questionnaire response' });

        return this.httpClient.post<LscmResponse<UserInfo>>(`${this.urlDefault}/questionnaireRes/submit`, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<any>) => {
                this.saveQuestionnaireResResultSub$.next(response.response);
            })
        );
    }

    paginteAdmins(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'admin', 'Paginate Iom user list', filter);
    }

    paginteUsers(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'user', 'Paginate PRA list', filter); //roles=[user
    }

    paginteUserManagements(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateByUserRole(options, 'managements', 'User Management list', filter, sorter);
    }

    paginteEmployerHistory(employerId: string, options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateEmployerHistory( employerId, options, 'Get list employer history', filter, sorter);
    }

    pagintePraEmployer(employerId: string, options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateListPraEmployer( employerId, options, 'Get list pra employer', filter, sorter);
    }

    paginteAllPraEmployer(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateListAllPraEmployer( options, 'Get list pra employer', filter, sorter);
    }

    getCompanyProfile( employerId: string ) {
        const getCompanyProfileUrl = `${this.urlDefault}/brands/employer/${employerId}/info`;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get company profile' });
        return this.httpClient.get<LscmResponse<CompanyProfile>>(getCompanyProfileUrl, { headers }).pipe(
            tap((res: LscmResponse<CompanyProfile>) => {
                this.conpanyProfileSub$.next(res.response);
            }),
            catchError(() => {
                this.conpanyProfileSub$.next(DEFAULT_COMPANY_PROFILE);
                return EMPTY;
            }),
        );
    }

    async inactivateAdmin(userId: string, pageSize: number):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete Iom user' });
        return this.httpClient.delete<UserInfo>(`${this.urlDefault}/employer/${userId}`, { headers }).pipe(
            tap(() => {
                this.inactivateUserErrorsSub$.next([]);
            }),
            switchMap(() =>
                this.paginteUserManagements({
                    pageIndex: 0,
                    pageSize,
                }),
            ),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.inactivateUserErrorsSub$.next(err.errorJson.message);
                } else {
                    this.inactivateUserErrorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        ).toPromise();
    }

    inactivateBrand(userId: string, status: UserStatus, pageSize: number): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated brand' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/employer/${userId}/status`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    getTotalCaseManager(): Promise<number> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get total Iom user' });

        return this.httpClient
            .get<number>(`${this.url}/number-caser-manager`, { headers })
            .pipe(
                tap((result: number) => {
                    this.errorsSub$.next([]);
                    return result;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    completeOnboarding(input: {
        id: string;
        newPassword: string;
        confirmPassword: string;
        language: number;
        time: string;
        sig: string;
    }) {
        const { id, newPassword, confirmPassword, time, sig, language } = input;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Complete onboarding process' });
        const body = {
            newPassword,
            confirmPassword,
            languageId: language,
        };

        return this.httpClient
            .put<void>(`${this.url}/onboarding-complete/${id}?time=${time}&sig=${sig}`, body, { headers })
            .pipe(
                tap(() => this.errorsSub$.next([])),
                map(() => true),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return of(false);
                }),
            );
    }

    isEmailUnique(email: string): Promise<any> {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of IOM user email' });

        return this.httpClient
            .get(`${this.url}/isEmailUnique`, { params, headers, responseType: 'text' })
            .pipe(
                map(value => {
                    if (value === 'true') {
                        return true;
                    }
                    return false;
                }),
                catchError(err => {
                    Logger.log(err);
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    isCompanyNameUnique(name: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('companyName', name);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of PRA company name' });

        return this.httpClient.get(`${this.url}/is-unique-company`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'false'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    registrationFormType: QuestionnaireType = 'employer_registration_form';
    getRegistrationFormUrl = `${this.urlDefault}/questionnaire?questionnaireType=${this.registrationFormType}`;
    questionnaire$ = this.httpClient.get(this.getRegistrationFormUrl).pipe(
        tap(console.log),
        map((res: QuestionnaireResponse) => res.response.questionnaire),
        catchError(this.handleError),
    );

    handleError(_err: any) {
        return throwError('');
    }

    getEmployerResponseFormUrl = `${this.urlDefault}/questionnaireRes`;
    documents$ = this.httpClient.get(this.getEmployerResponseFormUrl).pipe(
        tap(console.log),
        map((res: LscmResponse<IQuestionnaireEmployerResponse>) => res.response),
        catchError(this.handleError),
    );

    getQuestionnaireRes(employerId: string, questionnnaireResId: string) {
        return this.httpClient.get<QuestionnaireResponseBrand>(`${this.brandUrl}/${employerId}/questionnaire-response/${questionnnaireResId}`).pipe(
            tap((responses: QuestionnaireResponseBrand) => {
                const employer = responses?.response?.questionnaire?.employer?.sections[1]?.questions;
                const lstPra = responses?.response?.questionnaire?.pras;
                this.employerSub$.next(employer);
                this.lstPraSub$.next(lstPra);
                this.errorsSub$.next([]);
            }),
            catchError(this.handleError),
        );

    }

    async invitePra(input: PraUserInfo, employerId: string, options: PaginateOptions, order?: string):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite pra' });

        return this.httpClient.post<LscmResponse<UserInfo>>(`${this.urlDefault}/pra/invite`, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<UserInfo>) => {
                return response.response;
            }),
            concatMap(() => this.paginateListPraEmployer( employerId, options, 'Get list pra employer', undefined, order)),
        ).toPromise();
    }

    inactivatePra(praInfo: PraUserActionData, paginate: PaginateOptions, order?: string): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated pra' });
        const body = {
            status: praInfo.status === 'Active' ? 'Deactivated' : 'Active',
            email: praInfo.email
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/pra/updatePraStatus/${praInfo.questionnaireResponseId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginateListPraEmployer(praInfo.employerId, paginate, 'Get list pra employer', undefined, order),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    async deletePra(praInfo: PraUserActionData, pageSize: number): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete pra' });
        const body = {
            email: praInfo.email,
            employerId: praInfo.employerId
        };
        const options = {
            headers: headers,
            body: body
        }
        return this.httpClient
            .delete<UserInfo>(`${this.urlDefault}/pra/delete-pra/${praInfo.questionnaireResponseId}`, options)
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                    return true;
                }),
                switchMap(() =>
                    this.paginateListPraEmployer(praInfo.employerId,{
                        pageIndex: 0,
                        pageSize,
                    }, 'Get list pra employer')
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    async invitePraEmployerDashboard(input: PraUserInfo, options: PaginateOptions, order?: string):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite pra' });

        return this.httpClient.post<LscmResponse<UserInfo>>(`${this.urlDefault}/pra/invite`, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<UserInfo>) => {
                return response.response;
            }),
            concatMap(() => this.paginateListAllPraEmployer(options, 'Get list pra employer', undefined, order)),
        ).toPromise();
    }

    inactivatePraEmployerDashboard(praInfo: PraUserActionData){
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated pra' });
        const body = {
            status: praInfo.status === 'Active' ? 'Deactivated' : 'Active',
            email: praInfo.email
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/pra/updatePraStatus/${praInfo.questionnaireResponseId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
    }

    async deletePraEmployerDashboard(praInfo: PraUserActionData, paginate: PaginateOptions): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete pra' });
        const body = {
            email: praInfo.email,
            employerId: praInfo.employerId
        };
        const options = {
            headers: headers,
            body: body
        }
        return this.httpClient
            .delete<UserInfo>(`${this.urlDefault}/pra/delete-pra/${praInfo.questionnaireResponseId}`, options)
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                    return true;
                }),
                switchMap(() =>
                    this.paginateListAllPraEmployer(paginate, 'Get list pra employer')
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    downloadSAQAnswerCSV(role: string, employerId: string, questionnnaireResId: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const urlDownload = role === 'employer' ? `${this.urlDefault}/questionnaireRes/${questionnnaireResId}/downloadAnswer`
                                            : `${this.brandUrl}/${employerId}/questionnaire-response/${questionnnaireResId}/downloadAnswer`;
        return this.httpClient.post(urlDownload, null, {responseType: 'blob', observe: 'response', headers})
        .pipe(
            map(response => ({
                body: response.body,
                contentDisposition: response.headers.get('content-disposition') || '',
                contentType: response.headers.get('content-type') || 'application/octet-stream',
            })),
        );
    }

    downloadSAQEvaluationCSV(role: string, employerId: string, questionnnaireResId: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const urlDownload = role === 'employer' ? `${this.urlDefault}/questionnaireRes/${questionnnaireResId}/downloadEvaluation`
                                            : `${this.brandUrl}/${employerId}/questionnaire-response/${questionnnaireResId}/downloadEvaluation`;
        return this.httpClient.post(urlDownload, null, {responseType: 'blob', observe: 'response', headers})
        .pipe(
            map(response => ({
                body: response.body,
                contentDisposition: response.headers.get('content-disposition') || '',
                contentType: response.headers.get('content-type') || 'application/octet-stream',
            })),
        );
    }

    openFile(response: any) {
        if (response && response.body) {
            const { contentType } = response;
            const filename = this.getFileNameFromContentDisposition(response);
            saveAs(new Blob([response.body], { type: contentType }), filename);
        }
    }
    private getFileNameFromContentDisposition(res: any) {
        const { contentDisposition } = res;
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const tempFilename = ((matches && matches[1]) || 'untitled').trim();
        const filename = tempFilename.replace(/["]/g, '');
        return filename;
    }

    // Request link to Hotel/Hotel Group
    requestLinkHotelOrHotelGroup(role: string, code: string) {
        const url = role == 'brand' ? `${this.urlDefault}/brands/request-link-hotel` : `${this.urlDefault}/employer/request-link-hotel-group` ;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            code: code,
        };

        return this.httpClient.post<any>(url, body, {headers}).pipe(
            map((_res) => {
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsSub$.next(err.errorJson.message);
                } else {
                    this.errorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        );
    }

    // Hotel/Hotel Group link request
    acceptRequestHotelOrHotelGroup(role: string, id: string, responseRequestLink: any): Observable<any>{
        const url = role === 'brand' ? `${this.urlDefault}/brands/response-request-link-hotel/${id}`
                                     : `${this.urlDefault}/employer/response-request-link-hotel-group/${id}`;
        return this.httpClient.post<any>(url, responseRequestLink).pipe(
            map((_res) => {
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorSub$.next(err.errorJson.message);
                } else {
                    this.errorSub$.next([err.message]);
                }
                return EMPTY;
            }),
        )
    }

    isEmailDifferentHotelOrHotelGroup(email: string, partialId?: string): Promise<any> {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate email different hotel or hotel group email' });

        return this.httpClient
            .get(`${this.urlDefault}/pra/isEmailDifferentHotelOrHotelGroup`, { params, headers, responseType: 'text' })
            .pipe(
                map(value => {
                    let result = false;
                    if (value === 'true') {
                        result =  true;
                    }
                    let items = localStorage.getItem('lstEmailDiffOther');
                    if (items) {
                        this.listEmailDifferent = JSON.parse(items);
                    }
                    let newList: any = [];
                    this.listEmailDifferent?.map((x:validEmailDiff) => {
                        if (x.key === partialId) {
                            x.value = result;
                        }
                    })
                    const lstKey = this.listEmailDifferent?.map((x:validEmailDiff) => x.key);
                    if (!lstKey?.includes(partialId)) {
                        newList.push({key: partialId, value: result});
                    }
                    this.listEmailDifferent = this.listEmailDifferent?.concat(newList);
                    localStorage.setItem('lstEmailDiffOther', JSON.stringify(this.listEmailDifferent));
                    myGlobals.isEmailDifferentHotelOrHotelGroupSub$.next(this.listEmailDifferent);
                    return result;
                }),
                catchError(err => {
                    Logger.log(err);
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    getQuestionnaireOnboarding(hotelId: string) {
        return this.httpClient.get(`${this.getRegistrationFormUrl}&hotelId=${hotelId}`).pipe(
            tap(console.log),
            map((res: QuestionnaireResponse) => this.questionnaireOnboardingSub$.next(res.response.questionnaire)),
            catchError(this.handleError),
        );

    }

    isHotelNameUnique(name: string):Promise<any> {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('companyName', name);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of brand company name' });

        return this.httpClient.get(`${this.url}/is-unique-company`, { params, headers, responseType: 'text' }).pipe(
            map(value => {
                if (value === 'true') {
                    return true;
                }
                return false;
            }),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        ).toPromise();
    }

    isHotelNameUniqueHotelSAQA1(name: string):Promise<any> {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('companyName', name);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of brand company name' });

        return this.httpClient.get(`${this.url}/is-unique-company`, { params, headers, responseType: 'text' }).pipe(
            map(value => {
                if (value === 'true') {
                    myGlobals.validateEmployerSaqA1Sub$.next(true);
                    return true;
                }
                myGlobals.validateEmployerSaqA1Sub$.next(false);
                return false;
            }),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        ).toPromise();
    }

    brandCheckDeactivatePra(id: string, email: string):Promise<any> {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Check Deactivate RA' });

        return this.httpClient.get<LscmResponse<any>>(`${this.url}/${id}/check-deactivate-pra`, { params, headers })
        .pipe(
            tap((response: LscmResponse<any>) => {
                if (response) {
                    this.deactivateRASub$.next(response)
                    this.errorSub$.next([])
                }
            }),
            catchError(err => {
                if (err.errorJson) {
                    this.errorSub$.next(err.errorJson.message);
                    return err.errorJson.message;
                } else {
                    this.errorSub$.next([err.message]);
                    return err.message;
                }
            }),
        ).toPromise();
    }

    hotelCheckDeactivatePra(email: string):Promise<any> {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Check Deactivate RA' });

        return this.httpClient.get<LscmResponse<any>>(`${this.url}/check-deactivate-pra`, { params, headers })
        .pipe(
            tap((response: LscmResponse<any>) => {
                if (response) {
                    this.deactivateRASub$.next(response)
                    this.errorsSub$.next([])
                }
            }),
            catchError(err => {
                if (err.errorJson) {
                    this.errorsSub$.next(err.errorJson.message);
                    return err.errorJson.message;
                } else {
                    this.errorsSub$.next([err.message]);
                    return err.message;
                }
            }),
        ).toPromise();
    }

}
