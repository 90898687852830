import { Component, Input } from '@angular/core';
import { BadgeWidgetOptions, DEFAULT_BADGE_WIDGET_OPTIONS } from './badge-widget.types';

@Component({
  selector: 'app-badge-widget',
  templateUrl: './badge-widget.component.html',
  styleUrls: ['./badge-widget.component.scss']
})
export class BadgeWidgetComponent {
    @Input() options: BadgeWidgetOptions = DEFAULT_BADGE_WIDGET_OPTIONS;
    @Input() pdfPreview: boolean = false;
}
