import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import format from "date-fns/format";
import { Observable } from "rxjs";
import { BehaviorSubject, EMPTY, Subject, throwError } from "rxjs";
import { tap } from "rxjs/internal/operators/tap";
import { catchError, concatMap, map, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CustomEncoder, UserInfo, UserInfoCollection } from "../../core";
import { CompanyProfile, DEFAULT_COMPANY_PROFILE, EMPLOYER_API_TOKEN } from "../../employer/types";
import { PraInforResponse, PraUserActionData, PraUserInfo } from "../../pra";
import { Question } from "../../question-form";
import { ApiConstant, CustomHttpErrorResponse, IHistoryPra, IPraList, IPraResponse, LscmResponse, PaginateOptions, UserStatus } from "../../types";
const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable({
    providedIn: 'root',
  })
export class RecruitmentAgenciesService {
    urlDefault = this.apiConstant.endpoint;
    url = `${this.apiConstant.endpoint}/brands`;
    urlEmp = `${this.apiConstant.endpoint}/employer`;

    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private recruitmentAgencySub$ = new BehaviorSubject<IPraList>({recruitments: [], total: 0});
    recruitmentAgency$ = this.recruitmentAgencySub$.asObservable();

    private inactivateUserErrorsSub$ = new Subject<string[]>();
    inactivateUserErrors$ = this.inactivateUserErrorsSub$.asObservable();

    private agencyProfileOptionSub$ = new Subject<PraInforResponse>();
    agencyProfileOption$ = this.agencyProfileOptionSub$.asObservable();

    companyProfileSub = new BehaviorSubject<Question[]>([]);
    companyProfile$ = this.companyProfileSub.asObservable();
    documentSub$ = new BehaviorSubject<any>([]);
    document$ = this.documentSub$.asObservable();

    constructor(
        @Inject(EMPLOYER_API_TOKEN)
        private apiConstant: ApiConstant,
        private httpClient: HttpClient
    ) {}

    private paginateListRecruitmentAgencies(
        options: PaginateOptions,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params = new HttpParams();

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const getPraEmployerUrl = `${this.url}/recruitments`;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<LscmResponse<IPraList>>(getPraEmployerUrl, { params, headers }).pipe(
            tap((res: LscmResponse<IPraList>) => {
                const praResponse: IPraResponse[] = res.response?.recruitments?.map(u => {
                    const praLocation = u.location && typeof u.location == 'object'
                        ? u.location.map((item:any) => item.key)
                        : u.location;
                    const strLastUpdate = u.updatedAt
                        ? format(new Date(u.updatedAt), 'dd/MM/yyyy hh:mm a')
                        : '';
                    return {
                        ...u,
                        location: praLocation,
                        updatedAt: strLastUpdate
                    };
                });
                this.recruitmentAgencySub$.next({recruitments: praResponse, total: res.response?.total, startAtPage: { pageIndex }});
            }),
            catchError(() => {
                this.recruitmentAgencySub$.next({recruitments: [], total: 0});
                return EMPTY;
            }),
        );
    }

    private paginateListRecruitmentAgenciesDetail(
        role: string,
        employerId: string,
        questionnaireResId: string,
        options: PaginateOptions,
        filter?: string,
        orderBy?: string,
    ) {
        let params = new HttpParams();

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get all questionnaire response recruitment agency' });
            const url = role === 'brand' ?
                    `${this.url}/employer/${employerId}/recruitments/${questionnaireResId}/questionnaire-histories`
                    : `${this.urlDefault}/employer/recruitments/${questionnaireResId}/questionnaire-histories`;
            return this.httpClient.get<LscmResponse<IHistoryPra>>(url, { params, headers }).pipe(
                tap((res: LscmResponse<IHistoryPra>) => {
                    this.totalCountSub.next(res?.response?.histories?.total);
                    this.errorsSub$.next([]);
                }),
                map((res: LscmResponse<IHistoryPra>) => {
                    res?.response?.histories?.histories.map(item => {
                        const location = item.location && typeof item.location == 'object' ? item.location.map((x: any) => { return x.key ? x.key : x;}) : item.location;
                        return {
                            ...item,
                            location: location,
                        };
                    });
                    this.documentSub$.next(res?.response?.histories?.histories);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    async inviteRA(input: PraUserInfo, options: PaginateOptions, order?: string):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite pra' });

        return this.httpClient.post<LscmResponse<UserInfo>>(`${this.urlDefault}/pra/invite`, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<UserInfo>) => {
                return response.response;
            }),
            concatMap(() => this.paginateListRecruitmentAgencies(options, 'Get all recruitment agencies', undefined, order)),
        ).toPromise();
    }

    inactivateRA(praInfo: PraUserActionData, paginate: PaginateOptions, order?: string): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated pra' });
        const body = {
            status: praInfo.status === 'Active' ? 'Deactivated' : 'Active',
            email: praInfo.email
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/pra/updatePraStatus/${praInfo.questionnaireResponseId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginateListRecruitmentAgencies(paginate, 'Get all recruitment agencies', undefined, order),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    async deleteRA(praInfo: PraUserActionData, pageSize: number): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete pra' });
        const body = {
            email: praInfo.email,
            employerId: praInfo.employerId
        };
        const options = {
            headers: headers,
            body: body
        }
        return this.httpClient
            .delete<UserInfo>(`${this.urlDefault}/pra/delete-pra/${praInfo.questionnaireResponseId}`, options)
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                    return true;
                }),
                switchMap(() =>
                    this.paginateListRecruitmentAgencies({
                        pageIndex: 0,
                        pageSize,
                    }, 'Get all recruitment agencies')
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    paginteRA(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateListRecruitmentAgencies(options, 'Get all recruitment agencies', filter, sorter);
    }

    paginteRAQuestionnaireRes(role: string, employerId: string, questionnaireResId: string, options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateListRecruitmentAgenciesDetail(role, employerId, questionnaireResId, options, filter, sorter);
    }

    getRAgenciesDetail(role: string, userId: string, questResponseId: string) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Employer detail' });
        const url = role === 'brand' ? `${this.apiConstant.endpoint}/brands/employer/${userId}/recruitments/${questResponseId}` : `${this.urlEmp}/recruitments/${questResponseId}`;
        return this.httpClient.get<PraInforResponse>(`${url}`, { headers }).pipe(
            tap((result: PraInforResponse) => {
                this.agencyProfileOptionSub$.next(result);
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsSub$.next(err.errorJson.message);
                } else {
                    this.errorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        );
    }

    async inviteRAgenciesDetail(input?: PraUserInfo):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Invite pra' });

        return this.httpClient.post<LscmResponse<UserInfo>>(`${this.urlDefault}/pra/invite`, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<UserInfo>) => {
                return response.response;
            }),
        ).toPromise();
    }

    async requestSubmitRAgenciesDetail(role:string, input: PraUserInfo):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Request submit agency detail' });
        const urlReq = role === 'brand' ? `${this.url}/employer/${input?.employerId}/recruitments/${input?.questionnaireResponseId}/request-saq`
                                        : `${this.urlEmp}/recruitments/${input?.questionnaireResponseId}/request-saq`;
        return this.httpClient.post<LscmResponse<UserInfo>>(urlReq, input, { headers }).pipe(
            catchError((err: CustomHttpErrorResponse) => {
                this.errorsSub$.next(err.errorJson.message);
                return EMPTY;
            }),
            tap((response: LscmResponse<UserInfo>) => {
                return response.response;
            }),
        ).toPromise();
    }

    getHotelInfo(role: string, employerId: string) {
        const urlInfo = role === 'brand' ? `${this.url}/employer-info/${employerId}` : `${this.urlEmp}/info`;
        return this.httpClient.get<any>(urlInfo).pipe(
            tap((responses: any) => {
                const res = responses?.response?.info;
                this.companyProfileSub.next(res);
            }),
            catchError(this.handleError),
        );
    }

    handleError(_err: any) {
        return throwError('');
    }

    DEFAULT_PAGINATION: PaginateOptions = {
        pageIndex: 0,
        pageSize: 5,
    };

    paginationSub = new BehaviorSubject<PaginateOptions>(this.DEFAULT_PAGINATION);
    pagination$ = this.paginationSub.asObservable();

    sortSub = new BehaviorSubject<Sort>({ active: 'location', direction: 'asc' });
    sort$ = this.sortSub.asObservable();
    updateSort(sort: Sort) {
        this.sortSub.next(sort);
    }

    totalCountSub = new BehaviorSubject<number>(0);
    total$ = this.totalCountSub.asObservable();

    // Deactivate or Activate Agency
    private userCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userCollection$ = this.userCollectionSub$.asObservable();
    private conpanyProfileSub$ = new BehaviorSubject<CompanyProfile>(DEFAULT_COMPANY_PROFILE);
    conpanyProfile$ = this.conpanyProfileSub$.asObservable();

    private paginateByUserRole(
        options: PaginateOptions,
        role: string,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params;
        if (role === 'managements') {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', 'employer');
            //.append('roles', 'admin');
        } else {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', role);
        }

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient.get<UserInfoCollection>(`${this.urlDefault}/user/employers`, { params, headers }).pipe(
            tap(({ users, userCount }: UserInfoCollection) => {
                const localUsers: UserInfo[] = users.map(u => {
                    const strLastLoginTime = u.lastLoginTime
                        ? format(new Date(u.lastLoginTime), 'dd MMM, yyyy HH:mm:ss')
                        : '';
                    return {
                        ...u,
                        lastLoginTime: strLastLoginTime,
                    };
                });
                this.userCollectionSub$.next({ users: localUsers, userCount, startAtPage: { pageIndex } });
            }),
            catchError(() => {
                this.userCollectionSub$.next({ users: [], userCount: 0 });
                return EMPTY;
            }),
        );
    }

    paginteUserManagements(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateByUserRole(options, 'managements', 'User Management list', filter, sorter);
    }

    getCompanyProfile( employerId: string ) {
        const getCompanyProfileUrl = `${this.urlDefault}/brands/employer/${employerId}/info`;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get company profile' });
        return this.httpClient.get<LscmResponse<CompanyProfile>>(getCompanyProfileUrl, { headers }).pipe(
            tap((res: LscmResponse<CompanyProfile>) => {
                this.conpanyProfileSub$.next(res.response);
            }),
            catchError(() => {
                this.conpanyProfileSub$.next(DEFAULT_COMPANY_PROFILE);
                return EMPTY;
            }),
        );
    }

    inactivateRecruitmentAgencyDetail(questionnaireResId: string, status: UserStatus, email: string): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Deactivated or active recruitment agency detail' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
            email: email
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/pra/updatePraStatus/${questionnaireResId}`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                // switchMap(() =>
                //     this.paginteUserManagements({
                //         pageIndex: 0,
                //         pageSize,
                //     }),
                // ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    // Delete Agency detail
    async inactivateAdmin(userId: string, pageSize: number):Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete Iom user' });
        return this.httpClient.delete<UserInfo>(`${this.urlDefault}/employer/${userId}`, { headers }).pipe(
            tap(() => {
                this.inactivateUserErrorsSub$.next([]);
            }),
            switchMap(() =>
                this.paginteUserManagements({
                    pageIndex: 0,
                    pageSize,
                }),
            ),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.inactivateUserErrorsSub$.next(err.errorJson.message);
                } else {
                    this.inactivateUserErrorsSub$.next([err.message]);
                }
                return EMPTY;
            }),
        ).toPromise();
    }

    downloadEvaluationRASAQCSV(role: string, employerId: string, questionnnaireResId: string, praQuestionnnaireResId?: string): Observable<any> {
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Download evaluation recruitment agency SAQ',
        });
        const urlDownload = role === 'employer' ? `${this.urlDefault}/employer/recruitments/${questionnnaireResId}/questionnaire-response/${praQuestionnnaireResId}/downloadEvaluation`
                        : `${this.url}/employer/${employerId}/recruitments/${questionnnaireResId}/questionnaire-response/${praQuestionnnaireResId}/downloadEvaluation`;
        return this.httpClient.post(urlDownload, null, {responseType: 'blob', observe: 'response', headers})
        .pipe(
            map(response => ({
                body: response.body,
                contentDisposition: response.headers.get('content-disposition') || '',
                contentType: response.headers.get('content-type') || 'application/octet-stream',
            })),
        );
    }

    downloadAnswerRASAQCSV(role: string, employerId: string, questionnnaireResId: string, praQuestionnnaireResId?: string): Observable<any> {
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Download answer recruitment agency SAQ',
        });
        const urlDownload = role === 'employer' ? `${this.urlDefault}/employer/recruitments/${questionnnaireResId}/questionnaire-response/${praQuestionnnaireResId}/downloadAnswer`
                        : `${this.url}/employer/${employerId}/recruitments/${questionnnaireResId}/questionnaire-response/${praQuestionnnaireResId}/downloadAnswer`;

        return this.httpClient.post(urlDownload, null, {responseType: 'blob', observe: 'response', headers})
        .pipe(
            map(response => ({
                body: response.body,
                contentDisposition: response.headers.get('content-disposition') || '',
                contentType: response.headers.get('content-type') || 'application/octet-stream',
            })),
        );
    }

    openFile(response: any) {
        if (response && response.body) {
            const { contentType } = response;
            const filename = this.getFileNameFromContentDisposition(response);
            saveAs(new Blob([response.body], { type: contentType }), filename);
        }
    }
    private getFileNameFromContentDisposition(res: any) {
        const { contentDisposition } = res;
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const tempFilename = ((matches && matches[1]) || 'untitled').trim();
        const filename = tempFilename.replace(/["]/g, '');
        return filename;
    }
}
