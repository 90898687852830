<div class="pra-nav">
    <div class="pra-nav-wrap">
        <mat-card-actions>
            <button type="button" class="btn-add" mat-button aria-label="submit" (click)="addItem()">
                <img src="assets/images/icon-plus.svg" alt="icon" class="icon-plus" />
                Add Recruitment Agency
            </button>
        </mat-card-actions>
    </div>
    <div class="pra-nav-des">
        Please identify the third party service providers that you directly work with in recruiting migrant workers from
        the Philippines.
    </div>
</div>
