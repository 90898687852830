import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomEncoder, Logger, UserInfo, UserInfoCollection } from '../../core';
import { ApiConstant, CustomHttpErrorResponse } from '../../types';
import { BrandRegisterUserInfo, BRAND_REGISTER_API_TOKEN } from '../types';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class BrandRegisterService {
    constructor(@Inject(BRAND_REGISTER_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) {}

    url = `${this.apiConstant.endpoint}/brands`;
    urlAuth = `${this.apiConstant.endpoint}/auth`;

    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private registerSuccessSub$ = new Subject<string[]>();
    registerSuccess$ = this.registerSuccessSub$.asObservable();

    private inactivateUserErrorsSub$ = new Subject<string[]>();
    inactivateUserErrors$ = this.inactivateUserErrorsSub$.asObservable();

    private newUserSub$ = new Subject<UserInfo>();
    newUser$ = this.newUserSub$.asObservable();

    private lstCaseMangerSub$ = new BehaviorSubject<UserInfo[]>([]);
    lstCaseManger$ = this.lstCaseMangerSub$.asObservable();

    private userCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userCollection$ = this.userCollectionSub$.asObservable();

    registerBrand(input: BrandRegisterUserInfo) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Create new Brand user' });

        const { fullName, email, name, password, confirmPassword } = input;

        return this.httpClient
            .post<UserInfo>(
                `${this.url}/register`,
                {
                    fullName,
                    email,
                    name,
                    password,
                    confirmPassword,
                },
                { headers },
            )
            .pipe(
                tap((newUser: UserInfo) => {
                    this.newUserSub$.next(newUser);
                    this.errorsSub$.next([]);
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .subscribe();
    }

    isEmailUnique(email: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of BRAND user email' });

        return this.httpClient.get(`${this.urlAuth}/isEmailUnique`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'true'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    isCompanyNameUnique(name: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('companyName', name);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of brand company name' });

        return this.httpClient.get(`${this.url}/is-unique-company`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'true'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }
}
