import { InjectionToken } from '@angular/core';
import { ApiConstant } from '../types';

export const BRAND_API_TOKEN = new InjectionToken<ApiConstant>('api.constant');

export interface BrandUserInfo {
    fullname: string;
    email: string;
    company: string;
}
export interface ForcePasswordChangeBrand {
    newPassword: string;
    confirmPassword: string;
    language: number;
    time: string;
    sig: string;
}
export type ExportType = 'scoping-template' | 'hotel-saq' | 'recruitment-saq' | 'worker-interview';
