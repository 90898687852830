<div class="user-info">
    <form novalidate role="form" [formGroup]="form">
        <ng-container>
            <mat-card>
                <mat-card-content class="content-container">
                    <mat-card-title class="sub-title">
                        {{ brandName?.value }}
                    </mat-card-title>
                    <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="120px">
                        <div class="user-item">
                            <div class="form-field-label">Hotel Group ID</div>
                            <div class="mat-form-field-read-only">
                                <mat-form-field appearance="outline">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <input
                                            formControlName="identificationCode"
                                            #myIdentificationCode
                                            matInput
                                            [attr.disabled]="'disabled'"
                                            readonly
                                            placeholder="Hotel Group ID"
                                        />
                                        <mat-icon
                                            class="color-icon mat-icon-copy"
                                            matTooltip="Click to copy"
                                            [cdkCopyToClipboard]="identificationCode.value"
                                            >content_copy</mat-icon
                                        >
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="user-item">
                            <div class="form-field-label">Current Password</div>
                            <div class="item-group">
                                <mat-form-field appearance="outline" class="item-password">
                                    <input
                                        matInput
                                        [type]="hide ? 'password' : 'text'"
                                        placeholder="*********"
                                        [attr.aria-label]="'current password'"
                                        autocomplete="off"
                                        formControlName="password"
                                    />
                                    <button
                                        mat-icon-button
                                        type="button"
                                        matSuffix
                                        (click)="hide = !hide"
                                        [attr.aria-label]="'Hide current password'"
                                        [attr.aria-pressed]="hide"
                                    >
                                        <mat-icon>{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </button>
                                    <mat-error *ngIf="password.errors && password.errors['required']">
                                        Please input your current password.
                                    </mat-error>
                                    <mat-error *ngIf="password.errors && password.errors['pattern']">
                                        Please input a valid password.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!-- <app-error-box
                                *ngIf="!password.errors"
                                [errorMessages]="errorMessagesPass | async"
                                class="error-mess-box"
                            ></app-error-box> -->
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="120px">
                        <div class="user-item">
                            <div class="form-field-label">Headquarters</div>
                            <div class="mat-form-field-read-only">
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        formControlName="headquarter"
                                        placeholder="Hotel headquarters"
                                        [attr.disabled]="'disabled'"
                                        readonly
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="user-item">
                            <div class="form-field-label" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                                <span>New Password</span>
                                <mat-icon class="color-icon" [matTooltip]="tooltipList" [matTooltipPosition]="'right'"
                                    >info_outline</mat-icon
                                >
                            </div>
                            <div class="item-group">
                                <mat-form-field appearance="outline" class="item-password">
                                    <input
                                        matInput
                                        [type]="hideNewPassword ? 'password' : 'text'"
                                        placeholder="*********"
                                        [attr.aria-label]="'Password'"
                                        autocomplete="off"
                                        required
                                        formControlName="newPassword"
                                        (ngModelChange)="passwordChanged($event)"
                                    />
                                    <button
                                        mat-icon-button
                                        type="button"
                                        matSuffix
                                        (click)="hideNewPassword = !hideNewPassword"
                                        [attr.aria-label]="'Hide new password'"
                                        [attr.aria-pressed]="hideNewPassword"
                                    >
                                        <mat-icon>{{ hideNewPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </button>
                                    <mat-error *ngIf="newPassword.errors && newPassword.errors['required']">
                                        Please input your new password.
                                    </mat-error>
                                    <mat-error *ngIf="newPassword.errors && newPassword.errors['pattern']">
                                        Please input a valid password.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="120px">
                        <div class="user-item">
                            <div class="form-field-label">Address</div>
                            <div class="mat-form-field-read-only">
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        formControlName="address"
                                        [attr.disabled]="'disabled'"
                                        placeholder="Address"
                                        readonly
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="user-item">
                            <div class="form-field-label">Re-enter Password</div>
                            <div class="item-group">
                                <mat-form-field appearance="outline" class="item-password">
                                    <input
                                        matInput
                                        [type]="hideReEnterPassword ? 'password' : 'text'"
                                        placeholder="*********"
                                        [attr.aria-label]="'Password'"
                                        autocomplete="off"
                                        formControlName="confirmPassword"
                                        required
                                    />
                                    <button
                                        mat-icon-button
                                        type="button"
                                        matSuffix
                                        (click)="hideReEnterPassword = !hideReEnterPassword"
                                        [attr.aria-label]="'Hide confirm password'"
                                        [attr.aria-pressed]="hideReEnterPassword"
                                    >
                                        <mat-icon>{{ hideReEnterPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                                    </button>
                                    <mat-error *ngIf="confirmPassword.errors && confirmPassword.errors['required']">
                                        Please input your re-enter password.
                                    </mat-error>
                                    <mat-error *ngIf="confirmPassword.value && hasError(confirmPassword, 'passwordNotMatch')">
                                        Re-enter password doesn't match with New Password.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="120px">
                        <div class="user-item">
                            <div class="form-field-label">Name</div>
                            <div class="mat-form-field-read-only">
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        formControlName="fullName"
                                        placeholder="Full Name"
                                        readonly
                                        [attr.disabled]="'disabled'"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="user-item">
                            <mat-card-actions class="item-button">
                                <button
                                    type="button"
                                    mat-button
                                    aria-label="submit"
                                    [ngClass]="form.invalid ? 'btn-disabled' : ''"
                                    [disabled]="form.invalid"
                                    (click)="changePassWord()"
                                >
                                    Save new password
                                </button>
                            </mat-card-actions>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </form>
    <form novalidate role="form" [formGroup]="formEmail">
        <ng-container>
            <mat-card class="mat-elevation-z0">
                <mat-card-content class="content-container">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="120px">
                            <div class="user-item">
                                <div class="form-field-label">Email address</div>
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        type="email"
                                        formControlName="email"
                                        [attr.aria-label]="'Email'"
                                        placeholder="Enter Email"
                                        autocomplete="off"
                                        [errorStateMatcher]="matcher"
                                    />
                                    <mat-error *ngIf="email.errors && email.errors['required']"
                                        >Please input your email.
                                    </mat-error>
                                    <mat-error *ngIf="email.errors && email.errors['email']"
                                        >Please input a valid email.
                                    </mat-error>
                                    <mat-error *ngIf="email.errors && email.errors['taken']">
                                        This email already exists.
                                    </mat-error>
                                </mat-form-field>
                                <!-- <app-error-box
                                    *ngIf="!email.errors"
                                    [errorMessages]="errorMessages | async"
                                    class="error-mess-box"
                                ></app-error-box> -->
                            </div>
                            <div class="user-item"></div>
                        </div>
                        <div class="user-item">
                            <mat-card-actions class="item-button-email">
                                <button
                                    type="button"
                                    mat-button
                                    aria-label="submit"
                                    class="btn-email"
                                    [ngClass]="formEmail.invalid ? 'btn-disabled' : ''"
                                    [disabled]="formEmail.invalid"
                                    (click)="changeEmail()"
                                >
                                    Update email address
                                </button>
                            </mat-card-actions>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </form>
</div>
