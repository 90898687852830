import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as myGlobals from '../../../../globals';

@Component({
    selector: 'app-currency-input',
    templateUrl: './currency-input.component.html',
    styleUrls: ['./currency-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyInputComponent extends FieldType implements OnInit {
    ngOnInit() {
        if (this.field.formControl?.value) {
            this.formControl.markAsTouched();
        } else {
            this.formControl.markAsUntouched();
        }
    }
    onPercentChange($event: any) {
        this.to.change?.(this.field, $event);
        myGlobals.valueChangeSub$.next({questionId: this.field.key, value: this.field.formControl?.value});
    }
    onFocus() {
        if (this.formControl.value === undefined) {
            this.formControl.setValue('');
        }
    }
}
