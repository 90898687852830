<div class="layout-common">
    <div class="openModal" *ngIf="(openNotification$ | async)"></div>
    <div class="layout-common__wrap">
        <div class="layout-common__breadcrumb">
            <div class="layout-common__title">{{ layoutData.pageTitle }}</div>
            <div class="layout-common__des">{{ layoutData.pageDes }}</div>
            <div class="breadcrumb" *ngIf="layoutData.breadcrumb && !(hasAsyncBreadcrumb$ | async)">
                <div
                    *ngFor="let item of layoutData.breadcrumb; let idx = index"
                    class="breadcrumb-item"
                    [routerLink]="[item.path]"
                    [routerLinkActive]="'active-item'"
                    [routerLinkActiveOptions]="{exact: true}"
                >
                    {{ item.name }}
                </div>
            </div>
            <div class="breadcrumb" *ngIf="hasAsyncBreadcrumb$ | async">
                <div
                    *ngFor="let item of breadcrumbItems$ | async; let idx = index"
                    class="breadcrumb-item"
                    [routerLink]="[item.path]"
                    [routerLinkActive]="'active-item'"
                    [routerLinkActiveOptions]="{exact:true}"
                >
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="layout-common__actions">
            <button *ngIf="layoutData.isAddUser" style="width: 136px; min-width: 136px;" class="active-btn" type="submit" color="primary" name="submit" mat-button (click)="inviteEmployee()">
                <span class="btn-info">
                    <!-- <img src="../assets/images/add_user.png" alt="add" /> -->
                    <mat-icon>person_add_alt_1</mat-icon>
                    <span>Add User</span>
                </span>
            </button>
            <button *ngIf="layoutData.isIOM_AddUser" style="width: 136px; min-width: 136px;" class="active-btn" type="submit" color="primary" name="submit" mat-button (click)="inviteIOM()">
                <span class="btn-info">
                    <!-- <img src="../assets/images/add_user.png" alt="add" /> -->
                    <mat-icon>person_add_alt_1</mat-icon>
                    <span>Add User</span>
                </span>
            </button>
            <button *ngIf="layoutData.enableInvite" class="active-btn" type="submit" color="primary" name="submit" mat-button (click)="inviteHotel()">
                <mat-icon>add</mat-icon>
                    Invite Hotel
            </button>
            <button *ngIf="layoutData.enableRequestLink" class="active-btn" type="submit" color="primary" name="submit" mat-button (click)="requestLink()">
                <mat-icon>link</mat-icon>
                Request link to {{hotelName}}
            </button>
            <div *ngIf="actions$ | async" class="lst-action">
                <div *ngFor="let action of actions$ | async" class="action-item">
                    <button mat-raised-button color="accent" (click)="action.actionFn()">
                        <mat-icon *ngIf="action.iconCls" aria-hidden="false" aria-label="{{ action.iconCls }}">{{
                            action.iconCls
                        }}</mat-icon>
                        {{ action.name }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="layout-common__border"></div>
    <div>
        <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    </div>
</div>
