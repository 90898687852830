import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-brand-term-dialog',
    templateUrl: './brand-term-dialog.component.html',
    styleUrls: ['./brand-term-dialog.component.scss'],
})
export class BrandTermDialogComponent implements OnInit {
    agreeTermCheck = false;
    constructor(
        private matDialogRef: MatDialogRef<BrandTermDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            isCheck: boolean;
        },
    ) {
        this.agreeTermCheck = data.isCheck;
    }

    ngOnInit(): void {
        this.matDialogRef.backdropClick().subscribe(async () => await this.closeDialog());
    }

    async closeDialog() {
        this.matDialogRef.close(this.agreeTermCheck);
    }
    agreeTerm() {
        this.agreeTermCheck = !this.agreeTermCheck;
    }
}
