<!-- Layout: Modern -->
<div class="modern-layout" *ngIf="to.layout === 'modern'">
    <input  #numberCountry [value]="(numberCountry$ | async | json)" type="hidden" />
    <div *ngFor="let item of (numberCountry$ | async)">
        <div *ngIf="item.key === field?.partialId" >
            <input #myControl [value]="item.value" type="hidden" />
            <div class="user-input" [class]="myControl?.value >= 2 || field?.templateOptions?.readonly ? 'hidden-form-field' : ''">
                <div class="button-wrapper">
                    <button
                        (click)="addCountry(myControl?.value, numberCountry.value)"
                        type="button"
                        mat-button
                    >
                        <span class="symbol">
                            {{to?.symbol}}
                        </span>
                        <span class="label">
                            {{to?.label}}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
