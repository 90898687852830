import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreetextInputComponent } from './freetext-input/freetext-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MaterialModule } from '../material.module';
import { GroupQuestionComponent } from './group-question/group-question.component';
import { GroupQuestionItemComponent } from './group-question-item/group-question-item.component';
import { TotalValuePipe } from './total-value.pipe';
import { TotalValueByAnswerPipe } from './total-value-by-answer.pipe';
import { LogicalInputComponent } from './logical-input/logical-input.component';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { SingleSelectInputComponent } from './single-select-input/single-select-input.component';
import { QuantityInputComponent } from './quantity-input/quantity-input.component';
import { CustomValidators } from './custom-validators';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MultipleSelectInputComponent } from './multi-select-input/multi-select-input.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { WrapQuestionComponent } from './wrap-question/wrap-question.component';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { WrapCountryGroupComponent } from './wrap-country-group/wrap-country-group.component';
import { QuestionButtonComponent } from './question-button/question-button.component';
import { DropDownCountryInputComponent } from './drop-down-country-input/drop-down-country-input.component';
import { CountryFlagFormFieldPipe } from './country-flag-form-field.pipe';
import { QuestionGroupLabelComponent } from './question-group-label/question-group-label.component';

@NgModule({
    declarations: [
        FreetextInputComponent,
        GroupQuestionComponent,
        GroupQuestionItemComponent,
        TotalValuePipe,
        TotalValueByAnswerPipe,
        LogicalInputComponent,
        SingleSelectInputComponent,
        QuantityInputComponent,
        MultipleSelectInputComponent,
        WrapQuestionComponent,
        AttachmentComponent,
        CurrencyInputComponent,
        WrapCountryGroupComponent,
        QuestionButtonComponent,
        DropDownCountryInputComponent,
        CountryFlagFormFieldPipe,
        QuestionGroupLabelComponent
    ],
    imports: [
        NgMultiSelectDropDownModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FormlySelectModule,
        FormlyMaterialModule,
        MaterialModule,
        FormlyModule.forRoot({
            extras: { lazyRender: true },
            types: [
                {
                    name: 'currency',
                    component: CurrencyInputComponent,
                },
                {
                    name: 'quantity',
                    component: QuantityInputComponent,
                },
                {
                    name: 'freetext',
                    component: FreetextInputComponent,
                },
                {
                    name: 'url',
                    component: FreetextInputComponent,
                },
                {
                    name: 'single-select',
                    component: SingleSelectInputComponent,
                },
                {
                    name: 'logical',
                    component: LogicalInputComponent,
                },
                {
                    name: 'group-question',
                    component: GroupQuestionComponent,
                },
                {
                    name: 'group-question-item',
                    component: GroupQuestionItemComponent,
                },
                {
                    name: 'multi-select',
                    component: MultipleSelectInputComponent,
                },
                {
                    name: 'file-upload',
                    component: AttachmentComponent,
                },
                {
                    name: 'wrap-question',
                    component: WrapQuestionComponent,
                },
                {
                    name: 'wrap-country-group',
                    component: WrapCountryGroupComponent,
                },
                {
                    name: 'question-btn',
                    component: QuestionButtonComponent,
                },
                {
                    name: 'drop-down-country',
                    component: DropDownCountryInputComponent,
                },
                {
                    name: 'question-group-label',
                    component: QuestionGroupLabelComponent
                }
            ],
            validators: [
                {
                    name: 'currency',
                    validation: CustomValidators.currency,
                },
                {
                    name: 'email',
                    validation: CustomValidators.emailType,
                },
                {
                    name: 'quantity',
                    validation: CustomValidators.quantity,
                },
                {
                    name: 'url',
                    validation: CustomValidators.urlType,
                },
            ],
            validationMessages: [{ name: 'email', message: 'Please input a valid email' }],
        }),
    ],
    exports: [CountryFlagFormFieldPipe]
})
export class FormlyFieldsModule {}
