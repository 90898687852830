<div>
    <span class="title">Indicators of Risk</span>
    <div class="sub-title"><a (click) = "onClassification()"> Classification of Indicators</a></div>

    <div>
        <span class="total">Total</span>
        <app-grid-widget [options]="totalEvaluationOptions$ | async" class="form-total-evaluation"></app-grid-widget>
    </div>

    <div *ngFor="let evaluation of resEvaluations$ | async">
        <app-grid-widget [options]="evaluation"></app-grid-widget>
    </div>
    <div class="save-question">
        <div class="container-wrap">
            <div></div>
            <div class="group-button">
                <mat-card-actions>
                    <button
                        type="submit"
                        name="submit"
                        mat-button
                        color="primary"
                        (click)="redirectSelfAssessmentQuestionnaire()"
                    >
                        <mat-icon matPrefix>arrow_forward</mat-icon>
                        Questionnaire Review
                    </button>
                </mat-card-actions>
            </div>
        </div>
    </div>
</div>
