import { FormGroup } from '@angular/forms';
import { ISection } from './../types';
import { ScopingQuestionService } from './../services/scoping-question.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PartialResponse } from '../../question-form';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../shared/dialogs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DirtyComponent } from '../../core';
import { AuthService } from '../../auth';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobals from '../../../globals';
import { EmployerService } from '../..';

@Component({
    selector: 'scoping-question-container',
    template: `
        <div class="layout-padding">
            <div class="container-wrap">
                <div class="container-left">
                   <div class="survey-content-container" id="survey-content-container">
                        <survey-content
                            [partial]="currentPartial$ | async"
                            [nextBackBehaviour]="nextBackPartial$ | async"
                            (formChange)="notifyFormChanges($event)"
                            (next)="nextPartial()"
                            (back)="backPartial()"
                            [readOnlyMode]="(role$ | async) === 'employer' ? false : true"
                            [lstCountrySub]="countrySub$ | async"
                            [numberSectionRA]="numberSectionRA"
                        >
                        </survey-content>
                   </div>
                </div>
                <div class="scoping-sticky" id="right-nav">
                    <cdk-virtual-scroll-viewport [itemSize]="80" class="scroll-view-scoping" style="height: inherit; width: 100%;">
                        <scoping-question-navigator
                            [partialSummary]="partialSummary$ | async"
                            (partialDeleted)="deletePartial($event)"
                            (partialSelected)="selectPartial($event)"
                            [readOnlyMode]="(role$ | async) === 'employer' ? false : true"
                            [isCompleted]="isCompleted"
                        ></scoping-question-navigator>
                        <pra-navigator
                            *ngIf="(role$ | async) === 'employer'"
                            (addNewClicked)="praAddNewEventClick()"
                        ></pra-navigator>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>

            <div class="save-question-wrap" *ngIf="(role$ | async) === 'employer'">
                <save-question-bar
                    (saveEvent)="saveclicked()"
                    (submitEvent)="submitclicked()"
                    [isValid]="isValid$ | async"
                ></save-question-bar>
            </div>
        </div>
    `,
    styles: [
        `
            .layout-padding {
                background-color: #eaeef4;
            }

            .container-wrap {
                display: flex;
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }

            .container-left {
                width: calc(100% - 820px);
                padding-bottom: 15%;
                margin: 48px;
                margin-top: 0px;
                position: fixed;
                .survey-content-container {
                    overflow: auto;
                    height: calc(100vh - 260px);
                }
                .survey-content-container::-webkit-scrollbar {
                    display: none;
                }

                ::ng-deep .survey-content-container::-webkit-scrollbar {
                    display: none;
                }
            }

            .container-right {
                width: 415px;
                background-color: #fff;
                padding: 20px 50px;
            }

            .scoping-sticky {
                position: fixed;
                top: 152px;
                right: 0;
                margin-right: 5px;
                height: 85%;
                width: 415px;
                background-color: #fff;
                padding: 20px 50px;
            }

            .scroll-view-scoping::-webkit-scrollbar {
                display: none;
            }

            ::ng-deep.scoping-sticky::-webkit-scrollbar {
                display: none;
            }
        `,
    ],
    providers: [ScopingQuestionService],
})
export class ScopingQuestionContainer implements OnInit, DirtyComponent, OnDestroy {
    form: FormGroup;
    constructor(
        private scopingQuestionService: ScopingQuestionService,
        public dialog: MatDialog,
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private employerService: EmployerService
    ) {}

    nextBackPartial$ = this.scopingQuestionService.nextBackPartial$;
    partials$ = this.scopingQuestionService.partials$;
    currentPartial$ = this.scopingQuestionService.currentPartial$;
    isValid$ = this.scopingQuestionService.isValid$;
    partialSummary$ = this.scopingQuestionService.partialsSummary$;
    errors$ = this.scopingQuestionService.errors$;
    submitSuccess$ = this.scopingQuestionService.submitSuccess$;
    isUniqueEmailPra$ = this.scopingQuestionService.isCheckEmailDifferent$;
    private unsubscribe$ = new Subject();
    role$: Observable<string>;
    isCompleted: boolean;
    navbar : any;
    topPos : any;
    countrySub$ = myGlobals.numberCountry$;
    numberSectionRA = 0;

    public get isDirty() {
        return this.scopingQuestionService.isDirty;
    }

    ngOnInit(): void {
        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );

        this.role$.subscribe(role => {
            if (role === 'employer') {
                this.scopingQuestionService.getQuestionnaire().subscribe();
            } else {
                this.scopingQuestionService.getQuestionnaireAsBrand().subscribe(() => {
                    this.scopingQuestionService.addNewPraPartial();
                });
            }
        });

        this.isCompleted = this.route.snapshot.queryParams['isDone'];

        this.errors$.pipe(takeUntil(this.unsubscribe$)).subscribe(error => {
            const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                width: '450px',
                data: {
                    title: 'Error',
                    message: error[0] || 'Please try again!',
                    closeButtonText: 'Close',
                },
            });

            confirmDialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => this.spinner.hide());
        });
        this.submitSuccess$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (result) {
                this.spinner.hide();
                const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: 'Submitted Scoping question successfully!',
                        message: 'You have submitted Scoping question successfully.',
                        closeButtonText: 'Back to Dashboard',
                    },
                });

                confirmDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => this.router.navigateByUrl('/dashboard'));
            }
        });

        this.navbar = document.getElementById("right-nav");
        this.topPos = this.navbar?.offsetTop ?? 0;
        this.isUniqueEmailPra$.pipe(takeUntil(this.unsubscribe$)).subscribe(async x => {
            if(x.key) {
                await this.employerService.isEmailDifferentHotelOrHotelGroup(x.value, x.key);
            }
         })
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    praAddNewEventClick() {
        this.scrollToTop();
        this.numberSectionRA += 1;
        this.scopingQuestionService.addNewPraPartial();
    }

    createEmptyPraSection(startOrder: number): ISection {
        return {
            id: '',
            order: startOrder,
            text: `Recruitment Agency ${startOrder - 2}`,
            numOfAnswered: 0,
            numOfRequiredQuestions: 0,
            numOfQuestions: 0,
            isShowDelete: true,
        };
    }

    async saveclicked() {
        this.spinner.show();
        const result = await this.scopingQuestionService.saveDraft();
        if (result) {
            this.spinner.hide();
            const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                data: {
                    title: 'Your answers have been saved',
                    message:
                        'You can come back later and complete your self assessment questionnaire without losing your previous answers.',
                    closeButtonText: 'Close',
                },
            });

            confirmDialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe();
        }
    }

    async submitclicked() {
        this.spinner.show();
        await this.scopingQuestionService.submit();
        localStorage.removeItem('lstEmailDiffOther');
    }

    nextPartial() {
       this.scrollToTop();
        this.scopingQuestionService.selectNextPartial();
    }

    backPartial() {
       this.scrollToTop();
        this.scopingQuestionService.selectPrevPartial();
    }

    deletePartial($event: string) {
        this.numberSectionRA -= 1;
        this.scopingQuestionService.removePraPartial($event);
    }

    selectPartial($event: string) {
        this.scrollToTop();
        this.scopingQuestionService.selectPartial($event);
    }

    notifyFormChanges($event: PartialResponse) {
        this.scopingQuestionService.updatePartialResponses($event);
    }

    scrollToTop() {
        let el = document.getElementById('survey-content-container')
        if (el) {
            el.scrollTop = 0;
        }
    }
}
