import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Subject, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ApiConstant } from "../..";
import { PraDetails, Question, QuestionnaireResponseBrand } from "../../question-form";
import { EMPLOYER_API_TOKEN } from '../types';
@Injectable({
    providedIn: "root"
})
export class ScopingReviewService {
    urlDefault = this.apiConstant.endpoint;
    url = `${this.apiConstant.endpoint}/employer`;
    brandUrl = `${this.apiConstant.endpoint}/brands/employer`;
    constructor(@Inject(EMPLOYER_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) { }

    companyProfileSub = new BehaviorSubject<Question[]>([]);
    companyProfile$ = this.companyProfileSub.asObservable();

    workerStatisticsSub = new BehaviorSubject<Question[]>([]);
    workerStatistics$ = this.workerStatisticsSub.asObservable();

    lstWorkerStatisticsQuestionSub = new BehaviorSubject<Question[]>([]);
    lstWorkerStatisticsQuestion$ = this.lstWorkerStatisticsQuestionSub.asObservable();

    prasSub = new BehaviorSubject<PraDetails[]>([]);
    pras$ = this.prasSub.asObservable();

    createdAtSub = new BehaviorSubject<string>('');
    createdAt$ = this.createdAtSub.asObservable();

    errorSub$ = new Subject<string>();
    error$ = this.errorSub$.asObservable();

    workderStatisticsQuestionCodes = ['employer_scoping_A9', 'employer_scoping_A10', 'employer_scoping_A11', 'employer_scoping_A12', 'employer_scoping_A13'];
    workderStatisticsSubCodes = ['employer_scoping_A14', 'employer_scoping_A15', 'employer_scoping_A16',
    'employer_scoping_A14_2', 'employer_scoping_A14_2_1', 'employer_scoping_A14_2_2', 'employer_scoping_A14_3',
    'employer_scoping_A14_3_1', 'employer_scoping_A14_3_2'];


    getResponse(employerId: string, questionnnaireResId: string, roleUser: string) {
        let url = `${this.brandUrl}/${employerId}/questionnaire-response/${questionnnaireResId}`
        if (roleUser === 'employer') {
            url = `${this.urlDefault}/questionnaireRes/${questionnnaireResId}`
        }
        return this.httpClient.get<QuestionnaireResponseBrand>(url).pipe(
            tap((responses: QuestionnaireResponseBrand) => {
                const createdAt = responses?.response?.questionnaire?.questionnaireResponse?.createdAt
                const sections = responses?.response?.questionnaire?.employer?.sections
                const company = sections[0]?.questions;
                const workerStatistics = sections[1]?.questions.filter(question => this.workderStatisticsQuestionCodes.some(code => code === question.code));
                const workerStatisticsQuestion = sections[1]?.questions.filter(question => this.workderStatisticsSubCodes.some(code => code === question.code));
                const questionPras = responses?.response?.questionnaire?.pras.map(pra => {
                    let questions: Question[] = []
                    pra.sections.forEach(section => {
                        questions = questions.concat(section.questions);
                    });
                    const praItem: PraDetails = {
                        questions,
                        praStatus: pra?.praStatus,
                        questionnaireResponseId: pra?.questionnaireResponseId
                    };
                    return praItem;
                });
                this.companyProfileSub.next(company);
                this.workerStatisticsSub.next(workerStatistics);
                this.lstWorkerStatisticsQuestionSub.next(workerStatisticsQuestion);
                this.prasSub.next(questionPras);
                this.createdAtSub.next(createdAt);
            }),
            catchError(this.handleError),
        );
    }


    handleError(err: any) {
        this.errorSub$.next(err);
        return throwError('');
    }
}
