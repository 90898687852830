import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-quantity-input',
    templateUrl: './quantity-input.component.html',
    styleUrls: ['./quantity-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityInputComponent extends FieldType implements OnInit {
    ngOnInit() {
        if (this.field.formControl?.value) {
            this.formControl.markAsTouched();
        } else {
            this.formControl.markAsUntouched();
        }
    }

    onFocus() {
        if (this.formControl.value === undefined) {
            this.formControl.setValue('');
        }
    }
}
