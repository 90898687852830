import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Observable, Subject } from "rxjs";
import { filter, pluck, shareReplay, takeUntil } from "rxjs/operators";
import { AuthService, HttpPaginatedDataSource, PaginateOptions, UserInfo, UserInfoCollection, UserStatus } from "src/app/modules";
import format from 'date-fns/format';
import { DataTableConfig } from "src/app/modules/shared";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
    selector: 'app-list-hotel',
    templateUrl: './list-hotel.component.html',
    styleUrls: ['./list-hotel.component.scss'],
})
export class ListHotelComponent implements OnInit {
    @Input()
    userCollection: UserInfoCollection;

    @Input()
    pageSize = 5;

    @Input()
    errorMessages: string[];

    @Output()
    paginate = new EventEmitter<PaginateOptions>();

    @Output()
    filterUser = new EventEmitter<string>();

    @Output()
    sortChange = new EventEmitter<Sort>();

    @Output()
    deleteHotelUser = new EventEmitter<{
        fullname: string;
        email: string;
        userId: string;
    }>();

    @Output()
    inactivateHotelUser = new EventEmitter<{
        status: UserStatus;
        email: string;
        userId: string;
    }>();

    userDataSource: HttpPaginatedDataSource<UserInfo>;
    currentUserId$: Observable<string>;
    currentUserId: string;
    nextClicked$ = new Subject();
    unsubscribe$ = new Subject();
    smallScreen$ = this.breakpointObserver
    .observe(['(max-width: 600px)'])
    .pipe(pluck<BreakpointState, boolean>('matches'), takeUntil(this.unsubscribe$), shareReplay(1));


    config: DataTableConfig<any>;

    constructor(private authService: AuthService, private breakpointObserver: BreakpointObserver) {}

    ngOnInit(): void {
        this.currentUserId$ = this.authService.user$.pipe(
            filter(user => !!user),
            pluck('id'),
        );
        this.currentUserId$.subscribe((userId: string) => {
            this.currentUserId = userId;
        });
    }

    ngAfterViewInit() {
        const { users = [], userCount = 0 } = this.userCollection || {};
        this.initDatatable(users, userCount, this.userCollection.startAtPage);
    }

    ngOnChanges(changes: SimpleChanges) {
        const { userCollection = null } = changes || {};
        const { currentValue = null } = userCollection || {};
        const { users = null, userCount = 0 } = currentValue || {};
        this.initDatatable(users, userCount, this.userCollection.startAtPage);
    }

    initDatatable(data: UserInfo[], count: number, startAtPage?: { pageIndex: number }) {
        if (!data) {
            return;
        }

        this.config = {
            data,
            tableName: 'List hotel',
            hideFilter: true,
            columns: [
                {
                    text: 'Name',
                    label: 'Name',
                    fieldName: 'hotelName',
                    style: { flex: 2 },
                    sortable: true,
                },
                {
                    text: 'Location',
                    label: 'country',
                    fieldName: 'hotelLocation',
                    style: { flex: 2 },
                    sortable: true,
                },
                {
                    text: 'SAQ Submmited',
                    label: 'SAQSubmmited',
                    fieldName: 'saqReceived',
                    valueFunction: element => {
                        return  element.saqReceived ? element.saqReceived : 0;
                    },
                    style: { flex: 2 },
                    sortable: true,
                },
                {
                    text: 'Last Update',
                    label: 'last update',
                    fieldName: 'updatedAt',
                    valueFunction: element => {
                        return  this.formatDate(element.updatedAt);
                    },
                    style: { flex: 1 },
                    sortable: true,
                }
            ],
            mergeActionColumns: true,
            actions: [
                {
                    text: 'Delete',
                    label: 'Delete',
                    fieldName: 'delete',
                    displayIcon: true,
                    iconValue: 'assets/images/delete.svg',
                    style: { flex: 1 },
                    actionFunction: this.deleteUser.bind(this),
                },
                {
                    fieldName: 'action',
                    text: 'ACTION',
                    label: 'Active',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/active_deactive.png',
                    actionFunction: this.activeOrInactiveHotel.bind(this),
                    condition: element => {
                        return this.currentUserId !== element.id;
                    },
                    valueFunction: element => this.activeOrDeactiveAction(element.status),
                },
            ],
            meta: {
                pageSize: this.pageSize,
                rowsNumber: count,
                startAtPage,
            },
            pageChange: ({ pageSize, pageIndex }: PaginateOptions) => {
                this.paginate.emit({ pageIndex, pageSize });
            },
        };
    }

    filterChangeValue(filterValue: string) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.filterUser.emit(filterValue.trim());
    }

    sortChangeValue(sort: Sort) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.sortChange.emit(sort);
    }

    selectedPageAtValue(index: number) {
        this.paginate.emit({ pageIndex: index, pageSize: this.pageSize });
    }

    formatDate(date: string) {
        return format(new Date(date), 'dd/MM/yyyy hh:mm a');
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    deleteUser($event: Event, element: UserInfo) {
        $event.preventDefault();
        $event.stopPropagation();
        this.deleteHotelUser.emit({
            fullname: element.fullName,
            email: element.email,
            userId: element.id,
        });
        this.currentUserId$ = this.authService.user$.pipe(
            filter(user => !!user),
            pluck('id'),
        );
        this.currentUserId$.subscribe((userId: string) => {
            this.currentUserId = userId;
        });
    }

    activeOrDeactiveAction(status: UserStatus): string {
        switch(status) {
            case 'Invited':
                return '';
            case 'Active':
                return 'Deactivate';
            case 'Deactivated':
                return 'Activate';
            default:
                return '';
        }
    }

    activeOrInactiveHotel($event: Event, element: UserInfo) {
        $event.preventDefault();
        $event.stopPropagation();
        this.inactivateHotelUser.emit({
            status: element.status,
            email: element.email,
            userId: element.id,
        });
    }
}
