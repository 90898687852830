import { BehaviorSubject, combineLatest, EMPTY, of, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { NextBackBehaviour, PartialResponse, Question, QuestionnaireResponse, Section } from '../../question-form';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionnaireType } from '../../shared/types';
import { ApiConstant, CustomHttpErrorResponse, IUserQuestionResponse, IUserResponse, validEmailDiff } from '../../types';
import { API_TOKEN } from '../..';
import { environment } from 'src/environments/environment';
import { ISection, isSection, ISurveyPartial } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobals from '../../../globals';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class ScopingQuestionService {
    urlDefault = this.apiConstants.endpoint;
    url = `${this.apiConstants.endpoint}/questionnaire`;
    urlEmployer = `${this.apiConstants.endpoint}/employer/questionnaire`;

    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private submitSuccessSub$ = new Subject<boolean>();
    submitSuccess$ = this.submitSuccessSub$.asObservable();

    private savedQuestionnaire: QuestionnaireResponse;

    constructor(@Inject(API_TOKEN) private apiConstants: ApiConstant, private httpClient: HttpClient, private spinner: NgxSpinnerService) {}

    defaultPra: ISurveyPartial = {
        id: uuidv4(),
        label: `Recruitment Agency 1`,
        content: [],
    };

    hotelPartialsSub = new BehaviorSubject<ISurveyPartial[]>([]);
    hotelPartials$ = this.hotelPartialsSub.asObservable();

    praPartialsTemplateSub = new BehaviorSubject<ISurveyPartial>(this.defaultPra);
    praPartialsTemplate$ = this.praPartialsTemplateSub.asObservable();

    praQuestionnaireId = '';

    praPartialsSub = new BehaviorSubject<ISurveyPartial[]>([]);
    praPartials$ = this.praPartialsSub.asObservable();

    selectedPartialIdSub = new BehaviorSubject<string>('');
    selectedPartialId$ = this.selectedPartialIdSub.asObservable();

    allPartialResponsesSub = new BehaviorSubject<PartialResponse[]>([]);
    allPartialResponses$ = this.allPartialResponsesSub.asObservable();

    isCheckEmailDifferentSub$ = new BehaviorSubject<any>({});
    isCheckEmailDifferent$ = this.isCheckEmailDifferentSub$.asObservable();

    isDirtySub = new BehaviorSubject<boolean>(false);
    get isDirty() {
        if (!this.isDirtySub) {
            return false;
        }
        return this.isDirtySub.value;
    }
    allPartialResponsesSubscription = this.allPartialResponses$
        .pipe(
            tap(partials => {
                const isDirty = partials.some(partial => partial.formDirty);
                this.isDirtySub.next(isDirty);
                return of(isDirty);
            }),
        )
        .subscribe();

    getQuestionnaireAsBrand() {
        this.spinner.show();
        const headers = new HttpHeaders({
            [TRANSACTION_NAME]: 'Get questionnaire by questionnaire type with brand role',
        });
        const employerScopingType: QuestionnaireType = 'employer_scoping';
        let questionnaireResponseSub = this.httpClient
            .get<QuestionnaireResponse>(`${this.url}?questionnaireType=${employerScopingType}`, { headers })
            .pipe(
                tap(questionnaire => {
                    this.savedQuestionnaire = questionnaire;
                    const employerPartials = questionnaire?.response?.questionnaire?.employer?.sections.map(section => {
                        const result: ISurveyPartial = {
                            id: uuidv4(),
                            label: section.label,
                            content: section,
                        };
                        return result;
                    });
                    this.hotelPartialsSub.next(employerPartials);
                    const praPartials = questionnaire.response.questionnaire.pras.map((pra, idx) => {
                        this.praQuestionnaireId = pra.id;
                        const sections = pra.sections.map(section =>
                            Object.assign({}, section, { questions: [...section.questions] }),
                        );
                        const result: ISurveyPartial = {
                            id: uuidv4(),
                            label: `Recruitment Agency ${idx + 1}`,
                            content: sections,
                            canDelete: true,
                        };

                        return result;
                    });
                    const filteredPraPartials = praPartials.filter(partial => {
                        if (isSection(partial.content)) {
                            return (partial.content as Section).questions.some(question =>
                                this.isNotEmpty(question.value),
                            );
                        } else {
                            return (partial.content as Section[]).some(section => {
                                return section.questions.some(question => this.isNotEmpty(question.value));
                            });
                        }
                    });
                    this.praPartialsSub.next(filteredPraPartials);
                    this.praPartialsTemplateSub.next(JSON.parse(JSON.stringify(praPartials[0])));
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
        return questionnaireResponseSub;
    }

    getQuestionnaire() {
        this.spinner.show();
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get questionnaire by questionnaire type' });
        const employerScopingType: QuestionnaireType = 'employer_scoping';
        let questionnaireResponseSub = this.httpClient
            .get<QuestionnaireResponse>(`${this.urlEmployer}?questionnaireType=${employerScopingType}`, { headers })
            .pipe(
                tap(questionnaire => {
                    this.savedQuestionnaire = questionnaire;
                    const employerPartials = questionnaire?.response?.questionnaire?.employer?.sections.map(section => {
                        const result: ISurveyPartial = {
                            id: uuidv4(),
                            label: section.label,
                            content: section,
                        };
                        return result;
                    });
                    this.hotelPartialsSub.next(employerPartials);
                    const praPartials = questionnaire.response.questionnaire.pras.map((pra, idx) => {
                        this.praQuestionnaireId = pra.id;
                        const sections = pra.sections.map(section =>
                            Object.assign({}, section, { questions: [...section.questions] }),
                        );

                        const result: ISurveyPartial = {
                            id: uuidv4(),
                            label: `Recruitment Agency ${idx + 1}`,
                            content: sections,
                            canDelete: true,
                        };

                        return result;
                    });
                    const filteredPraPartials = praPartials.filter(partial => {
                        if (isSection(partial.content)) {
                            const questions = (partial.content as Section).questions;
                            const quesEmail = questions.find(q => q.code === 'emp_pra_scop_A13');
                            if (quesEmail) {
                                this.isCheckEmailDifferentSub$.next({key: partial.id, value: quesEmail.value});
                            }
                            return questions.some(question =>
                                this.isNotEmpty(question.value),
                            );
                            // return (partial.content as Section).questions.some(question =>
                            //     this.isNotEmpty(question.value),
                            // );
                        } else {
                            return (partial.content as Section[]).some(section => {
                                const questions = section.questions;
                                const quesEmail = questions.find(q => q.code === 'emp_pra_scop_A13');
                                if (quesEmail) {
                                    this.isCheckEmailDifferentSub$.next({key: partial.id, value: quesEmail.value});
                                }
                                return questions.some(question =>
                                    this.isNotEmpty(question.value),
                                );
                                // return section.questions.some(question => this.isNotEmpty(question.value));
                            });
                        }
                    });
                    this.praPartialsSub.next(filteredPraPartials);
                    this.praPartialsTemplateSub.next(JSON.parse(JSON.stringify(praPartials[0])));
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
        // if (this.savedQuestionnaire) {
        //     questionnaireResponseSub = of(this.savedQuestionnaire);
        // }
        return questionnaireResponseSub;
    }

    partialsSub = new BehaviorSubject<ISurveyPartial[]>([]);
    partials$ = this.partialsSub.asObservable();

    isAllAnsweredSub = new BehaviorSubject<boolean>(false);
    isAllAnswered$ = this.isAllAnsweredSub.asObservable();

    isValid$ = combineLatest([this.isAllAnswered$, this.allPartialResponses$]).pipe(
        switchMap(([isAllAnswered, allPartialResponses]) => {
            return of(isAllAnswered && allPartialResponses.every(partial => partial.formValid));
        }),
    );

    partialsSubscription = combineLatest([this.hotelPartials$, this.praPartials$, this.allPartialResponses$, myGlobals.isEmailDifferentHotelOrHotelGroup$])
        .pipe(
            switchMap(([hotelPartials, praPartial, allPartialResponses, isEmailDifferentHotelOrHotelGroup]) => {
                return of(this.bindResponses(hotelPartials.concat(praPartial), allPartialResponses, isEmailDifferentHotelOrHotelGroup));
            }),
        )
        .subscribe(partials => {
            partials.map(partial => {
                if (isSection(partial.content)) {
                    (partial.content as Section).questions = (partial.content as Section).questions.map(question => {
                        if (question.valueType === "email") {
                            // myGlobals.isEmailDifferentHotelOrHotelGroup$.subscribe(item => {
                            //     item.map(x => {
                            //         if (x.key === partial.id) {
                            //             question.isEmailDifferentHotelOrHotelGroup = x.value
                            //         }
                            //     })
                            // });
                            let items = localStorage.getItem('lstEmailDiffOther');
                                if (items) {
                                    let itemsValue: validEmailDiff[] = JSON.parse(items)
                                    itemsValue.forEach(x => {
                                        if (x.key === partial.id) {
                                            question.isEmailDifferentHotelOrHotelGroup = x.value
                                        }
                                    })
                                }
                        }
                        return question;
                    });
                } else {
                    partial.content = (partial.content as Section[]).map(section => {
                        section.questions = section.questions.map(question => {
                            if (question.valueType === "email") {
                                // myGlobals.isEmailDifferentHotelOrHotelGroup$.subscribe(item => {
                                //     item.map(x => {
                                //         if (x.key === partial.id) {
                                //             question.isEmailDifferentHotelOrHotelGroup = x.value
                                //         }
                                //     })
                                // });
                                let items = localStorage.getItem('lstEmailDiffOther');
                                if (items) {
                                    let itemsValue: validEmailDiff[] = JSON.parse(items)
                                    itemsValue.forEach(x => {
                                        if (x.key === partial.id) {
                                            question.isEmailDifferentHotelOrHotelGroup = x.value
                                        }
                                    })
                                }
                            }
                            return question;
                        });
                        return section;
                    });
                }
            });

            this.spinner.hide();
            const noSelected = !this.selectedPartialIdSub.value;
            const selectedHasBeenDeleted = !partials.some(p => p.id === this.selectedPartialIdSub.value);
            if (partials && partials.length && noSelected) {
                this.selectedPartialIdSub.next(partials[0].id);
            } else if (partials && partials.length && selectedHasBeenDeleted) {
                this.selectedPartialIdSub.next(partials[partials.length - 1].id);
            }
            this.isAllAnsweredSub.next(this.isValid(partials));
            this.partialsSub.next(partials);
        });

    currentPartial$ = combineLatest([this.partials$, this.selectedPartialId$]).pipe(
        switchMap(([partials, selectedPartialId]) => {
            const partial = partials.find(partial => partial.id === selectedPartialId);
            return of(partial);
        }),
    );

    partialsSummary$ = combineLatest([this.partials$, this.selectedPartialId$, myGlobals.isEmailDifferentHotelOrHotelGroup$]).pipe(
        switchMap(([partials, selectedPartialId, isEmailDifferentHotelOrHotelGroup]) => {
            // if no partial is selected, select the first one
            // else if the partial is deleted, select the last one
            if (!selectedPartialId) {
                selectedPartialId = partials && partials.length ? partials[0].id : '';
            } else if (!partials.some(partial => partial.id === selectedPartialId)) {
                selectedPartialId = partials && partials.length ? partials[partials.length - 1].id : '';
            }

            const sections = partials.map((partial, idx) => {
                const numOfQuestions = this.countNumOfQuestions(partial);
                let isDiff = false;
                isEmailDifferentHotelOrHotelGroup.forEach(x => {
                    if (x.key === partial.id) {
                        isDiff = x.value || isDiff;
                    }
                })
                const { numOfAnswered, numOfAnsweredRequired } = this.countNumOfAnsweredQuestions(partial, isDiff);
                const numOfRequiredQuestions = this.countNumOfRequiredQuestions(partial);
                const section: ISection = {
                    id: partial.id,
                    text: partial.label,
                    order: idx,
                    isShowDelete: partial.canDelete,
                    numOfAnswered: numOfAnswered,
                    numOfRequiredQuestions: numOfRequiredQuestions,
                    numOfQuestions: numOfQuestions,
                    isSelected: partial.id === selectedPartialId,
                    isCompleted: numOfAnsweredRequired >= numOfRequiredQuestions,
                    isCompletedForward: numOfAnswered > 0,
                };
                return section;
            });

            return of(sections);
        }),
    );

    nextBackPartial$ = combineLatest([this.partials$, this.selectedPartialId$]).pipe(
        switchMap(([partials, selectedPartialId]) => {
            if (selectedPartialId) {
                const idx = partials.findIndex(partial => partial.id == selectedPartialId);
                const nextBack: NextBackBehaviour = {
                    canBack: idx > 0 && idx < partials.length,
                    canNext: idx > -1 && idx < partials.length - 1,
                };
                return of(nextBack);
            } else {
                return of({
                    canBack: false,
                    canNext: true,
                });
            }
        }),
    );

    updatePartialResponses(partialResponse: PartialResponse) {
        if (!partialResponse) {
            return;
        }
        const partialResponses = [...this.allPartialResponsesSub.value];
        const existedResponse = partialResponses.find(item => item.partialId === partialResponse.partialId);
        if (!existedResponse) {
            const newPartialResponses = partialResponses.concat(partialResponse);
            this.allPartialResponsesSub.next(newPartialResponses);
        } else {
            // keep dirty state
            partialResponse.formDirty = partialResponse.formDirty || existedResponse.formDirty;
            const newPartialResponses = partialResponses
                .filter(item => item.partialId !== partialResponse.partialId)
                .concat(partialResponse);
            this.allPartialResponsesSub.next(newPartialResponses);
        }
    }

    selectPartial(id: string) {
        this.selectedPartialIdSub.next(id);
    }

    addNewPraPartial() {
        const pras = [...this.praPartialsSub.value];
        const praTemplate: ISurveyPartial = JSON.parse(JSON.stringify(this.praPartialsTemplateSub.value));
        if (isSection(praTemplate.content)) {
            (praTemplate.content as Section).questions.forEach(question => {
                question.value = '';
            });
        } else {
            (praTemplate.content as Section[]).forEach(section => {
                section.questions.forEach(question => {
                    question.value = '';
                });
            });
        }
        const newPraName = `Recruitment Agency ${pras.length + 1}`;
        const newPraPartialId = uuidv4();

        pras.push(Object.assign({}, praTemplate, { id: newPraPartialId, label: newPraName }));
        this.praPartialsSub.next(pras);
        this.selectedPartialIdSub.next(newPraPartialId);
    }

    removePraPartial(id: string) {
        const pras = [...this.praPartialsSub.value];
        if (this.allPartialResponsesSub.value) {
            const newAllParitalResponses = this.allPartialResponsesSub.value.filter(
                partial => partial.partialId !== id,
            );
            this.allPartialResponsesSub.next(newAllParitalResponses);
        }
        const newPras = pras
            .filter(pra => pra.id !== id)
            .map((pra, idx) => {
                const newPra: ISurveyPartial = Object.assign({}, pra, {
                    label: `Recruitment Agency ${idx + 1}`,
                });
                return newPra;
            });
        this.praPartialsSub.next(newPras);
    }

    selectNextPartial() {
        const partials = this.partialsSub.value;
        const selectedPartial = this.selectedPartialIdSub.value || partials[0].id;
        if (selectedPartial) {
            const idx = partials.findIndex(partial => partial.id == selectedPartial);
            const nextPartialIdx = Math.min(idx + 1, partials.length - 1);
            this.selectedPartialIdSub.next(partials[nextPartialIdx].id);
        }
    }

    selectPrevPartial() {
        const partials = this.partialsSub.value;
        const selectedPartial = this.selectedPartialIdSub.value || partials[0].id;
        if (selectedPartial) {
            const idx = partials.findIndex(partial => partial.id == selectedPartial);
            const prevPartialIdx = Math.max(idx - 1, 0);
            this.selectedPartialIdSub.next(partials[prevPartialIdx].id);
        }
    }

    saveDraft() {
        const questionResponse = this.getDataToSaveOrSubmit();
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Save employer scoping question' });

        return this.httpClient
            .post<any>(`${this.urlDefault}/questionnaireResDraft/save`, questionResponse, { headers })
            .pipe(
                tap((_result: any) => {
                    this.isDirtySub.next(false);
                    return EMPTY;
                }),
                catchError((_err: CustomHttpErrorResponse) => {
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    standardizeValue(valueType: string | undefined, value: any) {
        if (typeof value === 'undefined') {
            return '';
        }
        if (typeof value === 'object') {
            if (!value) {
                return '';
            }
            if (Array.isArray(value)) {
                // Array
                return value.filter(val => !!val);
            } else {
                for (var key in value) {
                    // Object
                    if (valueType === 'number') {
                        value[key] = value[key] || 0;
                    } else {
                        value[key] = value[key] || '';
                    }
                }
            }
        }
        return value !== 0 && value !== '0' ? (value || ''): value;
    }

    getDataToSaveOrSubmit() {
        const employerResponse: IUserResponse = {
            questionnaireId: this.savedQuestionnaire.response.questionnaire.employer.id,
            questionResponses: [],
        };
        this.hotelPartialsSub.value.map(partial => {
            if (isSection(partial.content)) {
                partial.content.questions.forEach(question => {
                    const result: IUserQuestionResponse = {
                        questionId: question.id,
                        value: this.standardizeValue(question.valueType, question.value),
                    };
                    employerResponse.questionResponses.push(result);
                });
            } else {
                (partial.content as Section[]).forEach(section => {
                    section.questions.forEach(question => {
                        const result: IUserQuestionResponse = {
                            questionId: question.id,
                            value: this.standardizeValue(question.valueType, question.value),
                        };
                        employerResponse.questionResponses.push(result);
                    });
                });
            }
        });
        const praResponses: IUserResponse[] = this.praPartialsSub.value.map(partial => {
            const praResponse: IUserResponse = {
                questionnaireId: this.praQuestionnaireId,
                questionResponses: [],
            };
            if (isSection(partial.content)) {
                partial.content.questions.forEach(question => {
                    const result: IUserQuestionResponse = {
                        questionId: question.id,
                        value: question.value || '',
                    };
                    praResponse.questionResponses.push(result);
                });
            } else {
                (partial.content as Section[]).forEach(section => {
                    section.questions.forEach(question => {
                        const result: IUserQuestionResponse = {
                            questionId: question.id,
                            value: question.value || '',
                        };
                        praResponse.questionResponses.push(result);
                    });
                });
            }
            return praResponse;
        });

        return praResponses.concat(employerResponse);
    }

    async submit(): Promise<any> {
        const questionResponse = this.getDataToSaveOrSubmit();
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Submit employer scoping question' });

        return this.httpClient
            .post<any>(`${this.urlDefault}/questionnaireRes/submit`, questionResponse, { headers })
            .pipe(
                tap((result: any) => {
                    if (result) {
                        this.submitSuccessSub$.next(true);
                        this.isDirtySub.next(false);
                    }
                    return EMPTY;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    private saveEvent = new BehaviorSubject<any[]>([]);
    save$ = this.saveEvent.asObservable();

    countNumOfQuestions(partial: ISurveyPartial) {
        if (isSection(partial.content)) {
            const normalQuestion = (partial.content as Section).questions.filter(x => !x.parentId && !x.questionGroupId);
            const groupQuestions = (partial.content as Section).questionGroups;
            return normalQuestion.length + (groupQuestions ? groupQuestions.length : 0);
        } else {
            let count = 0;
            (partial.content as Section[]).forEach(section => {
                count += section.questions.filter(x => !x.parentId && !x.questionGroupId).length;
                count += section.questionGroups ? section.questionGroups.length : 0;
            });
            return count;
        }
    }

    countNumOfRequiredQuestions(partial: ISurveyPartial) {
        let sections: Section[];
        const lstGroup: any[] = [];
        if (isSection(partial.content)) {
            sections = [partial.content];
        } else {
            sections = partial.content as Section[];
        }
        let questions: Question[] = [];
        sections.forEach(section => {
            questions = questions.concat(section.questions);
            if (section.questionGroups) {
                lstGroup.push(section.questionGroups);
            }
        });
        let numberGroupQuestionRequired = 0;
        lstGroup.forEach(lstGr => {
            lstGr.forEach((group:any) => {
                const groupQuestions = questions.filter(q => q.questionGroupId && q.controlType != 'question-btn' && q.controlType != 'question-group-label' && q.questionGroupId === group.id);
                const numberGroupParentQuestions = groupQuestions.filter(q => !q.parentId).length;
                const numberQuestionRequired = groupQuestions.filter(q => !q.parentId && q.required).length;
                if (numberGroupParentQuestions === numberQuestionRequired) {
                    numberGroupQuestionRequired += 1;
                }
            })
        });
        const normalQuestions = questions.filter(q => !q.parentId && q.controlType !== 'question-btn' && !q.questionGroupId);
        const parentIds = normalQuestions.filter(question => !!question.parentId).map(question => question.parentId);
        const numberOfNormalQuestionRequired =  (normalQuestions || []).filter(q => !parentIds.some(id => id === q.id) && q.required).length;
        return numberOfNormalQuestionRequired + numberGroupQuestionRequired;
    }

    countNumOfAnsweredQuestions(partial: ISurveyPartial, isEmailDifferentHotelOrHotelGroup: boolean) {
        let sections: Section[];
        const lstGroup: any[] = [];
        if (isSection(partial.content)) {
            sections = [partial.content];
        } else {
            sections = partial.content as Section[];
        }
        let questions: Question[] = [];
        sections.forEach(section => {
            questions = questions.concat(section.questions);
        });
        const rootQuestions = questions;
        questions = questions.filter(q => !q.parentId && q.controlType != 'question-btn' && !q.questionGroupId);
        let lstQuestionWithEmptyAnswer: string[] = [];
        lstQuestionWithEmptyAnswer = this.checkValidForNormalGroupQuestion(questions, rootQuestions, false);
        //handle for group question
        sections.forEach(sec => {
            if (sec.questionGroups) {
                lstGroup.push(sec.questionGroups);
            }
        });
        let numOfGroupQAnswered = 0;
        let numOfAnsweredGroupQRequired = 0;
        lstGroup.forEach(lstItem => {
            const {_numOfAnsweredGroupQRequired, _numOfGroupQAnswered} = this.countAndCheckValidGroupQuestions(lstItem, rootQuestions)
            numOfGroupQAnswered += _numOfGroupQAnswered;
            numOfAnsweredGroupQRequired += _numOfAnsweredGroupQRequired;
        })
        const numOfNormalAnswered = (questions || []).filter(
            q => q.valueType === 'email' ? (this.isNotEmpty(q.value) && isEmailDifferentHotelOrHotelGroup && this.isValidEmail(q.value)) : (this.isNotEmpty(q.value) && lstQuestionWithEmptyAnswer.indexOf(q.id) == -1)).length;
        const numOfNormalAnsweredRequired = (questions || []).filter(
            q => q.valueType === 'email' ? (q.required && this.isNotEmpty(q.value) && isEmailDifferentHotelOrHotelGroup && this.isValidEmail(q.value)) : q.required && this.isNotEmpty(q.value) && lstQuestionWithEmptyAnswer.indexOf(q.id) == -1).length;

        const numOfAnswered = numOfNormalAnswered + numOfGroupQAnswered;
        const numOfAnsweredRequired = numOfNormalAnsweredRequired + numOfAnsweredGroupQRequired;
        return {
            numOfAnswered,
            numOfAnsweredRequired,
        };
    }

    isCurrency(res: any) {
        const reg = new RegExp(/^(?![0,.][0]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/);
        return reg.test(res);
    }

    isQuantity(res: any) {
        const reg = new RegExp('^[0-9]+$');
        if (typeof res === 'string') {
            try {
                return reg.test(res) && Number(res) >= 0;
            } catch {
                return false;
            }
        } else if (typeof res === 'number') {
            return reg.test(res+'') && Number(res) >= 0;
        }
        return false;
    }

    isValidEmail(res: any) {
        const reg = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        return reg.test(res);
    }

    isNotEmpty(res: any): boolean {
        if (typeof res === 'boolean' || typeof res === 'number') {
            return true;
        } else if (typeof res === 'string' || typeof res === 'undefined') {
            return !!res;
        } else if (typeof res === 'object') {
            return res && Object.keys(res).length > 0;
        }
        return false;
    }

    countUnique(iterable: string[]) {
        return new Set(iterable).size;
    }

    bindResponses(partials: ISurveyPartial[], allPartialResponses: PartialResponse[], isEmailDifferentHotelOrHotelGroup: validEmailDiff[]): ISurveyPartial[] {
        return partials.map(partial => {

            const partialResponse = allPartialResponses.find(item => item.partialId === partial.id);

            if (partialResponse) {
                if (isSection(partial.content)) {
                    (partial.content as Section).questions = (partial.content as Section).questions.map(question => {
                        question.value = partialResponse.responses[question.id];
                        if (question.valueType === "email") {
                            isEmailDifferentHotelOrHotelGroup.forEach(x => {
                                if (x.key === partial.id) {
                                    question.isEmailDifferentHotelOrHotelGroup = x.value
                                }
                            })
                        }
                        return question;
                    });
                } else {
                    partial.content = (partial.content as Section[]).map(section => {
                        section.questions = section.questions.map(question => {
                            question.value = partialResponse.responses[question.id];
                            if (question.valueType === "email") {
                                isEmailDifferentHotelOrHotelGroup.forEach(x => {
                                    if (x.key === partial.id) {
                                        question.isEmailDifferentHotelOrHotelGroup = x.value
                                    }
                                })
                            }
                            return question;
                        });
                        return section;
                    });
                }
            }

            return partial;
        });
    }

    isValid(partials: ISurveyPartial[]) {
        const invalid = partials.some(partial => {
            if (isSection(partial.content)) {
                const questions = (partial.content as Section).questions
                const parentIds = questions
                    .filter(question => !!question.parentId && !question.questionGroupId)
                    .map(question => question.parentId);
                const lstNormalGroupQ = this.checkValidForNormalGroupQuestion(questions.filter(question => !question.parentId && !question.questionGroupId), questions);
                const normalQuestion = questions
                    .filter(question => !parentIds.some(id => id === question.id) && !question.questionGroupId)
                    .some(question => (question.required && !this.isNotEmpty(question.value)) || (lstNormalGroupQ && lstNormalGroupQ.length > 0));
                const lstGroupQuestion = (partial.content as Section).questionGroups;
                const {lstQuestionWithEmptyAnswer} = this.countAndCheckValidGroupQuestions(lstGroupQuestion, questions);
                return normalQuestion || (lstQuestionWithEmptyAnswer && lstQuestionWithEmptyAnswer.length > 0);
            } else {
                return (partial.content as Section[]).some(partial => {
                    const questions = partial.questions;
                    const parentIds = questions
                        .filter(question => !!question.parentId && !question.questionGroupId)
                        .map(question => question.parentId);
                    const lstGroupQuestion = partial.questionGroups;
                    const {lstQuestionWithEmptyAnswer} = this.countAndCheckValidGroupQuestions(lstGroupQuestion, questions);
                    const normalQuestion = questions
                        .filter(question => !parentIds.some(id => id === question.id) && !question.questionGroupId)
                        .some(question => question.valueType === 'email' ? (question.required && (!question.isEmailDifferentHotelOrHotelGroup || !this.isValidEmail(question.value))) : question.required && !this.isNotEmpty(question.value));
                    return normalQuestion || (lstQuestionWithEmptyAnswer && lstQuestionWithEmptyAnswer.length > 0);
                });
            }
        });
        return !invalid;
    }

    countAndCheckValidGroupQuestions(group: any, questions: Question[]) {
        let _numOfAnsweredGroupQRequired = 0;
        let _numOfGroupQAnswered = 0;
        const lstQuestionWithEmptyAnswer: string[] = [];
        group.forEach((group:any) => {
            const questionCondition = questions.find(q => q.questionGroupId && q.controlType === 'question-btn' && q.questionGroupId === group.id);
            const groupQuestions = questions.filter(q => q.questionGroupId && q.controlType != 'question-btn' && q.controlType != 'question-group-label' && q.questionGroupId === group.id);
            const groupParentQuestions = groupQuestions.filter(q => !q.parentId);
            groupParentQuestions.forEach(parent => {
                if (parent.requireCondition && parent.required && parent.requireCondition.length > 0) {
                    const valueConditionOfQuestionCondition = questionCondition  && questionCondition.value ? questionCondition.value : '1';
                    if (valueConditionOfQuestionCondition) {
                        parent.requireCondition.forEach(cond => {
                            if (cond.value === valueConditionOfQuestionCondition) {
                                const lstChildQuestion = groupQuestions.filter(q => q.parentId === parent.id);
                                const childNotAnswers = lstChildQuestion.some(item => {
                                    return !this.isNotEmpty(item.value);
                                });
                                const someEmptyAnswer = lstChildQuestion.some(ques => {
                                    let result:any;
                                    switch (ques.valueType) {
                                        case 'number':
                                            result = !this.isQuantity(ques.value);
                                            break;
                                        case 'currency':
                                            result = !this.isCurrency(ques.value);
                                            break;
                                        default:
                                            result = ques.valueType != 'number' ? !this.isNotEmpty(ques.value) : !this.isQuantity(ques.value);
                                            break;
                                    }
                                    return result;
                                });

                                if (someEmptyAnswer || childNotAnswers || !this.isNotEmpty(parent.value)) {
                                    lstQuestionWithEmptyAnswer.push(parent.id);
                                }
                            }
                        })
                    }
                }
            });
            let flag = true;
            (groupParentQuestions || []).forEach(q => {
                if ((q.hidden !== null && q.hidden !== undefined && q.hidden === false) || q.value) {
                    let checkExistCountry = false;
                    checkExistCountry = q.value ? this.isExistCountryName(q, groupParentQuestions) : checkExistCountry;
                    if (checkExistCountry) {
                        lstQuestionWithEmptyAnswer.push(q.id);
                    }
                    if (lstQuestionWithEmptyAnswer.indexOf(q.id) !== -1 || checkExistCountry) {
                        flag = false;
                    }                    
                }
                if ((q.hidden !== null && q.hidden !== undefined && q.hidden === false) && (q.value === '' || q.value === null || q.value === undefined)) {
                    flag = false;
                }
            });
            _numOfGroupQAnswered += flag ? 1 : 0;
            flag = true;
            (groupParentQuestions || []).forEach(q => {
                let checkExistCountry = false;
                checkExistCountry = q.value ? this.isExistCountryName(q, groupParentQuestions) : checkExistCountry;
                if (lstQuestionWithEmptyAnswer.indexOf(q.id) !== -1 && q.required && !this.isNotEmpty(q.value) || checkExistCountry) {
                    flag = false;
                }
                if ((q.hidden !== null && q.hidden !== undefined && q.hidden === false) && (q.value === '' || q.value === null || q.value === undefined)) {
                    flag = false;
                }
                if ((q.hidden !== null && q.hidden !== undefined && q.hidden === false) && (!q.required)) {
                    flag = false;
                }
                if ((q.hidden !== null && q.hidden !== undefined && q.hidden === false) && (q.required)) {
                    const groupQuestionsChild = questions.filter(q2 => q2.questionGroupId && q2.questionGroupId === group.id && q2.parentId === q.id);
                    groupQuestionsChild.forEach(q3 => { 
                        if (q3.required && (q3.value === null || q3.value === undefined)) {
                            flag = false;
                        }
                    })    
                }
            })            
            _numOfAnsweredGroupQRequired += flag ? 1 : 0;
        })
        return {
            _numOfGroupQAnswered,
            _numOfAnsweredGroupQRequired,
            lstQuestionWithEmptyAnswer
        }
    }

    isExistCountryName(question: Question, lstCountry: Question[]) {
        return lstCountry.filter(q => q.id != question.id).some(q => {
            return q.value === question.value && q.value !== null && q.value !== undefined;
        })
    }

    checkValidForNormalGroupQuestion(questions: Question[], rootQuestions: Question[], ischeckValid: boolean = true) {
        const lstQuestionWithEmptyAnswer: string[] = [];
        questions.forEach(item => {
            const lstChildQuestionOfItem = rootQuestions.filter(q => q.parentId == item.id);
            if (lstChildQuestionOfItem.length) {
                if (!item.required) {
                    const allEmptyAnswer = lstChildQuestionOfItem.every(ques => {
                        return this.isNotEmpty(item.value) && ( ques.valueType != 'number' ? !this.isNotEmpty(ques.value) : !this.isQuantity(ques.value));
                    });
                    const checkGroupAnswer = lstChildQuestionOfItem.some(ques => ques.value);
                    let someAnswerNotValid = false;
                    if (checkGroupAnswer) {
                        let numberAnswer = lstChildQuestionOfItem.length;
                        lstChildQuestionOfItem.forEach(ques => {
                            if (ques.valueType === 'number' && !this.isQuantity(ques.value)) {
                                numberAnswer --;
                            }
                        });
                        if (numberAnswer === lstChildQuestionOfItem.length) {
                            someAnswerNotValid = false;
                        } else {
                            someAnswerNotValid = true;
                        }
                    }

                    if ((allEmptyAnswer && !ischeckValid) || someAnswerNotValid) {
                        lstQuestionWithEmptyAnswer.push(item.id);
                    }
                } else {
                    const allChildRequired = lstChildQuestionOfItem.every(item => item.required);
                    if (allChildRequired) {
                        const someEmptyAnswer = lstChildQuestionOfItem.some(ques => {
                            let result:any;
                            switch (ques.valueType) {
                                case 'number':
                                    result = this.isNotEmpty(item.value) && !this.isQuantity(ques.value);
                                    break;
                                case 'currency':
                                    result = this.isNotEmpty(item.value) && !this.isCurrency(ques.value);
                                    break;
                                default:
                                    result = this.isNotEmpty(item.value) && (ques.valueType != 'number' ? !this.isNotEmpty(ques.value) : !this.isQuantity(ques.value));
                                    break;
                            }
                            return result;
                        });

                        if (someEmptyAnswer) {
                            lstQuestionWithEmptyAnswer.push(item.id);
                        }
                    } else {
                        // handle for case if there are some child is required
                        const childQuestionAnsRequiredNotAns = lstChildQuestionOfItem.some(ques => {
                            return this.isNotEmpty(item.value) && ques.required && !this.isNotEmpty(ques.value);
                        });
                        const someEmptyAnswer = lstChildQuestionOfItem.every(ques => {
                                let result:any;
                                switch (ques.valueType) {
                                    case 'number':
                                        result = this.isNotEmpty(item.value) && !this.isQuantity(ques.value);
                                        break;
                                    case 'currency':
                                        result = this.isNotEmpty(item.value) && !this.isCurrency(ques.value);
                                        break;
                                    default:
                                        result = this.isNotEmpty(item.value) && (ques.valueType != 'number' ? !this.isNotEmpty(ques.value) : !this.isQuantity(ques.value));
                                        break;
                                }
                                return result;
                        });

                        if (someEmptyAnswer || childQuestionAnsRequiredNotAns) {
                            lstQuestionWithEmptyAnswer.push(item.id);
                        }
                    }
                }
            }
        });
        return lstQuestionWithEmptyAnswer;
    }
}
