import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { FieldType } from '@ngx-formly/core';
import { CountryFlagPipe } from '../../pipes/country-flag.pipe';
import * as myGlobals from '../../../../globals';
import { CustomFormlyFieldConfig } from '../../types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-drop-down-country-input',
    templateUrl: './drop-down-country-input.component.html',
    styleUrls: ['./drop-down-country-input.component.scss'],
    providers: [CountryFlagPipe]
})
export class DropDownCountryInputComponent extends FieldType implements OnInit{
    defaultOptions = {
        templateOptions: {
            options: [],
            compareWith(o1: any, o2: any) {
                return o1 === o2;
            },
        },
    };
    allCountryValid$ = myGlobals.countryChange$;
    showErr$ = new BehaviorSubject<boolean>(true);
    ngOnInit() {
        if (this.field.formControl?.value) {
            this.field.formControl?.markAsTouched();
        } else {
            this.field.formControl?.markAsUntouched();
        }
        this.allCountryValid$.subscribe(x => {
            if ((this.field as CustomFormlyFieldConfig).partialId === x.key) {
                if (x.value) {
                    this.showErr$.next(false);
                    if (this.field.formControl?.hasError('uniqueCountry')) {
                        this.field?.formControl?.setErrors(null);
                        this.field.formControl?.markAsUntouched();
                    }
                } else {
                    this.showErr$.next(true);
                }
            }
        })
    }

    change($event: MatSelectChange) {
        this.to.change?.(this.field, $event);
        myGlobals.saqDirtySub$.next({isDirty: true});
    }
}
