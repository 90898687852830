<form (ngSubmit)="registerHotelID()" #f="ngForm" novalidate>
    <ng-container *ngIf="smallScreen$ | async as smallScreen">
        <mat-card class="mat-elevation-z-p-r6" [ngClass]="'mat-elevation-z0'">
            <mat-card-header>
                <mat-card-title class="page-section-title"> Request link to {{hotelName}} </mat-card-title>
            </mat-card-header>
            <mat-card-content class="content-container">
                <ng-container *ngIf="smallScreen === 'no'" [formGroup]="form">
                    <div class="user-form-wrapper">
                        <div class="user-item">
                            <div class="form-field-label">{{hotelName}} ID</div>
                            <div class="item-group">
                                <mat-form-field appearance="outline">
                                    <input
                                        matInput
                                        type="text"
                                        placeholder="{{hotelName}} ID"
                                        formControlName="hotelId"
                                        autocomplete="off"
                                    />
                                    <mat-error *ngIf="hotelId.errors && hotelId.errors['pattern']">
                                        Please input valid {{hotelName}} ID.
                                    </mat-error>
                                    <mat-error *ngIf="hotelId.errors && hotelId.errors['required']">
                                        Please input {{hotelName}} ID.
                                    </mat-error>
                                    <!-- <mat-error *ngIf="hotelId.errors && hotelId.errors['maxlength']">
                                        {{hotelName}} ID cannot exceed 5 characters.
                                    </mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <app-error-box [errorMessages]="errorMessages"></app-error-box>
                <div>
                    <button class="btn-cancel" type="button" mat-button color="primary" (click)="closePopup()">
                        Cancel
                    </button>

                    <button class="btn-add-new" type="submit" mat-button color="primary" [class]="form.valid ? 'btn-add-active' : ''" [disabled]="form.invalid">
                        Request Link
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
</form>
