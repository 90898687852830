import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiConstant } from '../types';
import { BrandLazyModule } from './brand-lazy.module';
import { BRAND_API_TOKEN } from './types';
import { BrandService } from './services/brand.service';

@NgModule({
    imports: [BrandLazyModule],
    exports: [BrandLazyModule],
})
export class BrandModule {
    static forRoot(apiConstant: ApiConstant): ModuleWithProviders<BrandModule> {
        return {
            ngModule: BrandModule,
            providers: [
                {
                    provide: BRAND_API_TOKEN,
                    useValue: apiConstant,
                },
                BrandService,
            ],
        };
    }
}
