import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-group-question-item',
    templateUrl: './group-question-item.component.html',
    styleUrls: ['./group-question-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupQuestionItemComponent extends FieldType {}
