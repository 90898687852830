import { CustomValidators } from './../../../shared/formly-fields/custom-validators';
import { TouchedErrorStateMatcher } from './../../../shared/validators/touched-error-state.matcher';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/modules/shared/dialogs';
import { PaginateOptions } from 'src/app/modules/types';
import { StringValuePair } from '../../../core';
import { EmployeeService } from '../../services';
import { EmployeeUserInfo } from '../../types';
import { validateIomEmail } from '../../validators';
import * as myGlobals from '../../../../globals';
@Component({
    selector: 'new-user-form',
    templateUrl: './new-user-form.component.html',
    styleUrls: ['./new-user-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUserFormDialogComponent implements OnInit, OnDestroy {
    matcher = new TouchedErrorStateMatcher()
    @ViewChild('f', { static: true })
    formDirective: NgForm;

    @Input()
    errorMessages: string[];

    @Output()
    registerClicked = new EventEmitter<EmployeeUserInfo>();

    form: FormGroup;

    filteredGeographies: StringValuePair[];

    private unsubscribed$ = new Subject();

    smallScreen$ = this.breakpointObserver
        .observe(['(max-width: 1200px)'])
        .pipe(map(observer => (observer.matches ? 'yes' : 'no')));

    constructor(
        private fb: FormBuilder,
        private breakpointObserver: BreakpointObserver,
        private service: EmployeeService,
        private cdr: ChangeDetectorRef,
        private spinner: NgxSpinnerService,
        private matDialogRef: MatDialogRef<NewUserFormDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            email?: string,
            fullname?: string,
        },
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            username: new FormControl('', {
                validators: [Validators.required, Validators.maxLength(100)],
            }),
            email: new FormControl('', {
                validators: [Validators.required, CustomValidators.emailType],
                asyncValidators: [validateIomEmail(this.service, this.cdr)],
            }),
        });
    }


    get email() {
        return this.form.controls.email as FormControl;
    }

    get username() {
        return this.form.controls.username as FormControl;
    }

    // get reemail() {
    //     return this.form.controls.reemail as FormControl;
    // }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }

    registerUser() {
        if (this.form.valid) {
            const registerInfo = this.form.value;
            const options:PaginateOptions = {pageIndex: 0, pageSize: 10};
            this.spinner.show();
            this.service.registerBrand(registerInfo, options).subscribe(() => {
                myGlobals.emailAddUserSub$.next('')
                myGlobals.fullNameAddUserSub$.next('')
                this.spinner.hide();
                this.closePopup();
                this.dialog.open(ConfirmationDialog, {
                    data: {
                        title: 'Add new user successfully!',
                        message:
                            `Successfully added ` +
                            registerInfo['username'] +
                            ` to LMPM. Invitation email was sent to ` +
                            registerInfo['email'],
                    },
                });
            });
        }
    }

    closePopup() {
        this.matDialogRef.close();
        this.resetForm();
    }

    resetForm() {
        this.formDirective.resetForm();
        this.form.reset();

        Object.keys(this.form.controls).forEach(key => this.form.controls[key].setErrors(null));
    }

    ngOnDestroy() {
        this.unsubscribed$.next();
        this.unsubscribed$.complete();
    }

    emailCf() {
        if (this.form !== null && this.form !== undefined) {
            const email = this.form.controls.email;
            const confirmEmail = this.form.controls.reemail;
            return email.value === confirmEmail.value ? null : { emailNotMatch: true };
        }
        return null;
    }

    emailChanged(value: string) {
        // if (this.form !== null && this.form !== undefined) {
        //     const reemail = this.form.controls.reemail;
        //     if (reemail.value !== '' && value !== reemail.value) {
        //         this.form.controls.reemail.setErrors({ emailNotMatch: true });
        //     }
        //     if (reemail.value !== '' && value === reemail.value) {
        //         this.form.controls.reemail.setErrors(null);
        //     }
        // }
        if (value !== null) {
            myGlobals.emailAddUserSub$.next(value)
        }
    }

    fullNameChanged(value: string) {
        if (value !== null) {
            myGlobals.fullNameAddUserSub$.next(value)
        }
    }
}
