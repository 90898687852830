<div class="notification-container">
    Latest updates
    <div class="notification-content" *ngIf="(notifications$ | async)">
        <p class="nothing-layout" *ngIf="(notifications$ | async).length === 0">We don't have any update for you</p>
        <div *ngFor="let notification of notifications; let i = index">
            <div class="ntf"
                [class]="'ntf-questions-response'"
                *ngIf="notification.type === 'employer_scoping_submitted' || notification.type === 'employer_saq_submitted' || notification.type === 'recruitment_saq_submitted'"
            >
                <div class="ntf-text">
                    <span class="ntf-title">{{notification?.sender?.role?.role !== 'pra' ? notification?.data?.hotel?.companyName : notification?.sender?.fullName}} submitted {{notification.type === 'employer_scoping_submitted' ? 'Scoping Questions' : 'Self Assessment Questions'}}</span>
                    <span class="ntf-description">
                        {{notification.type === 'employer_scoping_submitted' ? 'Scoping Questions' : 'Self Assessment Questions'}} submitted at
                        {{notification.createdAt | date: 'dd MMMM, YYYY - hh:mm a'}}
                    </span>
                </div>
                <div class="ntf-button">
                    <button class="active-btn" type="submit" color="primary" name="submit" mat-button (click)="redirectNotification(notification?.id, notification?.type, notification?.sender?.id, notification?.data?.questionnaireResponseId)">
                        <mat-icon>bar_chart</mat-icon>
                        Review Answers
                    </button>
                </div>
            </div>

            <div class="ntf"
                [class]="'ntf-invite-response'"
                *ngIf="notification.type === 'hotel_request_link_to_hotel_group' || notification.type === 'hotel_group_request_link_to_hotel'"
            >
                <div class="ntf-text-link">
                    <span class="ntf-title">{{notification?.data?.hotel?.companyName}} requested to link under your hotel group</span>
                    <span class="ntf-description">requested at {{notification.createdAt | date: 'dd MMMM, YYYY - hh:mm a'}}</span>
                </div>
                <div class="ntf-button-link">
                    <button class="active-btn" type="submit" color="primary" name="submit" mat-button (click)="redirectRequest(notification?.id, notification?.type, notification?.data, notification?.sender)">
                        <mat-icon>create</mat-icon>
                        View Request
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
