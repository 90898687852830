import { InjectionToken } from '@angular/core';
import { ApiConstant } from '../types';

export const BRAND_REGISTER_API_TOKEN = new InjectionToken<ApiConstant>('api.constant');

export interface BrandRegisterUserInfo {
    fullName: string;
    email: string;
    name: string;
    password: string;
    confirmPassword: string;
}
