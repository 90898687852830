const endpoint = 'https://api.lmpmhospitality.org';

export const environment = {
    production: true,
    hmr: false,
    api: {
        endpoint,
        socketServer: endpoint,
    },
    elasticAPM: {
        serviceName: 'lmpm-frontend-prod',
        serverUrl: 'https://apm.diginex.com',
        distributedTracingOrigins: [endpoint],
        transactionName: 'Transaction-Name',
        sensitiveParamNames: ['password', 'currentPassword', 'newPassword', 'confirmPassword'],
    },
    enableLogging: false,
    findOutMoreUrl: 'https://lmpm.diginex.com/',
    enableIomTermsAndConditions: false,
};
