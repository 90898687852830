import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../auth';
import { ConfirmationDialog } from '../../shared';
import { OnboardingUserResults } from '../../shared/onboarding-form';
import { PraService } from '../services';
import { ForcePasswordChangePra } from '../types';

@Component({
    selector: 'pra-user-onboarding-container',
    template: `
        <ng-container *ngIf="onboardingUserResults$ | async as onboardingUserResults">
            <admin-onboarding-form
                [userResults]="onboardingUserResults"
                [errorMessages]="errors$ | async"
                (completeOnboardingClicked)="completeOnboarding$.next($event)"
                (gotoLogin)="gotoLoginPage()"
                [userType]="'iom'"
            ></admin-onboarding-form>
        </ng-container>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PraUserOnboardingContainer implements OnInit, OnDestroy {
    id: string;

    private unsubscribe$ = new Subject();
    errors$ = this.praService.errors$;
    completeOnboarding$ = new Subject<ForcePasswordChangePra>();
    onboardingUserResults$: Observable<OnboardingUserResults>;

    constructor(
        private praService: PraService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService,
        private spinner: NgxSpinnerService
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.params.id;

        this.authService.invalidateSession();

        this.onboardingUserResults$ = this.praService.onboardingUser$;
        this.praService.getOnboardingUser(this.id).pipe(takeUntil(this.unsubscribe$)).subscribe();

        this.completeOnboarding$
            .pipe(
                delay(0),
                tap(() => this.spinner.show()),
                map(({ newPassword, confirmPassword, language }) => ({
                    id: this.id,
                    newPassword,
                    confirmPassword,
                    language,
                    time: this.route.snapshot.queryParams.time,
                    sig: this.route.snapshot.queryParams.sig,
                    questionnaireResId: this.route.snapshot.queryParams.questionnaireResId,
                })),
                switchMap(input => this.praService.completeOnboarding(input)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((success: boolean) => {
                if (success) {
                    this.spinner.hide();
                    const dialogRef = this.dialog.open(ConfirmationDialog, {
                        width: '700px',
                        data: {
                            title: 'LMPM Registration Complete',
                            message: `You have completed LMPM Registration. Please use your email address and your chosen password to login`,
                        },
                    });

                    dialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(() => this.router.navigate(['/login']));
                }
            });
    }

    gotoLoginPage() {
        this.router.navigate(['/login']);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
