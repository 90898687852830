import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { debounceTime, delay, exhaustMap, switchMap, takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from '../../core';
import { ConfirmationDialog, ConfirmCancelDialog } from '../../shared';
import { UserStatus, PaginateOptions } from '../../types';
import { ListUsersFormComponent } from '../components';
import { BrandService } from '../services';
import { BrandUserInfo } from '../types';

@Component({
    selector: 'brand-dashboard',
    template: `
        <div class="grid-container1 layout-padding user-padding">
            <list-users-form
                class="list-users-form"
                [pageSize]="pageSize"
                [userCollection]="userCollection$ | async"
                (paginate)="paginateUsers$.next($event)"
                (inactivateAdmin)="openDialog($event)"
                (inactivateBrand)="activeOrDeActive($event)"
                [errorMessages]="inactivateUserErrors$ | async"
                (filterUser)="filter$.next($event)"
                (sortChange)="sorter$.next($event)"
            >
            </list-users-form>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
            }

            .grid-container {
                align-items: stretch;

                display: grid;

                grid-template-rows: 10px minmax(min-content, auto) minmax(min-content, auto) 10px;
                grid-template-columns: minmax(30px, 1fr) minmax(280px, 8fr) minmax(30px, 1fr);
            }

            .grid-container .list-users-form {
                grid-row: 3;
                grid-column: 2;
            }

            .grid-container .new-user-form {
                grid-row: 2;
                grid-column: 2;
                margin-bottom: 2rem;
            }

            @media only screen and (max-width: 600px) {
                .grid-container {
                    grid-template-columns: 1fr;
                }

                .grid-container .list-users-form {
                    grid-column: 1;
                }

                .grid-container .new-user-form {
                    grid-column: 1;
                }
            }
            .user-padding {
                padding: 48px
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandAdminContainer implements OnInit, OnDestroy {
    pageSize = 10;

    @ViewChild(ListUsersFormComponent, { static: true })
    listUserForm: ListUsersFormComponent;

    private unsubscribe$ = new Subject();
    registerClicked$ = new Subject<BrandUserInfo>();
    paginateUsers$ = new BehaviorSubject<PaginateOptions>({ pageIndex: 0, pageSize: this.pageSize });
    inactivateAdmin$ = new Subject<any>();
    inactivateBrand$ = new Subject<any>();
    filter$ = new BehaviorSubject<string>('');
    sorter$ = new BehaviorSubject<Sort>({ active: 'fullName', direction: 'asc' });

    userCollection$ = this.BrandService.userCollection$;
    errors$ = this.BrandService.errors$;
    inactivateUserErrors$ = this.BrandService.inactivateUserErrors$;
    lstCaseManager$: any;

    newUserData$: any;

    constructor(private BrandService: BrandService, private dialog: MatDialog, private spinner: NgxSpinnerService, private breadCrumbService: BreadcrumbService) {}

    ngOnInit() {
        let breadcrumbItems = [{name: 'Hotel Groups', path: '/brands'}]
        this.breadCrumbService.setBreadCrumbItemsFirstPage(breadcrumbItems);

        this.registerClicked$
            .pipe(
                delay(0),
                exhaustMap(registerInfo =>
                    this.BrandService.registerBrand(registerInfo, this.paginateUsers$.getValue()),
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        combineLatest([this.paginateUsers$, this.filter$, this.sorter$])
            .pipe(
                debounceTime(300),
                switchMap(([pagination, filter, sorter]) => {
                    let orderBy = '';
                    if (sorter && sorter.active) {
                        orderBy = `${sorter.active} ${sorter.direction}`;
                    }
                    return this.BrandService.paginteUserManagements(pagination, filter, orderBy);
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

        this.inactivateAdmin$
            .pipe(
                delay(0),
                exhaustMap(res => this.BrandService.deleteBrand(res.brandId, this.pageSize)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
        this.inactivateBrand$
            .pipe(
                delay(0),
                exhaustMap(res => this.BrandService.inactivateBrand(res.userId, res.status, this.pageSize)),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();
    }

    activeOrDeActive(data: { status: UserStatus; userId: string }) {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: data.status === 'Active' ? `Deactivate Hotel group user` : `Activate Hotel group user`,
                subTitle:
                    data.status === 'Active'
                        ? `Are you sure you want to deactivate account?`
                        : `Are you sure you want to activate account?`,
                message:
                    data.status === 'Active'
                        ? `When deactivated, user cannot work on LMPM platform. However, you will still be able to view their information, answers and activities.`
                        : ` When activate, Hotel group user can continue working on LMPM platform.`,
                cancelButtonText: 'Discard',
                confirmButtonText: data.status === 'Active' ? 'Deactivate account' : 'Activate account',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((res:any) => {
                    if (res) {
                        this.spinner.show();
                        return this.BrandService.inactivateBrand(data.userId, data.status, this.pageSize);
                    }
                    return of(undefined);
                })
            )
            .subscribe(async result => {
                if (result) {
                    this.spinner.hide();
                    const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                        data: {
                            title: data.status == 'Active' ? 'Deactivated Successfully!' : 'Activated Successfully',
                            message:
                                data.status == 'Active'
                                    ? 'User was successfully deactivated from LMPM platform.'
                                    : 'User was successfully activated from LMPM platform.',
                            closeButtonText: 'Close',
                        },
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe();
                }
            });
    }

    openDialog(data: { brandId: string }) {
        const { brandId } = data;
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Warning`,
                subTitle: ``,
                message: `
                    Deleting a hotel group will also delete all hotels and data that is associated with this group. Are you sure you want to continue?
                `,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    this.spinner.show();
                    const deleteResult = await this.BrandService.deleteBrand(brandId, this.pageSize);
                    if (deleteResult) {
                        this.spinner.hide();
                        this.dialog.open(ConfirmationDialog, {
                            width: '600px',
                            data: {
                                title: `Delete Successfully`,
                                message: `
                                User was successfully deleted from LMPM platform.
                                `,
                                closeButtonText: 'Close',
                            },
                        });
                    }
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
