
<!-- Layout: Modern -->
<div class="modern-layout" *ngIf="to.layout === 'modern'">
    <input  #numberCountry [value]="(numberCountry$ | async | json)" type="hidden" />
    <div *ngFor="let item of (numberCountry$ | async)">
        <div *ngIf="item.key === field?.partialId" >
            <input #myControl [value]="item.value" type="hidden" />
            <div class="icon-delete-country" *ngIf="myControl.value > 0 && myControl.value < 3 && !field?.templateOptions?.readonly && (field.key !== 'emp_regis_A10' && field.key !== 'employer_scoping_A14'  && field.key !== 'emp_pra_scop_A9')">
                <mat-icon (click)="removeCountry(myControl.value, numberCountry.value)">close</mat-icon>
            </div>
        </div>
    </div>

    <div class="top-label" *ngIf="to.symbol">
        <div class="symbol-input-left">
            <span>{{ to.symbol }}</span>
            <!-- <img src="assets/images/icon-question.svg" alt="icon" class="icon-question" /> -->
        </div>
        <div class="right-item" *ngIf="to?.required">
            <mat-label class="asterix--before"></mat-label>
        </div>
    </div>
    <div *ngIf="field.fieldGroup.length === 3">
        <div class="layout-parent">
            <formly-field [field]="field.fieldGroup[0]"></formly-field>
        </div>
        <div class="layout-parent">
            <formly-field [field]="field.fieldGroup[1]"></formly-field>
        </div>
        <div class="layout-parent">
            <formly-field [field]="field.fieldGroup[2]"></formly-field>
        </div>
    </div>
    <div *ngIf="field.fieldGroup.length !== 3">
        <div *ngFor="let question of field.fieldGroup; let i = index; let last = last">
            <formly-field [field]="question"></formly-field>
        </div>
    </div>
</div>
