<!-- Layout: Normal -->
<div class="normal-layout" *ngIf="to.layout !== 'modern'">
    <label class="label-input-left">
        <span *ngIf="to.symbol">{{ to.symbol }}</span>
        {{ to.label }}
    </label>
    <div class="group-question-item">
        <div class="spacer"></div>
        <div
            class="group-question-item-question"
            [class]="question.readonly ? 'mat-form-field-read-only' : ''"
            *ngFor="let question of field.fieldGroup; let i = index; let last = last"
        >
            <formly-field [field]="question"></formly-field>
        </div>
        <div class="group-question-item-question" *ngIf="to.total">
            <label><span class="label-input-left">Total:</span> {{ field.model | totalValue }}</label>
        </div>
    </div>
</div>
<!-- Layout: Modern -->
<div class="modern-layout" *ngIf="to.layout === 'modern'">
    <div class="top-label" *ngIf="to.symbol">
        <div class="symbol-input-left">
            <span>{{ to.symbol }}</span>
        </div>
        <div class="right-item" *ngIf="to?.required">
            <mat-label class="asterix--before"></mat-label>
        </div>
    </div>
    <label class="label-input-left" *ngIf="to?.label">
        {{ to?.label }}
    </label>
    <div class="group-question-item">
        <div
            class="group-question-item-question"
            [class]="question.readonly ? 'mat-form-field-read-only' : ''"
            *ngFor="let question of field.fieldGroup; let i = index; let last = last"
        >
            <formly-field [field]="question"></formly-field>
        </div>
        <div class="group-question-item-question" *ngIf="to.total">
            <div class="total-section">
                <div class="user-input">
                    <label class="label-input-left" *ngIf="to?.label"> Total </label>
                    <mat-form-field appearance="outline" floatLabel="never">
                        <input matInput type="number" [value]="field.model | totalValue" disabled />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
