import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth';
import { BreadcrumbService } from 'src/app/modules/core';
import { NewUserFormDialogComponent } from 'src/app/modules/employee/components';
import { NewUserFormComponent } from 'src/app/modules/iom/components/new-user-form/new-user-form.component';
import { PopupRequestComponent } from 'src/app/modules/user-settings/components/popup-request/popup-request.component';
import { CommonLayoutData, DEFAULT_COMMON_LAYOUT_DATA } from '../types';
import * as myGlobals from '../../../../globals';
import { ConfirmCancelDialog } from '../..';

@Component({
    selector: 'app-common-layout',
    templateUrl: './common-layout.component.html',
    styleUrls: ['./common-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLayoutComponent implements OnInit, OnDestroy {
    layoutData: CommonLayoutData = DEFAULT_COMMON_LAYOUT_DATA;

    role$: Observable<string>;
    role = '';
    hotelName = '';

    childComponent: any

    unsubscribe$ = new Subject();
    openNotification$: Observable<boolean>;
    emailAddUser = '';
    fullNameAddUser = '';

    constructor(
        private routerApp: Router,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private authenService: AuthService,
        ) {
        this.layoutData = Object.assign({}, this.layoutData, {
            pageTitle: this.route.snapshot.data['pageTitle'],
            pageDes: this.route.snapshot.data['pageDes'],
            enableInvite: this.route.snapshot.data['enableInvite'],
            isAddUser: this.route.snapshot.data['isAddUser'],
            enableRequestLink: this.route.snapshot.data['enableRequestLink'],
            breadcrumb: this.route.snapshot.data['breadcrumb'],
            isIOM_AddUser: this.route.snapshot.data['isIOM_AddUser'],
        });
    }

    public onRouterOutletActivate(event : any) {
        this.childComponent = event
    }

    ngOnInit(){
        if (this.routerApp.url.includes('/employers/agency/self-assessment-questionnaire/') || this.routerApp.url.includes('agency/self-assessment-questionnaire/')) {
            this.layoutData.pageTitle = 'Self Assessment Questionnaire';
        }
        if (this.routerApp.url.includes('employers/self-assessment-questionnaire')) {
            this.layoutData.pageTitle = 'Hotels';
        }
        this.role$ = this.authenService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : ''))
        );
        this.role$.subscribe( role => this.role = role);
        if(this.role === 'brand'){
            this.hotelName = "hotel";
        } else {
            this.hotelName = "hotel group";
        }

        if(this.role === 'admin' && this.layoutData.pageTitle === 'Dashboard') {
            this.layoutData.pageTitle = 'Platform Metrics';
        }

        this.openNotification$ = myGlobals.openNotification$.pipe(
            map(result => {
                return result
            }),
        takeUntil(this.unsubscribe$));

        myGlobals.emailAddUser$.pipe(
            map(result => {
                return result
            }),
        takeUntil(this.unsubscribe$)).subscribe(result => {
            this.emailAddUser = result
        });

        myGlobals.fullNameAddUser$.pipe(
            map(result => {
                return result
            }),
        takeUntil(this.unsubscribe$)).subscribe(result => {
            this.fullNameAddUser = result
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    actions$ = this.breadcrumbService.actions$;
    breadcrumbItems$ = this.breadcrumbService.breadcrumbItems$;
    hasAsyncBreadcrumb$ = this.breadcrumbService.breadcrumbItems$.pipe(
        switchMap(items => {
            return of(items && items.length);
        }),
    );

    inviteHotel() {
        this.routerApp.navigate(['/employers/registration']);
    }

    requestLink() {
        this.dialog.open(PopupRequestComponent, {
            width: '660px',
        });
    }

    inviteEmployee(email?: string, fullName?: string) {
        let dialogRef = this.dialog.open(NewUserFormDialogComponent, {
            width: '660px',
            height: '450px',
            data: {
                email: email || '',
                fullName: fullName || '',
            }
        });

        dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(result => {
            if (!result) {
                if (this.emailAddUser !== '' || this.fullNameAddUser !== '') {
                    let dialogConfirm = this.dialog.open(ConfirmCancelDialog, {
                        data: {
                            title: '',
                            message: 'Unsaved change. Are you sure you want to exit this screen?',
                            cancelButtonText: 'No',
                            confirmButtonText: 'Yes',
                            hideCancelButton: false,
                            color: 'warn',
                        },
                    });

                    dialogConfirm
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(result => {
                        if (!result) {
                            this.inviteEmployee(this.emailAddUser, this.fullNameAddUser)
                        }
                    })
                }
            }
        });
    }

    inviteIOM(email?: string, fullName?: string) {
        let dialogRef = this.dialog.open(NewUserFormComponent, {
            width: '660px',
            height: '450px',
            data: {
                email: email || '',
                fullName: fullName || '',
            }
        });

        dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(result => {
            if (!result) {
                if (this.emailAddUser !== '' || this.fullNameAddUser !== '') {
                    let dialogConfirm = this.dialog.open(ConfirmCancelDialog, {
                        data: {
                            title: '',
                            message: 'Close this popup will remove all input data. Are you sure you want to close?',
                            cancelButtonText: 'No',
                            confirmButtonText: 'Yes',
                            hideCancelButton: false,
                            color: 'warn',
                        },
                    });

                    dialogConfirm
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(result => {
                        if (!result) {
                            this.inviteEmployee(this.emailAddUser, this.fullNameAddUser)
                        }
                    })
                }
            }
        });
    }
}
