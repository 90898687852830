<div class="list-widget" *ngIf="options">
    <div class="list-widget__title">
        <div style="width: calc(100% - 240px);"><span>{{ options.title }}</span></div>
        <div style="display: flex;justify-content: flex-end;" *ngIf="!pdfPreview && isPraDetail && (options?.praStatus === 'Invited' || options?.praStatus === 'Not Invited')">
            <button class="active-btn" type="submit" color="primary" name="submit" mat-button (click)="inviteRA()">
                + Invite Recruitment Agency
            </button>
        </div>
    </div>
    <div class="list-widget__list" *ngIf="options.data">
        <div class="list-widget__item" *ngFor="let item of options.data">
            <div class="{{isPraDetail ? 'item-label pra-label' : 'item-label'}}" *ngIf="item.code !== 'emp_pra_scop_A1'">{{ item.label }}</div>
            <div class="{{isPraDetail ? 'item-value pra-value' : 'item-value'}}" *ngIf="item.valueType === 'normal'">{{ item.value }}</div>
            <div class="{{isPraDetail ? 'item-value pra-value' : 'item-value'}}" *ngIf="item.valueType === 'email'">
                <a href="mailto:{{ item.value }}">{{ item.value }}</a>
            </div>
        </div>
    </div>
</div>
