import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnaireType } from '../../types';

@Pipe({
    name: 'documentName',
})
export class DocumentNamePipe implements PipeTransform {
    transform(value: QuestionnaireType): string {
        switch (value) {
            case 'employer_scoping':
                return 'Scoping Questions';
            case 'employer_saq':
                return 'Self Assessment Questions';
            default:
                return '';
        }
    }

    transform2(value: QuestionnaireType): string {
        switch (value) {
            case 'employer_scoping':
                return 'Scoping Questions';
            case 'employer_saq':
                return 'Questionnaire';
            case 'recruitment_saq':
                return 'Recruiment Agency';
            default:
                return '';
        }
    }
}
