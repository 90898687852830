import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules';
import { BrandService } from '../../services';

@Component({
    selector: 'app-questionnaire-container',
    templateUrl: './questionnaire-container.component.html',
    styleUrls: ['./questionnaire-container.component.scss'],
})
export class QuestionnaireContainerComponent implements OnInit {
    constructor(private authService: AuthService, private router: Router, private brandService: BrandService) {}
    isDone: boolean;
    role$: Observable<string>;
    role: string = '';

    ngOnInit(): void {
        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );
        this.role$.subscribe(value => {
            this.role = value;
        });
    }

    scopingClick() {
        if (this.role === 'brand') {
            this.downloadScopingQuestions()
        } else {
            this.redirectScopingQuestion();
        }
    }

    saqForHotelClick() {
        if (this.role === 'brand') {
            this.downloadSAQForHotel()
        } else {
            this.redirectScopingQuestion();
        }
    }

    wiqForHotelClick() {
        if (this.role === 'brand') {
            this.downloadWIQForHotel()
        } else {
            this.redirectScopingQuestion();
        }
    }

    saqForPRAClick() {
        if (this.role === 'brand') {
            this.downloadSAQForPRA()
        } else {
            this.redirectScopingQuestion();
        }
    }

    redirectScopingQuestion(_isDone?: boolean) {
        const user = this.authService.getUser();
        if (user?.role == 'brand') {
            this.router.navigate(['/questionnaire/scoping-questions'], {
                queryParams: { isDone: false },
            });
        } else {
            this.router.navigate(['/scoping-questions']);
        }
    }

    downloadScopingQuestions() {
        this.brandService.downloadTemplateCSV('scoping-template').subscribe(
            res => {
                this.brandService.openFile(res);
            }
        );
    }

    downloadSAQForHotel() {
        this.brandService.downloadTemplateCSV('hotel-saq').subscribe(
            res => {
                this.brandService.openFile(res);
            }
        );
    }

    downloadWIQForHotel() {
        this.brandService.downloadTemplateCSV('worker-interview').subscribe(
            res => {
                this.brandService.openFile(res);
            }
        );
    }

    downloadSAQForPRA() {
        this.brandService.downloadTemplateCSV('recruitment-saq').subscribe(
            res => {
                this.brandService.openFile(res);
            }
        );
    }
}
