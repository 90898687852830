import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import format from 'date-fns/format';
import { BehaviorSubject, EMPTY, of, Subject } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomEncoder, Logger, UserInfo, UserInfoCollection } from '../../core';
import { UserStatus, ApiConstant, CustomHttpErrorResponse, PaginateOptions } from '../../types';
import { EmployeeUserInfo, EMPLOYEE_API_TOKEN } from '../types';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable()
export class EmployeeService {
    urlDefault = this.apiConstant.endpoint;
    url = `${this.apiConstant.endpoint}/auth`;

    private errorsSub$ = new Subject<string[]>();
    errors$ = this.errorsSub$.asObservable();

    private inactivateUserErrorsSub$ = new Subject<string[]>();
    inactivateUserErrors$ = this.inactivateUserErrorsSub$.asObservable();

    private newUserSub$ = new Subject<UserInfo>();
    newUser$ = this.newUserSub$.asObservable();

    private lstCaseMangerSub$ = new BehaviorSubject<UserInfo[]>([]);
    lstCaseManger$ = this.lstCaseMangerSub$.asObservable();

    private userCollectionSub$ = new BehaviorSubject<UserInfoCollection>({ users: [], userCount: 0 });
    userCollection$ = this.userCollectionSub$.asObservable();

    private paginateByUserRole(
        options: PaginateOptions,
        role: string,
        transactionName: string,
        filter?: string,
        orderBy?: string,
    ) {
        let params;
        if (role === 'managements') {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', 'brand');
        } else {
            params = new HttpParams({ encoder: new CustomEncoder() }).set('roles', role);
        }

        const { pageIndex, pageSize } = options;
        if (filter && filter != '') {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`).set('filter', `${filter}`);
        } else {
            params = params.set('page', `${pageIndex}`).set('limit', `${pageSize}`);
        }
        if (orderBy) {
            params = params.set('orderBy', orderBy);
        }
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: transactionName });
        return this.httpClient
            .get<UserInfoCollection>(`${this.apiConstant.endpoint}/brands/employees`, { params, headers })
            .pipe(
                tap(({ users, userCount }: UserInfoCollection) => {
                    const localUsers: UserInfo[] = users.map(u => {
                        const strLastLoginTime = u.lastLoginTime
                            ? format(new Date(u.lastLoginTime), 'dd MMM, yyyy HH:mm:ss')
                            : '';
                        return {
                            ...u,
                            lastLoginTime: strLastLoginTime,
                        };
                    });
                    this.userCollectionSub$.next({ users: localUsers, userCount, startAtPage: { pageIndex } });
                }),
                catchError(() => {
                    this.userCollectionSub$.next({ users: [], userCount: 0 });
                    return EMPTY;
                }),
            );
    }

    constructor(@Inject(EMPLOYEE_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) {}

    registerBrand(input: EmployeeUserInfo, options: PaginateOptions) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Create new Brand user' });

        const { username, email, company } = input;

        return this.httpClient
            .post<UserInfo>(
                `${this.apiConstant.endpoint}/user/invite-user-brand`,
                {
                    fullName: username,
                    email,
                    companyName: company,
                    confirmEmail: email,
                },
                { headers },
            )
            .pipe(
                tap((newUser: UserInfo) => {
                    this.newUserSub$.next(newUser);
                    this.errorsSub$.next([]);
                }),
                concatMap(() => this.paginateByUserRole(options, 'managements', 'User Management list')),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    paginteAdmins(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'admin', 'Paginate Iom user list', filter);
    }

    paginteUsers(options: PaginateOptions, filter?: string) {
        return this.paginateByUserRole(options, 'user', 'Paginate PRA list', filter);
    }

    paginteUserManagements(options: PaginateOptions, filter?: string, sorter?: string) {
        return this.paginateByUserRole(options, 'managements', 'User Management list', filter, sorter);
    }

    deleteEmployee(userId: string, pageSize: number): Promise<any> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Delete employee in hotel group' });
        return this.httpClient
            .delete<UserInfo>(`${this.urlDefault}/brands/employee/${userId}`, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    inactivateBrand(userId: string, status: UserStatus, pageSize: number) {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Inactive brand' });
        const body = {
            status: status === 'Active' ? 'Deactivated' : 'Active',
        };
        return this.httpClient
            .put<UserInfo>(`${this.urlDefault}/brands/${userId}/status`, body, { headers })
            .pipe(
                tap(() => {
                    this.inactivateUserErrorsSub$.next([]);
                }),
                switchMap(() =>
                    this.paginteUserManagements({
                        pageIndex: 0,
                        pageSize,
                    }),
                ),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.inactivateUserErrorsSub$.next(err.errorJson.message);
                    } else {
                        this.inactivateUserErrorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            );
    }

    getTotalCaseManager(): Promise<number> {
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get total Iom user' });

        return this.httpClient
            .get<number>(`${this.url}/number-caser-manager`, { headers })
            .pipe(
                tap((result: number) => {
                    this.errorsSub$.next([]);
                    return result;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return EMPTY;
                }),
            )
            .toPromise();
    }

    completeOnboarding(input: {
        id: string;
        newPassword: string;
        confirmPassword: string;
        language: number;
        time: string;
        sig: string;
    }) {
        const { id, newPassword, confirmPassword, time, sig, language } = input;
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Complete onboarding process' });
        const body = {
            newPassword,
            confirmPassword,
            languageId: language,
        };

        return this.httpClient
            .put<void>(`${this.url}/onboarding-complete/${id}?time=${time}&sig=${sig}`, body, { headers })
            .pipe(
                tap(() => this.errorsSub$.next([])),
                map(() => true),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsSub$.next(err.errorJson.message);
                    } else {
                        this.errorsSub$.next([err.message]);
                    }
                    return of(false);
                }),
            );
    }

    isEmailUnique(email: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('email', email);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of IOM user email' });

        return this.httpClient.get(`${this.url}/isEmailUnique`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'true'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }

    isCompanyNameUnique(name: string) {
        const params = new HttpParams({ encoder: new CustomEncoder() }).set('companyName', name);
        const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Validate uniqueness of PRA company name' });

        return this.httpClient.get(`${this.url}/is-unique-company`, { params, headers, responseType: 'text' }).pipe(
            map(value => value === 'false'),
            catchError(err => {
                Logger.log(err);
                return EMPTY;
            }),
        );
    }
}
