<!-- Layout: Normal -->
<div class="normal-layout" *ngIf="to.layout !== 'modern'">
    <div class="user-input">
        <label class="label-input-left" *ngIf="to?.label">
            <span  *ngIf="to.symbol">{{ to.symbol }}</span>
            {{ to?.label }}
            <strong style="color: red" *ngIf="to?.required">*</strong>
        </label>
        <div [class]="to.readonly ? 'mat-form-field-read-only' : ''">
            <mat-form-field appearance="outline" floatLabel="never">
                <input
                    matInput
                    [formControl]="formControl"
                    [placeholder]="to.placeholder"
                    [formlyAttributes]="field"
                    [tabIndex]="to.tabIndex"
                    [required]="to.required"
                    [readonly]="to.readonly"
                    [min]="0"
                />
                <mat-error>
                    <formly-validation-message [field]="field"></formly-validation-message>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
<!-- Layout: Modern -->
<div class="modern-layout" [ngClass]="field.className" *ngIf="to.layout === 'modern'">
    <div class="user-input">
        <div class="top-label" *ngIf="to.symbol">
            <div class="symbol-input-left">
                <span>{{ to.symbol }}</span>
                <img src="assets/images/icon-question.svg" alt="icon" class="icon-question" />
            </div>
            <div class="right-item" *ngIf="to?.required">
                <mat-label class="asterix--before"></mat-label>
            </div>
        </div>
        <label class="label-input-left" *ngIf="to?.label">
            {{ to?.label }}
        </label>
        <div [class]="to.readonly ? 'mat-form-field-read-only' : ''">
            <mat-form-field appearance="outline" floatLabel="never">
                <input
                    matInput
                    [formControl]="formControl"
                    [placeholder]="to.placeholder"
                    [formlyAttributes]="field"
                    [tabIndex]="to.tabIndex"
                    [required]="to.required"
                    [readonly]="to.readonly"
                    [min]="0"
                    (ngModelChange)="onPercentChange($event)"
                    (focus)="onFocus()"
                />
                <mat-error>
                    <formly-validation-message [field]="field"></formly-validation-message>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
