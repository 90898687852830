import { ScopingQuestionModule } from './../scoping-question/scoping-question.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { BrandDashboardComponent, LastNotificationComponent, LastSubmittedComponent, ListUsersFormComponent, QuestionnaireContainerComponent } from './components';
import { BrandAdminContainer } from './containers';

@NgModule({
    declarations: [
        BrandAdminContainer, 
        ListUsersFormComponent, 
        QuestionnaireContainerComponent, 
        BrandDashboardComponent, 
        LastNotificationComponent, 
        LastSubmittedComponent],
    imports: [
        CommonModule, 
        SharedModule, 
        ReactiveFormsModule, 
        FormsModule, 
        ScopingQuestionModule],
    exports: [
        BrandAdminContainer, 
        ListUsersFormComponent, 
        QuestionnaireContainerComponent, 
        BrandDashboardComponent, 
        LastNotificationComponent, 
        LastSubmittedComponent],
})
export class BrandLazyModule {}
