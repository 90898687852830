<div class="employer-history">
    <div class="employer-history__title">History</div>
    <div class="employer-history__table">
        <app-datatable
            *ngIf="config"
            [config]="config"
            (sortChange)="sortChangeValue($event)"
            (filterChange)="filterChangeValue($event)"
            (selectedPageAt)="selectedPageAtValue($event)"
        ></app-datatable>
    </div>
</div>
