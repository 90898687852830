import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { NotificationItemComponent } from './notification-item.component';

@NgModule({
    declarations: [NotificationItemComponent],
    imports: [MaterialModule],
    exports: [NotificationItemComponent],
})
export class NotificationItemModule {}
