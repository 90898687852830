import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSettingsRoutingModule } from './user-settings-routing.module';
import { UserSettingsContainerComponent } from './components/user-settings-container/user-settings-container.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { SharedModule } from '../shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AdminUserComponent } from './components/admin-user/admin-user.component';
import { BrandUserComponent } from './components/brand-user/brand-user.component';
import { EmployerUserComponent } from './components/employer-user/employer-user.component';
import { PraUserComponent } from './components/pra-user/pra-user.component';
import { MyAccountService } from './services/my-account.service';
import { AuthModule } from '../auth';
import { PopupRequestComponent } from './components/popup-request/popup-request.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
    declarations: [
        UserSettingsContainerComponent,
        ChangePasswordFormComponent,
        AdminUserComponent,
        BrandUserComponent,
        EmployerUserComponent,
        PraUserComponent,
        PopupRequestComponent,
    ],
    imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule, RouterModule, UserSettingsRoutingModule, AuthModule,  ClipboardModule],
    providers: [MyAccountService],
})
export class UserSettingsModule {
    static forRoot(): ModuleWithProviders<UserSettingsModule> {
        return {
            ngModule: UserSettingsModule,
        };
    }
}
