<div class="save-question" *ngIf="!readOnlyMode">
    <div class="container-wrap">
        <div class="save-title"></div>
        <div class="group-button">
            <mat-card-actions>
                <button type="button" name="save" class="button-discard" [disabled]="responseNoti" color="danger" mat-button aria-label="button" (click)="onDiscard()">
                    <mat-icon matPrefix>close</mat-icon>
                    Discard
                </button>
                <button
                    type="submit"
                    name="submit"
                    mat-button
                    [ngClass]="isValid ? '' : 'btn-disabled'"
                    [disabled]="!isValid || responseNoti "
                    (click)="onSubmit()"
                >
                    <mat-icon matPrefix>send</mat-icon>
                    Submit
                </button>
            </mat-card-actions>
        </div>
    </div>
</div>
