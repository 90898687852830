import { CustomValidators } from './../../../shared/formly-fields/custom-validators';
import { TouchedErrorStateMatcher } from './../../../shared/validators/touched-error-state.matcher';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../auth';
import { ConfirmationDialog, ConfirmCancelDialog, confirmPasswordValidator, MIN_PASSWORD_LEN } from '../../../shared';
import { BrandAccountInfo, UpdateBrandAccountInfo } from '../../types';
import { MyAccountService } from '../../services/my-account.service';
import { AdminService, BrandService, BreadcrumbService, ChangePasswordInfo } from 'src/app/modules';
import { NgxSpinnerService } from 'ngx-spinner';
import { PopupRequestComponent } from '../popup-request/popup-request.component';
import { validateIomEmail } from 'src/app/modules/brand/validators';

@Component({
    selector: 'user-settings-brand-user',
    templateUrl: './brand-user.component.html',
    styleUrls: ['./brand-user.component.scss'],
})
export class BrandUserComponent implements OnInit, AfterViewInit, OnDestroy {
    matcher = new TouchedErrorStateMatcher()
    @Output()
    deleteBrandChangeEmit = new EventEmitter<{
        formValid: boolean;
        data: any;
    }>();
    errorMessages = new BehaviorSubject<string[]>([]);
    errorMessagesPass = new BehaviorSubject<string[]>([]);

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private myAccountService: MyAccountService,
        private adminService: AdminService,
        private authService: AuthService,
        private service: BrandService,
        private cdr: ChangeDetectorRef,
        private spinner: NgxSpinnerService,
        private breadCrumbService: BreadcrumbService,
    ) {}
    form: FormGroup;
    formEmail: FormGroup;
    userRole: string;
    hide = true;
    hideNewPassword = true;
    hideReEnterPassword = true;
    private unsubscribe$ = new Subject();
    isLogin$: Observable<boolean>;
    fullname$: Observable<string>;
    originAccountInfo: BrandAccountInfo;
    formChanged = false;
    minPasswordLen = MIN_PASSWORD_LEN;
    dialogRef: any;
    successBrand$ = this.myAccountService.successBrand$;
    errorsBrand$ = this.myAccountService.errorsBrand$;
    successChangePass$ = this.adminService.success$;
    errorsChangePass$ = this.adminService.errors$;

    tooltipList = `Valid Password format:
    - Have at least 8 characters
    - Have at least 1 uppercase
    - Have at least 1 lowercase
    - Have at least 1 numeric character
    - Have at least 1 special characters: ! @ # $ % ^ & *
    - Must not contain space
    `;

    ngOnInit(): void {
        this.breadCrumbService.setActions([
            {
                actionFn: this.requestLink.bind(this),
                iconCls: 'link',
                name: 'Request link to Hotel',
            }
        ]);

        this.isLogin$ = this.authService.isLogin$;

        this.form = this.fb.group(
            {
                brandName: new FormControl(''),
                identificationCode: new FormControl(''),
                headquarter: new FormControl(''),
                address: new FormControl(''),
                fullName: new FormControl(''),
                password: new FormControl('', {
                    validators: [
                        Validators.required,
                        Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
                    ],
                }),
                newPassword: new FormControl('', {
                    validators: [
                        Validators.required,
                        Validators.minLength(this.minPasswordLen),
                        Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
                    ],
                }),
                confirmPassword: new FormControl('', {
                    validators: [Validators.required, this.passwordCf.bind(this)],
                }),
            },
            { validators: confirmPasswordValidator() },
        );

        this.formEmail =  this.fb.group(
            {
                email: new FormControl('', {
                    validators: [Validators.required, CustomValidators.emailType],
                    asyncValidators: [validateIomEmail(this.service, this.cdr, () => this.originAccountInfo?.email)],
                }),
            }
        )

        this.form.controls.identificationCode.disable();
        this.form.controls.headquarter.disable();
        this.form.controls.address.disable();
        this.form.controls.fullName.disable();
        this.myAccountService
            .getAccountInfoForBrand()
            .pipe(
                catchError(() => {
                    this.dialog.open(ConfirmationDialog, {
                        data: {
                            title: 'Operation failed!',
                            message: 'Failed to get your account info!',
                            closeButtonText: 'Cancel',
                        },
                    });
                    return EMPTY;
                }),
                map(res => {
                    this.originAccountInfo = res.response as BrandAccountInfo;
                    this.listenToFormChanges();
                    this.bindFormValues(this.originAccountInfo);
                }),
                shareReplay(1),
                takeUntil(this.unsubscribe$),
            )
            .subscribe();

            this.successChangePass$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
                this.spinner.hide();
                if (result) {
                    const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: 'New password has been saved',
                            message: 'Please login LMPM again with you new password',
                            closeButtonText: 'OK',
                        },
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(() => {
                            this.authService.logout();
                        });
                }
            });

            this.errorsChangePass$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
                this.spinner.hide();
                this.errorMessagesPass.next(result);
                if (result && result.length > 0) {
                    this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: 'Unable to save change',
                            message: result[0] || 'Your change has not been saved due to an error. Please try again',
                            closeButtonText: 'Close',
                        },
                    });
                }
            });

            this.successBrand$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
                this.spinner.hide();
                if (result) {
                    const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: 'Your email address has been updated',
                            message: 'Please check your new mailbox and verify email',
                            closeButtonText: 'Close',
                        },
                    });

                    confirmDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(() => {
                            this.authService.logout();
                        });
                }
            });

            this.errorsBrand$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
                this.spinner.hide();
                this.errorMessages.next(result);
                if (result.length > 0) {
                    this.dialog.open(ConfirmationDialog, {
                        width: '600px',
                        data: {
                            title: 'Unable to save change',
                            message: result[0] || 'Your change has not been saved due to an error. Please try again',
                            closeButtonText: 'Close',
                        },
                    });
                }
            });
    }

    ngAfterViewInit() {
        this.form.valueChanges.subscribe(() => {
            this.deleteBrandChangeEmit.emit({
                formValid: this.form.valid,
                data: this.form.value,
            });
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    listenToFormChanges() {
        this.form.valueChanges.subscribe(val => {
            this.formChanged = val.fullName !== this.originAccountInfo.fullName;
        });

        this.formEmail.valueChanges.subscribe(val =>{
            this.formChanged = val.email !== this.originAccountInfo.email
        })
    }

    bindFormValues(info: BrandAccountInfo) {
        if (info) {
            this.form.controls.brandName.setValue(info.companyName);
            const code = info.identificationCode ? info.identificationCode : '';
            this.form.controls.identificationCode.setValue(code);
            this.form.controls.headquarter.setValue(info.headquarter);
            this.form.controls.address.setValue(info.address);
            this.form.controls.fullName.setValue(info.fullName);
            this.formEmail.controls.email.setValue(info.email);
        }
    }

    discard() {
        this.bindFormValues(this.originAccountInfo);
    }

    get brandName() {
        return this.form.controls.brandName as FormControl;
    }

    get identificationCode() {
        return this.form.controls.identificationCode as FormControl;
    }

    get location() {
        return this.form.controls.location as FormControl;
    }

    get address() {
        return this.form.controls.address as FormControl;
    }

    get fullName() {
        return this.form.controls.fullName as FormControl;
    }

    get email() {
        return this.formEmail.controls.email as FormControl;
    }

    get password() {
        return this.form.controls.password as FormControl;
    }

    get newPassword() {
        return this.form.controls.newPassword as FormControl;
    }

    get confirmPassword() {
        return this.form.controls.confirmPassword as FormControl;
    }

    // copyFnc(inputElement: any) {
    //     inputElement.select();
    //     document.execCommand('copy');
    //     inputElement.setSelectionRange(0, 0);
    // }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }

    passwordCf() {
        if (this.form !== null && this.form !== undefined) {
            const newPass = this.form.controls.newPassword;
            const confirmPass = this.form.controls.confirmPassword;
            return newPass?.value === confirmPass?.value ? null : { passwordNotMatch: true };
        }
        return null;
    }

    passwordChanged(value: string) {
        if (this.form !== null && this.form !== undefined) {
            const repassword = this.form.controls.confirmPassword;
            if (repassword.value !== '' && value !== repassword.value) {
                this.form.controls.confirmPassword.setErrors({ passwordNotMatch: true });
            }
            if (repassword.value !== '' && value === repassword.value) {
                this.form.controls.confirmPassword.setErrors(null);
            }
        }
    }

    changeEmail() {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: 'Update email address',
                message: 'Are you sure you want to move from this email address to the new one?',
                cancelButtonText: 'Discard',
                confirmButtonText: 'Update email address',
                hideCancelButton: false,
                color: 'warn',
            },
        });
        dialogRef
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    const infoBrand: UpdateBrandAccountInfo = {
                        email: this.email.value,
                    };
                    this.spinner.show();
                    this.myAccountService.updateAccountInfoForBrand(infoBrand);
                }
            });
    }

    async changePassWord() {
        const inputBrand: ChangePasswordInfo = {
            newPassword: this.newPassword.value,
            currentPassword: this.password.value,
            confirmPassword: this.confirmPassword.value,
        };
        this.spinner.show();
        this.adminService.updatePassword(inputBrand);
    }

    requestLink() {
        this.dialog.open(PopupRequestComponent, {
            width: '660px',
        });
    }
}
