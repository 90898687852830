import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { confirmPasswordValidator, CrossFieldErrorMatcher, MIN_PASSWORD_LEN } from '../../../shared';
import { ChangePasswordInfo } from '../../types';

@Component({
    selector: 'change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['./change-password-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {
    @ViewChild('f', { static: true })
    formDirective: NgForm;

    @Input()
    errorMessages: string[];

    @Output()
    changePasswordClicked = new EventEmitter<ChangePasswordInfo>();

    @Output()
    cancelClicked = new EventEmitter();

    errorMatcher = new CrossFieldErrorMatcher();
    unsubscribe$ = new Subject();

    form: FormGroup;
    hideCurrentPassword = true;
    hideNewPassword = true;
    hideConfirmPassword = true;
    minPasswordLen = MIN_PASSWORD_LEN;
    errorServerSub$ = new BehaviorSubject<string>('');

    smallScreen$ = this.breakpointObserver
        .observe(['(max-width: 600px)'])
        .pipe(map(observer => (observer.matches ? 'yes' : 'no')));

    criteria = this.updateCriteria('');

    tooltipList = `Valid Password format:
    - Have at least 8 characters
    - Have at least 1 uppercase
    - Have at least 1 lowercase
    - Have at least 1 numeric character
    - Have at least 1 special characters: ! @ # $ % ^ & *
    - Must not contain space
    `;

    private updateCriteria(newPassword: string) {
        const criteria = [
            {
                failed: true,
                description: `ACCOUNT_SETTINGS.CHANGE_PASSWORD.LEAST_MINIMUM_CHARACTERS`,
            },
            {
                failed: true,
                description: 'ACCOUNT_SETTINGS.CHANGE_PASSWORD.LEAST_ONE_NUMBER',
            },
            {
                failed: true,
                description: 'ACCOUNT_SETTINGS.CHANGE_PASSWORD.LEAST_ONE_LOWERCASE',
            },
            {
                failed: true,
                description: 'ACCOUNT_SETTINGS.CHANGE_PASSWORD.LEAST_ONE_UPPERCASE',
            },
            {
                failed: true,
                description: 'ACCOUNT_SETTINGS.CHANGE_PASSWORD.NO_SPACE_CHARACTER',
            },
        ];

        if (newPassword.length >= this.minPasswordLen) {
            criteria[0].failed = false;
        }

        if (/^(?=.*\d).{1,}$/.test(newPassword)) {
            criteria[1].failed = false;
        }

        if (/^(?=.*[a-z]).{1,}$/.test(newPassword)) {
            criteria[2].failed = false;
        }

        if (/^(?=.*[A-Z]).{1,}$/.test(newPassword)) {
            criteria[3].failed = false;
        }

        if (newPassword.length > 0 && newPassword.indexOf(' ') === -1) {
            criteria[4].failed = false;
        }
        return criteria;
    }

    constructor(
        private fb: FormBuilder,
        private breakpointObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
        private matDialogRef: MatDialogRef<ChangePasswordFormComponent>,
    ) {}

    ngOnInit() {
        this.form = this.fb.group(
            {
                currentPassword: new FormControl('', { validators: [Validators.required]}),
                newPassword: new FormControl('', {
                    validators: [
                        Validators.required,
                        Validators.minLength(this.minPasswordLen),
                        Validators.pattern(/^(?=.{8,}$)(?=.*[a-z])(?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/),
                    ],
                }),
                confirmPassword: new FormControl('', {
                    validators: [Validators.required, this.passwordCf.bind(this)],
                }),
            },
            { validators: confirmPasswordValidator() },
        );

        this.newPassword.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
            this.criteria = this.updateCriteria(value);
            this.cdr.markForCheck();
        });
    }

    get currentPassword() {
        return this.form.controls.currentPassword as FormControl;
    }

    get newPassword() {
        return this.form.controls.newPassword as FormControl;
    }

    get confirmPassword() {
        return this.form.controls.confirmPassword as FormControl;
    }

    changePassword() {
        if (this.form.valid) {
            this.changePasswordClicked.emit(this.form.value);
        }
    }

    resetForm() {
        this.formDirective.resetForm();
        this.form.setValue({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closeDialog() {
        this.matDialogRef.close();
    }

    passwordCf() {
        if (this.form !== null && this.form !== undefined) {
            const newPass = this.form.controls.newPassword;
            const confirmPass = this.form.controls.confirmPassword;
            return newPass.value === confirmPass.value ? null : { passwordNotMatch: true };
        }
        return null;
    }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }
}
