import { CurrencyValidator } from './validators/currency.validator';
import { EmailValidator } from './validators/email.validator';
import { QuantityValidator } from './validators/quantity.validator';
import { UrlValidator } from './validators/url.validator';

export const CustomValidators = {
    currency: CurrencyValidator,
    quantity: QuantityValidator,
    emailType: EmailValidator,
    urlType: UrlValidator,
};
