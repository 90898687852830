import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PraService } from "src/app/modules";
import { ConfirmationDialog } from "src/app/modules/shared";

@Component({
    selector: 'app-list-hotels',
    templateUrl: './list-hotels.component.html',
    styleUrls: ['./list-hotels.component.scss'],
})

export class ListHotelsComponent implements OnInit, OnChanges,OnDestroy {
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
    @Input() lstHotelIds: string[] = [];

    private unsubscribe$ = new Subject();
    hotelsUser$ = this.service.hotelsUser$;
    hotels: [{companyname: string, createdat: string, id: string, prastatus: string, continue: boolean}];
    slideConfig = { "slidesToShow": 3.5, "slidesToScroll": 4, "infinite": false, };
    disablePrev = true;
    disableNext = false;

    constructor(private service: PraService, private router: Router, private dialog: MatDialog) {}

    ngOnInit() {
        this.service.getHotelsUser();
        this.hotelsUser$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.hotels = result.response.hotels
            this.checkListHotels()
        })
    }

    ngOnChanges(_changes: SimpleChanges) {
        if (this.lstHotelIds.length > 0) {
            this.checkListHotels()
        } else {
            if (this.hotels) {
                this.hotels.forEach(x => {
                    x.continue = false;
                })
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    checkListHotels() {
        if (this.lstHotelIds?.length > 0 && this.hotels?.length > 0) {
            this.hotels.forEach(x => {
                if (this.lstHotelIds.indexOf(x.id) !== -1) {
                    x.continue = true;
                } else {
                    x.continue = false;
                }
            })
        }
    }

    startAssessment(hotelId: string, praStatus: string) {
        if (praStatus === 'Deactivated') {
            const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                data: {
                    message: `This hotel deactivated your account. You are not be able to answer Self Assessment Questionnaire for this Hotel`,
                    closeButtonText: 'Close',
                },
            });

            confirmDialogRef
                .afterClosed()
                .subscribe();
        } else {
            this.router.navigate([`dashboard/agency/self-assessment-questionnaire/${hotelId}`]);
        }
    }

    next() {
        this.slickModal.slickNext();
    }

    prev() {        
        this.slickModal.slickPrev();
    }

    slickInit(_e: any) {
    }

    breakpoint(_e: any) {
    }

    afterChange(e: any) {
        this.disablePrev = e.first;
        if (e.currentSlide === this.hotels.length - 1) {
            this.disableNext = true;
        } else {
            this.disableNext = false;
        }
        
    }

    beforeChange(_e: any) {
    }
}
