import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { EmployerService } from '../../employer';
import { Question } from '../../question-form/types';
import { FormlyInputType } from '../../types';
import { CustomFormlyFieldConfig, FormlyFieldLayout } from '../types';
import * as myGlobals from '../../../globals';

@Injectable({
    providedIn: 'root',
})
export class Formly2Service {
    constructor(private employerService: EmployerService) {}
    bindingNaviSub = new Subject<any>();
    bindingNavi$ = this.bindingNaviSub.asObservable();
    headquaterCode = "employer_scoping_A3";
    lstCountryQuestion = ["emp_regis_A10","emp_regis_A11_2", "emp_regis_A11_3", "employer_scoping_A14", "employer_scoping_A14_2", "employer_scoping_A14_3","emp_pra_scop_A9","emp_pra_scop_A10","emp_pra_scop_A11"]
    lstCountryQuestionDropDown: Question[];

    allQuestionsToFormlyConfig(
        questions: Question[],
        layout?: FormlyFieldLayout,
        readonly?: boolean,
        partialGroupNumber?: number,
        partialNumber?: number,
        extraContent?: boolean,
        saq?: boolean,
        displayLabel?: boolean,
        partialId?: string,
    ): CustomFormlyFieldConfig[] {
        layout = layout || 'default';
        let filteredQuestions: Question[];
        if (saq !== undefined && !saq) {
            if (displayLabel) {
                filteredQuestions = questions.filter(question => {
                    return !question.parentId;
                });
                this.lstCountryQuestionDropDown = filteredQuestions.filter(q => this.lstCountryQuestion.includes(q.code));
                filteredQuestions = this.groupQuestionsCountryGroup(questions)
                let result = filteredQuestions.map((question, questionIdx) =>
                    this.convertQuestionToFormlyConfig(questions, question, questionIdx + 1, layout, readonly, undefined, undefined, undefined, undefined, displayLabel, partialId));
                return result;
            } else {
                filteredQuestions = questions.filter(question => {
                    return !question.parentId;
                });
                let hasIdx = false
                if(filteredQuestions.length > 1) {
                    hasIdx = true
                }
                const result = filteredQuestions.map((question, questionIdx) =>
                    this.convertQuestionToFormlyConfig(questions, question, questionIdx + 1, layout, readonly, hasIdx));
                return result;
            }
        } else {
            filteredQuestions = this.groupQuestionsSAQ(questions);
            let hasIdx = false
            if(filteredQuestions.length > 1) {
                hasIdx = true
            }
            const result = filteredQuestions.map((question, questionIdx) =>
                this.convertQuestionToFormlyConfig(filteredQuestions, question, questionIdx + 1, layout, readonly,hasIdx, partialGroupNumber, partialNumber, extraContent),
            );
            return result;
        }
    }

    convertQuestionToFormlyConfig(
        questions: Question[],
        question: Question,
        questionIdx: number,
        layout?: FormlyFieldLayout,
        readonly?: boolean,
        hasIdx?: boolean,
        partialGroupNumber?: number,
        partialNumber?: number,
        extraContent?: boolean,
        displayLabel?: boolean,
        partialId?: string,
    ): CustomFormlyFieldConfig {
        let config: CustomFormlyFieldConfig;
        const hideExpression = this.getHideExpression(question, readonly);
        const validators = this.getValidators(question);
        const asyncValidators = this.getAsyncValidators(question, partialId);
        let questionIndex = `${questionIdx}.`;
        if (partialGroupNumber && partialNumber) {
            questionIndex = hasIdx ?
             `${partialGroupNumber}.` + `${partialNumber}.` + `${questionIdx}` :
              questionIndex = `${partialGroupNumber}.` + `${partialNumber}`;
        }
        if ((extraContent !== undefined && extraContent) || displayLabel) {
            questionIndex = question.symbol;
        }

        const required = this.getRequired(question);
        let placeholder = ''
        if (question.controlType === 'text-box') {
            placeholder = 'Input'
        }
        config = {
            partialId: partialId,
            questionId: question.id,
            key: this.formatKey(question.code),
            type: this.getInputType(question),
            hide: hideExpression,
            templateOptions: {
                symbol: questionIndex,
                label: question.label,
                required: required,
                multiple: this.getMultipleConfig(question),
                options: this.getOptions(question),
                disabled: readonly || !question.editable,
                layout: layout,
                total: this.getTotalConfig(question),
                readonly: readonly || !question.editable,
                placeholder: question.placeholder || placeholder,
            },
            fieldGroup: (question.controlType === 'wrap-question' || question.controlType === 'wrap-country-group')
                        ? this.convertQuestionWrapToFormlyConfig(question, layout, readonly, partialId)
                        : this.convertSubAnswersToFormlyConfig(question, layout, readonly, questions),
            validators: validators,
            asyncValidators: asyncValidators,
            defaultValue: this.getValue(question) ? this.getValue(question) : undefined,
        };

        return config;
    }

    groupQuestionsSAQ(questions: Question[]) {
        let questionsResult: Question[] = questions.map(a => Object.assign({}, a));
        for(let question of questionsResult){
            if (question.parentId === null) {
                let childQuestions = questions.filter(quest => quest.parentId === question.id)

                if (childQuestions && childQuestions.length) {
                    const clone = Object.assign({}, question)
                    childQuestions.unshift(clone);
                    question.childQuestions = childQuestions;
                    question.controlType = 'wrap-question';
                }
            }
        }

        questionsResult = questionsResult.filter(question => question.parentId === null);
        return questionsResult;
    }

    groupQuestionsCountryGroup(questions: Question[]) {
        let questionsResult: Question[] = questions.map(a => Object.assign({}, a));
        for(let question of questionsResult){
            if (question.parentId === null && question.questionGroupId) {
                let childQuestions = questions.filter(quest => quest.parentId === question.id)

                if (childQuestions && childQuestions.length) {
                    const clone = Object.assign({}, question)
                    childQuestions.unshift(clone);
                    question.childQuestions = childQuestions;
                    question.controlType = 'wrap-country-group';
                }
            }
        }

        const questionsGroupResult = questionsResult.filter(question => question.parentId === null && question.questionGroupId);
        const lstQuestionsGroupIdResult = questionsGroupResult.map(q => q.id);
        const normalQuestionResult = questionsResult.filter(q => !q.parentId && !lstQuestionsGroupIdResult.includes(q.id));
        questionsResult = questionsGroupResult.concat(normalQuestionResult);
        questionsResult.sort((a,b) => a.order - b.order)
        return questionsResult;
    }

    getTotalConfig(question: Question): boolean | undefined {
        if (!question.subAnswer) {
            return undefined;
        }
        return true;
    }

    convertSubAnswersToFormlyConfig(
        question: Question,
        layout?: FormlyFieldLayout,
        readonly?: boolean,
        rootQuestions?: Question[]
    ): CustomFormlyFieldConfig[] | undefined {
        if (rootQuestions?.length && rootQuestions?.filter(x => x.parentId == question.id).length) {
            return this.convertToHasChildFormlyConfig(rootQuestions, question, layout, readonly);
        }
        if (question.controlType == "wrap-question") {
            return this.convertQuestionWrapToFormlyConfig(question, layout, readonly);
        }
        if (!question.subAnswer) {
            return undefined;
        }
        const parentKey = this.formatKey(question.code);
        return question?.subAnswer?.answerTitle?.map((answerTitle, index) => {
            let defaultValue: number | undefined = undefined;
            if (question.value && this.isNotEmpty(question.value[answerTitle.key])) {
                defaultValue = parseInt(question.value[answerTitle.key]);
            }

            const hideExpression = this.getHideExpression(question, readonly);
            const required = this.getRequired(question);
            const config: CustomFormlyFieldConfig = {
                key: `${parentKey}-${index}`,
                type: 'quantity',
                hide: hideExpression,
                templateOptions: {
                    label: answerTitle.key,
                    placeholder: '0',
                    required: required,
                    type: 'number',
                    disabled: readonly || !question.editable,
                    layout: layout,
                    readonly: readonly || !question.editable,
                },
                className: 'no-padding',
                validators: { validation: ['quantity'] },
                defaultValue: defaultValue,
            };
            return config;
        });
    }
    convertQuestionWrapToFormlyConfig(
        question: Question,
        layout?: FormlyFieldLayout,
        readonly?: boolean,
        partialId?: string
    ): CustomFormlyFieldConfig[] | undefined {
        if (!question.childQuestions) {
            return undefined;
        }
        return question.childQuestions?.map((child) => {
            // const hideExpression = this.getHideExpression(questions, question);
            const validators = this.getValidators(child);
            const asyncValidators = this.lstCountryQuestion.includes(child.code) ? this.getAsyncValidatorsForCountryDropdown(child, partialId) : this.getAsyncValidators(child);
            let placeholder = child.required ? '' : '(optional)';
            if (child.controlType === 'drop-down' || child.controlType === 'drop-down-country') {
                placeholder = 'Select';
            }
            const required = this.getRequired(child);
            return {
                questionId: child.id,
                key: this.formatKey(child.code),
                type: this.getInputType(child),
                partialId: partialId,
                // hideExpression: hideExpression,
                templateOptions: {
                    symbol: "",
                    label: child.label,
                    required: required,
                    placeholder: child.placeholder ? child.placeholder : placeholder,
                    multiple: this.getMultipleConfig(child),
                    options: this.getOptions(child),
                    disabled: readonly || !child.editable,
                    layout: layout,
                    total: this.getTotalConfig(child),
                    readonly: readonly || !child.editable,
                },
                // fieldGroup: this.convertQuestionWrapToFormlyConfig(child, layout, readonly),
                validators: validators,
                asyncValidators: asyncValidators,
                defaultValue: this.getValue(child),
            }
        });
    }

    convertToHasChildFormlyConfig(
        rootQuestion: Question[],
        question: Question,
        layout?: FormlyFieldLayout,
        readonly?: boolean,
    ): CustomFormlyFieldConfig[] | undefined {
        const parentKey = this.formatKey(question.code);
        return rootQuestion?.filter(x => x.parentId == question.id).map((ques, index) => {
            let defaultValue: number | undefined = undefined;
            if (question.value && this.isNotEmpty(ques.value)) {
                defaultValue = (ques.controlType == 'drop-down' || ques.controlType == 'drop-down-country') ? ques.value : parseInt(ques.value);
            }
            const validators = this.getValidators(ques);
            const asyncValidators = this.getAsyncValidators(ques);
            const required = this.getRequired(ques);
            const config: CustomFormlyFieldConfig = {
                key: `${parentKey}-${index}`,
                type: this.getInputType(ques),
                templateOptions: {
                    questionId: ques.id,
                    label: ques.label,
                    placeholder: ques.controlType == 'drop-down' || ques.controlType == 'drop-down-country' ? 'Choose option' : '0',
                    required: required,
                    disabled: readonly || !ques.editable,
                    layout: layout,
                    options: this.getOptions(ques),
                    readonly: readonly || !ques.editable,
                },
                className: 'no-padding',
                validators: validators,
                asyncValidators: asyncValidators,
                defaultValue: defaultValue,
            };
            return config;
        });
    }

    getValue(question: Question): any {
        switch (question.controlType) {
            case 'question-group':
                return undefined;
            default: {
                return question.value;
            }
        }
    }

    getInputType(question: Question): FormlyInputType {
        switch (question.controlType) {
            case 'text-box':
                if (question.valueType === 'currency') {
                    return 'currency';
                }
                if (question.valueType === 'number') {
                    return 'quantity';
                }
                if (question.valueType === 'url') {
                    return 'url';
                }
                return 'freetext';
            case 'radio-btn':
            case 'group-btn':
                return 'logical';
            case 'drop-down':
                //if (this.isYesNoQuestion(question)) return 'logical';
                return 'single-select';
            case 'drop-down-date':
                return 'single-select';
            case 'drop-down-multi':
                return 'multi-select';
            case 'question-group':
                return 'group-question-item';
            case 'file-upload':
                return 'file-upload';
            case 'wrap-question':
                return 'wrap-question';
            case 'wrap-country-group':
                return 'wrap-country-group';
            case 'question-btn':
                return 'question-btn';
            case 'drop-down-country':
                return 'drop-down-country';
            case 'question-group-label':
                return 'question-group-label';
            default:
                return 'freetext';
        }
    }
    getMultipleConfig(question: Question): boolean | undefined {
        if (question.controlType !== 'drop-down-multi') {
            return undefined;
        }
        return true;
    }
    getOptions(question: Question): undefined | Array<{ value: string | number; label: string } | any> {
        const options = question.options;
        // Special case
        if (question.controlType === 'drop-down-date') {
            const listYears = this.getListYears();
            return listYears.map(year => {
                const opt: { label: string; value: string | number } = {
                    label: year.toString(),
                    value: year,
                };
                return opt;
            });
        }

        if (!options) return undefined;

        if (question.controlType === 'drop-down-multi') {
            return options.map(option => {
                const opt: { key: string; value: string } = {
                    key: option.key,
                    value: option.value,
                };
                return opt;
            });
        }

        if (this.isYesNoQuestion(question)) {
            return options.map(option => {
                const opt: { value: string | number | boolean; label: string } = {
                    label: option.key,
                    value: option.key.localeCompare('Yes') > -1 ? true : false,
                };
                return opt;
            });
        }
        return options.map(option => {
            const opt: { value: string; label: string } = {
                label: option.key,
                value: option.value || option.key,
            };
            return opt;
        });
    }
    isYesNoQuestion(question: Question): boolean {
        return (
            question &&
            !!question.options &&
            question.options.length === 2 &&
            question.options.some(option => option.key === 'Yes')
        );
    }
    formatKey(code: string): string {
        return (code || '').replace('.', '_');
    }
    getChildren(questions: Question[], question: Question) {
        const children = (questions || []).filter(q => q.parentId && q.parentId == question.id);
        return { hasChildren: children && children.length, children };
    }
    getTotalValues(question: Question): string[] {
        if (!question.subAnswer) {
            return [];
        }
        return question.subAnswer.answerTitle.map(answerTitle => answerTitle.key);
    }
    getHideExpression(question: Question, readOnly?: Boolean) {
        let result = true;
        if (!question.displayCondition) {
            result = false;
        }
        if(readOnly) {
            return false
        }
        myGlobals.allQuestion.forEach(q => {
            question.displayCondition?.forEach(questionCondition => {
               if(q.id === questionCondition.key && !q.hidden){
                   if(question.displayConditionType && question.displayConditionType === "and") {
                        if(q.value?.toLowerCase() !== questionCondition.value?.toLowerCase()) {
                            result = false;
                        }
                   }else if(q.value?.toLowerCase() == questionCondition.value?.toLowerCase()) {
                        result = false;
                   }
               }
            });

        });
        myGlobals.allQuestion.forEach(q => {
            if(q.id === question.id){
                q.hidden = result;
            }
        });
        return result;
    }

    getRequired(question: Question) {
        let result = false;
        if (!question.requireCondition || (question.requireCondition && question.questionGroupId)) {
            result = question.required;
        }
        myGlobals.allQuestion.forEach(q => {
            question.requireCondition?.forEach(requireCondition => {
               if(q.id === requireCondition.key){
                    if(q.value === question.value) {
                        result = true;
                    }
               }
            });

        });

       return result
    }

    getValidators(question: Question): { validation: { name: string }[] } | undefined {
        if (question.controlType === 'question-group') {
            return undefined;
        }
        switch (question.valueType) {
            case 'currency':
                return { validation: [{ name: 'currency' }] };
            case 'email':
                return { validation: [{ name: 'email' }] };
            case 'unique-email':
                return { validation: [{ name: 'email' }] };
            case 'number':
                return { validation: [{ name: 'quantity' }] };
            case 'url':
                return { validation: [{ name: 'url' }] };
            default:
                return undefined;
        }
    }
    getAsyncValidators(question: Question, partialId?: string): any | undefined {
        if (question.valueType === 'unique-email') {
            return {
                uniqueEmail: {
                    expression: (control: FormControl) => {
                        return new Promise(resolve => {
                            setTimeout(async () => {
                                resolve(this.employerService.isEmailUnique(control.value));
                            }, 300);
                        });
                    },
                    message: 'This email already exists.',
                },
            };
        }
        if (question.code === 'emp_regis_A1') {
            return {
                uniqueHotelName: {
                    expression: (control: FormControl) => {
                        return new Promise(resolve => {
                            setTimeout(async () => {
                                resolve(this.employerService.isHotelNameUnique(control.value));
                            }, 300);
                        });
                    },
                    message: 'Hotel account with this name already exists.',
                },
            };
        }
        if (question.code === "employer_saq_A1") {
            return {
                uniqueHotelName: {
                    expression: (control: FormControl) => {
                        return new Promise(resolve => {
                            setTimeout(async () => {
                                resolve(this.employerService.isHotelNameUniqueHotelSAQA1(control.value));
                            }, 300);
                        });
                    },
                    message: 'Hotel account with this name already exists.',
                },
            };
        }
        //handle check email must be different with hotel or hotel group email
        if (question.valueType === 'email' && question.code === 'emp_pra_scop_A13') {
            return {
                differentEmail: {
                    expression: (control: FormControl) => {
                        return new Promise(resolve => {
                            setTimeout(async () => {
                                resolve(this.employerService.isEmailDifferentHotelOrHotelGroup(control.value, partialId));
                            }, 300);
                        });
                    },
                    message: 'This email belongs to an user that has different user type.',
                },
            };
        }
        return undefined;
    }

    getAsyncValidatorsForCountryDropdown(question: Question, partialId?: string): any | undefined {
        if (question.controlType === 'drop-down-country') {
            return {
                uniqueCountry: {
                    expression: (control: FormControl) => {
                        return new Promise(resolve => {
                            setTimeout(async () => {
                                let flag = true;
                                this.lstCountryQuestionDropDown.forEach(x => {
                                    if (x.code === question.code) {
                                        x.value = control.value;
                                    }
                                })
                                this.lstCountryQuestionDropDown.filter(q => q.value).forEach(q => {
                                    flag = this.lstCountryQuestionDropDown.filter(z => z.id != q.id && z.value).map(z => z.value).includes(q.value) ? false : flag;
                                    return flag;
                                });
                                myGlobals.countryChangeSub$.next({key: partialId, value: flag});
                                resolve(
                                    !this.lstCountryQuestionDropDown.filter(item=>item.id != question.id).map(ques=>ques.value).includes(control.value) || control.value == undefined
                                );
                            }, 400);
                        });
                    },
                    message: 'This country is already existed.',
                },
            };
        }
        return undefined;
    }

    mapFormlyFieldToResponse(fields: CustomFormlyFieldConfig[], userResponse: any): { [name: string]: any } {
        const result: any = {};
        if (!userResponse) {
            return result;
        }
        fields.forEach(field => {
            if (field.questionId) {
                if(field.type === "wrap-question" || field.type === 'wrap-country-group') {
                    if (field.fieldGroup && field.fieldGroup.length) {
                        const answer1 = userResponse[this.keyToString(field.key)];
                        if (this.isNotEmpty(answer1)) {
                            field.fieldGroup.forEach(childField => {
                                const childFieldKey = this.keyToString(childField.key);
                                const childAnswer = answer1[childFieldKey];
                                if(childField.questionId ) {
                                    result[childField.questionId] = childAnswer;
                                }
                            });
                        }
                    } else {
                        const answer1 = userResponse[this.keyToString(field.key)];
                        // if (this.isNotEmpty(answer1)) {
                        //     result[field.questionId] = answer1;
                        // }
                        result[field.questionId] = answer1;
                    }
                }else if (field.fieldGroup && field.fieldGroup.length) {
                    const answer1 = userResponse[this.keyToString(field.key)];
                    if (this.isNotEmpty(answer1)) {
                        const childResponse: any = {};
                        field.fieldGroup.forEach(childField => {
                            const childFieldKey = this.keyToString(childField.key);
                            const childAnswer = answer1[childFieldKey];
                            if (childField.templateOptions?.label) {
                                childResponse[childField.templateOptions?.questionId] = childAnswer;
                                result[childField.templateOptions?.questionId] = childAnswer;
                            }
                        });
                        result[field.questionId] = childResponse;
                    }
                } else {
                    const answer1 = userResponse[this.keyToString(field.key)];
                    // if (this.isNotEmpty(answer1)) {
                    //     result[field.questionId] = answer1;
                    // }
                    result[field.questionId] = answer1;
                }
            }
        });
        return result;
    }

    keyToString(key: string | number | string[] | undefined): string {
        if (typeof key === 'string') {
            return key;
        } else if (typeof key === 'number') {
            return key.toString();
        } else if (typeof key === 'object') {
            return key?.join('');
        } else {
            return '';
        }
    }

    getListYears() {
        const currentYear = new Date().getFullYear();
        const years = [];
        let startYear = 1900;
        while (startYear <= currentYear) {
            years.push(startYear++);
        }

        var sortedArray: number[] = years.sort((n1, n2) => n2 - n1);
        return sortedArray;
    }

    isNotEmpty(res: any): boolean {
        if (typeof res === 'boolean' || typeof res === 'number') {
            return true;
        } else if (typeof res === 'string' || typeof res === 'undefined') {
            return !!res;
        } else if (typeof res === 'object') {
            return res && Object.keys(res).length > 0;
        }
        return false;
    }

    convertChildrenToFormlyConfig(questions: Question[], parentQuestionIdx: number, rootQuestions: Question[]): CustomFormlyFieldConfig[] {
        return questions.map((question, questionIdx) => this.convertChildToFormlyConfig(question, parentQuestionIdx, questionIdx + 1, rootQuestions));
    }

    convertChildToFormlyConfig(question: Question, parentQuestionIdx: number, questionIdx: number, rootQuestions?: Question[]): CustomFormlyFieldConfig {
        const config: CustomFormlyFieldConfig = {
            questionId: question.id,
            key: this.formatKey(question.code),
            type: 'group-question-item',
            templateOptions: {
                label: `${parentQuestionIdx}.${questionIdx}. ${question.label}`,
                required: question.required,
                total: true,
            },
            fieldGroup: this.convertSubChildsToFormlyConfig(question, rootQuestions),
        };
        return config;
    }

    convertSubChildsToFormlyConfig(question: Question, questions?: Question[]) {
        if (!questions?.length && !questions?.filter(x => x.parentId == question.id).length) {
            return [];
        }
        const parentKey = this.formatKey(question.code);
        return questions?.filter(x => x.parentId == question.id).map((ques, index) => {
            const config: CustomFormlyFieldConfig = {
                key: `${parentKey}-${index}`,
                type: 'quantity',
                templateOptions: {
                    label: ques.label,
                    placeholder: ques.label,
                    required: ques.required,
                    type: 'number',
                },
                className: 'vertical-layout',
                validators: { validation: ['quantity'] },
            };
            return config;
        });
    }
}
