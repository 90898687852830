<div class="employer-button">
    <div class="group-button" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1.5rem">
        <mat-card-actions>
            <button class="delete-button" type="button" name="save" mat-button aria-label="submit" (click)="DeleteHotel()">
                Delete Hotel
            </button>
            <button *ngIf="employerStatus != 'Onboarding' && employerStatus != 'Invited'" class="active-btn" type="submit" (click)="activeOrDeactive()" name="submit" mat-button >
                {{ employerStatus == 'Active' ? 'Deactivate' : 'Activate' }}
            </button>
        </mat-card-actions>
    </div>
</div>
