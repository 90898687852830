import { CustomValidators } from './../../../shared/formly-fields/custom-validators';
import { TouchedErrorStateMatcher } from './../../../shared/validators/touched-error-state.matcher';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordInfo } from '../../types';

@Component({
    selector: 'forgot-password-form',
    template: `
        <form [formGroup]="form" (ngSubmit)="clicked()" novalidate>
            <mat-card class="mat-elevation-z0">
                <mat-card-header>
                    Forgot Your Password?
                </mat-card-header>
                <mat-card-content class="content-container">
                    <p class="instruction">
                        Enter your email below to receive your password reset instructions
                    </p>
                    <div class="username-container">
                        <div class="form-field-label">Email</div>
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                type="email"
                                formControlName="email"
                                [attr.aria-label]="'Email'"
                                required
                                placeholder="Enter Email"
                                autocomplete="off"
                                [errorStateMatcher]="matcher"
                            />
                            <mat-error *ngIf="email.errors && email.errors['required']"
                                >Please input your email.</mat-error
                            >
                            <mat-error *ngIf="email.errors && email.errors['email']"
                                >Please input a valid email.</mat-error
                            >
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <app-error-box [errorMessages]="errorMessages"></app-error-box>
                <mat-card-actions align="center" style="text-align: center">
                    <button
                        type="submit"
                        mat-button
                        color="primary"
                        [attr.aria-label]="'Login'"
                        style="margin-top: 0;"
                        [disabled]="form.invalid"
                        [class]="form.valid ? 'btn-active' : ''"
                    >
                        Send
                    </button>
                    <button
                        mat-button
                        class="mat-underline"
                        type="button"
                        (click)="loginClicked.emit()"
                        style="margin-top: 1rem; text-transform: unset !important"
                    >
                        Back to Login
                    </button>
                </mat-card-actions>
            </mat-card>
        </form>
    `,
    styleUrls: ['./forgot-password-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent implements OnInit {
    matcher = new TouchedErrorStateMatcher()
    @Output()
    forgotPasswordClicked = new EventEmitter<ForgotPasswordInfo>();

    @Output()
    loginClicked = new EventEmitter();

    @Input()
    errorMessages: string[] = [];

    form: FormGroup;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: new FormControl('', { validators: [Validators.required, CustomValidators.emailType] }),
        });
    }

    get email() {
        return this.form.controls.email as FormControl;
    }

    clicked() {
        if (this.form.valid) {
            this.forgotPasswordClicked.emit(this.form.value);
        }
    }
}
