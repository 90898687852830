import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isSection, ISurveyPartial } from '../scoping-question/types';
import { PartialResponse, Question, Section } from './types';
@Component({
    selector: 'app-question-form',
    templateUrl: './question-form.component.html',
    styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent implements OnChanges {
    @Input() data: ISurveyPartial;
    @Input() disabled: boolean;
    @Output() formChange = new EventEmitter<PartialResponse>();
    form!: FormGroup;
    payLoad: any;
    sections: Section[] = [];
    formValuesSub: Subscription;

    isFirstTimeChange = false;

    ngOnChanges() {
        if (this.data && this.data.content) {
            if (isSection(this.data.content)) {
                this.sections = [this.data.content as Section];
            } else {
                this.sections = this.data.content as Section[];
            }
            for (var i = 0; i < this.sections.length; i++) {
                if (this.sections[i] && this.sections[i].questions) {
                    this.sections[i].questions.forEach(ques => {
                        ques.editable = this.disabled ? !this.disabled : ques.editable;
                        ques.parentId = ques.parentId ? ques.parentId : '';
                        this.makeSymbol(this.sections[i], '');
                    });
                }
            }
            this.form = this.toFormGroup(this.sections);
            this.isFirstTimeChange = true;
            const summary: PartialResponse = {
                partialId: this.data.id,
                responses: this.form.getRawValue(),
                formValid: this.form.valid,
                formDirty: false
            };
            this.formChange.emit(summary);

            if (this.formValuesSub) {
                this.formValuesSub.unsubscribe();
            }
            this.formValuesSub = this.form.valueChanges
                .pipe(debounceTime(300))
                .subscribe(_formValues => {
                    const summary: PartialResponse = {
                        partialId: this.data.id,
                        responses: this.form.getRawValue(),
                        formValid: this.form.valid,
                        formDirty: this.isFirstTimeChange ? false : this.form.dirty
                    };
                    this.isFirstTimeChange = false;
                    this.formChange.emit(summary);
                });
        }
    }

    toFormGroup(sections: Section[]) {
        const group: any = {};
        sections.forEach(section => {
            section.questions.forEach(question => {
                group[question.id] = question.required
                    ? new FormControl(question.value || '', Validators.required)
                    : new FormControl(question.value || '');
            });
        });
        return new FormGroup(group);
    }
    onSubmit() {
        this.payLoad = { ...this.form.value };
    }
    makeSymbol(section: Section, parent: string) {
        const arr: Question[] = section.questions;
        arr.filter(x => x.parentId == parent).forEach((item, index) => {
            let parentSymbol = '';
            if (item.parentId == '') {
                if (item.sectionId != '') {
                    if (section.id == item.sectionId) {
                        if (section.symbolType == 'number') {
                            parentSymbol = section.order.toString();
                        } else {
                            parentSymbol = String.fromCharCode(65 + index);
                        }
                    }
                }
            } else {
                const parentQuestion = section.questions.find(
                    question => question.id == item.parentId && question.sectionId == item.sectionId,
                );
                if (parentQuestion) {
                    parentSymbol = parentQuestion.symbol;
                }
            }
            if ((item.symbolType = 'number')) {
                if (parentSymbol != '') {
                    item.symbol = parentSymbol + '.' + (index + 1);
                } else {
                    item.symbol = (index + 1).toString();
                }
            }
            this.makeSymbol(section, item.id);
        });
    }
}
