import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule, RouterModule, TranslateModule],
    declarations: [],
    exports: [CommonModule, SharedModule],
    providers: [],
})
export class AdminLazyModule {}
