import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'pra-navigator',
    templateUrl: './pra-navigator.component.html',
    styleUrls: ['./pra-navigator.component.scss'],
})
export class PraNavigatorComponent {
    @Output()
    addNewClicked = new EventEmitter();

    addItem() {
        this.addNewClicked.emit();
    }
}
