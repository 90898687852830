import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { BrandRegisterSuccessComponent, BrandTermDialogComponent } from './components';
import { BrandRegisterComponent } from './components/containers/brand-register.component';

@NgModule({
    declarations: [BrandRegisterSuccessComponent, BrandTermDialogComponent, BrandRegisterComponent],
    imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule],
    exports: [BrandRegisterSuccessComponent, BrandTermDialogComponent, BrandRegisterComponent],
})
export class BrandRegisterLazyModule {}
