<div class="brand-container">
    <app-last-notification
        [notificationResult]="notificationResult$ | async"
        (viewNotification)="redirectNotification($event)"
        (viewRequest)="viewRequest($event)"
        (listResponses)="listResponsesFromLatestUpdates($event)"
    ></app-last-notification>
    <app-last-submitted
        [documents]="document$ | async"
        [total]="total$ | async"
        [pageSize]="5"
        [pageIndex]="pageIndex$ | async"
        (paginate)="docListPaginate($event)"
        (sortChange)="docListSortChange($event)"
        (viewClicked)="goToDocument($event)"
        (deleteClicked)="deleteScopingPending($event)"
        (downloadCSV)="downloadCSV($event)"
    ></app-last-submitted>
</div>
