<div class="metrics-container">
    <div class="metrics-item">
        <div class="body-top">
            <div class="icon-group">
                <div class="icon">
                    <img class="img-dashboard" src="../assets/images/apartment.svg" alt="logo" />
                </div>
            </div>
            <div class="total">
                <label class="title">Hotel Groups</label>
                <p class="number">{{matrixRes?.brand?.total | json}}</p>
            </div>
        </div>
        <div class="body-mid">
            <div class="body-mid-item">
                <label class="status-item">Active</label>
                <p class="number">{{matrixRes?.brand?.active | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Deleted</label>
                <p class="number">{{matrixRes?.brand?.deleted | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Deactivated</label>
                <p class="number">{{matrixRes?.brand?.deactive | json}}</p>
            </div>
        </div>
        <div class="body-bottom">
        </div>
    </div>
    <div class="metrics-item">
        <div class="body-top">
            <div class="icon-group">
                <div class="icon">
                    <img class="img-dashboard" src="../assets/images/apartment.svg" alt="logo" />
                </div>
            </div>
            <div class="total">
                <label class="title">Hotels</label>
                <p class="number">{{matrixRes?.hotel?.total | json}}</p>
            </div>
        </div>
        <div class="body-mid">
            <div class="body-mid-item">
                <label class="status-item">Active</label>
                <p class="number">{{matrixRes?.hotel?.active | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Invited</label>
                <p class="number">{{matrixRes?.hotel?.invited | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Deactivated</label>
                <p class="number">{{matrixRes?.hotel?.deactive | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Deleted</label>
                <p class="number">{{matrixRes?.hotel?.deleted | json}}</p>
            </div>
        </div>
        <div class="body-bottom">
            <div class="body-bottom-item">
                <label class="status-item">Questionnaires</label>
                <p class="number">{{matrixRes?.hotel?.questionnaire | json}}</p>
            </div>
            <div class="body-bottom-item">
                <label class="status-item">Scoping Documents</label>
                <p class="number">{{matrixRes?.hotel?.scoping | json}}</p>
            </div>
        </div>
    </div>
    <div class="metrics-item">
        <div class="body-top">
            <div class="icon-group">
                <div class="icon">
                    <img class="img-dashboard" src="../assets/images/business_center.svg" alt="logo" />
                </div>
            </div>
            <div class="total">
                <label class="title">Recruitment Agencies</label>
                <p class="number">{{matrixRes?.pra?.total | json}}</p>
            </div>
        </div>
        <div class="body-mid">
            <div class="body-mid-item">
                <span class="status-item">Active</span>
                <p class="number">{{matrixRes?.pra?.active | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Invited</label>
                <p class="number">{{matrixRes?.pra?.invited | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Deactivated</label>
                <p class="number">{{matrixRes?.pra?.deactive | json}}</p>
            </div>
            <div class="body-mid-item">
                <label class="status-item">Deleted</label>
                <p class="number">{{matrixRes?.pra?.deleted | json}}</p>
            </div>
        </div>
        <div class="body-bottom">
            <div class="body-bottom-item">
                <label class="status-item">Questionnaires</label>
                <p class="number">{{matrixRes?.pra?.questionnaire | json }}</p>
            </div>
        </div>
    </div>
</div>
