import { Pipe, PipeTransform } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Pipe({
    name: 'totalValueByAnswer',
    pure: false,
})
export class TotalValueByAnswerPipe implements PipeTransform {
    transform(fieldGroup: FormlyFieldConfig[], answer: string): number | string {
        if (!fieldGroup || !answer) return 'N/A';
        let total = 0;
        (fieldGroup || []).forEach(group => {
            (group.fieldGroup || [])
                .filter(childGroup => childGroup.templateOptions?.label === answer)
                .forEach(childGroup => {
                    if (typeof childGroup.key == 'string' && childGroup.model) {
                        total += this.total(childGroup.model[childGroup.key]);
                    }
                });
        });
        return total;
    }

    total(model: any) {
        if (!model) {
            return 0;
        }
        if (typeof model === 'number') {
            return model;
        } else if (typeof model === 'string') {
            return parseFloat(model);
        } else if (typeof model === 'object') {
            let total = 0;
            const fields = Object.keys(model);
            (fields || []).forEach(field => {
                if (typeof model[field] === 'number') {
                    total += model[field];
                } else if (typeof model[field] === 'object') {
                    total += this.total(model[field]);
                } else if (typeof model[field] === 'string') {
                    total += parseFloat(model[field]) || 0;
                }
            });
            return total;
        }
        return 0;
    }
}
