import { Component, Input, OnChanges } from '@angular/core';
import { GridWidget, GridWidgetOptions, GridWidgetRow } from './grid-widget.types';

@Component({
    selector: 'app-grid-widget',
    templateUrl: './grid-widget.component.html',
    styleUrls: ['./grid-widget.component.scss'],
})
export class GridWidgetComponent implements OnChanges {
    @Input() options: GridWidgetOptions;

    widget: GridWidget = {
        headers: [],
        rows: [],
    };

    ngOnChanges() {
        if (!this.options) {
            this.widget = {
                headers: [],
                rows: [],
            };
        }
        this.widget = this.toGridTable(this.options);
    }

    toGridTable(options: GridWidgetOptions): GridWidget {
        const Widget: GridWidget = {
            headers: [options.labelHeader].concat(options.columns.map(col => col.name)),
            rows: [],
        };
        Widget.rows = options.data.map(item => {
            const row: GridWidgetRow = {
                label: item.label,
                values: [],
            };
            if (item.value) {
                const itemValues = Object.values(item.value);
                options.columns.forEach((col, colIdx) => {
                    if (!col.valueFn) {
                        row.values.push(itemValues[colIdx]);
                    } else {
                        row.values.push(col.valueFn(item.value));
                    }
                });
            }

            return row;
        });
        return Widget;
    }
}
