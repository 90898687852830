import { DocumentListComponent } from './components/document-list/document-list.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { SharedModule } from '../shared';
import { ListUsersFormComponent, NewUserFormComponent } from './components';
import { EmployerDashboardComponent } from './components/employer-dashboard/employer-dashboard.component';
import { EmployerAdminContainer, EmployerDetailContainer, EmployerEvaluationContainer, InviteEmployerContainer, OnboardingEmployerContainer, ViewScopingQuestionsContainer } from './containers';
import { EmployerProfileComponent } from './components/employer-profile/employer-profile.component';
import { EmployerHistoryComponent } from './components/employer-history/employer-history.component';
import { EmployerButtonComponent } from './components/employer-button/employer-button.component';
import { WorkerStatisticsComponent } from './components/worker-statistics/worker-statistics.component';
import { ListPraComponent } from './components/list-pra/list-pra.component';
import { ViewScopingQuestionsPdfContainer } from './containers/view-scoping-questions.pdf.container';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { RegistrationSaveButtonComponent } from './components/registration-save-button/registration-save-button.component';

@NgModule({
    declarations: [
        EmployerAdminContainer,
        InviteEmployerContainer,
        ListUsersFormComponent,
        NewUserFormComponent,
        DocumentListComponent,
        EmployerDashboardComponent,
        EmployerDetailContainer,
        EmployerEvaluationContainer,
        EmployerProfileComponent,
        EmployerHistoryComponent,
        EmployerButtonComponent,
        ViewScopingQuestionsContainer,
        ViewScopingQuestionsPdfContainer,
        ListPraComponent,
        WorkerStatisticsComponent,
        EvaluationComponent,
        RegistrationSaveButtonComponent,
        OnboardingEmployerContainer
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        FormlyModule.forRoot({
            validationMessages: [{ name: 'required', message: 'Please input your answer' }],
        }),
    ],
    exports: [
        EmployerAdminContainer,
        InviteEmployerContainer,
        ListUsersFormComponent,
        NewUserFormComponent,
        EmployerDashboardComponent,
        EmployerDetailContainer,
        EmployerEvaluationContainer,
        ViewScopingQuestionsContainer,
        EvaluationComponent,
        RegistrationSaveButtonComponent,
        OnboardingEmployerContainer
    ],
})
export class EmployerLazyModule {}
