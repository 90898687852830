<mat-card class="mat-elevation-z-p-r6" [ngClass]="'mat-elevation-z0'">
    <!-- <mat-card-header>
        <mat-card-title [ngClass]="(smallScreen$ | async) ? 'small' : ''"> Hotel group management </mat-card-title>
    </mat-card-header> -->
    <mat-card-content class="content-container">
        <app-datatable-mobile [config]="config" *ngIf="smallScreen$ | async; else web"></app-datatable-mobile>
        <app-error-box [errorMessages]="errorMessages"></app-error-box>
    </mat-card-content>
</mat-card>

<ng-template #web>
    <app-datatable
        *ngIf="config"
        [config]="config"
        (sortChange)="sortChangeValue($event)"
        (filterChange)="filterChangeValue($event)"
        (selectedPageAt)="selectedPageAtValue($event)"
    ></app-datatable>
</ng-template>
