import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrintRoutingModule } from './print-routing.module';
import { PdfComponent } from './pdf/pdf.component';


@NgModule({
  declarations: [
    PdfComponent
  ],
  imports: [
    CommonModule,
    PrintRoutingModule
  ]
})
export class PrintModule { }
