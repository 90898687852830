import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployerEvaluationService } from '../services/evaluation.service';

@Component({
    selector: 'employer-evaluation',
    template: `
        <div class="grid-container1 layout-padding">
            <app-evaluation
                [isRecruitmentAgencyDetail]="agencyDetail"
            ></app-evaluation>
        </div>
    `,
    styles: [
        `
            .layout-padding {
                padding-bottom: 5%;
                margin: 48px;
            }
        `,
    ],
    providers: [EmployerEvaluationService],
})
export class EmployerEvaluationContainer implements OnInit {
    responseId = '';
    agencyDetail = false;
    constructor(private route: ActivatedRoute,) {}

    ngOnInit() {
        this.responseId = this.route.snapshot.params.id;
        this.agencyDetail = this.route.snapshot.params.agency_detail || false;
    }
}
