<form [formGroup]="form" (ngSubmit)="resetPassword()" novalidate role="form">
    <ng-container *ngIf="smallScreen$ | async as smallScreen">
        <mat-card class="mat-elevation-z0">
            <mat-card-header>
                <mat-card-title> Reset Password </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-card-content class="content-container">
                    <div class="row" class="new-password-container">
                        <div class="form-field-label">
                            New Password
                            <img src="../assets/images/info.png" class="password-criteria" [matTooltip]="tooltipList" [matTooltipPosition]="'right'" alt="newPassword"/>
                        </div>
                        <mat-form-field appearance="outline" class="mat-form-field-reset-pass">
                            <input
                                matInput
                                [type]="hideNewPassword ? 'password' : 'text'"
                                formControlName="newPassword"
                                [attr.aria-label]="'new password'"
                                required
                                placeholder="Enter Password"
                                autocomplete="off"
                            />
                            <button
                                mat-icon-button
                                type="button"
                                matSuffix
                                (click)="hideNewPassword = !hideNewPassword"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide"
                            >
                                <mat-icon>{{ hideNewPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                            </button>
                            <mat-error *ngIf="newPassword.errors && newPassword.errors['required']">
                                Please input your password.
                            </mat-error>
                            <mat-error *ngIf="newPassword.errors && newPassword.errors['pattern']">
                                Please input a valid password.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="confirm-password-container">
                        <div class="form-field-label">Confirm Password</div>
                    <mat-form-field appearance="outline" class="mat-form-field-reset-pass">
                        <input
                            matInput
                            [type]="hideConfirmPassword ? 'password' : 'text'"
                            formControlName="confirmPassword"
                            [attr.aria-label]="'confirm password'"
                            required
                            autocomplete="off"
                            placeholder="Re-enter Password"
                        />
                        <button
                            mat-icon-button
                            type="button"
                            matSuffix
                            (click)="hideConfirmPassword = !hideConfirmPassword"
                            [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide"
                        >
                            <mat-icon>{{ hideConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                        </button>
                        <mat-error *ngIf="confirmPassword.errors && confirmPassword.errors['required']">
                            Please input your re-enter password.
                        </mat-error>
                        <mat-error *ngIf="confirmPassword.value && hasError(confirmPassword, 'passwordNotMatch')">
                            Re-enter password doesn't match with New Password.
                        </mat-error>
                    </mat-form-field>
                    </div>
                </mat-card-content>
                <app-error-box [errorMessages]="errorMessages"></app-error-box>
            </mat-card-content>
            <div class="button-container">
                <button type="submit" mat-button color="primary" [class]="form.valid ? 'btn-active' : ''" [disabled]="form.invalid" style="margin-bottom: 1rem">
                    Reset
                </button>
            </div>
        </mat-card>
    </ng-container>
</form>
