import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { ScopingQuestionLazyModule } from './scoping-question-lazy.module';
import { ScopingQuestionService } from './services/scoping-question.service';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    imports: [ScopingQuestionLazyModule, SharedModule, ScrollingModule],
    exports: [ScopingQuestionLazyModule],
    providers: [ScopingQuestionService],
})
export class ScopingQuestionModule {}
