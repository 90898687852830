import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ListWidgetOptions } from './list-widget.types';
import * as myGlobals from '../../../../globals';
import { PraUserInfo } from 'src/app/modules/pra';

@Component({
    selector: 'app-list-widget',
    templateUrl: './list-widget.component.html',
    styleUrls: ['./list-widget.component.scss'],
})
export class ListWidgetComponent implements OnInit, OnChanges {
    ngOnChanges(_changes: SimpleChanges): void {
        console.log(this.options)
        // this.options.data.forEach(item => {
            
        // })
    }

    ngOnInit(): void {
        
    }
    @Input() options: ListWidgetOptions;
    @Input() isPraDetail: boolean;
    @Input() pdfPreview: boolean = false;

    inviteRA() {
        let email = ''
        let licenseNumber = ''
        this.options.data.forEach(x => {
            if (x.code === 'emp_pra_scop_A13') {
                email = x.value.toString()
            }
            if (x.code === 'emp_pra_scop_A6') {
                licenseNumber = x.value.toString()
            }
            if (x.code === 'emp_pra_scop_A5') {
                // if (Array.isArray(x.value)) {
                //     x.value = x.value.map(item => {
                //     if (typeof item == "object") {
                //     const values = Object.values(item);
                //     if (Array.isArray(values) && values.length){
                //     return values.join(', ')
                //     }
                //     }
                //     return item;
                //     })
                //     x.value = x.value.join(', ')
                //     }
            }
        })

        let raInfo: PraUserInfo = {email: email, fullName: this.options?.praName || '', agency: this.options?.praName || '', licenseNumber: licenseNumber, questionnaireResponseId: this.options?.questionnaireResponseId}
        myGlobals.inviteRAFromScopingReviewSub$.next(raInfo)
    }
}
