<!--The content below is only a placeholder and can be replaced.-->
<div>
    <mat-toolbar *ngIf="(isShowTopBar$ | async) || isStandalonePage" color="primary" class="toolbar">
        <div class="toolbar-content">
            <a routerLink="/">
                <img src="assets/images/lmpm-logo-white.png" alt="LMPM" width="91px" />
            </a>
        </div>
    </mat-toolbar>

    <mat-sidenav-container
        id="mat-sidenav-container"
        class="sidenav-container"
        [ngStyle]="{
            flex: isStandalonePage || (isShowTopBar$ | async) ? '1 0 auto' : 'inherit',
            height: isStandalonePage || (isShowTopBar$ | async) ? 'inherit' : '100vh'
        }"
    >
        <mat-sidenav
            id="sidebar"
            *ngIf="!isStandalonePage && (isShowSideBar$ | async)"
            #snav
            disableClose="false"
            [opened]="!isStandalonePage && !mobileQuery.matches"
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="false"
        >
            <div style="text-align: center; margin: 3rem 0; flex-direction: column-reverse">
                <img src="assets/images/lmpm-logo-white.png" alt="LMPM" width="91px" />
            </div>
            <mat-nav-list class="left-menu" style="width: 252px">
                <div *ngFor="let menuItem of menuItems | menuFilter: { id: 0, code: '', name: '' }">
                    <mat-expansion-panel
                        (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false"
                        [expanded]="panelOpenState"
                        [@.disabled]="true"
                        class="menu-item expand-item"
                        *ngIf="menuItem.role.indexOf(role$ | async) !== -1 && menuItem.hasChild"
                    >
                        <mat-expansion-panel-header
                            [class]="
                                menuItem.valid > 0 && (role$ | async) !== 'admin' ? 'content-invalid' : 'content-valid'
                            "
                        >
                            <mat-panel-title>
                                <img [src]="menuItem.icon" class="menu-icon" alt="menu"/>
                                <img [src]="menuItem.activeIcon" class="menu-icon-active" alt="menu-active"/>
                                <span class="break-text">
                                    {{ menuItem.text }}
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngFor="let child of menuItems | menuFilter: menuItem" class="sub-menu">
                            <a
                                mat-list-item
                                class="nav-item child-item"
                                routerLink="{{ child.url }}"
                                routerLinkActive="active"
                                *ngIf="child.role.indexOf(role$ | async) !== -1"
                                [class]="
                                    child.valid > 0 && (role$ | async) !== 'admin' ? 'content-invalid' : 'content-valid'
                                "
                            >
                                <div class="break-text">{{ child.text }}</div>
                            </a>
                        </div>
                    </mat-expansion-panel>
                    <div
                        *ngIf="!menuItem.hasChild && menuItem.id !== 10 && menuItem.id !== 11"
                        class="menu-item normal-item"
                        [class]="menuItem.id === 9 ? 'menu-item-show-bt' : ''"
                    >
                        <a
                            mat-list-item
                            class="nav-item normal-menu-item"
                            routerLink="{{ menuItem.url }}"
                            routerLinkActive="active"
                            *ngIf="menuItem.role.indexOf(role$ | async) !== -1"
                            (click)="changeActive()"
                            [class]="activeMenu && menuItem.id === 8 ? 'active' : ''"
                        >
                            <img [src]="menuItem.icon" class="menu-icon" alt="menu"/>
                            <img [src]="menuItem.activeIcon" class="menu-icon-active" alt="menu-active"/>
                            <span class="break-text">{{ menuItem.text }}</span>
                            <div
                                class="total-unread"
                                style="display: none"
                                [class]="menuItem.valid && menuItem.valid != 0 ? 'showing' : 'not-showing'"
                            >
                                {{ menuItem.valid <= 9 ? menuItem.valid : '9+' }}
                            </div>
                        </a>
                    </div>
                    <div
                        *ngIf="!menuItem.hasChild && menuItem.id === 10 && menuItem.role.indexOf(role$ | async) !== -1"
                        class="menu-item normal-item"
                        [class]="'menu-item-show-noti'"
                    >
                        <a
                            mat-list-item
                            class="nav-item normal-menu-item"
                            routerLinkActive="active"
                            [class]="activeMenu && menuItem.id === 8 ? 'active' : ''"
                            (click)="markAllReadNoti()"
                        >
                            <notification-item [menuTitle]="menuItem.text" style="width: 100%"></notification-item>
                        </a>
                    </div>
                    <div
                        *ngIf="!menuItem.hasChild && menuItem.id === 11 && menuItem.role.indexOf(role$ | async) !== -1"
                        class="menu-item normal-item"
                        [class]="'menu-item-logout'"
                    >
                        <a
                            mat-list-item
                            class="nav-item normal-menu-item"
                            routerLinkActive="active"
                            (click)="logout()"
                        >
                            <img [src]="menuItem.icon" class="menu-icon" alt="menu"/>
                            <img [src]="menuItem.activeIcon" class="menu-icon-active" alt="menu-active"/>
                            <span class="break-text">{{ menuItem.text }}</span>
                        </a>
                    </div>
                </div>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav
            *ngIf="!isStandalonePage && (isShowSideBarUser$ | async)"
            #snav
            disableClose="false"
            [opened]="!isStandalonePage && !mobileQuery.matches"
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="false"
        >
            <div style="text-align: center; margin: 20px 0; flex-direction: column-reverse">
                <img src="assets/images/lmpm-logo-white.png" alt="LMPM" width="91px" />
            </div>
            <div style="flex-grow: 10; display: flex; margin-bottom: 30px">
                <auth-email-container *ngIf="isLogin$ | async"></auth-email-container>
            </div>
        </mat-sidenav>
        <mat-sidenav-content
        id="mat-sidenav-content">
            <div *ngIf="mobileQuery.matches">
                <button
                    mat-icon-button
                    class="menu"
                    [attr.aria-label]="'menu'"
                    (click)="snav.toggle()"
                >
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
            <router-outlet class="" (activate)="onRouterOutletActivate($event)"></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
