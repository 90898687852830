<app-list-widget [options]="companyProfileOptions"></app-list-widget>
<ng-template #recruiment>
    <div class="recruiment-agency">
        <div class="recruiment-agency__title">Recruiment Agency</div>
        <div class="recruiment-agency__list" *ngIf="recruimentAgency">
            <div class="recruiment-agency__item" *ngFor="let item of recruimentAgency">
                <div class="recruiment-agency-name">{{ item.label }}</div>
                <div class="recruiment-agency-detail"><a href="mailto:{{ item.value }}"></a>{{ item.value }}</div>
            </div>
        </div>
    </div>
</ng-template>
