import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiConstant, IOM_API_TOKEN, LscmResponse } from '../..';
import { CustomHttpErrorResponse } from '../../types';
import {
    BrandAccountInfo,
    EmployerAccountInfo,
    IomAccountInfo,
    PRAAccountInfo,
    UpdateBrandAccountInfo,
    UpdateEmployerAccountInfo,
    UpdateIomAccountInfo,
    UpdatePRAAccountInfo,
} from '../types';

@Injectable()
export class MyAccountService {
    urlDefault = this.apiConstant.endpoint;

    private errorsAdminSub$ = new Subject<string[]>();
    errorsAdmin$ = this.errorsAdminSub$.asObservable();

    private successAdminSub$ = new Subject<LscmResponse<IomAccountInfo>>();
    successAdmin$ = this.successAdminSub$.asObservable();

    private errorsBrandSub$ = new Subject<string[]>();
    errorsBrand$ = this.errorsBrandSub$.asObservable();

    private successBrandSub$ = new Subject<LscmResponse<BrandAccountInfo>>();
    successBrand$ = this.successBrandSub$.asObservable();

    private errorsEmployerSub$ = new Subject<string[]>();
    errorsEmployer$ = this.errorsEmployerSub$.asObservable();

    private successEmployerSub$ = new Subject<LscmResponse<PRAAccountInfo>>();
    successEmployer$ = this.successEmployerSub$.asObservable();

    private errorsPRASub$ = new Subject<string[]>();
    errorsPRA$ = this.errorsPRASub$.asObservable();

    private successPRASub$ = new Subject<LscmResponse<PRAAccountInfo>>();
    successPRA$ = this.successPRASub$.asObservable();

    constructor(@Inject(IOM_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) {}

    getAccountInfoForIom() {
        return this.httpClient.get<LscmResponse<IomAccountInfo>>(`${this.urlDefault}/user/my-account`);
    }

    getAccountInfoForBrand() {
        return this.httpClient.get<LscmResponse<BrandAccountInfo>>(`${this.urlDefault}/brands/my-account`);
    }

    getAccountInfoForEmployer() {
        return this.httpClient.get<LscmResponse<EmployerAccountInfo>>(`${this.urlDefault}/employer/my-account`);
    }

    getAccountInfoForPRA() {
        return this.httpClient.get<LscmResponse<PRAAccountInfo>>(`${this.urlDefault}/pra/my-account`);
    }

    updateAccountInfoForIom(info: UpdateIomAccountInfo) {
        return this.httpClient
        .post<LscmResponse<IomAccountInfo>>(`${this.urlDefault}/user/my-account`, info)
        .pipe(
            tap((respone) => {
                this.successAdminSub$.next(respone);
                this.errorsAdminSub$.next([]);
                return EMPTY;
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsAdminSub$.next(err.errorJson.message);
                    return err.errorJson.message;
                } else {
                    this.errorsAdminSub$.next([err.message]);
                    return err.message;
                }
            })
        )
        .subscribe();
    }

    updateAccountInfoForBrand(info: UpdateBrandAccountInfo) {
        return this.httpClient
            .post<LscmResponse<BrandAccountInfo>>(`${this.urlDefault}/brands/my-account`, info)
            .pipe(
                tap((respone) => {
                    this.successBrandSub$.next(respone);
                    this.errorsBrandSub$.next([]);
                    return EMPTY;
                }),
                catchError((err: CustomHttpErrorResponse) => {
                    if (err.errorJson) {
                        this.errorsBrandSub$.next(err.errorJson.message);
                        return err.errorJson.message;
                    } else {
                        this.errorsBrandSub$.next([err.message]);
                        return err.message;
                    }
                })
            )
            .subscribe();
    }

    updateAccountInfoForEmployer(info: UpdateEmployerAccountInfo) {
        return this.httpClient
        .post<LscmResponse<EmployerAccountInfo>>(`${this.urlDefault}/employer/my-account`, info)
        .pipe(
            tap((respone) => {
                this.successEmployerSub$.next(respone);
                this.errorsEmployerSub$.next([]);
                return EMPTY;
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsEmployerSub$.next(err.errorJson.message);
                    return err.errorJson.message;
                } else {
                    this.errorsEmployerSub$.next([err.message]);
                    return err.message;
                }
            })
        )
        .subscribe();
    }

    updateAccountInfoForPRA(info: UpdatePRAAccountInfo) {
        return this.httpClient
        .post<LscmResponse<PRAAccountInfo>>(`${this.urlDefault}/pra/my-account`, info)
        .pipe(
            tap((respone) => {
                this.successPRASub$.next(respone);
                this.errorsPRASub$.next([]);
                return EMPTY;
            }),
            catchError((err: CustomHttpErrorResponse) => {
                if (err.errorJson) {
                    this.errorsPRASub$.next(err.errorJson.message);
                    return err.errorJson.message;
                } else {
                    this.errorsPRASub$.next([err.message]);
                    return err.message;
                }
            })
        )
        .subscribe();
    }
}
