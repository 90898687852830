import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { PaginateOptions } from 'src/app/modules';
import { DataTableConfig } from 'src/app/modules/shared';
import { IPraList, IPraResponse, UserStatus } from 'src/app/modules/types';
import { CountryFlagPipe } from 'src/app/modules/shared/pipes/country-flag.pipe';
import { PraUserInfo } from 'src/app/modules/pra';

@Component({
    selector: 'list-pra',
    templateUrl: './list-pra.component.html',
    styleUrls: ['./list-pra.component.scss'],
})
export class ListPraComponent implements OnInit, AfterViewInit, OnChanges {
    @Input()
    employerDashboard: boolean = false;

    @Input()
    praCollection: IPraList;

    @Input()
    isDisplayHeader: boolean;

    @Input()
    pageSize = 5;

    @Input()
    errorMessages: string[];

    @Output()
    paginate = new EventEmitter<PaginateOptions>();

    @Output()
    filterUser = new EventEmitter<string>();

    @Output()
    sortChange = new EventEmitter<Sort>();

    @Output()
    invitePra = new EventEmitter<PraUserInfo>();

    @Output()
    inactivatePra = new EventEmitter<{
        status: UserStatus;
        email: string;
        employerId: string;
        questionnaireResponseId: string;
        hotelName?: string;
    }>();

    @Output()
    deletePra = new EventEmitter<{
        email: string;
        questionnaireResponseId: string;
        employerId: string;
    }>();

    @Input()
    currentEmployerId = '';

    @Input()
    hotelName = '';

    nextClicked$ = new Subject();
    unsubscribe$ = new Subject();
    config: DataTableConfig<IPraResponse>;

    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {
        const lstHistory = this.praCollection?.recruitments || {};
        const historyCount = this.praCollection?.total || 0;
        this.initDatatable(lstHistory, historyCount, this.praCollection?.startAtPage);
    }

    ngOnChanges(changes: SimpleChanges) {
        const { praCollection = null } = changes || {};
        const { currentValue = null } = praCollection || {};
        const { recruitments = null, total = 0 } = currentValue || {};
        this.initDatatable(recruitments, total || 0, this.praCollection?.startAtPage);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initDatatable(data: IPraResponse[], count: number, startAtPage?: { pageIndex: number }) {
        if (!data) {
            return;
        }

        this.config = {
            data,
            tableName: 'employer-pra',
            hideFilter: true,
            filterKeys: ['hotelName', 'username', 'email', 'lastLoginTime', 'status', 'country'],
            columns: [
                {
                    text: 'Name',
                    label: 'Name',
                    fieldName: 'name',
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.name}</a>`;
                    },
                },
                {
                    text: 'Location',
                    label: 'Location',
                    fieldName: 'location',
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        const pipe = new CountryFlagPipe();
                        const flag = pipe.transform(element.location);
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${flag}&nbsp;&nbsp;${element.location}</a>`;
                    }
                },
                {
                    text: 'Contact Person',
                    label: 'Contact Person',
                    fieldName: 'contactPerson',
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.contactPerson}</a>`;
                    },
                },
                {
                    text: 'Status',
                    label: 'scopping status',
                    fieldName: 'status',
                    valueFunction: element => {
                        return element.status;
                    },
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.status}</a>`;
                    },
                },
                {
                    text: 'Last Update',
                    label: 'last update',
                    fieldName: 'updatedAt',
                    valueFunction: element => {
                        return element.updatedAt || 'N/A';
                    },
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.updatedAt || 'N/A'}</a>`;
                    },
                },
            ],
            mergeActionColumns: true,
            actions: [
                {
                    text: 'Invite',
                    label: 'Invite',
                    fieldName: 'invite',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/invite.png',
                    actionFunction: (_action, element) => {
                        return this.invitePraFnc(element)
                    },
                    condition: element => {
                        return element.status != 'Active' && element.status != 'Deactivated';
                    },
                },
                {
                    text: 'Delete',
                    label: 'Delete',
                    fieldName: 'delete',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/delete.svg',
                    actionFunction: (_action, element) => {
                        this.deletePraFnc(element)
                    },
                    condition: element => {
                        return element.status !== 'Not Invited';
                    },
                },
                {
                    fieldName: 'action',
                    text: 'ACTION',
                    label: 'Active',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/active_deactive.png',
                    actionFunction: (_action, element) => {
                        this.activeOrInactivePra(element)
                    },
                    condition: element => {
                        return element.status != 'Invited' && element.status != 'Not Invited';
                    },
                    valueFunction: element => this.activeOrDeactiveAction(element.status),
                },
            ],

            meta: {
                pageSize: this.pageSize,
                rowsNumber: count,
                startAtPage,
            },
            pageChange: ({ pageSize, pageIndex }: PaginateOptions) => {
                this.paginate.emit({ pageIndex, pageSize });
            },
        };
    }

    filterChangeValue(filterValue: string) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.filterUser.emit(filterValue.trim());
    }

    sortChangeValue(sort: Sort) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.sortChange.emit(sort);
    }

    selectedPageAtValue(index: number) {
        this.paginate.emit({ pageIndex: index, pageSize: this.pageSize });
    }

    getUrl(element: IPraResponse) {
        if (this.employerDashboard) {
            return `dashboard/agency/detail/${element.id}/${element.hotelId}/true`;
        }
        return `employers/agency/detail/${element.id}/${element.hotelId}/true`;
    }

    invitePraFnc (element: any) {
        const praUser:PraUserInfo = {
            questionnaireResponseId: element.id,
            email: element.emailPra,
            fullName: element.name,
            agency: element.name,
            licenseNumber: element.licenseNumber,
            status: element.status,
        };
        this.invitePra.emit(praUser);
    }

    activeOrInactivePra (element: any) {
        this.inactivatePra.emit({
            status: element.status,
            email: element.emailPra,
            employerId: this.currentEmployerId,
            questionnaireResponseId: element.id,
            hotelName: element.hotelName,
        });
    }

    deletePraFnc (element: any) {
        this.deletePra.emit({
            email: element.emailPra,
            questionnaireResponseId: element.id,
            employerId: this.currentEmployerId
        });
    }

    activeOrDeactiveAction(status: UserStatus): string {
        switch(status) {
            case 'Active':
                return 'Deactivate';
            case 'Deactivated':
                return 'Activate';
            default:
                return '';
        }
    }
}
