import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserInfo } from '../../../core';
import { AuthService } from '../../../auth';
import { filter, map, takeUntil,} from 'rxjs/operators';
import { UpdateAdmin, UpdateBrand, UpdateEmployer, UpdatePRA,} from '../../types';
import { ConfirmationDialog, ConfirmCancelDialog } from 'src/app/modules/shared';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/modules';

@Component({
    selector: 'user-settings-container',
    template: `
        <div>
            <ng-container [ngSwitch]="(user$ | async)?.role">
                <div [class]="(role$ | async) === 'brand' ? 'account-setting-content' : ''">
                    <user-settings-admin-user *ngSwitchCase="'admin'"></user-settings-admin-user>

                    <user-settings-brand-user *ngSwitchCase="'brand'"
                    (deleteBrandChangeEmit)="deleteBrandChangeEmit($event)"
                    ></user-settings-brand-user>

                    <user-settings-employer-user *ngSwitchCase="'employer'"></user-settings-employer-user>

                    <user-settings-pra-user *ngSwitchCase="'pra'"></user-settings-pra-user>
                </div>

                <div class="acount-setting-sidenav" *ngIf="(user$ | async)?.role === 'brand'">
                    <button class="button-delete" (click)="deleteAccount()">Delete Account</button>
                </div>
            </ng-container>
        </div>
    `,
    styles: [
        `
            .account-setting-content{
                overflow: auto;
                padding-bottom: 5rem;
            }

            .acount-setting-sidenav {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 9;
                background-color: #fff;
                border-top: 1px solid #c9cfd6;
                height: 92px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .button-delete {
                color: #E26A74;
                background: white;
                border: 1px solid #E26A74;
                box-sizing: border-box;
                width: 144px;
                height: 40px;
                margin-right: 48px;
                border-radius: 4px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsContainerComponent implements OnInit {
    user$: Observable<UserInfo | null>;
    isLogin$: Observable<boolean>;
    role$: Observable<string>;
    role: string;
    infoChangeAdmin: UpdateAdmin;
    infoChangeBrand: UpdateBrand;
    infoChangeEmployer: UpdateEmployer;
    infoChangeRA: UpdatePRA;
    formValid = false;
    private unsubscribe$ = new Subject();

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private adminService: AdminService,
    ) {}

    ngOnInit(): void {
        this.user$ =  this.authService.user$;
        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );
        this.isLogin$ = this.authService.isLogin$;

        this.role$.subscribe(value => {
            this.role = value;
        });
    }

    deleteBrandChangeEmit(elementBrand: any){
        this.infoChangeBrand = elementBrand?.data;
        this.formValid = elementBrand.formValid;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    deleteAccount() {
        let dialogRef = this.dialog.open(ConfirmCancelDialog, {
            width: '600px',
            data: {
                title: `Warning`,
                subTitle: ``,
                message: `
                    Deleting a hotel group will also delete all hotels and data that is associated with this group. Are you sure you want to continue?
                `,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                hideCancelButton: false,
                color: 'warn',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async result => {
                if (result) {
                    const brandId = this.authService.getUser()?.brandId;
                    const deleteResult = await this.adminService.deleteBrand(brandId);
                    if (deleteResult) {
                        const confirmDialogRef = this.dialog.open(ConfirmationDialog, {
                            data: {
                                title: 'Delete Successfully!',
                                message: 'User was successfully delete from LMPM platform.',
                                closeButtonText: 'Close',
                            },
                        });

                        confirmDialogRef
                            .afterClosed()
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(() => this.authService.logout());
                    }
                }
            });
    }
}
