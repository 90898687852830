<!-- Layout: Normal -->
<div class="normal-layout" *ngIf="to.layout !== 'modern'">
    <div class="user-input">
        <label class="label-input-left" *ngIf="to?.label">
            <span  *ngIf="to.symbol">{{ to.symbol }}</span>
            {{ to?.label }}
            <strong style="color: red" *ngIf="to?.required">*</strong>
        </label>
        <mat-radio-group
            [formControl]="formControl"
            [formlyAttributes]="field"
            [required]="to.required"
            [tabindex]="to.tabindex"
        >
            <mat-radio-button
                *ngFor="let option of to.options | formlySelectOptions: field | async; let i = index"
                [id]="id + '_' + i"
                [color]="to.color"
                [labelPosition]="to.labelPosition"
                [disabled]="option.disabled"
                [value]="option.value"
            >
                {{ option.label }}
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<!-- Layout: Modern -->
<div class="modern-layout" *ngIf="to.layout === 'modern'">
    <div class="user-input" *ngIf="(discardInviteHotelFormSub$ | async)">
        <div class="top-label" *ngIf="to.symbol">
            <div class="symbol-input-left">
                <span>{{ to.symbol }}</span>
            </div>
            <div class="right-item" *ngIf="to?.required">
                <mat-label class="asterix--before"></mat-label>
            </div>
        </div>
        <label class="label-input-left" *ngIf="to?.label" [innerHtml]="to.label">
            <!-- {{ to?.label }} -->
        </label>
        <div class="button-group" [class]="to.readonly ? 'opacity-layout' : ''">
            <div *ngFor="let item of to.options" class="lst-item">
                <button
                    mat-button
                    isRoundButton="true"
                    type="button"
                    class="option-btn"
                    [ngClass]="{ 'btn-color': field.defaultValue === item.label}"
                    (click)="selectOption(item.label)"
                    [class]="item.label != 'No' && item.label !== 'Yes' && item.label !== 'Not applicable' ? 'btn-normal' : ''"
                    [disabled]="to.readonly"
                >
                    <mat-icon class="icon-mat" *ngIf="item.label === 'Not applicable'">remove_circle_outline</mat-icon>
                    <mat-icon class="icon-mat" *ngIf="item.label === 'Yes'">check</mat-icon>
                    <mat-icon class="icon-mat" *ngIf="item.label === 'No'">close</mat-icon>
                    <span>{{item.label}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
