import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiConstant } from '../types';
import { BrandRegisterLazyModule } from './brand-register-lazy.module';
import { BrandRegisterService } from './services';
import { BRAND_REGISTER_API_TOKEN } from './types';

@NgModule({
    imports: [BrandRegisterLazyModule],
    exports: [BrandRegisterLazyModule],
})
export class BrandRegisterModule {
    static forRoot(apiConstant: ApiConstant): ModuleWithProviders<BrandRegisterModule> {
        return {
            ngModule: BrandRegisterModule,
            providers: [
                {
                    provide: BRAND_REGISTER_API_TOKEN,
                    useValue: apiConstant,
                },
                BrandRegisterService,
            ],
        };
    }
}
