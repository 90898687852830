<div *ngIf="partial">
    <div class="header-text-extra">{{partial.label}}</div>
    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
    <div class="btn-group">
        <button
            mat-button
            color="primary"
            aria-label="back"
            class="mat-icon-left"
            [disabled]="!nextBackBehaviour.canBack"
            (click)="onBack()"
        >
            <mat-icon class="icon-back">arrow_back</mat-icon>
            Back
        </button>
        <button
            mat-button
            color="primary"
            aria-label="next"
            class="mat-icon-right"
            [disabled]="!nextBackBehaviour.canNext"
            (click)="onNext()"
        >
            Next
            <mat-icon class="icon-next">arrow_forward</mat-icon>
        </button>
    </div>
</div>
