import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'worker-statistics',
    templateUrl: './worker-statistics.component.html',
    styleUrls: ['./worker-statistics.component.scss'],
})
export class WorkerStatisticsComponent implements OnChanges{
    @Input() listQuestion: any;
    @Input() pdfPreview: boolean = false;

    ngOnChanges () {
    }
}
