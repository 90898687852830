export const DEFAULT_BADGE_WIDGET_OPTIONS: BadgeWidgetOptions = {
    title: 'Unknown',
    value: 0
};

export const LIST_DEFAULT_BADGE_WIDGET_CODE = ["employer_scoping_A15", "employer_scoping_A16", "employer_scoping_A17"];

export interface BadgeWidgetOptions {
    title: string;
    value?: number;
    description?: string;
    htmlTemplate?: string;
    lstHtml?: [
        {
            value?: number;
            htmlTemplate?: string;
        }
    ]
}
