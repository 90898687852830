import { Component, OnInit } from "@angular/core";
import { ListWidgetItem, ListWidgetItemValueType, ListWidgetOptions } from "src/app/modules/shared/widgets/list-widget/list-widget.types";
import { IomService } from "../../services";


@Component({
    selector: 'app-hotel-group-profile',
    templateUrl: './hotel-group-profile.component.html',
    styleUrls: ['./hotel-group-profile.component.scss'],
})
export class HotelGroupProfileComponent implements OnInit {
    companyProfileOptions: ListWidgetOptions = {
        title: '',
        data: []
    };
    constructor(private iomService: IomService) {
        this.iomService.hotelGroupProfile$.subscribe(x => {
            if (!x || !x.info) {
                this.companyProfileOptions = {
                    title: '',
                    data: []
                }
            } else {
                this.companyProfileOptions = {
                    title: x.name,
                    data: x.info.map(item => {
                        const widgetItem: ListWidgetItem = {
                            label: item.label,
                            value: item.value,
                            valueType: this.guessValueType(item.label)
                        };
                        return widgetItem;
                    })
                };
            }
        })
    }

    ngOnInit(): void {
    }

    guessValueType(itemLabel: string): ListWidgetItemValueType {
        const maybeEmail = (itemLabel || '').toLowerCase().indexOf('email') > -1;
        return maybeEmail ? 'email' : 'normal'
    }

}
