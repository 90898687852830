<div class="change-password">
    <div mat-dialog-actions>
        <button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <form [formGroup]="form" (ngSubmit)="changePassword()" #f="ngForm" novalidate role="form" style="background: #fff">
        <ng-container *ngIf="smallScreen$ | async as smallScreen">
            <mat-card class="mat-elevation-z0">
                <mat-card-header>
                    <mat-card-title> CHANGE PASSWORD </mat-card-title>
                </mat-card-header>
                <mat-card-content class="content-container" style="height: 100%">
                    <div class="form-field-label">CURRENT PASSWORD</div>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            [type]="hideCurrentPassword ? 'password' : 'text'"
                            formControlName="currentPassword"
                            [attr.aria-label]="'current password'"
                            required
                            autocomplete="off"
                        />
                        <button
                            mat-icon-button
                            type="button"
                            matSuffix
                            (click)="hideCurrentPassword = !hideCurrentPassword"
                            [attr.aria-label]="'Hide current password'"
                            [attr.aria-pressed]="hideCurrentPassword"
                        >
                            <mat-icon>{{ hideCurrentPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                        </button>
                        <mat-error *ngIf="currentPassword.errors && currentPassword.errors['required']"
                            >Please input your password.</mat-error
                        >
                    </mat-form-field>
                    <div class="form-field-label">NEW PASSWORD</div>
                    <div class="row" class="new-password-container">
                        <mat-form-field appearance="outline">
                            <input
                                matInput
                                [type]="hideNewPassword ? 'password' : 'text'"
                                formControlName="newPassword"
                                [attr.aria-label]="'new password'"
                                required
                                autocomplete="off"
                            />
                            <button
                                mat-icon-button
                                type="button"
                                matSuffix
                                (click)="hideNewPassword = !hideNewPassword"
                                [attr.aria-label]="'Hide new password'"
                                [attr.aria-pressed]="hideNewPassword"
                            >
                                <mat-icon>{{ hideNewPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                            </button>
                            <mat-error *ngIf="newPassword.errors && newPassword.errors['required']"
                                >Please input your password.</mat-error
                            >
                            <mat-error *ngIf="newPassword.errors && newPassword.errors['pattern']"
                                >Please input a valid password.</mat-error
                            >
                        </mat-form-field>
                        <mat-icon class="password-criteria" [matTooltip]="tooltipList"> info </mat-icon>
                    </div>
                    <admin-password-pattern-checker [criteria]="criteria"></admin-password-pattern-checker>
                    <div class="form-field-label">RE-ENTER PASSWORD</div>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            [type]="hideConfirmPassword ? 'password' : 'text'"
                            formControlName="confirmPassword"
                            [attr.aria-label]="'confirm password'"
                            required
                            autocomplete="off"
                        />
                        <button
                            mat-icon-button
                            type="button"
                            matSuffix
                            (click)="hideConfirmPassword = !hideConfirmPassword"
                            [attr.aria-label]="'Hide confirm password'"
                            [attr.aria-pressed]="hideConfirmPassword"
                        >
                            <mat-icon>{{ hideConfirmPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                        </button>
                        <mat-error *ngIf="confirmPassword.errors && confirmPassword.errors['required']"
                            >Please input your re-enter password.</mat-error
                        >
                        <mat-error *ngIf="hasError(confirmPassword, 'passwordNotMatch')">
                            Re-enter password doesn't match with New Password.
                        </mat-error>
                    </mat-form-field>
                    <app-error-box>
                        <mat-error *ngIf="hasError(newPassword, 'passwordNotChange')">
                            Re-enter password doesn't match with New Password.
                        </mat-error>
                    </app-error-box>
                    <mat-error *ngIf="(errorServerSub$ | async) !== ''">
                        {{ errorServerSub$ | async }}
                    </mat-error>
                </mat-card-content>
                <mat-card-actions>
                    <button type="submit" mat-button color="primary" [disabled]="form.invalid">Change password</button>
                </mat-card-actions>
            </mat-card>
        </ng-container>
    </form>
</div>
