import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { ISection } from '../../types';

@Component({
    selector: 'scoping-question-navigator',
    templateUrl: './scoping-question-navigator.component.html',
    styleUrls: ['./scoping-question-navigator.component.scss'],
})
export class ScopingQuestionNavigatorComponent implements OnInit, OnChanges {
    
    @Input() partialSummary: ISection[];
    @Input() isCompleted: boolean;
    @Output()
    partialDeleted = new EventEmitter<string>();

    @Output()
    partialSelected = new EventEmitter<string>();

    @Input() readOnlyMode: boolean;

    public sections$: Observable<ISection[]>;
    public praSections$: Observable<ISection[]>;

    firstLoaded = true;

    ngOnInit(): void {}

    ngOnChanges(_changes: SimpleChanges): void {
        if(this.partialSummary && this.partialSummary.length > 0) {
            if (this.firstLoaded) {
                for (let index = 0; index < this.partialSummary.length + 1; index++) {
                    const partial = this.partialSummary[index];
                    setTimeout(() => {
                        if (partial) {
                            this.partialSelected.emit(partial.id);
                        } else {
                            this.partialSelected.emit(this.partialSummary[0].id);
                        }
                    }, 1);
                }
                this.firstLoaded = false;
            }
        }
    }

    deletePartial(partial: ISection) {
        this.partialDeleted.emit(partial.id);
    }

    selectPartial(partial: ISection) {
        this.partialSelected.emit(partial.id);
    }
}
