import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'totalValue',
    pure: false,
})
export class TotalValuePipe implements PipeTransform {
    transform(model: any): number | string {
        if (!model) return '0';
        return this.total(model);
    }

    total(model: any) {
        if (!model) {
            return 0;
        }
        if (typeof model === 'number') {
            return model;
        } else if (typeof model === 'string') {
            return parseFloat(model);
        } else if (typeof model === 'object') {
            let total = 0;
            const fields = Object.keys(model);
            (fields || []).forEach(field => {
                if (typeof model[field] === 'number') {
                    total += model[field];
                } else if (typeof model[field] === 'object') {
                    total += this.total(model[field]);
                } else if (typeof model[field] === 'string') {
                    total += parseFloat(model[field]) || 0;
                }
            });
            return total;
        }
        return 0;
    }
}
