import { Component, Input } from '@angular/core';
import { ListWidgetItem, ListWidgetItemValueType, ListWidgetOptions } from '../../../shared/widgets/list-widget/list-widget.types';
import { EmployerService } from '../../services';

export interface CompanyProfileInfo {
    name: string;
    info: Array<{ label: string; value: string; }>;
}
@Component({
    selector: 'employer-profile',
    templateUrl: './employer-profile.component.html',
    styleUrls: ['./employer-profile.component.scss'],
})


export class EmployerProfileComponent {
    @Input()
    recruimentAgency: any;

    companyProfileOptions: ListWidgetOptions = {
        title: '',
        data: []
    };
    constructor(private employerService: EmployerService) {
        this.employerService.conpanyProfile$.subscribe(x => {
            if (!x || !x.info) {
                this.companyProfileOptions = {
                    title: '',
                    data: []
                }
            } else {
                this.companyProfileOptions = {
                    title: x.name,
                    data: x.info.map(item => {
                        const widgetItem: ListWidgetItem = {
                            label: item.label,
                            value: item.value,
                            valueType: this.guessValueType(item.label)
                        };
                        return widgetItem;
                    })
                };
            }
        })
    }
    guessValueType(itemLabel: string): ListWidgetItemValueType {
        const maybeEmail = (itemLabel || '').toLowerCase().indexOf('email') > -1;
        return maybeEmail ? 'email' : 'normal'
    }
}
