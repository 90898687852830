import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import format from 'date-fns/format';
import { Observable, Subject } from 'rxjs';
import { filter, pluck, shareReplay, takeUntil } from 'rxjs/operators';
import { UserInfoCollection } from 'src/app/modules';
import { AuthService } from 'src/app/modules/auth';
import { HttpPaginatedDataSource, UserInfo } from 'src/app/modules/core';
import { DataTableConfig } from 'src/app/modules/shared';
import { PaginateOptions, UserStatus } from 'src/app/modules/types';

@Component({
    selector: 'list-users-form',
    templateUrl: './list-users-form.component.html',
    styleUrls: ['./list-users-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListUsersFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input()
    userCollection: UserInfoCollection;

    @Input()
    pageSize = 10;

    @Input()
    errorMessages: string[];

    @Output()
    paginate = new EventEmitter<PaginateOptions>();

    @Output()
    filterUser = new EventEmitter<string>();

    @Output()
    sortChange = new EventEmitter<Sort>();

    @Output()
    inactivateAdmin = new EventEmitter<{
        fullname: string;
        email: string;
        brandId?: string;
    }>();

    @Output()
    inactivateBrand = new EventEmitter<{
        status: UserStatus;
        email: string;
        userId: string;
    }>();

    displayedColumns: string[] = ['firstname', 'lastname', 'email', 'lastLoginTime', 'status', 'delete'];
    detailsColumns: string[] = ['rowDetails'];
    userDataSource: HttpPaginatedDataSource<UserInfo>;
    currentUserId$: Observable<string>;
    currentUserId: string;
    nextClicked$ = new Subject();
    unsubscribe$ = new Subject();

    smallScreen$ = this.breakpointObserver
        .observe(['(max-width: 600px)'])
        .pipe(pluck<BreakpointState, boolean>('matches'), takeUntil(this.unsubscribe$), shareReplay(1));
    config: DataTableConfig<UserInfo>;

    constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService) {}

    ngOnInit() {
        this.currentUserId$ = this.authService.user$.pipe(
            filter(user => !!user),
            pluck('id'),
        );
        this.currentUserId$.subscribe((userId: string) => {
            this.currentUserId = userId;
        });
    }

    ngAfterViewInit() {
        const { users = [], userCount = 0 } = this.userCollection || {};
        this.initDatatable(users, userCount, this.userCollection.startAtPage);
    }

    ngOnChanges(changes: SimpleChanges) {
        const { userCollection = null } = changes || {};
        const { currentValue = null } = userCollection || {};
        const { users = null, userCount = 0 } = currentValue || {};
        this.initDatatable(users, userCount, this.userCollection.startAtPage);
    }

    deleteUser($event: Event, element: UserInfo) {
        $event.preventDefault();
        $event.stopPropagation();
        this.inactivateAdmin.emit({
            fullname: element.fullName,
            email: element.email,
            brandId: element.brandId,
        });
        this.currentUserId$ = this.authService.user$.pipe(
            filter(user => !!user),
            pluck('id'),
        );
        this.currentUserId$.subscribe((userId: string) => {
            this.currentUserId = userId;
        });
    }

    activeOrInactiveBrand($event: Event, element: UserInfo) {
        $event.preventDefault();
        $event.stopPropagation();
        this.inactivateBrand.emit({
            status: element.status,
            email: element.email,
            userId: element.id,
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initDatatable(data: UserInfo[], count: number, startAtPage?: { pageIndex: number }) {
        if (!data) {
            return;
        }

        this.config = {
            data,
            tableName: 'iom-users',
            filterKeys: ['fullname', 'email', 'role', 'lastLoginTime', 'status', 'country'],
            hideFilter: true,
            columns: [
                {
                    text: 'Brand Name',
                    label: 'Brand Name',
                    fieldName: 'brandName',
                    valueFunction: element => {
                        return element.brandName ?? 'Unknow Hotel group Name';
                    },
                    style: { flex: 2 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.brandName}</a>`;
                    },
                },
                {
                    text: 'Email',
                    label: 'email',
                    fieldName: 'email',
                    style: { flex: 2 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.email}</a>`;
                    },
                },
                {
                    text: 'SAQ received',
                    label: 'SAQ received',
                    fieldName: 'saqReceived',
                    style: { flex: 2 },
                    valueFunction: element => {
                        return element.saqReceived ? element.saqReceived + '' : '0';
                    },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.saqReceived ? element.saqReceived + '' : '0'}</a>`;
                    },
                },
                {
                    text: 'Status',
                    label: 'user status',
                    fieldName: 'brandStatus',
                    valueFunction: element => {
                        return element.status;
                    },
                    style: { flex: 1 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${element.status}</a>`;
                    },
                },
                {
                    text: 'Last Activity Date',
                    label: 'Last Activity Date',
                    fieldName: 'updated',
                    valueFunction: element => {
                        return this.formatDate(element.updatedAt) || 'N/A';
                    },
                    style: { flex: 2 },
                    sortable: true,
                    templateFunction: element => {
                        return `<a href="${this.getUrl(element)}" class='document-list-item'>${this.formatDate(element.lastLoginTime ? element.lastLoginTime : element.updatedAt ? element.updatedAt : '')}</a>`;
                    },
                },
            ],
            mergeActionColumns: true,
            actions: [
                {
                    text: 'Delete',
                    label: 'Delete',
                    fieldName: 'delete',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/delete.svg',
                    actionFunction: this.deleteUser.bind(this),
                    condition: element => {
                        return this.currentUserId !== element.id;
                    },
                },
                {
                    fieldName: 'action',
                    text: 'ACTION',
                    label: 'Active',
                    style: { flex: 1 },
                    displayIcon: true,
                    iconValue: 'assets/images/active_deactive.png',
                    actionFunction: this.activeOrInactiveBrand.bind(this),
                    condition: element => {
                        return this.currentUserId !== element.id;
                    },
                    valueFunction: element => this.activeOrDeactiveAction(element.status),
                },
            ],
            meta: {
                pageSize: this.pageSize,
                rowsNumber: count,
                startAtPage,
            },
            pageChange: ({ pageSize, pageIndex }: PaginateOptions) => {
                this.paginate.emit({ pageIndex, pageSize });
            },
        };
    }

    activeOrDeactiveAction(status: UserStatus): string {
        switch(status) {
            case 'Invited':
                return '';
            case 'Active':
                return 'Deactivate';
            case 'Deactivated':
                return 'Activate';
            default:
                return '';
        }
    }

    filterChangeValue(filterValue: string) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.filterUser.emit(filterValue.trim());
    }

    sortChangeValue(sort: Sort) {
        this.paginate.emit({ pageIndex: 0, pageSize: this.pageSize });
        this.sortChange.emit(sort);
    }

    selectedPageAtValue(index: number) {
        this.paginate.emit({ pageIndex: index, pageSize: this.pageSize });
    }

    formatDate(date?: string) {
        date = date ? date : '';
        return format(new Date(date), 'dd/MM/yyyy hh:mm a');
    }

    getUrl(element: UserInfo) {
        return `brands/detail/${element.id}/${element.brandId}`;
    }
}
