<div class="dialog-style-form">
    <div class="dialog-header">
        <span>Terms of use, disclaimer and privacy policy</span>
    </div>
    <div
        class="content"
        [class]="!lmpmLink ? 'current-term-content' : ''"
        div
        fxLayout="column"
        fxLayoutAlign="space-between start"
    >
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Scope</span>
            </div>

            <div class="text-content">
                <p>These terms and conditions govern the use of the following website (the "website"):</p>
                <p *ngIf="lmpmLink; else current">
                    <a [href]="lmpmLink">{{ lmpmLink }}</a>
                </p>
                <ng-template #current>
                    <a [href]="currentLink">{{ currentLink }}</a>
                </ng-template>
                <p>By using the website, the user agrees to the terms and conditions contained herein.</p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>General</span>
            </div>

            <div class="text-content">
                <p>
                    The International Organization for Migration ("IOM") provides all materials (the "IOM materials") published on this website including but not limited to texts, documents, logos, designs, visual materials, photographs and videos for informational purposes only.
                </p>
                <br />
                <p>
                    IOM reserves the exclusive right, at its sole discretion, to alter, limit or discontinue its website without notice, and shall have no obligation to take the needs of any user into consideration in connection therewith.
                </p>
                <br />
                <p>
                    IOM reserves the right to deny, at its sole discretion, any user access to its website or any portion thereof without notice.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Intellectual property</span>
            </div>

            <div class="text-content">
                <p>
                    All intellectual property rights relating to the IOM materials shall remain vested in IOM, unless otherwise stated.  Intellectual property rights of any content, files or materials held and uploaded by a user (“User content”) shall remain with the user.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Copyright and permissions</span>
            </div>

            <div class="text-content">
                <p>
                    All IOM materials are copyright of the International Organization for Migration, unless otherwise stated. The IOM materials may be viewed, downloaded, and printed for personal, non-commercial use only, without any right to sell, resell, redistribute or create derivative works therefrom.
                </p>
                <br />
                <p>
                    With the notable exception of media information, any other use including extraction, translation, reproduction, and distribution, in any form, or by any means, electronic, mechanical, photocopying, or otherwise, require the explicit prior written permission of IOM.
                </p>
                <br />
                <p>
                    Permission to use User content which are not owned by IOM, must be requested from the copyright owner.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Media information</span>
            </div>

            <div class="text-content">
                <p>
                    The Labour Migration Process Mapping Tool was developed to support the hospitality industry in identifying the different actors involved in the recruitment and employment of migrant workers in the industry. It also supports hotels in identifying the different recruitment and employment practices, as well as the gaps in their management systems that could expose migrant workers to risks of forced labour. The identified risks are categorized based on the ILO Forced Labour Indicators and are further classified into three categories: definite, strong, and possible indicators. The identified risks may warrant further investigation by the user outside of the platform to determine the context and any interplay with other indicators in order to accurately establish if it rises to the level of forced labour as defined in ILO Convention No.29.
                </p>
                <br />
                <p>
                    Assessment reports that may be generated by this platform relate to potential risks only that require verification with the company represented by the user. The findings from the reports are not final and as such, users cannot share it publicly via print, webpage or social media or use it for publicity and marketing.
                </p>
                <br />
                <p>
                    IOM should be credited as the source, unless otherwise stated. The user must include the URL of the materials, as well as the following credit line: © International Organization for Migration (IOM) 2021.
                </p>
                <br />
                <p>
                    Mention of IOM as the source of the materials shall not be made in any way that would implicitly or explicitly assert or suggest endorsement, sponsorship or affiliation by IOM.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Other Materials</span>
            </div>

            <div class="text-content">
                <p>To request permission to use any other materials, please contact:</p>
                <br />
                <p>International Organization for Migration (IOM)</p>
                <p>Manila Administrative Centre, 28th Floor Citibank Tower,</p>
                <p>8741 Paseo de Roxas, 1226 Makati City, Philippines</p>
                <p>E-mail: info.lmpm-hospitality@iom.int</p>
                <br />
                <p>
                    <img src="../assets/images/IOM-logo.png" alt="logo" />
                </p>
                <br />
                <p>To request permission to use the IOM logo, please contact:</p>
                <br />
                <p>International Organization for Migration (IOM)</p>
                <p>Manila Administrative Centre, 28th Floor Citibank Tower,</p>
                <p>8741 Paseo de Roxas, 1226 Makati City, Philippines</p>
                <p>E-mail: info.lmpm-hospitality@iom.int</p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Linking to the website</span>
            </div>

            <div class="text-content">
                <p>
                    Other websites may link to the website without seeking the permission of IOM. However, such links must clearly identify IOM as the source of the information displayed and preserve the integrity of the web pages without alteration.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-header">
                <span>Privacy policy</span>
            </div>
            <div class="dialog-subHeader">
                <span>Monitoring website traffic</span>
            </div>

            <div class="text-content">
                <p>IOM monitors general trends in the use of its websites.</p>
                <br />
                <p>
                    IOM uses the information to generate statistics and measure the website’s activity in order to improve the usefulness of the websites. This information is never connected with any personal information you supply.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Personal information</span>
            </div>

            <div class="text-content">
                <p>
                    To access the website, IOM requires personal information, such as the name, email address, and other information of individual users. In such event, the user consents to the collection of the personal data above for the purpose of facilitating user access and participation in the platform.  IOM will only use the personal information for the purpose for which it was disclosed, unless written consent is provided for further use.
                </p>
                <br />
                <p>
                    This website collects and processes data in accordance with IOM’s Data Protection Principles and in line with IOM’s mandate and commitment to facilitate humane and orderly migration movements, understand migration challenges, and respect the human dignity and well-being of migrants.
                </p>
                <br />
                <p>IOM does not sell, distribute or rent personal information collected on its websites.</p>
                <br />
                <p>
                    IOM relies on online service providers for the provision of services, such as hosting services, website traffic analysis, electronic newsletters and online surveys.
                </p>
                <br />
                <p>
                    IOM does not share personal information with any third party without obtaining the consent of the data subject.
                </p>
                <br />
                <p>
                    IOM does not intend to either solicit or collect personal information from anyone under the age of 13 years. Individuals under the age of 13, should not use or enter information on the websites.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Confidentiality</span>
            </div>

            <div class="text-content">
                <p>
                    IOM shall take all reasonable and necessary precautions to preserve the confidentiality of personal data, User content and the anonymity of data subjects.  All personal data shall be collected, used, transferred, and stored securely in accordance with the IOM data protection principles.
                </p>
                <br />
                <p>
                    Users agree to provide accurate and complete information when registering and using the account. Users are advised to maintain the confidentiality and security of their login credentials and maintain a backup of any content uploaded into the website.  In case of security breach of the account, users should inform IOM immediately.  IOM shall not be responsible for any unauthorized use of a user’s account, or for any losses arising therefrom.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Disclaimer</span>
            </div>

            <div class="text-content">
                <p>
                    The materials are provided on an "as is" basis, without warranty of any kind, either express or implied, including without limitation, warranties of merchantability, fitness for a particular purpose, and non-infringement. IOM specifically does not make any warranties or representations as to the appropriateness, quality, timeliness, accuracy or completeness of the materials and User content.
                </p>
                <br />
                <p>
                    While IOM cannot make any warranties that there are no errors on the website, it does endeavour, where appropriate, to correct errors that are drawn to its attention.
                </p>
                <br />
                <p>
                    The use of the website is at a user's sole risk. By using the website, the user agrees to defend, indemnify and hold harmless IOM, its employees, information providers and content providers from and against any losses, damages, expenses (including legal fees and costs), claims, suits and liabilities to the extent arising out of or resulting from the use of the websites.
                </p>
                <br />
                <p>
                    Neither IOM nor any of its employees, information providers or content providers accept any responsibility whatsoever for the appropriateness, quality, accuracy, timeliness and completeness of the materials.
                </p>
                <br />
                <p>
                    Neither IOM nor any of its employees, information providers or content providers shall be liable to any user or anyone else for any inaccuracy, error, omission, interruption, timeliness, incompleteness, deletion, defect, failure of performance, computer virus, communication line failure, alteration or use of any content published on the website, regardless of cause, or for any damages resulting therefrom.
                </p>
                <p>
                    If a user is dissatisfied with any material on the website or with any of the terms and conditions governing the website, a user's sole and exclusive remedy is to discontinue using the website.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Boundaries and geographic names</span>
            </div>

            <div class="text-content">
                <p>
                    The depiction and use of boundaries, geographic names, and related data shown on maps and included in lists, tables, documents, and databases on the website are not warranted to be error free nor do they imply judgment on the legal status of any territory, or any endorsement or acceptance of such boundaries by IOM.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Advice, opinions, and statements</span>
            </div>

            <div class="text-content">
                <p>
                    The website may contain advice, opinions, and statements of various information and content providers. IOM does not represent or endorse the accuracy or reliability of any advice, opinion, statement or other information provided by any information provider or content provider, any user of the website or any other person or entity. Reliance upon any such opinion, advice, statement, or other information shall be at a user's own risk.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Other websites</span>
            </div>

            <div class="text-content">
                <p>
                    IOM does not necessarily support policies and beliefs of organizations and individuals listed or referred to in materials held on the website.
                </p>
                <br />
                <p>
                    IOM does not provide any warranties and specifically disclaims any liability whatsoever in connection with third party websites or links. IOM provides linked sites only as a convenience and the inclusion of a link or reference does not imply the endorsement of the linked site by IOM.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Other users</span>
            </div>

            <div class="text-content">
                <p>A user specifically acknowledges and agrees that IOM is not liable for any conduct of any user.</p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Bugs, virus, malicious code, etc.</span>
            </div>

            <div class="text-content">
                <p>
                    While IOM makes every effort to ensure the materials available on the website are free of any software virus, it cannot guarantee that the materials are free from any or all software viruses. IOM is not responsible for any loss or damage caused by software and related codes, including viruses and worms.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Security measures</span>
            </div>

            <div class="text-content">
                <p>
                    The websites implement a series of technologies and security measures, such as encryption and/or authentication tools, in order to safeguard the information maintained on IOM's systems from misuse, unauthorized access or disclosure, alteration, or loss.
                </p>
                <br />
                <p>
                    Although IOM applies reasonable means to ensure that the information you furnish is not used by third parties for purposes other than that for which it was provided, IOM cannot guarantee that any uploaded content or access will not be subject to inadvertent damage, corruption, loss, removal, or that unauthorized third parties will never be able to defeat security measures or use your personal information for improper purposes. You should be aware that no transmission of information via the internet is ever totally secure. Transmission is therefore at your own risk.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Online service providers</span>
            </div>

            <div class="text-content">
                <p>
                    While IOM has a strong interest in the integrity of its online service providers, particularly with a view towards their reliability and the protection of personal information, it does not provide any warranties and specifically disclaims any liability whatsoever in connection with services. IOM further disclaims any liability of any nature for any loss or damage howsoever caused in connection with using any of the services.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Electronic messages</span>
            </div>

            <div class="text-content">
                <p>
                    IOM attempts to respond to electronic messages sent to e-mail addresses listed on the website. However, IOM is under no obligation whatsoever to respond to any or all such messages.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Viewing</span>
            </div>

            <div class="text-content">
                <p>
                    The website is best viewed with Microsoft Edge and recent versions of Internet Explorer, Firefox, Safari and Chrome using 1152 x 720 pixels screen resolution and maximized browser window.
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Contact</span>
            </div>

            <div class="text-content">
                <p>If you have any questions or concerns about these terms and conditions please contact:</p>
                <br />
                <p>International Organization for Migration (IOM)</p>
                <p>Manila Administrative Centre, 28th Floor Citibank Tower,</p>
                <p>8741 Paseo de Roxas, 1226 Makati City, Philippines</p>
                <p>E-mail: info.lmpm-hospitality@iom.int</p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Notification of changes</span>
            </div>

            <div class="text-content">
                <p>IOM reserves the right to change these terms and conditions at any time, without notice.</p>
                <p>
                    IOM will post changes to these terms and conditions at: 
                    <a [href]="lmpmTermLink ? lmpmTermLink : currentTermLink">
                        {{ lmpmTermLink ? lmpmTermLink : currentTermLink }}
                    </a>
                </p>
            </div>
        </div>
        <div class="content-item">
            <div class="dialog-subHeader">
                <span>Miscellaneous</span>
            </div>

            <div class="text-content">
                <p>
                    Nothing in these terms and conditions affects the privileges and immunities enjoyed by IOM as an intergovernmental organization.
                </p>
            </div>
        </div>
    </div>
</div>
