import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiConstant, CustomHttpErrorResponse } from '../../types';
import { EMPLOYER_API_TOKEN, EvaluationRes, EvaluationSection, EvaluationTotal } from '../types';

const TRANSACTION_NAME = environment.elasticAPM.transactionName;

@Injectable({
  providedIn: 'root'
})
export class EmployerEvaluationService {

  constructor(@Inject(EMPLOYER_API_TOKEN) private apiConstant: ApiConstant, private httpClient: HttpClient) { }
  urlDefault = this.apiConstant.endpoint;

  errorsSub = new BehaviorSubject<any[]>([]);
  errors$ = this.errorsSub.asObservable();

  resEvaluationTotalSub = new BehaviorSubject<EvaluationTotal[]>([]);
  resEvaluationTotal$ = this.resEvaluationTotalSub.asObservable();

  resEvaluationSub = new BehaviorSubject<EvaluationSection[]>([]);
  resEvaluation$ = this.resEvaluationSub.asObservable();

  resDatetimeSub = new BehaviorSubject<{createdAt: string}>({createdAt: ''});
  resDatetime$ = this.resDatetimeSub.asObservable();

  getEvaluation(reponseId: string) {
    const headers = new HttpHeaders({ [TRANSACTION_NAME]: 'Get evaluation' });
    const url = `${this.urlDefault}/questionnaireRes/${reponseId}/evaluation`
    return this.httpClient.get<EvaluationRes>(url, {headers}).pipe(
        tap((res: EvaluationRes) => {
            this.resEvaluationTotalSub.next([res.response.evaluation.total]);
            this.resEvaluationSub.next(res.response.evaluation.sections);
            this.resDatetimeSub.next(res.response.questionnaireResponse || {createdAt: ''})
        }),
        catchError((err: CustomHttpErrorResponse) => {
            if (err.errorJson) {
                this.errorsSub.next(err.errorJson.message);
            } else {
                this.errorsSub.next([err.message]);
            }
            return EMPTY;
        }),
    );
  }
}
