import { CustomValidators } from './../../../shared/formly-fields/custom-validators';
import { TouchedErrorStateMatcher } from './../../../shared/validators/touched-error-state.matcher';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringValuePair } from '../../../core';
import { PraUserInfo } from '../../types';
@Component({
    selector: 'new-user-form',
    templateUrl: './new-user-form.component.html',
    styleUrls: ['./new-user-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUserFormComponent implements OnInit, OnDestroy {
    matcher = new TouchedErrorStateMatcher()
    @ViewChild('f', { static: true })
    formDirective: NgForm;

    @Input()
    errorMessages: string[];

    @Output()
    registerClicked = new EventEmitter<PraUserInfo>();

    form: FormGroup;

    filteredGeographies: StringValuePair[];

    private unsubscribed$ = new Subject();

    smallScreen$ = this.breakpointObserver
        .observe(['(max-width: 1200px)'])
        .pipe(map(observer => (observer.matches ? 'yes' : 'no')));

    constructor(private fb: FormBuilder, private breakpointObserver: BreakpointObserver) {}

    ngOnInit() {
        this.form = this.fb.group({
            fullName: new FormControl('', {
                validators: [Validators.required, Validators.maxLength(100)],
            }),
            agency: new FormControl('', {
                validators: [Validators.required],
            }),
            reemail: new FormControl('', {
                validators: [Validators.required, CustomValidators.emailType, this.emailCf.bind(this)],
            }),
            email: new FormControl('', {
                validators: [Validators.required, CustomValidators.emailType],
            }),
            licenseNumber: new FormControl('', {
                validators: [Validators.required],
            }),
        });
    }

    get agency() {
        return this.form.controls.agency as FormControl;
    }

    get email() {
        return this.form.controls.email as FormControl;
    }

    get fullName() {
        return this.form.controls.fullName as FormControl;
    }

    get reemail() {
        return this.form.controls.reemail as FormControl;
    }

    get licenseNumber() {
        return this.form.controls.licenseNumber as FormControl;
    }

    hasError(control: AbstractControl, errorName: string) {
        return control && (control.touched || control.dirty) && control.hasError(errorName);
    }

    registerUser() {
        if (this.form.valid) {
            this.registerClicked.emit({
                ...this.form.value,
            });
        }
    }

    resetForm() {
        this.formDirective.resetForm();
        this.form.reset();

        Object.keys(this.form.controls).forEach(key => this.form.controls[key].setErrors(null));
    }

    ngOnDestroy() {
        this.unsubscribed$.next();
        this.unsubscribed$.complete();
    }

    emailCf() {
        if (this.form !== null && this.form !== undefined) {
            const email = this.form.controls.email;
            const confirmEmail = this.form.controls.reemail;
            return email.value === confirmEmail.value ? null : { emailNotMatch: true };
        }
        return null;
    }

    emailChanged(value: string) {
        if (this.form !== null && this.form !== undefined) {
            const reemail = this.form.controls.reemail;
            if (reemail.value !== '' && value !== reemail.value) {
                this.form.controls.reemail.setErrors({ emailNotMatch: true });
            }
            if (reemail.value !== '' && value === reemail.value) {
                this.form.controls.reemail.setErrors(null);
            }
        }
    }
}
