import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../..';
import { PraDetails, Question } from '../../question-form';
import { CountryFlagPipe } from '../../shared/pipes/country-flag.pipe';
import { BadgeWidgetOptions } from '../../shared/widgets/badge-widget/badge-widget.types';
import { GridWidgetOptions } from '../../shared/widgets/grid-widget/grid-widget.types';
import {
    ListWidgetItem,
    ListWidgetItemValueType,
    ListWidgetOptions,
} from '../../shared/widgets/list-widget/list-widget.types';
import { ScopingReviewService } from '../services/scoping-review.service';

@Component({
    template: `
        <div class="page">
            <app-list-widget [options]="companyProfileOptions$ | async"></app-list-widget>
        </div>
        <div class="page">
            <worker-statistics [pdfPreview]="true" [listQuestion]="lstWorkerStatisticsQuestion$ | async"></worker-statistics>
            <app-grid-widget [options]="workderStatisticsOptions$ | async"></app-grid-widget>
        </div>
        <div class="page" *ngFor="let praOption of lstPraOptions$ | async">
            <div class="agency-layout">
                <app-list-widget [options]="praOption" [pdfPreview]="true" [isPraDetail]="true"></app-list-widget>
            </div>
        </div>
    `,
    styles: [
        `

        `,
    ],
    providers: [ScopingReviewService],
})
export class ViewScopingQuestionsPdfContainer implements OnInit, OnDestroy {
    @Input() scopingQuestionId = '';
    @Input() employerId = '';

    private unsubscribe$ = new Subject();

    lstWorkerStatisticsQuestion$ = this.scopingReviewService.lstWorkerStatisticsQuestion$.pipe(
        switchMap((workerStatistics: Question[]) => {
            const widgetItems = [];

            const pipe = new CountryFlagPipe();
            const lstCountryCode = workerStatistics.filter(x => x.controlType === 'drop-down-country' && !x.parentId);
            let lstCountryItem:any = [];
            let totalAgency = 0;
            lstCountryCode.forEach(ctr => {
                let i = 0;
                let object:any = {};
                let value_1 = 0;
                workerStatistics.forEach(item => {
                    if (item.parentId === ctr.id && item.value) {
                        if (i === 0) {
                           value_1 = item.value;
                        } else if (i === 1) {
                            totalAgency += item.value;
                        }
                        ++i;
                    }
                    if (i === 2) {
                        object['value'] = value_1;
                    }
                })
                if (ctr.value) {
                    const countryCode = pipe.getCountryCode(ctr.value)
                    const flag = pipe.transform(ctr.value);
                    object['htmlTemplate'] = `from&nbsp; ${flag}&nbsp;${ctr.value}`;
                    object['countryCode'] = countryCode;
                    object['countryName'] = ctr.value;
                    lstCountryItem.push(object);
                }
            })
            const widgetItemFirst: BadgeWidgetOptions = {
                title: 'Country of origin and number of migrant workers',
                lstHtml: lstCountryItem
            };

            const widgetlast: BadgeWidgetOptions = {
                title: 'Number of Recruitment Agencies involved',
                value: +totalAgency,
                description: 'Recruitment Agencies',
            };
            widgetItems.push(widgetlast);
            widgetItems.push(widgetItemFirst);
            return of(widgetItems);
        }),
    );

    companyProfileOptions$ = this.scopingReviewService.companyProfile$.pipe(
        tap((_: Question[]) => {

        }),
        switchMap((questions: Question[]) => {
            const companyName = 'Company Profile';
            const options: ListWidgetOptions = {
                title: companyName || '',
                data: questions.map(item => {
                    const widgetItem: ListWidgetItem = {
                        label: item.label,
                        value: item.value,
                        valueType: this.guessValueType(item.label),
                    };
                    return widgetItem;
                }),
            };
            return of(options);
        }),
    );
    workderStatisticsOptions$ = this.scopingReviewService.workerStatistics$.pipe(
        switchMap((workerStatistics: Question[]) => {
            const data = workerStatistics
                // .filter(stat => !!stat.value)
                .map(stat => {
                    const item = {
                        label: stat.label,
                        value: stat.value === '' || stat.value === null ? '0' : stat.value,
                    };
                    return item;
                });
            const opts: GridWidgetOptions = {
                labelHeader: 'Current number of workers at the hotel',
                columns: [
                    { name: 'Local' },
                    { name: 'Migrant' },
                    {
                        name: 'Total',
                        valueFn: (dataItem: any) => {
                            const keys = Object.keys(dataItem);
                            let total = 0;
                            keys.forEach(key => (total += parseInt(dataItem[key]) || 0));
                            return total;
                        },
                    },
                ],
                data: data,
            };
            return of(opts);
        }),
    );
    lstPraOptions$ = this.scopingReviewService.pras$.pipe(
        switchMap((pras: PraDetails[]) => {
            const praOptions: ListWidgetOptions[] = [];
            pras.forEach(praItem => {
                const questions = praItem.questions;
                const companyName = questions.find(question => question.code === 'emp_pra_scop_A1')?.value;
                const lstOfCountry = questions.filter(question => question.controlType === 'relationship' && question.value).map(q => q.value);
                const lstOfEmployed = questions.filter(question => question.controlType === 'employed' && question.value).map(q => q.value);
                let totalOfCountry = 0;
                let totalOfEmployed = 0;
                if (lstOfCountry && lstOfCountry.length > 0){
                    lstOfCountry.forEach(item => {
                        return totalOfCountry += item;
                    });
                }

                if (lstOfEmployed && lstOfEmployed.length > 0){
                    lstOfEmployed.forEach(item => {
                        return totalOfEmployed += item;
                    });
                }
                const lstNonDisplay = ['emp_pra_scop_A1', 'emp_pra_scop_A10', 'emp_pra_scop_A9_1', 'emp_pra_scop_A9_2',
                    'emp_pra_scop_A10_1', 'emp_pra_scop_A10_2', 'emp_pra_scop_A11_1', 'emp_pra_scop_A11_2']

                const options: ListWidgetOptions = {
                    title: companyName || '',
                    praStatus: praItem?.praStatus,
                    questionnaireResponseId: praItem?.questionnaireResponseId,
                    data: questions
                        .filter(question => lstNonDisplay.indexOf(question.code) < 0 )
                        .map(item => {
                            if(item.code === 'emp_pra_scop_A11') {
                                item.label = "Total number of migrant workers from the Country of Origin that are currently employed by the company"
                                item.value = totalOfEmployed
                            }
                            if(item.code === 'emp_pra_scop_A9') {
                                item.value = totalOfCountry
                            }
                            const widgetItem: ListWidgetItem = {
                                label: item.label,
                                value: this.tryGetValue(item.value, item.controlType),
                                valueType: this.guessValueType(item.label),
                                code: item.code,
                            };
                            return widgetItem;
                        }),
                };
                praOptions.push(options);
            });
            return of(praOptions);
        }),
    );
    role$: Observable<string>;
    role: string = '';

    constructor(private scopingReviewService: ScopingReviewService, private authService: AuthService,) {}

    ngOnInit() {
        this.role$ = this.authService.user$.pipe(
            filter(user => !!user),
            map(user => (user && user.role ? user.role.toString() : '')),
        );
        this.role$.subscribe(value => {
            this.role = value;
        });
        this.scopingReviewService.getResponse(this.employerId, this.scopingQuestionId, this.role).subscribe();
        // this.employerService.getQuestionnaireRes(this.employerId, this.scopingQuestionId).subscribe();
        // this.employerService.getCompanyProfile(this.employerId).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    guessValueType(itemLabel: string): ListWidgetItemValueType {
        const maybeEmail = (itemLabel || '').toLowerCase().indexOf('email') > -1;
        return maybeEmail ? 'email' : 'normal';
    }
    tryGetValue(value: any, controlType: string) {
        let val = 0;
        let flag = false;
        const questionValue = value && typeof value == 'object' ? (controlType == 'drop-down-multi'
                    ? this.handleDropdownMulti(value)
                    : Object.keys(value).map((item: any) => {
                        if (typeof value[item] == 'number') {
                            flag = true;
                            return val += +value[item];
                        }else {
                            return value[item];
                        }
                    }))
                : value;
        return flag ? val : questionValue;
    }
    handleDropdownMulti(value: any[]) {
        let isObject = false;
        value.forEach(item => {
            if (typeof item === "object") {
                isObject = true
            }
        })
        if (isObject) {
            return value.map((item: any) => item.key)
        } else {
            return value
        }
    }
}
