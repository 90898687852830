<div class="employer-button">
    <div class="container-wrap">
        <div class="group-button">
            <mat-card-actions>
                <button class="delete" type="button" name="save" mat-button aria-label="submit" (click)="delete()">
                    Delete
                </button>
                <button *ngIf="employerStatus === 'Active' || employerStatus === 'Deactivated'" [class]="employerStatus == 'Active' ? 'deactive' : 'active'" type="button" name="save" mat-button aria-label="submit" (click)="activeOrDeactive()">
                    {{employerStatus == 'Active' ? 'Deactivate' : 'Activate'}}
                </button>
            </mat-card-actions>
        </div>
    </div>
</div>
