import { TouchedErrorStateMatcher } from './../../validators/touched-error-state.matcher';
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as myGlobals from '../../../../globals';
import { CustomFormlyFieldConfig } from '../../types';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-freetext-input',
    templateUrl: './freetext-input.component.html',
    styleUrls: ['./freetext-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreetextInputComponent extends FieldType implements OnInit, OnDestroy {
    matcher = new TouchedErrorStateMatcher();
    lstDifferentEmail$ = myGlobals.isEmailDifferentHotelOrHotelGroup$;
    validateEmployerSaqA1$ = myGlobals.validateEmployerSaqA1$;
    showErr$ = new BehaviorSubject<boolean>(true);
    private unsubscribe$ = new Subject();
    private unsubscribeSAQA1$ = new Subject();

    ngOnInit() {
        if (this.field.formControl?.value) {
            this.formControl.markAsTouched();
        } else {
            this.formControl.markAsUntouched();
        }

        this.lstDifferentEmail$.pipe(takeUntil(this.unsubscribe$)).subscribe(item => {
            item.forEach(x => {
                if ((this.field as CustomFormlyFieldConfig).partialId === x.key && this.field.type === 'email') {
                    if (x.value) {
                        this.showErr$.next(false);
                        if (this.field.formControl?.hasError('differentEmail')) {
                            this.field?.formControl?.setErrors(null);
                            this.field.formControl?.markAsUntouched();
                        }
                    } else {
                        this.showErr$.next(true);
                    }
                }
            })

        })
        this.validateEmployerSaqA1$.pipe(takeUntil(this.unsubscribeSAQA1$)).subscribe(_value => {
            if ((this.field as CustomFormlyFieldConfig).key === 'employer_saq_A1') {
                if (!_value) {
                    this.showErr$.next(true);
                } else {
                    this.showErr$.next(false);
                }
                this.unsubscribeSAQA1$.next();
                this.unsubscribeSAQA1$.complete();
            }
        })
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribeSAQA1$.next();
        this.unsubscribeSAQA1$.complete();
    }

    onPercentChange($event: any) {
        if ((this.field as CustomFormlyFieldConfig).key === 'employer_saq_A1') {
            this.showErr$.next(false);
        }
        this.to.change?.(this.field, $event);
        myGlobals.valueChangeSub$.next({questionId: this.field.key, value: this.field.formControl?.value});
    }

    onFocus() {
        if (this.formControl.value === undefined) {
            this.formControl.setValue('');
        }
    }
}
