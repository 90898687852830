<!-- Layout: Normal -->
<div class="normal-layout" *ngIf="to.layout !== 'modern'">
    <label class="label-input-left">
        <span  *ngIf="to.symbol">{{ to.symbol }}</span>
        {{ to.label }}
    </label>
    <div *ngFor="let question of field.fieldGroup; let i = index; let last = last">
        <formly-field [field]="question"></formly-field>
    </div>

    <div class="group-question-item">
        <div class="group-question-item-question">
            <label class="label-input-left" *ngIf="to?.totalValues">Total:</label>
        </div>
        <div *ngFor="let totalValue of to.totalValues" class="group-question-item-question">
            <label class="label-input-left"> {{ totalValue }}</label>
            {{ field.fieldGroup | totalValueByAnswer: totalValue }}
        </div>
        <div class="group-question-item-question">
            <span class="label-input-left">Total:</span>{{ field.model | totalValue }}
        </div>
    </div>
</div>
<!-- Layout: Modern -->
<div class="modern-layout" *ngIf="to.layout === 'modern'">
</div>
