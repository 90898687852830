import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    template: `
        <div class="dialog-style-form">
            <mat-icon (click)="dialogRef.close()" [attr.aria-label]="'close dialog'">close</mat-icon>
            <div class="dialog-header">
                <span>{{ data.title }}</span>
            </div>
            <div class="dialog-subHeader">
                <span>{{ data.subTitle }}</span>
            </div>
            <div>
                <div>
                    <span class="text-content">
                        <a class="highlight">Definite Indicator -</a> indicates forced labour and/or exploitation;
                        requires remediation
                    </span>
                </div>
                <div class="style-form-content">
                    <span class="text-content">
                        <a class="highlight">Strong indicator -</a> signals an increased risk of forced labour or
                        exploitation, but further investigation may be needed to determine the context and any interplay
                        with other indicators in order to establish whether or not it rises to the level of forced
                        labour and exploitation, and thus requires remediation
                    </span>
                </div>
                <div>
                    <span class="text-content">
                        <a class="highlight">Possible -</a> if found alongside other indicators may warrant further
                        investigation
                    </span>
                </div>
            </div>
            <div class="border-bottom"></div>
            <div>
                <div>
                    <span class="text-content">
                        <a class="highlight">a) Risks related to recruitment or employment arrangements</a> Possible
                        indicator of forced labour and/or exploitation risks; this arrangement may enable systematic
                        exploitation, abuse of vulnerability, or non-compliance with applicable laws
                    </span>
                </div>
                <div style="margin-top: 24px;">
                    <span class="text-content">
                        <a class="highlight">(b) Risks related to gaps in management system</a> Possible indicator of
                        forced labour and/or exploitation risks; this gap may enable systematic exploitation, abuse of
                        vulnerability, or non-compliance with applicable laws
                    </span>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./classification-of-indicators.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationDialog {
    constructor(
        public dialogRef: MatDialogRef<ClassificationDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: { message: string; title?: string; subTitle?: string; color?: string },
    ) {}
}
