import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Notification, NotificationResult } from "src/app/modules/auth";

@Component({
    selector: 'app-last-notification',
    templateUrl: './last-notification.component.html',
    styleUrls: ['./last-notification.component.scss'],
})

export class LastNotificationComponent implements OnInit, OnChanges {
    @Input() notificationResult: NotificationResult;
    @Output() viewNotification = new EventEmitter<{notiId: string, type: string, employerId: string, id?: any}>();
    @Output() viewRequest = new EventEmitter<{notiId: string, type: string, data?: any, sender?: any}>();
    @Output() listResponses = new EventEmitter<Notification[]>();
    notifications: Notification[];    
    notifications$ = new BehaviorSubject<Notification[]>([]);

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(_changes: SimpleChanges) {
        if (this.notificationResult) {
            this.notifications = this.notificationResult.notifications;
            this.notifications = this.notifications.filter(x => 
                x.type !== 'onboarding' && 
                x.type !== 'hotel_reject_request_link_to_hotel_group' &&
                x.type !== 'hotel_group_reject_request_link_to_hotel' &&
                x.type !== 'myself_accept_request_link_to_hotel_group' &&
                x.type !== 'hotel_group_accept_request_link_to_hotel' &&
                x.type !== 'myself_accept_request_link_to_hotel' &&
                x.type !== 'hotel_accept_request_link_to_hotel_group' &&
                !x.isRead);
            if (this.notifications.length >= 3) {
                this.notifications.splice(2);
            }
            this.notifications$.next(this.notifications)
            const listResponses = this.notifications.filter(x => x.type === 'employer_scoping_submitted' || x.type === 'employer_saq_submitted' || x.type === 'recruitment_saq_submitted')
            this.listResponses.emit(listResponses)
        }
    }

    redirectNotification(notiId: string, type: string, employerId: string, id?: any) {
        this.viewNotification.emit({notiId: notiId, type: type, employerId: employerId, id: id})
    }

    redirectRequest(notiId: string, type: string, data?: any, sender?: any) {
        this.viewRequest.emit({notiId: notiId, type: type, data: data, sender: sender})
    }
}
