<!-- Layout: Normal -->
<div class="normal-layout" *ngIf="to.layout !== 'modern'">
    <div class="user-input">
        <label class="label-input-left" *ngIf="to?.label">
            <span  *ngIf="to.symbol">{{ to.symbol }}</span>
            {{ to?.label }}
            <strong style="color: red" *ngIf="to?.required">*</strong>
        </label>
        <div class="multi-select-dropdown-registration">
            <ng-multiselect-dropdown
                [formControl]="to.id"
                [placeholder]="'Please fill your answer'"
                [settings]="dropdownSettings"
                [data]="to.options"
                [formlyAttributes]="field"
                [formControl]="formControl"
                [(ngModel)]="to.value"
                [disabled]="to.disabled"
            >
            </ng-multiselect-dropdown>
            <mat-error *ngIf="formControl.touched">
                <formly-validation-message [field]="field"></formly-validation-message>
            </mat-error>
        </div>
    </div>
</div>
<!-- Layout: Modern -->
<div class="modern-layout" *ngIf="to.layout === 'modern'">
    <div class="user-input">
        <div class="top-label" *ngIf="to.symbol">
            <div class="symbol-input-left">
                <span>{{ to.symbol }}</span>
            </div>
            <div class="right-item" *ngIf="to?.required">
                <mat-label class="asterix--before"></mat-label>
            </div>
        </div>
        <label class="label-input-left" *ngIf="to?.label">
            {{ to?.label }}
        </label>
        <div class="multi-select-dropdown-registration" style="font-size: 14px !important;">
            <ng-multiselect-dropdown
                [formControl]="to.id"
                [placeholder]="'Please fill your answer'"
                [settings]="dropdownSettings"
                [data]="to.options"
                [formlyAttributes]="field"
                [formControl]="formControl"
                [(ngModel)]="field.defaultValue"
                [disabled]="to.disabled"
                (click)="dropDownSelect = true"
            >
            </ng-multiselect-dropdown>
            <mat-error *ngIf="dropDownSelect">
                <formly-validation-message [field]="field"></formly-validation-message>
            </mat-error>
        </div>
    </div>
</div>
