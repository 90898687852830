import { InjectionToken } from '@angular/core';
import { ApiConstant, } from '../types';

export const EMPLOYER_API_TOKEN = new InjectionToken<ApiConstant>('api.constant');

export interface EmployerUserInfo {
    companyName: string;
    email: string;
    fullName: string;
    website: string;
    location: string;
    address: string;
    relationshipWithBrand: string;
    isBrandResponsibility: boolean;
    questionnaireResponse?: any[];
    isSelfRegis?: boolean;
    identificationCode?: string;
}
export interface ForcePasswordChangeEmployer {
    newPassword: string;
    confirmPassword: string;
    language: number;
    time: string;
    sig: string;
}
export interface StringValueCountry {
    id: string;
    name: string;
}

export interface EmployerProfile {
    website: string;
    headquarters: string;
    location: string;
    address: string;
    nameContact: string;
    emailContact: string;
}

export interface CompanyProfileInfo {
    code: string;
    label: string;
    order: number;
    value: string;
    valueType: string;
}

export const DEFAULT_COMPANY_PROFILE: CompanyProfile = {
    name: '',
    info: [],
    status: '',
};

export interface CompanyProfile {
    info: CompanyProfileInfo[];
    name: string;
    status: string;
}

export interface EvaluationRes {
    message: string;
    response: responseEvaluation;
}

export interface responseEvaluation {
    evaluation: EvaluaSection;
    questionnaireResponse?: {createdAt: string}
}

export interface EvaluaSection {
    total: EvaluationTotal;
    sections: EvaluationSection[];
}

export interface EvaluationTotal {
    definite: number;
    strong: number;
    possible: number;
}

export interface EvaluationSection {
    name: string;
    ilos: Ilos[];
    oder: number
}

export interface Ilos {
    name: string;
    difinite: number;
    strong: number;
    possible: number
}
